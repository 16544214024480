function Degree(props) {
  return (
    <>
      <option value="无 other">无 other</option>
      <option value="初中 Junior School">初中 Junior School</option>
      <option value="高中 High school">高中 High school</option>
      <option value="大专 Vocational College">大专 Vocational College</option>
      <option value="本科 Bachelor's degree">本科 Bachelor's degree</option>
      <option value="硕士 Master's degree">硕士 Master's degree</option>
      <option value="中专 Vocational school">中专 Vocational school</option>
      <option value="本科在读 Undergraduate Student">
        本科在读 Undergraduate Student
      </option>
      <option value="硕士研究生在读 Postgraduate Student">
        硕士研究生在读 Postgraduate Student
      </option>
      <option value="非学历 Non-degree">非学历 Non-degree</option>
      <option value="小学 Primary School">小学 Primary School</option>
      <option value="高职 Vocational College">高职 Vocational College</option>
    </>
  );
}
export default Degree;
