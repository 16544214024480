function Degree2(props) {
  return (
    <>
      <option value="本科 Bachelor's degree">本科 Bachelor's degree</option>
      <option value="硕士 Master's degree">硕士 Master's degree</option>
      <option value="博士 Doctoral degree">博士 Doctoral degree</option>
      <option value="专科生 Associate Degree">专科生 Associate Degree</option>
      <option value="语言生 Chinese Language">语言生 Chinese Language</option>
    </>
  );
}
export default Degree2;
