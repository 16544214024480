import React, { useState } from "react";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import { useForm } from "react-hook-form";
import { password_change } from "../../services/actions/AuthAction";
import { connect } from "react-redux";

function Password(props) {
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (data.new !== data.confirm) {
      errors = { old: true };
    } else {
      props.password_change(data);
      reset();
    }
  };
  const checkHandler = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>
      <Navber />

      <div class="container py-5 my-5">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div class="row">
            <div class="col-lg-10">
              <p class="h1 mb-5">Change Your Password</p>
            </div>

            <div class="col-lg-6 mx-auto">
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
              {props.errMsg !== null && (
                <div class="alert alert-danger" role="alert">
                  {props.errMsg}
                </div>
              )}

              <div class="contact-area pt-5">
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Old Password</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="password"
                        class="form-control"
                        id="exampleFormControlInput133"
                        {...register("old", { required: true })}
                      />
                      {errors.old && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">New Password</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type={isChecked ? "text" : "password"}
                        class="form-control"
                        id="exampleFormControlInput12"
                        {...register("new", { required: true })}
                      />
                      {errors.new && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Confirm New Password</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type={isChecked ? "text" : "password"}
                        class="form-control"
                        id="exampleFormControlInput11111"
                        {...register("confirm", {
                          required: true,
                          validate: (val) => {
                            if (watch("new") != val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      {errors.confirm && (
                        <span>
                          This field is required And Password must be same.
                        </span>
                      )}
                      <br />
                      <span>
                        <input
                          type="checkbox"
                          name="nameee"
                          checked={isChecked}
                          onChange={checkHandler}
                        />
                        Show Password
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row pt-4">
                  <div class="col-md-12 text-end">
                    <div class=" ">
                      <button type="submit" class="btn-two">
                        Set Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    profile: state.auth.profile,
  };
};
const mapDispatchToProps = (dispatch) => ({
  password_change: (data) => dispatch(password_change(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Password);
