import React from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";

import {
  applicationTwo,
  applicationTwoData,
} from "../../services/actions/AuthAction";
import { Link, Navigate, useParams } from "react-router-dom";
import Country from "../helper/Country";
import ApplicationDataP from "../helper/ApplicationDataP";
import Industry from "../helper/Industry";
import Relation from "../helper/Relation";
import Occupation from "../helper/Occupation";
import Degree from "../helper/Degree";
import Marks from "../helper/Marks";
import CertificateType from "../helper/CertificateType";
import Hsk from "../helper/Hsk";
import SchoolType from "../helper/SchoolType";

class Test3 extends React.Component {
  constructor() {
    super();

    this.state = {
      sector: "",

      family: true,
      family_name: "",
      family_relation: "",
      family_institute: "",
      family_ins_type: "",
      family_occupation: "",
      family_phone: "",
      family_email: "",
      familyInfo: [],
      familyError: "",
      familyAdd: true,
      familyUpdate: false,
      familyItem: null,

      award: true,
      award_name: "",
      award_type: "",
      award_level: "",
      award_fields: "",
      award_time: "",
      awardInfo: [],
      awardError: "",
      awardAdd: true,
      awardUpdate: false,
      awardItem: null,
    };
  }
  //awards
  addAwards = () => {
    this.setState({ award: true });
    this.setState({ awardAdd: true });
    this.setState({ awardUpdate: false });
  };

  submitAwards = () => {
    const item = {
      award_name: this.state.award_name,
      award_type: this.state.award_type,
      award_level: this.state.award_level,
      award_fields: this.state.award_fields,
      award_time: this.state.award_time,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 5) {
      this.setState({ awardError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        awardInfo: [...prevState.awardInfo, item],
      }));
      this.setState({ awardError: "" });
      this.setState({ award: false });

      this.setState({ award_name: "" });
      this.setState({ award_type: "" });
      this.setState({ award_level: "" });
      this.setState({ award_fields: "" });
      this.setState({ award_time: "" });
    }
  };

  submitUpdateAwards = () => {
    const item = {
      award_name: this.state.award_name,
      award_type: this.state.award_type,
      award_level: this.state.award_level,
      award_fields: this.state.award_fields,
      award_time: this.state.award_time,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 5) {
      this.setState({ awardError: "All * mark fields are requird." });
    } else {
      const { awardInfo } = this.state;
      awardInfo[this.state.awardItem] = item;

      this.setState({
        awardInfo: awardInfo,
      });

      this.setState({ awardError: "" });
      this.setState({ award: false });

      this.setState({ award_name: "" });
      this.setState({ award_type: "" });
      this.setState({ award_level: "" });
      this.setState({ award_fields: "" });
      this.setState({ award_time: "" });
    }
  };

  editAwards(si) {
    this.setState({ awardAdd: false });
    this.setState({ awardUpdate: true });
    this.setState({ awardItem: si });
    this.setState({ award: true });

    const ii = this.state.awardInfo[si];

    this.setState({ award_name: ii.award_name });
    this.setState({ award_type: ii.award_type });
    this.setState({ award_level: ii.award_level });
    this.setState({ award_fields: ii.award_fields });
    this.setState({ award_time: ii.award_time });
  }
  deleteAwards(ix) {
    this.state.awardInfo.splice(ix, 1);
    this.setState({
      awardInfo: this.state.awardInfo,
    });
  }
  //end award
  //family
  addFamily = () => {
    this.setState({ family: true });
    this.setState({ familyAdd: true });
    this.setState({ familyUpdate: false });
  };

  submitFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,

      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        familyInfo: [...prevState.familyInfo, item],
      }));
      this.setState({ familyError: "" });
      this.setState({ family: false });
      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  submitUpdateFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,

      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      const { familyInfo } = this.state;
      familyInfo[this.state.familyItem] = item;

      this.setState({
        familyInfo: familyInfo,
      });

      this.setState({ familyError: "" });
      this.setState({ family: false });

      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });

      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  editFamily(si) {
    this.setState({ familyAdd: false });
    this.setState({ familyUpdate: true });
    this.setState({ familyItem: si });
    this.setState({ family: true });

    const ii = this.state.familyInfo[si];

    this.setState({ family_name: ii.family_name });
    this.setState({ family_relation: ii.family_relation });

    this.setState({ family_institute: ii.family_institute });
    this.setState({ family_ins_type: ii.family_ins_type });
    this.setState({ family_occupation: ii.family_occupation });
    this.setState({ family_phone: ii.family_phone });
    this.setState({ family_email: ii.family_email });
  }
  deleteFamily(ix) {
    this.state.familyInfo.splice(ix, 1);
    this.setState({
      familyInfo: this.state.familyInfo,
    });
  }
  //end family

  checkHandler = (a) => {
    this.setState({ sector: a });
  };

  onChangeData = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmitDetails = (e) => {
    e.preventDefault();
  };
  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    var id = 1;
    return (
      <>
        <UserHeader />
        <div class="">
          <div class="container">
            <div class="row">
              <ApplicationDataP post={id} />
            </div>
            <div class="row mb-5">
              <div class="col-lg-12">
                <p class="display-3 mb-5 fsw-bold">
                  <span class="cor-1"> Other </span> Information
                </p>

                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <input type="hidden" name="user_id" value={data.id} />

              <div class="row application">
                <div class="col-lg-12">
                  <p class="h3 mb-4">Applicant Video</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Video</div>
                    </div>
                    <div class="col-md-8">
                      <input type="file" class="form-control" name="ielts" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <p class="h3 mb-4">Nationality Background</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Are You Immigrend</div>
                    </div>
                    <div class="col-md-8">
                      <input type="radio" name="ielts" />
                      Yes
                      <input type="radio" name="ielts" />
                      No
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">origin Country Of Region</div>
                    </div>
                    <div class="col-md-8">
                      <select class="form-control">
                        <option value="">Select Country</option>
                        <Country />
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Whether one or both parants are Chines citizen?
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input type="radio" name="ielts" />
                      Yes
                      <input type="radio" name="ielts" />
                      No
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">When did you current citizenship?</div>
                    </div>
                    <div class="col-md-8">
                      <input type="date" class="form-control" name="ielts" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="h3">
                    If applicant age under 18 years old Please add guardian
                    information{" "}
                  </p>
                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name</td>
                      <td>Relation</td>
                      <td>Work Place</td>
                      <td>Occupation</td>
                      <td>Mobile</td>
                      <td>Email</td>

                      <td>
                        <span onClick={this.addFamily}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.familyInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.family_name}</td>
                            <td>{s.family_relation}</td>

                            <td>{s.family_institute}</td>
                            <td>{s.family_occupation}</td>
                            <td>{s.family_phone}</td>
                            <td>{s.family_email}</td>

                            <td>
                              <span onClick={() => this.editFamily(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteFamily(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.family === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="bg">Name</div>

                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_name"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_name}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Relationship</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_relation"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_relation}
                        >
                          <option value="">Select One</option>
                          <Relation />
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Institute/Employer</div>

                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_institute"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_institute}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Industry Type</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_ins_type"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_ins_type}
                        >
                          <Industry />
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Occupation</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_occupation"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_occupation}
                        >
                          <Occupation />
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Phone</div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_phone"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_phone}
                        />
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Email</div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_email"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_email}
                        />
                      </div>
                      <p>{this.state.familyError}</p>
                      {this.state.familyAdd === true && (
                        <span
                          onClick={this.submitFamily}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.familyUpdate === true && (
                        <span
                          onClick={this.submitUpdateFamily}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div class="col-lg-12 my-5">
                  <p class="h3">Awards Information</p>
                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name of Awards</td>
                      <td>Rewards Type</td>
                      <td>Awards level</td>
                      <td>Rewards Area</td>
                      <td>Rewards Time</td>
                      <td>
                        <span onClick={this.addAwards}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.awardInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.award_name}</td>
                            <td>{s.award_type}</td>
                            <td>{s.award_level}</td>
                            <td>{s.award_fields}</td>
                            <td>{s.award_time}</td>
                            <td>
                              <span onClick={() => this.editAwards(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteAwards(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.award === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="bg">Awards Name</div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="award_name"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_name}
                        />
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Awards Type</div>
                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="award_type"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_type}
                        >
                          <option value="">Select One</option>
                          <option value="Nationwide">Nationwide</option>
                          <option value="Regional">Regional</option>
                          <option value="School-level">School-level</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Awards Level</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="award_level"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_level}
                        >
                          <option value="">Select One</option>
                          <option value="First class">First class</option>
                          <option value="Second class">Second class</option>
                          <option value="Third class">Third class</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Awards Fields</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="award_fields"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_fields}
                        >
                          <option value="">Select One</option>
                          <option value="Academic">Academic</option>
                          <option value="Athletic">Athletic</option>
                          <option value="Arts">Arts</option>
                          <option value="Morality">Morality</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Awards Time</div>

                      <div class="mt-4 ">
                        <input
                          type="date"
                          class="form-control"
                          name="award_time"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_time}
                        />
                      </div>
                      <p>{this.state.awardError}</p>
                      {this.state.awardAdd === true && (
                        <span
                          onClick={this.submitAwards}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.awardUpdate === true && (
                        <span
                          onClick={this.submitUpdateAwards}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div class="col-lg-12">
                  <p class="h3 mb-4">Other Information</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">CSC/OS NO</div>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" name="ielts" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Hobbis</div>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" name="ielts" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Smoking</div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="gender"
                        required="required"
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">You Have non-criminal records?</div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="gender"
                        required="required"
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Are there any special Diseases</div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="gender"
                        required="required"
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">You Have Commited a crime</div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="gender"
                        required="required"
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">You Have Physical examination from?</div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="gender"
                        required="required"
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>

                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
                <div class="col-lg-12 text-center my-5">
                  <Link to={"/application-one/" + id} class="btn-one">
                    Back
                  </Link>
                  <button type="submit" class="btn-one">
                    save
                  </button>
                  {this.props.steptwo !== null ? (
                    <Link to={"/application-three/" + id} class="btn-one">
                      Next
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    steptwo: state.auth.steptwo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationTwo: (data, ii) => dispatch(applicationTwo(data, ii)),
  applicationTwoData: (data, post) => dispatch(applicationTwoData(data, post)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Test3);
