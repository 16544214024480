import React from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";

import {
  applicationTwo,
  applicationTwoData,
  applyThreeData,
  applyThreeDataGet,
} from "../../services/actions/AuthAction";
import { Link } from "react-router-dom";
import Country from "../helper/Country";

import Industry from "../helper/Industry";
import Relation from "../helper/Relation";
import Occupation from "../helper/Occupation";
import ApplyData from "../helper/ApplyData";
import Ber from "../helper/Ber";

class ApplyThree extends React.Component {
  constructor() {
    super();

    this.state = {
      sector: "",
      two: 1,
      family: false,
      family_name: "",
      family_relation: "",
      family_institute: "",
      family_ins_type: "",
      family_occupation: "",
      family_phone: "",
      family_email: "",
      familyInfo: [],
      familyError: "",
      familyAdd: true,
      familyUpdate: false,
      familyItem: null,

      award: false,
      award_name: "",
      award_type: "",
      award_level: "",
      award_fields: "",
      award_time: "",
      awardInfo: [],
      awardError: "",
      awardAdd: true,
      awardUpdate: false,
      awardItem: null,

      video: "",
      are_you_immigrend: "",
      collect: "",
      details: "",
      original_country: "",
      parants_are_chines: "",
      current_citizenship: "",

      csc: "",
      hobbis: "",
      smoking: "",
      criminal_recoreds: "",
      special_disease: "",
      crime: "",
      physical_exam: "",
    };
  }
  //awards
  addAwards = () => {
    this.setState({ award: true });
    this.setState({ awardAdd: true });
    this.setState({ awardUpdate: false });
  };
  cancleAwards = () => {
    this.setState({ award: false });
    this.setState({ awardAdd: false });
    this.setState({ awardUpdate: false });
    this.setState({ award_name: "" });
    this.setState({ award_type: "" });
    this.setState({ award_level: "" });
    this.setState({ award_fields: "" });
    this.setState({ award_time: "" });
  };
  submitAwards = () => {
    const item = {
      award_name: this.state.award_name,
      award_type: this.state.award_type,
      award_level: this.state.award_level,
      award_fields: this.state.award_fields,
      award_time: this.state.award_time,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 5) {
      this.setState({ awardError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        awardInfo: [...prevState.awardInfo, item],
      }));
      this.setState({ awardError: "" });
      this.setState({ award: false });

      this.setState({ award_name: "" });
      this.setState({ award_type: "" });
      this.setState({ award_level: "" });
      this.setState({ award_fields: "" });
      this.setState({ award_time: "" });
    }
  };

  submitUpdateAwards = () => {
    const item = {
      award_name: this.state.award_name,
      award_type: this.state.award_type,
      award_level: this.state.award_level,
      award_fields: this.state.award_fields,
      award_time: this.state.award_time,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 5) {
      this.setState({ awardError: "All * mark fields are requird." });
    } else {
      const { awardInfo } = this.state;
      awardInfo[this.state.awardItem] = item;

      this.setState({
        awardInfo: awardInfo,
      });

      this.setState({ awardError: "" });
      this.setState({ award: false });

      this.setState({ award_name: "" });
      this.setState({ award_type: "" });
      this.setState({ award_level: "" });
      this.setState({ award_fields: "" });
      this.setState({ award_time: "" });
    }
  };

  editAwards(si) {
    this.setState({ awardAdd: false });
    this.setState({ awardUpdate: true });
    this.setState({ awardItem: si });
    this.setState({ award: true });

    const ii = this.state.awardInfo[si];

    this.setState({ award_name: ii.award_name });
    this.setState({ award_type: ii.award_type });
    this.setState({ award_level: ii.award_level });
    this.setState({ award_fields: ii.award_fields });
    this.setState({ award_time: ii.award_time });
  }
  deleteAwards(ix) {
    this.state.awardInfo.splice(ix, 1);
    this.setState({
      awardInfo: this.state.awardInfo,
    });
  }
  //end award
  //family
  addFamily = () => {
    this.setState({ family: true });
    this.setState({ familyAdd: true });
    this.setState({ familyUpdate: false });
  };
  cancleFamily = () => {
    this.setState({ family: false });
    this.setState({ familyAdd: false });
    this.setState({ familyUpdate: false });
    this.setState({ family_name: "" });
    this.setState({ family_relation: "" });
    this.setState({ family_institute: "" });
    this.setState({ family_ins_type: "" });
    this.setState({ family_occupation: "" });
    this.setState({ family_phone: "" });
    this.setState({ family_email: "" });
  };

  submitFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,

      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        familyInfo: [...prevState.familyInfo, item],
      }));
      this.setState({ familyError: "" });
      this.setState({ family: false });
      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  submitUpdateFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,

      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      const { familyInfo } = this.state;
      familyInfo[this.state.familyItem] = item;

      this.setState({
        familyInfo: familyInfo,
      });

      this.setState({ familyError: "" });
      this.setState({ family: false });
      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  editFamily(si) {
    this.setState({ familyAdd: false });
    this.setState({ familyUpdate: true });
    this.setState({ familyItem: si });
    this.setState({ family: true });

    const ii = this.state.familyInfo[si];

    this.setState({ family_name: ii.family_name });
    this.setState({ family_relation: ii.family_relation });
    this.setState({ family_institute: ii.family_institute });
    this.setState({ family_ins_type: ii.family_ins_type });
    this.setState({ family_occupation: ii.family_occupation });
    this.setState({ family_phone: ii.family_phone });
    this.setState({ family_email: ii.family_email });
  }
  deleteFamily(ix) {
    this.state.familyInfo.splice(ix, 1);
    this.setState({
      familyInfo: this.state.familyInfo,
    });
  }
  //end family

  checkHandler = (a) => {
    this.setState({ sector: a });
  };

  onChangeData = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState({ [name]: checked });
    } else if (type === "file") {
      this.setState({ video: e.target.files[0] });
    } else if (type === "radio") {
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  onSubmitDetails = (e) => {
    e.preventDefault();

    this.setState({ two: 2 });

    const formData2 = new FormData();
    formData2.append("photo", this.state.video);

    const formData = {
      are_you_immigrend: this.state.are_you_immigrend,
      collect: this.state.collect,
      details: this.state.details,
      original_country: this.state.original_country,
      parants_are_chines: this.state.parants_are_chines,
      current_citizenship: this.state.current_citizenship,

      csc: this.state.csc,
      hobbis: this.state.hobbis,
      smoking: this.state.smoking,
      criminal_recoreds: this.state.criminal_recoreds,
      special_disease: this.state.special_disease,
      crime: this.state.crime,
      physical_exam: this.state.physical_exam,

      familyInfo: this.state.familyInfo,
      awardInfo: this.state.awardInfo,
    };

    formData2.append("data", JSON.stringify(formData));

    // const fileSize9 = this.state.video.size / 1024 / 1024;
    // if (fileSize9 > 1) {
    // }

    this.props.applyThreeData(window.location.href.split("/")[5], formData2);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.applyThreeDataGet(window.location.href.split("/")[5]);
  }
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.are_you_immigrend !== this.props.are_you_immigrend) {
      this.setState({ are_you_immigrend: this.props.are_you_immigrend });
    }
    if (previousProps.collect !== this.props.collect) {
      this.setState({ collect: this.props.collect });
    }
    if (previousProps.details !== this.props.details) {
      this.setState({ details: this.props.details });
    }
    if (previousProps.original_country !== this.props.original_country) {
      this.setState({ original_country: this.props.original_country });
    }
    if (previousProps.parants_are_chines !== this.props.parants_are_chines) {
      this.setState({ parants_are_chines: this.props.parants_are_chines });
    }
    if (previousProps.current_citizenship !== this.props.current_citizenship) {
      this.setState({ current_citizenship: this.props.current_citizenship });
    }
    if (previousProps.csc !== this.props.csc) {
      this.setState({ csc: this.props.csc });
    }
    if (previousProps.hobbis !== this.props.hobbis) {
      this.setState({ hobbis: this.props.hobbis });
    }
    if (previousProps.smoking !== this.props.smoking) {
      this.setState({ smoking: this.props.smoking });
    }
    if (previousProps.criminal_recoreds !== this.props.criminal_recoreds) {
      this.setState({ criminal_recoreds: this.props.criminal_recoreds });
    }
    if (previousProps.special_disease !== this.props.special_disease) {
      this.setState({ special_disease: this.props.special_disease });
    }
    if (previousProps.special_disease !== this.props.special_disease) {
      this.setState({ special_disease: this.props.special_disease });
    }
    if (previousProps.crime !== this.props.crime) {
      this.setState({ crime: this.props.crime });
    }
    if (previousProps.physical_exam !== this.props.physical_exam) {
      this.setState({ physical_exam: this.props.physical_exam });
    }
    if (previousProps.video !== this.props.video) {
      this.setState({ video: this.props.video });
    }
    if (previousProps.familyData !== this.props.familyData) {
      this.setState({ familyInfo: this.props.familyData });
    }
    if (previousProps.award !== this.props.award) {
      this.setState({ awardInfo: this.props.award });
    }
  }
  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    var id = window.location.href.split("/")[5];
    var url = window.location.href.split("/");
    return (
      <>
        <UserHeader />
        <div class="">
          <div class="container">
            <div class="row">
              <ApplyData pageType="newtable" data={id} />
            </div>
            <Ber page="three" />
            <div class="row mb-5">
              <div class="col-lg-12">
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <input type="hidden" name="user_id" value={data.id} />

              <div class="row application">
                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    申请人视频 <br />
                    Applicant's Video
                  </p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        自我介绍视频 Introduce yourself video Introduce yourself
                        video* at least two min
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="file"
                        class="form-control"
                        name="video"
                        accept="video/*"
                        onChange={this.onChangeData}
                        defaultValue={this.state.video}
                      />
                      <span>
                        The uploaded file type needs to be
                        *.mp4，*.rmvb,*.avi，*.mov，*.mkv，*.wmv Maximum video
                        size 50M{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    通知书接收方式 <br />
                    How to Collect the Admission Notice ?
                    <span className="colors star">*</span>
                  </p>
                </div>
                <div class="col-lg-12">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <input
                        type="radio"
                        name="collect"
                        required
                        onChange={this.onChangeData}
                        value="The same as home address"
                        checked={
                          this.state.collect === "The same as home address"
                        }
                      />
                      与本国家庭通讯地址相同 The same as home address
                    </div>
                    <div class="col-md-4">
                      <input
                        type="radio"
                        name="collect"
                        required
                        onChange={this.onChangeData}
                        value="The same as the current postral address"
                        checked={
                          this.state.collect ===
                          "The same as the current postral address"
                        }
                      />
                      与申请人当前联系方式相同 The same as the current postal
                      address
                    </div>
                    <div class="col-md-4">
                      <input
                        type="radio"
                        name="collect"
                        required
                        onChange={this.onChangeData}
                        value="Pick up by myself"
                        checked={this.state.collect === "Pick up by myself"}
                      />
                      自取 I will pick up by myself
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">收件人地址 Pickup Details Address</div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        name="details"
                        onChange={this.onChangeData}
                        defaultValue={this.state.details}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    国籍背景申报 <br />
                    Nationality Background Declaration
                  </p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        申请者是否为移民 Are you an immigrant?
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="radio"
                        required="required"
                        name="are_you_immigrend"
                        onChange={this.onChangeData}
                        value="Yes"
                        checked={this.state.are_you_immigrend === "Yes"}
                      />
                      Yes
                      <input
                        type="radio"
                        required="required"
                        name="are_you_immigrend"
                        onChange={this.onChangeData}
                        value="No"
                        checked={this.state.are_you_immigrend === "No"}
                      />
                      No
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        原来自国家或地区 Original Country or Region
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        required="required"
                        name="original_country"
                        onChange={this.onChangeData}
                        value={this.state.original_country}
                      >
                        <option value="">Select Country</option>
                        <Country />
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        父母双方或一方是否为中国公民 Whether one or both parents
                        are Chinese citizen?
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="radio"
                        required="required"
                        name="parants_are_chines"
                        onChange={this.onChangeData}
                        value="Yes"
                        checked={this.state.parants_are_chines === "Yes"}
                      />
                      Yes
                      <input
                        type="radio"
                        required="required"
                        name="parants_are_chines"
                        onChange={this.onChangeData}
                        value="No"
                        checked={this.state.parants_are_chines === "No"}
                      />
                      No
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        何时取得当前国籍 When did you get the current
                        citizenship?
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="date"
                        class="form-control"
                        required="required"
                        name="current_citizenship"
                        onChange={this.onChangeData}
                        defaultValue={this.state.current_citizenship}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    监护人信息（仅针对18岁以下学生）
                    <br />
                    Guardian Information (for applicants age below 18 years old)
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">姓名 Name</th>
                        <th scope="col">关系 Relationship</th>
                        <th scope="col">单位名称 Work Place</th>
                        <th scope="col">职业 Occupation </th>
                        <th scope="col">联系电话 Phone/Mobile</th>
                        <th scope="col">联系邮箱 Email</th>

                        <th scope="col">
                          <span className="addbtn" onClick={this.addFamily}>
                            <i class="fa-solid fa-plus"></i> 添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.familyInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.family_name}</td>
                            <td>{s.family_relation}</td>

                            <td>{s.family_institute}</td>
                            <td>{s.family_occupation}</td>
                            <td>{s.family_phone}</td>
                            <td>{s.family_email}</td>

                            <td>
                              <span onClick={() => this.editFamily(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                              </span>
                              <span onClick={() => this.deleteFamily(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.family === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="bg">
                        姓名 Name<span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_name"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_name}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        关系 Relationship<span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_relation"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.family_relation}
                        >
                          <option value="">Select One</option>
                          <Relation />
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        单位名称 Work Place
                        <span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_institute"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_institute}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        行业类型 Industry Type
                        <span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_ins_type"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.family_ins_type}
                        >
                          <Industry />
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        职业 Occupation <span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_occupation"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.family_occupation}
                        >
                          <Occupation />
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        联系电话 Phone/Mobile
                        <span className="colors star">*</span>
                      </div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_phone"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_phone}
                        />
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        联系邮箱 Email<span className="colors star">*</span>
                      </div>
                      <div class="mt-4 ">
                        <input
                          type="email"
                          class="form-control"
                          name="family_email"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_email}
                        />
                      </div>
                      <p>{this.state.familyError}</p>
                      {this.state.familyAdd === true && (
                        <span
                          onClick={this.submitFamily}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.familyUpdate === true && (
                        <span
                          onClick={this.submitUpdateFamily}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i> Update
                        </span>
                      )}
                      <span
                        onClick={this.cancleFamily}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}

                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    奖励信息 <br />
                    Award Information
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">奖励名称 Name of award</th>
                        <th scope="col">奖励性质 Reward type </th>
                        <th scope="col">奖励等级 Award Level</th>
                        <th scope="col">奖励领域 Reward areas </th>
                        <th scope="col">奖励时间 Reward time</th>
                        <th scope="col">
                          <span className="addbtn" onClick={this.addAwards}>
                            <i class="fa-solid fa-plus"></i> 添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.awardInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.award_name}</td>
                            <td>{s.award_type}</td>
                            <td>{s.award_level}</td>
                            <td>{s.award_fields}</td>
                            <td>{s.award_time}</td>
                            <td>
                              <span onClick={() => this.editAwards(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                              </span>
                              <span onClick={() => this.deleteAwards(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.award === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="bg">
                        奖励名称 Name of award
                        <span className="colors star">*</span>
                      </div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="award_name"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_name}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        奖励性质 Reward type{" "}
                        <span className="colors star">*</span>
                      </div>
                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="award_type"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.award_type}
                        >
                          <option value="">请选择 Please Choose</option>
                          <option value="全国性 Nationwide">
                            全国性 Nationwide
                          </option>
                          <option value="地区类 Regional">
                            地区类 Regional
                          </option>
                          <option value="学校类 School-level">
                            学校类 School-level
                          </option>
                          <option value="其它 Others">其它 Others</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        奖励等级 Award Level
                        <span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="award_level"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.award_level}
                        >
                          <option value=""> 请选择 Please Choose</option>
                          <option value="一等 First class">
                            一等 First class
                          </option>
                          <option value="二等 Second class">
                            二等 Second class
                          </option>
                          <option value="三等 Third class">
                            三等 Third class
                          </option>
                          <option value="其它 Others">其它 Others</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        奖励领域 Reward areas{" "}
                        <span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="award_fields"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.award_fields}
                        >
                          <option value=""> 请选择 Please Choose</option>
                          <option value="学习类 Academic">
                            学习类 Academic
                          </option>
                          <option value="体育类 Athletic">
                            体育类 Athletic
                          </option>
                          <option value="文艺类 Arts">文艺类 Arts</option>
                          <option value="品德类 Morality">
                            品德类 Morality
                          </option>
                          <option value="其它 Others">其它 Others</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        奖励时间 Reward time
                        <span className="colors star">*</span>
                      </div>

                      <div class="mt-4 ">
                        <input
                          type="date"
                          class="form-control"
                          name="award_time"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.award_time}
                        />
                      </div>
                      <p>{this.state.awardError}</p>
                      {this.state.awardAdd === true && (
                        <span
                          onClick={this.submitAwards}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.awardUpdate === true && (
                        <span
                          onClick={this.submitUpdateAwards}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update
                        </span>
                      )}
                      <span
                        onClick={this.cancleAwards}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}

                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    其它信息 <br /> Other Information
                  </p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">编号 CSC/CIS NO</div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        name="csc"
                        onChange={this.onChangeData}
                        defaultValue={this.state.csc}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">兴趣爱好 Hobbies</div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        name="hobbis"
                        onChange={this.onChangeData}
                        defaultValue={this.state.hobbis}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">是否吸烟 Smoking</div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="smoking"
                        onChange={this.onChangeData}
                        value={this.state.smoking}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        是否有无犯罪证明Do you have Certificate of Non- criminal
                        records?
                      </div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="criminal_recoreds"
                        onChange={this.onChangeData}
                        value={this.state.criminal_recoreds}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        是否有特殊疾病 Are there any particular diseases?
                      </div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="special_disease"
                        onChange={this.onChangeData}
                        value={this.state.special_disease}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        是否曾有过犯罪史 Have you ever committed a crime?
                      </div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="crime"
                        onChange={this.onChangeData}
                        value={this.state.crime}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        是否有体检表Do you have physical examination form?
                      </div>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        name="physical_exam"
                        onChange={this.onChangeData}
                        value={this.state.physical_exam}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                </div>

                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
                {this.props.loader === true && (
                  <div class="alert alert-danger" role="alert">
                    We are working Please wait.
                  </div>
                )}
                <div class="col-lg-12 text-center my-5">
                  <Link
                    to={
                      `/apply-two/` +
                      url[4] +
                      "/" +
                      url[5] +
                      "/" +
                      data.type +
                      "/" +
                      url[7]
                    }
                    class="btn-one"
                  >
                    上一步 Previous
                  </Link>
                  {this.props.loader === false &&
                    this.props.appStatus == "0" && (
                      <button type="submit" class="btn-one">
                        保存 Save
                      </button>
                    )}
                  {/* {this.props.steptwo > 0 ? ( */}
                  {this.state.two === 2 ? (
                    <Link
                      to={
                        `/apply-four/` +
                        url[4] +
                        "/" +
                        url[5] +
                        "/" +
                        data.type +
                        "/" +
                        url[7]
                      }
                      class="btn-one"
                    >
                      下一步 Next
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    steptwo: state.auth.insertID,

    appStatus: state.auth.datastatus,

    are_you_immigrend: state.auth.are_you_immigrend,
    collect: state.auth.collect,
    details: state.auth.details,
    original_country: state.auth.original_country,
    parants_are_chines: state.auth.parants_are_chines,
    current_citizenship: state.auth.current_citizenship,
    csc: state.auth.csc,
    hobbis: state.auth.hobbis,
    smoking: state.auth.smoking,
    criminal_recoreds: state.auth.criminal_recoreds,
    special_disease: state.auth.special_disease,
    crime: state.auth.crime,
    physical_exam: state.auth.physical_exam,
    video: state.auth.video,

    familyData: state.auth.familyData,
    award: state.auth.award,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationTwo: (data, ii) => dispatch(applicationTwo(data, ii)),
  applicationTwoData: (data, post) => dispatch(applicationTwoData(data, post)),
  applyThreeData: (data, post) => dispatch(applyThreeData(data, post)),
  applyThreeDataGet: (post) => dispatch(applyThreeDataGet(post)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ApplyThree);
