import React, { useEffect, useState } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import ApplyData from "../helper/ApplyData";
import {
  applicationThree,
  applicationThreeData,
  applyFourData,
  applyFourDataGet,
  applyFourDataDelete,
} from "../../services/actions/AuthAction";
import { Link } from "react-router-dom";

class Test4 extends React.Component {
  constructor() {
    super();

    this.state = {
      err: [],
      valid_ordinary_passport: [],
      valid_ordinary_passport_url: [],

      original_higtest_diploma: [],
      original_higtest_diploma_url: [],

      original_transcript: [],
      original_transcript_url: [],

      written_resume: [],
      written_resume_url: [],

      physical_record: [],
      physical_record_url: [],

      criminal_record: [],
      criminal_record_url: [],

      recommendation_letter: [],
      recommendation_letter_url: [],

      study_plan: [],
      study_plan_url: [],

      research_achievement: [],
      research_achievement_url: [],

      language_proficiency: [],
      language_proficiency_url: [],

      other: [],
      other_url: [],

      visa_page: [],
      visa_page_url: [],
      bank: [],
      bank_url: [],
      current_university: [],
      current_university_url: [],
      vaccine: [],
      vaccine_url: [],
    };
  }

  onChangeData = (e) => {
    const error = [];
    const urls = [];
    const fi = [];

    const { name, value, type, checked } = e.target;
    if (type === "file") {
      const files = e.target.files;

      if (files.length <= 3) {
        // urls.push(URL.createObjectURL(files[0]));
        fi.push(files[0]);
        const fileSize = files[0].size / 1024 / 1024;
        if (fileSize > 1) {
          error.push(
            files[0].name +
              " file size is big, Please upload less then 1 mb file at valid ordinary passport section"
          );
        }
      } else {
        error.push("Only 3 file allow.");
      }

      if (error.length > 0) {
        this.setState({ err: error });
        e.target.value = null;
      } else {
        this.setState({ err: [] });
        this.state[name].push(files[0]);
        // this.state[name + `_url`].push(urls[0]);
      }
    }
  };
  valid_ordinary_passport(data, type, i) {
    console.log(type);
    if (type === "local") {
      this.state[data + `_url`].splice(i, 1);
      this.state[data].splice(i, 1);
      this.setState({
        [data + `_url`]: this.state[data + `_url`],
      });
    } else {
      this.state[data + `_url`].splice(i, 1);
      this.state[data].splice(i, 1);
      this.setState({
        [data + `_url`]: this.state[data + `_url`],
      });

      this.props.applyFourDataDelete(
        window.location.href.split("/")[4],
        data,
        type
      );
    }
  }

  onSubmitDetails = (e) => {
    e.preventDefault();
    const formData = new FormData();

    this.state.valid_ordinary_passport.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`valid_ordinary_passport[]`, file, file.name);
      }
    });

    this.state.original_higtest_diploma.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`original_higtest_diploma[]`, file, file.name);
      }
    });
    this.state.original_transcript.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`original_transcript[]`, file, file.name);
      }
    });
    this.state.written_resume.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`written_resume[]`, file, file.name);
      }
    });
    this.state.physical_record.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`physical_record[]`, file, file.name);
      }
    });
    this.state.criminal_record.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`criminal_record[]`, file, file.name);
      }
    });
    this.state.recommendation_letter.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`recommendation_letter[]`, file, file.name);
      }
    });
    this.state.study_plan.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`study_plan[]`, file, file.name);
      }
    });
    this.state.research_achievement.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`research_achievement[]`, file, file.name);
      }
    });
    this.state.language_proficiency.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`language_proficiency[]`, file, file.name);
      }
    });
    this.state.other.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`other[]`, file, file.name);
      }
    });
    this.state.visa_page.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`visa_page[]`, file, file.name);
      }
    });
    this.state.bank.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`bank[]`, file, file.name);
      }
    });
    this.state.current_university.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`current_university[]`, file, file.name);
      }
    });
    this.state.vaccine.forEach((file, i) => {
      if (file.name !== undefined) {
        formData.append(`vaccine[]`, file, file.name);
      }
    });

    this.props.applyFourData(window.location.href.split("/")[4], formData);
  };

  componentDidMount() {
    this.props.applyFourDataGet(window.location.href.split("/")[4]);
  }

  componentDidUpdate(previousProps, previousState) {
    if (
      previousProps.valid_ordinary_passport !==
      this.props.valid_ordinary_passport
    ) {
      this.setState({
        valid_ordinary_passport: JSON.parse(this.props.valid_ordinary_passport),
      });
    }
    if (
      previousProps.valid_ordinary_passport_url !==
      this.props.valid_ordinary_passport_url
    ) {
      this.setState({
        valid_ordinary_passport_url: JSON.parse(
          this.props.valid_ordinary_passport_url
        ),
      });
    }

    if (
      previousProps.original_higtest_diploma !==
      this.props.original_higtest_diploma
    ) {
      this.setState({
        original_higtest_diploma: JSON.parse(
          this.props.original_higtest_diploma
        ),
      });
    }
    if (
      previousProps.original_higtest_diploma_url !==
      this.props.original_higtest_diploma_url
    ) {
      this.setState({
        original_higtest_diploma_url: JSON.parse(
          this.props.original_higtest_diploma_url
        ),
      });
    }

    if (previousProps.original_transcript !== this.props.original_transcript) {
      this.setState({
        original_transcript: JSON.parse(this.props.original_transcript),
      });
    }
    if (
      previousProps.original_transcript_url !==
      this.props.original_transcript_url
    ) {
      this.setState({
        original_transcript_url: JSON.parse(this.props.original_transcript_url),
      });
    }

    if (previousProps.written_resume !== this.props.written_resume) {
      this.setState({
        written_resume: JSON.parse(this.props.written_resume),
      });
    }
    if (previousProps.written_resume_url !== this.props.written_resume_url) {
      this.setState({
        written_resume_url: JSON.parse(this.props.written_resume_url),
      });
    }

    if (previousProps.physical_record !== this.props.physical_record) {
      this.setState({
        physical_record: JSON.parse(this.props.physical_record),
      });
    }
    if (previousProps.physical_record_url !== this.props.physical_record_url) {
      this.setState({
        physical_record_url: JSON.parse(this.props.physical_record_url),
      });
    }

    if (previousProps.criminal_record !== this.props.criminal_record) {
      this.setState({
        criminal_record: JSON.parse(this.props.criminal_record),
      });
    }
    if (previousProps.criminal_record_url !== this.props.criminal_record_url) {
      this.setState({
        criminal_record_url: JSON.parse(this.props.criminal_record_url),
      });
    }

    if (
      previousProps.recommendation_letter !== this.props.recommendation_letter
    ) {
      this.setState({
        recommendation_letter: JSON.parse(this.props.recommendation_letter),
      });
    }
    if (
      previousProps.recommendation_letter_url !==
      this.props.recommendation_letter_url
    ) {
      this.setState({
        recommendation_letter_url: JSON.parse(
          this.props.recommendation_letter_url
        ),
      });
    }

    if (previousProps.study_plan !== this.props.study_plan) {
      this.setState({
        study_plan: JSON.parse(this.props.study_plan),
      });
    }
    if (previousProps.study_plan_url !== this.props.study_plan_url) {
      this.setState({
        study_plan_url: JSON.parse(this.props.study_plan_url),
      });
    }

    if (
      previousProps.research_achievement !== this.props.research_achievement
    ) {
      this.setState({
        research_achievement: JSON.parse(this.props.research_achievement),
      });
    }
    if (
      previousProps.research_achievement_url !==
      this.props.research_achievement_url
    ) {
      this.setState({
        research_achievement_url: JSON.parse(
          this.props.research_achievement_url
        ),
      });
    }

    if (
      previousProps.language_proficiency !== this.props.language_proficiency
    ) {
      this.setState({
        language_proficiency: JSON.parse(this.props.language_proficiency),
      });
    }
    if (
      previousProps.language_proficiency_url !==
      this.props.language_proficiency_url
    ) {
      this.setState({
        language_proficiency_url: JSON.parse(
          this.props.language_proficiency_url
        ),
      });
    }

    if (previousProps.other !== this.props.other) {
      this.setState({
        other: JSON.parse(this.props.other),
      });
    }
    if (previousProps.other_url !== this.props.other_url) {
      this.setState({
        other_url: JSON.parse(this.props.other_url),
      });
    }

    if (previousProps.visa_page !== this.props.visa_page) {
      this.setState({
        visa_page: JSON.parse(this.props.visa_page),
      });
    }
    if (previousProps.visa_page_url !== this.props.visa_page_url) {
      this.setState({
        visa_page_url: JSON.parse(this.props.visa_page_url),
      });
    }

    if (previousProps.bank !== this.props.bank) {
      this.setState({
        bank: JSON.parse(this.props.bank),
      });
    }
    if (previousProps.bank_url !== this.props.bank_url) {
      this.setState({
        bank_url: JSON.parse(this.props.bank_url),
      });
    }

    if (previousProps.current_university !== this.props.current_university) {
      this.setState({
        current_university: JSON.parse(this.props.current_university),
      });
    }
    if (
      previousProps.current_university_url !== this.props.current_university_url
    ) {
      this.setState({
        current_university_url: JSON.parse(this.props.current_university_url),
      });
    }

    if (previousProps.vaccine !== this.props.vaccine) {
      this.setState({
        vaccine: JSON.parse(this.props.vaccine),
      });
    }
    if (previousProps.vaccine_url !== this.props.vaccine_url) {
      this.setState({
        vaccine_url: JSON.parse(this.props.vaccine_url),
      });
    }
  }
  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    var id = window.location.href.split("/")[4];

    return (
      <>
        <UserHeader />
        <div className="">
          <div className="container">
            <div className="row">
              <ApplyData pageType="newtable" />
            </div>
            <div className="row mb-5">
              <div className="col-lg-12">
                <p className="display-3 mb-5 fsw-bold">
                  <span className="cor-1"> Application </span> Matarials
                </p>

                {this.props.sucMsg !== null && (
                  <div className="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
                {this.state.err.length > 0 &&
                  this.state.err.map((ee) => {
                    return <li>{ee}</li>;
                  })}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <div className="row application">
                <div className="col-lg-12">
                  <p className="h3 mb-4">Application Documents</p>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Valid Ordinary Passport</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          name="valid_ordinary_passport"
                          onChange={this.onChangeData}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="roww">
                    {this.state.valid_ordinary_passport !== [] &&
                      this.state.valid_ordinary_passport.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "valid_ordinary_passport",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.valid_ordinary_passport_url[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.valid_ordinary_passport[i].name
                              : this.state.valid_ordinary_passport_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Visa Page</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          name="visa_page"
                          onChange={this.onChangeData}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="roww">
                    {this.state.visa_page !== [] &&
                      this.state.visa_page.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "visa_page",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.visa_page[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.visa_page[i].name
                              : this.state.visa_page_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Original Highest Diploma</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          name="original_higtest_diploma"
                          onChange={this.onChangeData}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.original_higtest_diploma !== [] &&
                      this.state.original_higtest_diploma.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "original_higtest_diploma",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.original_higtest_diploma[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.original_higtest_diploma[i].name
                              : this.state.original_higtest_diploma_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Original Transcripts</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="original_transcript"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.original_transcript !== [] &&
                      this.state.original_transcript.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "original_transcript",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.original_transcript[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.original_transcript[i].name
                              : this.state.original_transcript_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Detailed Written Resume</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="written_resume"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.written_resume !== [] &&
                      this.state.written_resume.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "written_resume",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.written_resume[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.written_resume[i].name
                              : this.state.written_resume_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Physical Examination Record</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="physical_record"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.physical_record !== [] &&
                      this.state.physical_record.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "physical_record",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.physical_record[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.physical_record[i].name
                              : this.state.physical_record_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Non Criminal Record</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="criminal_record"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.criminal_record !== [] &&
                      this.state.criminal_record.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "criminal_record",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.criminal_record[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.criminal_record[i].name
                              : this.state.criminal_record_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Recommendation Letters</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="recommendation_letter"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.recommendation_letter !== [] &&
                      this.state.recommendation_letter.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "recommendation_letter",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.recommendation_letter[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.recommendation_letter[i].name
                              : this.state.recommendation_letter_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Bank Statement</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          name="bank"
                          onChange={this.onChangeData}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="roww">
                    {this.state.bank !== [] &&
                      this.state.bank.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "bank",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.bank[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.bank[i].name
                              : this.state.bank_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">A Study Plan</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="study_plan"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.study_plan !== [] &&
                      this.state.study_plan.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "study_plan",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.study_plan[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.study_plan[i].name
                              : this.state.study_plan_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Original Research Achievements</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="research_achievement"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.research_achievement !== [] &&
                      this.state.research_achievement.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "research_achievement",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.research_achievement[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.research_achievement[i].name
                              : this.state.research_achievement_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">
                        Certificate of Language Proficiency
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="language_proficiency"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.language_proficiency !== [] &&
                      this.state.language_proficiency.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "language_proficiency",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.language_proficiency[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.language_proficiency[i].name
                              : this.state.language_proficiency_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Current University Certificate</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          name="current_university"
                          onChange={this.onChangeData}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="roww">
                    {this.state.current_university !== [] &&
                      this.state.current_university.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "current_university",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.current_university[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.current_university[i].name
                              : this.state.current_university_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Vaccination Certificate</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          name="vaccine"
                          onChange={this.onChangeData}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="roww">
                    {this.state.vaccine !== [] &&
                      this.state.vaccine.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "vaccine",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.vaccine[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.vaccine[i].name
                              : this.state.vaccine_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="bg">Other</div>
                    </div>
                    <div className="col-md-8">
                      <div className=" ">
                        <input
                          type="file"
                          className="form-control"
                          // multiple
                          accept="image/pdf"
                          onChange={this.onChangeData}
                          name="other"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="roww">
                    {this.state.other !== [] &&
                      this.state.other.map((u, i) => {
                        return (
                          <div class="columnn">
                            <img
                              src={
                                u.name !== undefined
                                  ? URL.createObjectURL(u)
                                  : u
                              }
                              alt="PDF"
                              width={100}
                              height={120}
                            />
                            <span
                              onClick={() =>
                                this.valid_ordinary_passport(
                                  "other",
                                  u.name !== undefined
                                    ? "local"
                                    : this.state.other[i],
                                  i
                                )
                              }
                            >
                              Delete
                            </span>
                            <br />
                            {u.name !== undefined
                              ? this.state.other[i].name
                              : this.state.other_url[i]}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
                <div className="col-lg-12 text-center my-5">
                  <Link to={"/apply-two/" + id} className="btn-one">
                    Back
                  </Link>
                  <button type="submit" className="btn-one">
                    save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    valid_ordinary_passport: state.auth.valid_ordinary_passport,
    valid_ordinary_passport_url: state.auth.valid_ordinary_passport_name,

    original_higtest_diploma: state.auth.original_higtest_diploma,
    original_higtest_diploma_url: state.auth.original_higtest_diploma_name,

    original_transcript: state.auth.original_transcript,
    original_transcript_url: state.auth.original_transcript_name,

    written_resume: state.auth.written_resume,
    written_resume_url: state.auth.written_resume_name,

    physical_record: state.auth.physical_record,
    physical_record_url: state.auth.physical_record_name,

    criminal_record: state.auth.criminal_record,
    criminal_record_url: state.auth.criminal_record_name,

    recommendation_letter: state.auth.recommendation_letter,
    recommendation_letter_url: state.auth.recommendation_letter_name,

    study_plan: state.auth.study_plan,
    study_plan_url: state.auth.study_plan_name,

    research_achievement: state.auth.research_achievement,
    research_achievement_url: state.auth.research_achievement_name,

    language_proficiency: state.auth.language_proficiency,
    language_proficiency_url: state.auth.language_proficiency_name,

    other: state.auth.other,
    other_url: state.auth.other_name,

    visa_page: state.auth.visa_page,
    visa_page_url: state.auth.visa_page_name,

    bank: state.auth.bank,
    bank_url: state.auth.bank_name,

    current_university: state.auth.current_university,
    current_university_url: state.auth.current_university_name,

    vaccine: state.auth.vaccine,
    vaccine_url: state.auth.vaccine_name,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applyFourData: (data, ii) => dispatch(applyFourData(data, ii)),
  applyFourDataGet: (ii) => dispatch(applyFourDataGet(ii)),
  applyFourDataDelete: (id, data, ii) =>
    dispatch(applyFourDataDelete(id, data, ii)),

  applicationThree: (data, ii) => dispatch(applicationThree(data, ii)),
  applicationThreeData: (data, post) =>
    dispatch(applicationThreeData(data, post)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Test4);
