import React, { useEffect } from "react";
import { circulerList } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";

function Circuler(props) {
  useEffect(() => {
    props.circulerList(props.sourse);
  }, []);
  var data = JSON.parse(localStorage.getItem("user"));
  var type = data ? data.type : 2;

  // if (!data) {
  //   return <Navigate replace to="/login" />;
  // }

  return (
    <>
      {props.show.map((u, i) => {
        return (
          <tr key={u.id} className="showss">
            <td scope="row">
              <span> {i + 1} </span>
            </td>
            <td>
              <Link to={`/circuler/` + u.id}>{u.university}</Link>
            </td>
            <td>
              <span>
                {u.country} <br /> <strong> {u.program}</strong>
              </span>
            </td>
            {props.sector === "Study" && (
              <td>
                <span>{u.end_date}</span>
              </td>
            )}

            <td style={{ color: `green` }}>
              <strong>{u.title}</strong>
            </td>

            {props.sourse === "all" && (
              <td>
                <Link className="btn btn-info" to={`/circuler/` + u.id}>
                  View
                </Link>
                {data ? (
                  <Link
                    className="btn btn-success"
                    to={`/apply-one/new/000/` + type + "/" + u.id}
                  >
                    Apply
                  </Link>
                ) : (
                  <Link className="btn btn-success" to={`/login`}>
                    Login and Apply
                  </Link>
                )}
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    univ: state.auth.blog,
    circulr: state.auth.circulr,
    show: state.auth.show,
  };
};

export default connect(mapStateToProps, { circulerList })(Circuler);
