import { Link } from "react-router-dom";

function Ber2(props) {
  var data = JSON.parse(localStorage.getItem("user"));
  var url = window.location.href.split("/");
  console.log(props.newID);
  console.log("ddddd");
  return (
    <div className="columns">
      <Link
        to={`/apply-one/old/` + props.newID + "/" + data.type + "/" + url[7]}
        className={props.page === "one" ? "activedata" : "column"}
      >
        基本信息 <br /> Basic Information
      </Link>
      <Link
        to={`/apply-two/old/` + props.newID + "/" + data.type + "/" + url[7]}
        className={props.page === "two" ? "activedata" : "column"}
      >
        申请信息 <br /> Application information
      </Link>
      <Link
        to={`/apply-three/old/` + props.newID + "/" + data.type + "/" + url[7]}
        className={props.page === "three" ? "activedata" : "column"}
      >
        补充信息 <br />
        More information
      </Link>
      <Link
        to={`/apply-four/old/` + props.newID + "/" + data.type + "/" + url[7]}
        className={props.page === "four" ? "activedata" : "column"}
      >
        申请材料
        <br /> Documents
      </Link>
      <Link
        to={`/apply-view/old/` + props.newID + "/" + data.type + "/" + url[7]}
        className={props.page === "view" ? "activedata" : "column"}
      >
        预览及提交 <br />
        Preview
      </Link>
    </div>
  );
}
export default Ber2;
