import React, { useEffect } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { agentHomeData } from "../../services/actions/AuthAction";
function AgentHome(props) {
  var data = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    props.agentHomeData();
  }, []);
  const bal = {
    // borderRadius: "50%",
    // marginLeft: `60px`,
    // float: `left`,
    boxShadow: `0px 0px 14px 0px #efff00`,
    margin: `5px`,
    border: `1px solid #f4f4f4`,
  };

  return (
    <>
      <UserHeader />
      <div class="">
        <div class="dataa">
          <div class="row">
            <div className="col-md-3 text-center">
              <img
                src={
                  data.image === null
                    ? window.location.origin + "/image/avater.png"
                    : data.image
                }
                style={bal}
                class="rounded-circle"
                alt="partners logo"
                width="140"
                height="140"
              />
            </div>
            <div className="col-md-9" style={{ color: `white` }}>
              <p style={{ color: `white` }}>Partner Profile</p>
              <p style={{ color: `white` }}>Name: {data.name} </p>
              <p style={{ color: `white` }}>Account: {data.email} </p>
              <p style={{ color: `white` }}>
                <Link
                  style={{ color: "white" }}
                  className="btn btn-outline-light change"
                  to="/password-change"
                >
                  Password Chanege
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3">
        <div className="rowwww">
          <div className="fast_track">Recomended Student</div>
          <div className="row p-5">
            <p>
              Total Application : <Link to="/agent/all">{props.count.all}</Link>
            </p>
            <table class="table table-bordered text-center ">
              <thead className="cocc">
                <th className="p-2">Incomplete</th>
                <th className="p-2">Submitted</th>
                <th className="p-2">In-Progress</th>
                <th className="p-2">Pre-Admission</th>
                <th className="p-2">Admitted</th>
                <th className="p-2">Rejectd</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Link to="/agent/incomplete">
                      {props.count.uncompleted}
                    </Link>
                  </td>
                  <td>
                    <Link to="/agent/submitted">{props.count.submitted}</Link>
                  </td>
                  <td>
                    <Link to="/agent/inprogress">{props.count.inprogress}</Link>
                  </td>
                  <td>
                    <Link to="/agent/preadmission">
                      {props.count.preadmission}
                    </Link>
                  </td>
                  <td>
                    <Link to="/agent/admitted">{props.count.admitted}</Link>
                  </td>
                  <td>
                    <Link to="/agent/rejectd">{props.count.rejected}</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container my-3">
        <div className="rowwww">
          <div className="fast_track">
            快捷通道 <br /> Fast track
          </div>
          <div className="row p-5">
            <div className="col-md-3 p-3">
              <Link to="/apply-one/new/000/2/null">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/c.jpg"
                />
                <p className="iconsite">
                  <span>报名申请</span>
                  <br /> <span> Start Application</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="/agent/all">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/e.jpg"
                />
                <p className="iconsite">
                  <span>我的学生</span>
                  <br /> <span> My Student</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="/update-profile">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/i.jpg"
                />
                <p className="iconsite">
                  <span>我的账户</span>
                  <br /> <span> My Account</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="/chat">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/d.jpg"
                />
                <p className="iconsite">
                  <span>收件箱</span>
                  <br /> <span> Inbox</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="#">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/b.jpg"
                />
                <p className="iconsite">
                  <span>宿舍预定</span>
                  <br /> <span> Accommodation</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="#">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/f.jpg"
                />
                <p className="iconsite">
                  <span>接机</span>
                  <br /> <span> Airport Pick-up</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="#">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/a.jpg"
                />
                <p className="iconsite">
                  <span>预约报到</span>
                  <br /> <span> Registration Appointment</span>
                </p>
              </Link>
            </div>
            <div className="col-md-3 p-3">
              <Link to="/all-application">
                <img
                  width="65"
                  height="65"
                  style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                  src="../../../image/logo/h.jpg"
                />
                <p className="iconsite">
                  <span></span>
                  <br /> <span> Explore </span>
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    home: state.auth.home,
    count: state.auth.count,
  };
};

export default connect(mapStateToProps, { agentHomeData })(AgentHome);
