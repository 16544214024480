import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../services/actions/AuthAction";
import { connect } from "react-redux";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    localStorage.clear();
    window.location.replace("/");
  }

  render() {
    return (
      <div>
        <h1>Successfully Logged Out</h1>
        <Link to="/login"> Want to Login?</Link>
      </div>
    );
  }
}

export default connect(null, { logout })(Logout);
