import React, { useEffect, useState } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  AgentApplicationThree,
  AgentApplicationThreeData,
} from "../../services/actions/AuthAction";
import { Link, useParams } from "react-router-dom";
import ApplicationDataP from "../helper/ApplicationDataP";

function Application3(props) {
  var data = JSON.parse(localStorage.getItem("user"));
  const [err, setErr] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const error = [];
  const onSubmit = (data) => {
    const formData = new FormData();

    const files = data.valid_ordinary_passport;
    for (let i = 0; i < files.length; i++) {
      const fileSize = files[i].size / 1024 / 1024;
      if (fileSize > 1) {
        error.push(
          files[i].name +
            " file size is big, Please upload less then 1 mb file at valid ordinary passport section"
        );
      } else {
        formData.append(`valid_ordinary_passport[${i}]`, files[i]);
      }
    }

    const files1 = data.original_higtest_diploma;
    for (let i = 0; i < files1.length; i++) {
      const fileSize1 = files1[i].size / 1024 / 1024;
      if (fileSize1 > 1) {
        error.push(
          files1[i].name +
            " file size is big, Please upload less then 1 mb file at original higtest diploma section"
        );
      } else {
        formData.append(`original_higtest_diploma[${i}]`, files1[i]);
      }
    }

    const files2 = data.original_transcript;
    for (let i = 0; i < files2.length; i++) {
      const fileSize2 = files2[i].size / 1024 / 1024;
      if (fileSize2 > 1) {
        error.push(
          files2[i].name +
            " file size is big, Please upload less then 1 mb file at original transcript section"
        );
      } else {
        formData.append(`original_transcript[${i}]`, files2[i]);
      }
    }

    const files3 = data.written_resume;
    for (let i = 0; i < files3.length; i++) {
      const fileSize3 = files3[i].size / 1024 / 1024;
      if (fileSize3 > 1) {
        error.push(
          files3[i].name +
            " file size is big, Please upload less then 1 mb file at written resume section"
        );
      } else {
        formData.append(`written_resume[${i}]`, files3[i]);
      }
    }

    const files4 = data.physical_record;
    for (let i = 0; i < files4.length; i++) {
      const fileSize4 = files4[i].size / 1024 / 1024;
      if (fileSize4 > 1) {
        error.push(
          files4[i].name +
            " file size is big, Please upload less then 1 mb file at physical record section"
        );
      } else {
        formData.append(`physical_record[${i}]`, files4[i]);
      }
    }

    const files5 = data.criminal_record;
    for (let i = 0; i < files5.length; i++) {
      const fileSize5 = files5[i].size / 1024 / 1024;
      if (fileSize5 > 1) {
        error.push(
          files5[i].name +
            " file size is big, Please upload less then 1 mb file at criminal record section"
        );
      } else {
        formData.append(`criminal_record[${i}]`, files5[i]);
      }
    }

    const files6 = data.recommendation_letter;
    for (let i = 0; i < files6.length; i++) {
      const fileSize6 = files6[i].size / 1024 / 1024;
      if (fileSize6 > 1) {
        error.push(
          files6[i].name +
            " file size is big, Please upload less then 1 mb file at recommendation letter section"
        );
      } else {
        formData.append(`recommendation_letter[${i}]`, files6[i]);
      }
    }

    const files7 = data.study_plan;
    for (let i = 0; i < files7.length; i++) {
      const fileSize7 = files7[i].size / 1024 / 1024;
      if (fileSize7 > 1) {
        error.push(
          files7[i].name +
            " file size is big, Please upload less then 1 mb file at study plan section"
        );
      } else {
        formData.append(`study_plan[${i}]`, files7[i]);
      }
    }

    const files8 = data.research_achievement;
    for (let i = 0; i < files8.length; i++) {
      const fileSize8 = files8[i].size / 1024 / 1024;
      if (fileSize8 > 1) {
        error.push(
          files8[i].name +
            " file size is big, Please upload less then 1 mb file at research achievement section"
        );
      } else {
        formData.append(`research_achievement[${i}]`, files8[i]);
      }
    }

    const files9 = data.language_proficiency;
    for (let i = 0; i < files9.length; i++) {
      const fileSize9 = files9[i].size / 1024 / 1024;
      if (fileSize9 > 1) {
        error.push(
          files9[i].name +
            " file size is big, Please upload less then 1 mb file at language proficiency section"
        );
      } else {
        formData.append(`language_proficiency[${i}]`, files9[i]);
      }
    }
    const files10 = data.other;
    for (let i = 0; i < files10.length; i++) {
      const fileSize10 = files10[i].size / 1024 / 1024;
      if (fileSize10 > 1) {
        error.push(
          files10[i].name +
            " file size is big, Please upload less then 1 mb file at other section"
        );
      } else {
        formData.append(`other[${i}]`, files10[i]);
      }
    }

    formData.append("user_id", data.user_id);
    formData.append("hidden_id", data.hidden_id);

    if (error.length === 0) {
      props.AgentApplicationThree(formData);
    } else {
      setErr(error);
    }
  };

  let { id } = useParams();
  useEffect(() => {
    props.AgentApplicationThreeData(id);
  }, []);
  return (
    <>
      <UserHeader />
      <div className="">
        <div className="container">
          <div className="row">
            <ApplicationDataP post={id} />
          </div>
          <div className="row mb-5">
            <div className="col-lg-12">
              <p className="display-3 mb-5 fsw-bold">
                <span className="cor-1"> Application </span> Matarials
              </p>
              <p>
                <span className="btn-one ">Study</span>
                <span className="btn-three ">Tourist</span>
                <span className="btn-three mt-4 mt-sm-0">Work</span>
              </p>

              {props.sucMsg !== null && (
                <div className="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
              {err.length > 0 &&
                err.map((ee) => {
                  return <li>{ee}</li>;
                })}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <input type="hidden" {...register("user_id")} value={data.id} />
            <input type="hidden" {...register("hidden_id")} value={id} />

            <div className="row application">
              <div className="col-lg-12">
                <p className="h3 mb-4">Application Documents</p>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Valid Ordinary Passport</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("valid_ordinary_passport")}
                      />
                    </div>
                    {props.valid_ordinary_passport !== null
                      ? JSON.parse(props.valid_ordinary_passport).map(
                          (u, i) => {
                            return (
                              <span>
                                {i + 1 + `. `}
                                {u}
                                <br />
                              </span>
                            );
                          }
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Original Highest Diploma</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("original_higtest_diploma")}
                      />
                    </div>
                    {props.original_higtest_diploma !== null
                      ? JSON.parse(props.original_higtest_diploma).map(
                          (u, i) => {
                            return (
                              <span>
                                {i + 1 + `. `}
                                {u}
                                <br />
                              </span>
                            );
                          }
                        )
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Original Transcripts</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("original_transcript")}
                      />
                    </div>
                    {props.original_transcript !== null
                      ? JSON.parse(props.original_transcript).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Detailed Written Resume</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("written_resume")}
                      />
                    </div>
                    {props.written_resume !== null
                      ? JSON.parse(props.written_resume).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Physical Examination Record</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("physical_record")}
                      />
                    </div>
                    {props.physical_record !== null
                      ? JSON.parse(props.physical_record).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Non Criminal Record</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("criminal_record")}
                      />
                    </div>
                    {props.criminal_record !== null
                      ? JSON.parse(props.criminal_record).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Recommendation Letters</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("recommendation_letter")}
                      />
                    </div>
                    {props.recommendation_letter !== null
                      ? JSON.parse(props.recommendation_letter).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">A Study Plan</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("study_plan")}
                      />
                    </div>
                    {props.study_plan !== null
                      ? JSON.parse(props.study_plan).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Original Research Achievements</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("research_achievement")}
                      />
                    </div>
                    {props.research_achievement !== null
                      ? JSON.parse(props.research_achievement).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">
                      Certificate of Language Proficiency
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("language_proficiency")}
                      />
                    </div>
                    {props.language_proficiency !== null
                      ? JSON.parse(props.language_proficiency).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="bg">Other</div>
                  </div>
                  <div className="col-md-8">
                    <div className=" ">
                      <input
                        type="file"
                        className="form-control"
                        id=" "
                        multiple
                        placeholder=" "
                        {...register("other")}
                      />
                    </div>
                    {props.other !== null
                      ? JSON.parse(props.other).map((u, i) => {
                          return (
                            <span>
                              {i + 1 + `. `}
                              {u}
                              <br />
                            </span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
              <div className="col-lg-12 text-center my-5">
                <Link to={"/application-two/" + id} className="btn-one">
                  Back
                </Link>
                <button type="submit" className="btn-one">
                  save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    valid_ordinary_passport: state.auth.valid_ordinary_passport,
    original_higtest_diploma: state.auth.original_higtest_diploma,
    original_transcript: state.auth.original_transcript,
    written_resume: state.auth.written_resume,
    physical_record: state.auth.physical_record,
    criminal_record: state.auth.criminal_record,
    recommendation_letter: state.auth.recommendation_letter,
    study_plan: state.auth.study_plan,
    research_achievement: state.auth.research_achievement,
    language_proficiency: state.auth.language_proficiency,
    other: state.auth.other,
  };
};

const mapDispatchToProps = (dispatch) => ({
  AgentApplicationThree: (data) => dispatch(AgentApplicationThree(data)),
  AgentApplicationThreeData: (post) =>
    dispatch(AgentApplicationThreeData(post)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application3);
