import React, { useEffect } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { homeData, deteleApply } from "../../services/actions/AuthAction";
function Home(props) {
  var data = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    props.homeData();
  }, []);
  const bal = {
    borderRadius: `50%`,
    border: `12px solid #2dace5`,
    boxShadow: `0px 0px 7px -1px red`,
    padding: `7px`,
  };
  const url = "url(../image/home.jpg)";

  function deteleApplyAction(ss) {
    const shouldRemove = window.confirm("Are you sure want to delete?");
    if (shouldRemove) {
      props.deteleApply(ss);
    }
  }

  return (
    <>
      <UserHeader />
      <section>
        <div class="container-fluid my-5">
          <div class="row g-5 conte" style={{ background: url }}>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6" style={{ extAlign: `right` }}>
                  <img
                    src={
                      data.image === null
                        ? window.location.origin + "/image/avater.png"
                        : data.image
                    }
                    style={bal}
                    width="140"
                    height="140"
                    alt="d"
                  />
                </div>
                <div class="col-md-6">
                  <h4 className="text-light">Hello,</h4>
                  <Link to="/log-out" class="wechat2">
                    Log Out
                  </Link>
                  <br />
                  <br />
                  <Link to="/password-change" class="wechat">
                    Password Change
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-md-2"></div>

            <div class="col-md-4">
              <div class="text-center ">
                <p className="account">Account: {data.name}</p>
                <Link class="wechat" to="/apply-one">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container text-center mb-3">
        <div className="row rowww">
          <div className="row">
            <div className="col-md-5">
              <hr className="hr" />
            </div>
            <div className="col-md-2" style={{ color: `#52ace9` }}>
              <b>菜单 Menu</b>
            </div>
            <div className="col-md-5">
              <hr className="hr" />
            </div>
          </div>

          <div className="col-md-3">
            <Link to="/apply-one/new/000/1/null">
              <img
                alt="a"
                width="65"
                height="65"
                src="../../../image/logo/c.jpg"
                style={{ float: "left", borderRadius: "50%", padding: `7px` }}
              />
              <p className="iconsite">
                <span>在线申请</span>
                <br /> <span>Start Application</span>
              </p>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/chat">
              <img
                alt="a"
                width="65"
                height="65"
                style={{ float: "left", borderRadius: "50%", padding: `7px` }}
                src="../../../image/logo/d.jpg"
              />
              <p className="iconsite">
                <span>消息</span>
                <br /> <span>Messages</span>
              </p>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/notification">
              <img
                alt="a"
                width="65"
                height="65"
                src="../../../image/logo/j.jpg"
                style={{ float: "left", borderRadius: "50%", padding: `7px` }}
              />
              <p className="iconsite">
                <span>通知</span>
                <br /> <span>Notices</span>
              </p>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/all-application">
              <img
                alt="a"
                width="65"
                height="65"
                src="../../../image/logo/h.jpg"
                style={{ float: "left", borderRadius: "50%", padding: `7px` }}
              />
              <p className="iconsite">
                <span></span>
                <br /> <span>Explore</span>
              </p>
            </Link>
          </div>
        </div>
      </section>
      <section className="container text-center mb-3 rowww">
        <table class="table table-bordered text-center">
          <thead className="bg-light">
            <tr>
              <th scope="col">S/L</th>
              <th scope="col">Application ID</th>
              <th scope="col">Name / Passport</th>
              <th scope="col">Course / Degree</th>
              <th scope="col">University Name / Expected Scholarship</th>
              <th scope="col"> Status</th>
              <th scope="col">Payment Status</th>
              <th scope="col">Manage</th>
            </tr>
          </thead>
          <tbody>
            {props.home.map((d, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{d.status === "0" ? "" : d.unique_id}</td>
                  <td>
                    {d.full_name}
                    <br />
                    {d.passport_no}
                  </td>
                  <td>
                    {d.mejor_subject}
                    <br />
                    {d.mejor_degree}
                  </td>
                  <td>
                    {d.university}
                    <br />
                    {d.expected_school}
                  </td>
                  <td>
                    {d.status === "0" && "Incomplete"}
                    {d.status === "2" && "Pending"}
                    {d.status === "3" && "In-Progress"}
                    {d.status === "4" && "Pre-Admission"}
                    {d.status === "5" && "Admitted"}
                    {d.status === "6" && "Rejected"}
                  </td>
                  <td>
                    {d.payment === "0" && "Application fee to be paid"}
                    {d.payment === "2" && "Application fee paid"}
                    {d.payment === "3" && "Admission fee to be paid"}
                    {d.payment === "4" && "Admission fee paid"}
                  </td>
                  <td>
                    <Link
                      className="btn btn-info text-white"
                      to={`/apply-view/old/` + d.appid + "/1/" + d.post_id}
                    >
                      View
                    </Link>

                    {d.status == "0" && (
                      <>
                        <Link
                          className="btn btn-success text-white"
                          to={`/apply-one/old/` + d.appid + "/1/" + d.post_id}
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() => deteleApplyAction(d.appid)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </>
                    )}
                    <br />
                    <Link
                      className="btn btn-secondary text-white"
                      to={`/payment/` + d.appid}
                    >
                      Payment
                    </Link>
                    <a
                      className="btn btn-primary text-white"
                      href={d.doc}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Application Form
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    home: state.auth.home,
  };
};

export default connect(mapStateToProps, { homeData, deteleApply })(Home);
