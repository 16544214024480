function CertificateType(props) {
  return (
    <>
      <option value="学以证书 Diploma/Degree certificate">
        学以证书 Diploma/Degree certificate
      </option>
      <option value="预毕业证明 Expected Graduation Certificate">
        预毕业证明 Expected Graduation Certificate
      </option>
      <option value="学业证书和预毕业证明 Both of all">
        学业证书和预毕业证明 Both of all
      </option>
      <option value="在读证明 Studying Certificate">
        在读证明 Studying Certificate
      </option>
      <option value="最高学历证书类型 Certificate type of highest degree">
        最高学历证书类型 Certificate type of highest degree
      </option>
    </>
  );
}
export default CertificateType;
