import React from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import Code from "../helper/Code";
import { withRouter } from "react-router-dom";
import {
  applicationOne,
  applicationOneData,
  applyAll,
  applyOneData,
  applyOneDataGet,
  applyOneDataGet22,
} from "../../services/actions/AuthAction";
import { Link } from "react-router-dom";
import Country from "../helper/Country";
import Language from "../helper/Language";
import Relation from "../helper/Relation";
import Occupation from "../helper/Occupation";
import ApplyData from "../helper/ApplyData";
import Industry from "../helper/Industry";
import Visa from "../helper/Visa";
import Ber from "../helper/Ber";
import Ber2 from "../helper/Ber2";

class ApplyOne extends React.Component {
  constructor() {
    super();

    this.state = {
      sector: "Study",

      appStatus: "0",

      sponser: false,
      sponsor_name: "",
      sponsor_word_place: "",
      sponsor_phone: "",
      sponsor_industry: "",
      sponsor_relation: "",
      sponsor_occupation: "",
      sponsor_email: "",
      sponserInfo: [],
      sponserError: "",
      sponserAdd: true,
      sponserUpdate: false,
      sponserItem: null,
      sponserError2: "",

      guarante: false,
      guarante_name: "",
      guarante_work_place: "",
      guarante_phone: "",
      guarante_industry: "",
      guarante_relation: "",
      guarante_occupation: "",
      guarante_email: "",
      guaranteInfo: [],
      guaranteError: "",
      guaranteAdd: true,
      guaranteUpdate: false,
      guaranteItem: null,
      guaranteError2: "",

      emergency: false,
      emergency_name: "",
      emergency_work_place: "",
      emergency_phone: "",
      emergency_industry: "",
      emergency_relation: "",
      emergency_occupation: "",
      emergency_email: "",
      emergencyInfo: [],
      emergencyError: "",
      emergencyAdd: true,
      emergencyUpdate: false,
      emergencyItem: null,
      emergencyError2: "",

      family_name: "",
      given_name: "",
      full_name: "",
      china_name: "",
      gender: "",
      nationality: "",
      dob: "",
      birth_country: "",
      photo: "",
      native_language: "",
      marital_status: "",
      religion: "",
      occupation: "",
      affiliate: "",
      wechat: "",
      phone: "",
      code: "",
      birth_place: "",

      parmanent_home: "",
      parmanent_city: "",
      parmanent_country: "",
      parmanent_zipcode: "",
      parmanent_phone: "",
      parmanent_email: "",

      same_as_current: "",
      current_home: "",
      current_city: "",
      current_country: "",
      current_zipcode: "",
      current_phone: "",
      current_email: "",

      passport_no: "",
      old_passport: "",
      new_expiry_date_start: "",
      new_expiry_date_end: "",
      old_expaire_date: "",
      emabassy: "",

      studed_in_china: "",
      visa_type: "",
      visa_no: "",

      in_china_new: "",
      visa_expairy_date: "",
      study_period_china: "",
      study_period_china_end: "",
      china_institute: "",
    };
  }

  addSponser = () => {
    this.setState({ sponser: true });
    this.setState({ sponserAdd: true });
    this.setState({ sponserUpdate: false });
  };

  cancleSponser = () => {
    this.setState({ sponser: false });
    this.setState({ sponserAdd: false });
    this.setState({ sponserUpdate: false });
    this.setState({ sponsor_name: "" });
    this.setState({ sponsor_word_place: "" });
    this.setState({ sponsor_phone: "" });
    this.setState({ sponsor_industry: "" });
    this.setState({ sponsor_relation: "" });
    this.setState({ sponsor_occupation: "" });
    this.setState({ sponsor_email: "" });
  };

  submitSponser = () => {
    const item = {
      sponsor_name: this.state.sponsor_name,
      sponsor_word_place: this.state.sponsor_word_place,
      sponsor_phone: this.state.sponsor_phone,
      sponsor_industry: this.state.sponsor_industry,
      sponsor_relation: this.state.sponsor_relation,
      sponsor_occupation: this.state.sponsor_occupation,
      sponsor_email: this.state.sponsor_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ sponserError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        sponserInfo: [...prevState.sponserInfo, item],
      }));
      this.setState({ sponserError: "" });
      this.setState({ sponserError2: "" });
      this.setState({ sponser: false });

      this.setState({ sponsor_name: "" });
      this.setState({ sponsor_word_place: "" });
      this.setState({ sponsor_phone: "" });
      this.setState({ sponsor_industry: "" });
      this.setState({ sponsor_relation: "" });
      this.setState({ sponsor_occupation: "" });
      this.setState({ sponsor_email: "" });
    }
  };

  submitUpdateSponser = () => {
    const item = {
      sponsor_name: this.state.sponsor_name,
      sponsor_word_place: this.state.sponsor_word_place,
      sponsor_phone: this.state.sponsor_phone,
      sponsor_industry: this.state.sponsor_industry,
      sponsor_relation: this.state.sponsor_relation,
      sponsor_occupation: this.state.sponsor_occupation,
      sponsor_email: this.state.sponsor_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ sponserError: "All * mark fields are requird." });
    } else {
      const { sponserInfo } = this.state;
      sponserInfo[this.state.sponserItem] = item;

      this.setState({
        sponserInfo: sponserInfo,
      });

      this.setState({ sponserError: "" });
      this.setState({ sponser: false });

      this.setState({ sponsor_name: "" });
      this.setState({ sponsor_word_place: "" });
      this.setState({ sponsor_phone: "" });
      this.setState({ sponsor_industry: "" });
      this.setState({ sponsor_relation: "" });
      this.setState({ sponsor_occupation: "" });
      this.setState({ sponsor_email: "" });
    }
  };

  editSponser(si) {
    this.setState({ sponserAdd: false });
    this.setState({ sponserUpdate: true });
    const ii = this.state.sponserInfo[si];
    this.setState({ sponserItem: si });
    this.setState({ sponser: true });

    this.setState({ sponsor_name: ii.sponsor_name });
    this.setState({ sponsor_word_place: ii.sponsor_word_place });
    this.setState({ sponsor_phone: ii.sponsor_phone });
    this.setState({ sponsor_industry: ii.sponsor_industry });
    this.setState({ sponsor_relation: ii.sponsor_relation });
    this.setState({ sponsor_occupation: ii.sponsor_occupation });
    this.setState({ sponsor_email: ii.sponsor_email });
  }
  deleteSponser(ix) {
    this.state.sponserInfo.splice(ix, 1);
    this.setState({
      sponserInfo: this.state.sponserInfo,
    });
  }
  //garante start
  addGuarante = () => {
    this.setState({ guarante: true });
    this.setState({ guaranteAdd: true });
    this.setState({ guaranteUpdate: false });
  };
  cancleGuarante = () => {
    this.setState({ guarante: false });
    this.setState({ guaranteAdd: false });
    this.setState({ guaranteUpdate: false });
    this.setState({ guarante_name: "" });
    this.setState({ guarante_work_place: "" });
    this.setState({ guarante_phone: "" });
    this.setState({ guarante_industry: "" });
    this.setState({ guarante_relation: "" });
    this.setState({ guarante_occupation: "" });
    this.setState({ guarante_email: "" });
  };
  submitGuarante = () => {
    const item = {
      guarante_name: this.state.guarante_name,
      guarante_work_place: this.state.guarante_work_place,
      guarante_phone: this.state.guarante_phone,
      guarante_industry: this.state.guarante_industry,
      guarante_relation: this.state.guarante_relation,
      guarante_occupation: this.state.guarante_occupation,
      guarante_email: this.state.guarante_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ guaranteError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        guaranteInfo: [...prevState.guaranteInfo, item],
      }));
      this.setState({ guaranteError: "" });
      this.setState({ guaranteError2: "" });
      this.setState({ guarante: false });

      this.setState({ guarante_name: "" });
      this.setState({ guarante_work_place: "" });
      this.setState({ guarante_phone: "" });
      this.setState({ guarante_industry: "" });
      this.setState({ guarante_relation: "" });
      this.setState({ guarante_occupation: "" });
      this.setState({ guarante_email: "" });
    }
  };

  submitUpdateGuarante = () => {
    const item = {
      guarante_name: this.state.guarante_name,
      guarante_work_place: this.state.guarante_work_place,
      guarante_phone: this.state.guarante_phone,
      guarante_industry: this.state.guarante_industry,
      guarante_relation: this.state.guarante_relation,
      guarante_occupation: this.state.guarante_occupation,
      guarante_email: this.state.guarante_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ guaranteError: "All * mark fields are requird." });
    } else {
      const { guaranteInfo } = this.state;
      guaranteInfo[this.state.guaranteItem] = item;

      this.setState({
        guaranteInfo: guaranteInfo,
      });

      this.setState({ guaranteError: "" });
      this.setState({ guarante: false });

      this.setState({ guarante_name: "" });
      this.setState({ guarante_work_place: "" });
      this.setState({ guarante_phone: "" });
      this.setState({ guarante_industry: "" });
      this.setState({ guarante_relation: "" });
      this.setState({ guarante_occupation: "" });
      this.setState({ guarante_email: "" });
    }
  };

  editGuarante(si) {
    this.setState({ guaranteAdd: false });
    this.setState({ guaranteUpdate: true });
    this.setState({ guaranteItem: si });
    this.setState({ guarante: true });

    const ii = this.state.guaranteInfo[si];
    this.setState({ guarante_name: ii.guarante_name });
    this.setState({ guarante_work_place: ii.guarante_work_place });
    this.setState({ guarante_phone: ii.guarante_phone });
    this.setState({ guarante_industry: ii.guarante_industry });
    this.setState({ guarante_relation: ii.guarante_relation });
    this.setState({ guarante_occupation: ii.guarante_occupation });
    this.setState({ guarante_email: ii.guarante_email });
  }
  deleteGuarante(ix) {
    this.state.guaranteInfo.splice(ix, 1);
    this.setState({
      guaranteInfo: this.state.guaranteInfo,
    });
  }
  //garante
  //Emergency stsrt
  addEmergency = () => {
    this.setState({ emergency: true });
    this.setState({ emergencyAdd: true });
    this.setState({ emergencyUpdate: false });
  };
  cancleEmergency = () => {
    this.setState({ emergency: false });
    this.setState({ emergencyAdd: false });
    this.setState({ emergencyUpdate: false });

    this.setState({ emergency_name: "" });
    this.setState({ emergency_work_place: "" });
    this.setState({ emergency_phone: "" });
    this.setState({ emergency_industry: "" });
    this.setState({ emergency_relation: "" });
    this.setState({ emergency_occupation: "" });
    this.setState({ emergency_email: "" });
  };
  submitEmergency = () => {
    const item = {
      emergency_name: this.state.emergency_name,
      emergency_work_place: this.state.emergency_work_place,
      emergency_phone: this.state.emergency_phone,
      emergency_industry: this.state.emergency_industry,
      emergency_relation: this.state.emergency_relation,
      emergency_occupation: this.state.emergency_occupation,
      emergency_email: this.state.emergency_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ emergencyError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        emergencyInfo: [...prevState.emergencyInfo, item],
      }));
      this.setState({ emergencyError: "" });
      this.setState({ emergencyError2: "" });
      this.setState({ emergency: false });

      this.setState({ emergency_name: "" });
      this.setState({ emergency_work_place: "" });
      this.setState({ emergency_phone: "" });
      this.setState({ emergency_industry: "" });
      this.setState({ emergency_relation: "" });
      this.setState({ emergency_occupation: "" });
      this.setState({ emergency_email: "" });
    }
  };

  submitUpdateEmergency = () => {
    const item = {
      emergency_name: this.state.emergency_name,
      emergency_work_place: this.state.emergency_work_place,
      emergency_phone: this.state.emergency_phone,
      emergency_industry: this.state.emergency_industry,
      emergency_relation: this.state.emergency_relation,
      emergency_occupation: this.state.emergency_occupation,
      emergency_email: this.state.emergency_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 7) {
      this.setState({ emergencyError: "All * mark fields are requird." });
    } else {
      const { emergencyInfo } = this.state;
      emergencyInfo[this.state.emergencyItem] = item;

      this.setState({
        emergencyInfo: emergencyInfo,
      });

      this.setState({ emergencyError: "" });
      this.setState({ emergency: false });

      this.setState({ emergency_name: "" });
      this.setState({ emergency_work_place: "" });
      this.setState({ emergency_phone: "" });
      this.setState({ emergency_industry: "" });
      this.setState({ emergency_relation: "" });
      this.setState({ emergency_occupation: "" });
      this.setState({ emergency_email: "" });
    }
  };

  editEmergency(si) {
    this.setState({ emergencyAdd: false });
    this.setState({ emergencyUpdate: true });
    this.setState({ emergencyItem: si });
    this.setState({ emergency: true });

    const ii = this.state.emergencyInfo[si];
    this.setState({ emergency_name: ii.emergency_name });
    this.setState({ emergency_work_place: ii.emergency_work_place });
    this.setState({ emergency_phone: ii.emergency_phone });
    this.setState({ emergency_industry: ii.emergency_industry });
    this.setState({ emergency_relation: ii.emergency_relation });
    this.setState({ emergency_occupation: ii.emergency_occupation });
    this.setState({ emergency_email: ii.emergency_email });
  }
  deleteEmergency(ix) {
    this.state.emergencyInfo.splice(ix, 1);
    this.setState({
      emergencyInfo: this.state.emergencyInfo,
    });
  }
  //Emergency

  checkHandler = (a) => {
    this.setState({ sector: a });
  };

  onChangeData = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState({ [name]: checked });
    } else if (type === "file") {
      this.setState({ photo: e.target.files[0] });
    } else if (type === "radio") {
      this.setState({ [name]: value });
    } else {
      if (
        "given_name" === name ||
        "family_name" === name ||
        "full_name" === name
      ) {
        this.setState({ [name]: value.toUpperCase() });
      } else {
        this.setState({ [name]: value });
      }
    }
  };

  onSubmitDetails = (e) => {
    e.preventDefault();
    const formData2 = new FormData();

    formData2.append("photo", this.state.photo);

    formData2.append("family_name", this.state.family_name);
    formData2.append("given_name", this.state.given_name);
    formData2.append("full_name", this.state.full_name);
    formData2.append("china_name", this.state.china_name);
    formData2.append("gender", this.state.gender);
    formData2.append("nationality", this.state.nationality);
    formData2.append("dob", this.state.dob);
    formData2.append("birth_country", this.state.birth_country);
    formData2.append("photo", this.state.photo);
    formData2.append("native_language", this.state.native_language);
    formData2.append("marital_status", this.state.marital_status);
    formData2.append("religion", this.state.religion);
    formData2.append("occupation", this.state.occupation);
    formData2.append("affiliate", this.state.affiliate);
    formData2.append("wechat", this.state.wechat);
    formData2.append("phone", this.state.phone);
    formData2.append("code", this.state.code);
    formData2.append("birth_place", this.state.birth_place);

    formData2.append("parmanent_home", this.state.parmanent_home);
    formData2.append("parmanent_city", this.state.parmanent_city);
    formData2.append("parmanent_country", this.state.parmanent_country);
    formData2.append("parmanent_zipcode", this.state.parmanent_zipcode);
    formData2.append("parmanent_phone", this.state.parmanent_phone);
    formData2.append("parmanent_email", this.state.parmanent_email);

    formData2.append("current_home", this.state.current_home);
    formData2.append("current_city", this.state.current_city);
    formData2.append("current_country", this.state.current_country);
    formData2.append("current_zipcode", this.state.current_zipcode);
    formData2.append("current_phone", this.state.current_phone);
    formData2.append("current_email", this.state.current_email);

    formData2.append("passport_no", this.state.passport_no);
    formData2.append("old_passport", this.state.old_passport);
    formData2.append("new_expiry_date_start", this.state.new_expiry_date_start);
    formData2.append("new_expiry_date_end", this.state.new_expiry_date_end);
    formData2.append("old_expaire_date", this.state.old_expaire_date);
    formData2.append("emabassy", this.state.emabassy);

    formData2.append("studed_in_china", this.state.studed_in_china);
    formData2.append("visa_type", this.state.visa_type);
    formData2.append("visa_no", this.state.visa_no);

    formData2.append("in_china_new", this.state.in_china_new);
    formData2.append("visa_expairy_date", this.state.visa_expairy_date);
    formData2.append("study_period_china", this.state.study_period_china);
    formData2.append(
      "study_period_china_end",
      this.state.study_period_china_end
    );
    formData2.append("china_institute", this.state.china_institute);

    formData2.append("sponserInfo", JSON.stringify(this.state.sponserInfo));
    formData2.append("guaranteInfo", JSON.stringify(this.state.guaranteInfo));
    formData2.append("emergencyInfo", JSON.stringify(this.state.emergencyInfo));
    formData2.append("sector", this.state.sector);

    var url = window.location.href.split("/");
    formData2.append("post_id", url[7]);
    formData2.append("hidden_id", this.props.insertIds);

    if (this.state.sponserInfo.length === 0) {
      this.setState({ sponserError2: "Please Add Sonser Information" });
    } else if (this.state.guaranteInfo.length === 0) {
      this.setState({ guaranteError2: "Please Add Guarante Information" });
    } else if (this.state.emergencyInfo.length === 0) {
      this.setState({ emergencyError2: "Please Add Emergency Information" });
    } else {
      this.props.applyOneData(formData2);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    var url = window.location.href.split("/");
    //console.log(url);
    // this.props.applyAll(url[4], url[5], url[6], url[7]);
    if (url[6] === "1") {
      if (url[4] === "new") {
        if (url[7] === "null") {
          this.props.applyOneDataGet();
        } else {
          // not null > 0
          // need word
          this.props.applyAll(url[4], url[5], url[6], url[7]);
        }
      } else {
        //old
        // work with id
        this.props.applyOneDataGet22(url[5]);
      }
    } else {
      //agent
      if (url[4] === "new") {
        console.log("agent", "new");
      } else {
        //old
        this.props.applyOneDataGet22(url[5]);
        // console.log("agent", "old");
      }
    }

    // var data = JSON.parse(localStorage.getItem("user"));
    // if (data.type === 1) {
    //   this.props.applyOneDataGet();
    // }
    // if (data.type === 2) {
    //   var id = window.location.href.split("/");
    //   if (id.length === 5) {
    //     if (id[4] != "000") {
    //       this.props.applyOneDataGet22(id[4]);
    //     }
    //   }
    // }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.family_name !== this.props.family_name) {
      this.setState({ family_name: this.props.family_name });
    }
    if (previousProps.given_name !== this.props.given_name) {
      this.setState({ given_name: this.props.given_name });
    }
    if (previousProps.full_name !== this.props.full_name) {
      this.setState({ full_name: this.props.full_name });
    }
    if (previousProps.china_name !== this.props.china_name) {
      this.setState({ china_name: this.props.china_name });
    }
    if (previousProps.gender !== this.props.gender) {
      this.setState({ gender: this.props.gender });
    }
    if (previousProps.nationality !== this.props.nationality) {
      this.setState({ nationality: this.props.nationality });
    }
    if (previousProps.dob !== this.props.dob) {
      this.setState({ dob: this.props.dob });
    }
    if (previousProps.birth_country !== this.props.birth_country) {
      this.setState({ birth_country: this.props.birth_country });
    }
    if (previousProps.photo !== this.props.photo) {
      this.setState({ photo: this.props.photo });
    }
    if (previousProps.native_language !== this.props.native_language) {
      this.setState({ native_language: this.props.native_language });
    }
    if (previousProps.marital_status !== this.props.marital_status) {
      this.setState({ marital_status: this.props.marital_status });
    }
    if (previousProps.religion !== this.props.religion) {
      this.setState({ religion: this.props.religion });
    }
    if (previousProps.occupation !== this.props.occupation) {
      this.setState({ occupation: this.props.occupation });
    }
    if (previousProps.affiliate !== this.props.affiliate) {
      this.setState({ affiliate: this.props.affiliate });
    }
    if (previousProps.wechat !== this.props.wechat) {
      this.setState({ wechat: this.props.wechat });
    }
    if (previousProps.phone !== this.props.phone) {
      this.setState({ phone: this.props.phone });
    }
    if (previousProps.code !== this.props.code) {
      this.setState({ code: this.props.code });
    }
    if (previousProps.birth_place !== this.props.birth_place) {
      this.setState({ birth_place: this.props.birth_place });
    }
    if (previousProps.parmanent_home !== this.props.parmanent_home) {
      this.setState({ parmanent_home: this.props.parmanent_home });
    }
    if (previousProps.parmanent_city !== this.props.parmanent_city) {
      this.setState({ parmanent_city: this.props.parmanent_city });
    }
    if (previousProps.parmanent_country !== this.props.parmanent_country) {
      this.setState({ parmanent_country: this.props.parmanent_country });
    }
    if (previousProps.parmanent_zipcode !== this.props.parmanent_zipcode) {
      this.setState({ parmanent_zipcode: this.props.parmanent_zipcode });
    }
    if (previousProps.parmanent_phone !== this.props.parmanent_phone) {
      this.setState({ parmanent_phone: this.props.parmanent_phone });
    }
    if (previousProps.parmanent_email !== this.props.parmanent_email) {
      this.setState({ parmanent_email: this.props.parmanent_email });
    }
    if (previousProps.current_home !== this.props.current_home) {
      this.setState({ current_home: this.props.current_home });
    }
    if (previousProps.current_city !== this.props.current_city) {
      this.setState({ current_city: this.props.current_city });
    }
    if (previousProps.current_country !== this.props.current_country) {
      this.setState({ current_country: this.props.current_country });
    }
    if (previousProps.current_zipcode !== this.props.current_zipcode) {
      this.setState({ current_zipcode: this.props.current_zipcode });
    }
    if (previousProps.current_phone !== this.props.current_phone) {
      this.setState({ current_phone: this.props.current_phone });
    }
    if (previousProps.current_email !== this.props.current_email) {
      this.setState({ current_email: this.props.current_email });
    }
    if (previousProps.passport_no !== this.props.passport_no) {
      this.setState({ passport_no: this.props.passport_no });
    }
    if (previousProps.old_passport !== this.props.old_passport) {
      this.setState({ old_passport: this.props.old_passport });
    }
    if (
      previousProps.new_expiry_date_start !== this.props.new_expiry_date_start
    ) {
      this.setState({
        new_expiry_date_start: this.props.new_expiry_date_start,
      });
    }
    if (previousProps.new_expiry_date_end !== this.props.new_expiry_date_end) {
      this.setState({ new_expiry_date_end: this.props.new_expiry_date_end });
    }
    if (previousProps.old_expaire_date !== this.props.old_expaire_date) {
      this.setState({ old_expaire_date: this.props.old_expaire_date });
    }
    if (previousProps.emabassy !== this.props.emabassy) {
      this.setState({ emabassy: this.props.emabassy });
    }

    if (previousProps.studed_in_china !== this.props.studed_in_china) {
      this.setState({ studed_in_china: this.props.studed_in_china });
    }
    if (previousProps.visa_type !== this.props.visa_type) {
      this.setState({ visa_type: this.props.visa_type });
    }
    if (previousProps.visa_no !== this.props.visa_no) {
      this.setState({ visa_no: this.props.visa_no });
    }
    if (previousProps.in_china_new !== this.props.in_china_new) {
      this.setState({ in_china_new: this.props.in_china_new });
    }
    if (previousProps.visa_expairy_date !== this.props.visa_expairy_date) {
      this.setState({ visa_expairy_date: this.props.visa_expairy_date });
    }
    if (previousProps.study_period_china !== this.props.study_period_china) {
      this.setState({ study_period_china: this.props.study_period_china });
    }
    if (
      previousProps.study_period_china_end !== this.props.study_period_china_end
    ) {
      this.setState({
        study_period_china_end: this.props.study_period_china_end,
      });
    }
    if (previousProps.china_institute !== this.props.china_institute) {
      this.setState({ china_institute: this.props.china_institute });
    }

    if (previousProps.sponser !== this.props.sponser) {
      this.setState({ sponserInfo: this.props.sponser });
    }
    if (previousProps.gurdian !== this.props.gurdian) {
      this.setState({ guaranteInfo: this.props.gurdian });
    }
    if (previousProps.emergency !== this.props.emergency) {
      this.setState({ emergencyInfo: this.props.emergency });
    }

    if (previousProps.appStatus !== this.props.appStatus) {
      this.setState({ appStatus: this.props.appStatus });
    }
  }

  same_as_current = (e) => {
    const { name, type, checked } = e.target;

    if (checked) {
      this.setState({ current_home: this.state.parmanent_home });
      this.setState({ current_city: this.state.parmanent_city });
      this.setState({ current_country: this.state.parmanent_country });
      this.setState({ current_zipcode: this.state.parmanent_zipcode });
      this.setState({ current_phone: this.state.parmanent_phone });
      this.setState({ current_email: this.state.parmanent_email });
    } else {
      this.setState({ current_home: "" });
      this.setState({ current_city: "" });
      this.setState({ current_country: "" });
      this.setState({ current_zipcode: "" });
      this.setState({ current_phone: "" });
      this.setState({ current_email: "" });
    }
  };

  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    const idddd = this.props.insertIds > 0 ? this.props.insertIds : "000";
    // console.log(idddd);
    var url = window.location.href.split("/");

    return (
      <>
        <UserHeader />
        <div class="">
          <div class="container">
            <div class="row">
              <ApplyData pageType="newtable" data={idddd} />
            </div>

            {this.props.insertIds > 0 ? (
              <Ber2 page="one" newID={this.props.insertIds} />
            ) : (
              <Ber page="one" />
            )}

            <div class="row mb-5">
              <div class="col-lg-12">
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <input type="hidden" name="user_id" value={data.id} />
              <input type="hidden" name="hidden_id" value={idddd} />

              <div class="row application" style={{ textAlign: `left` }}>
                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    个人基本信息 <br /> Personal Information
                  </p>
                </div>
                <div class="col-lg-6" style={{ textAlign: `left` }}>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        护照姓 Passport Family Name{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="family_name"
                          onChange={this.onChangeData}
                          value={this.state.family_name}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        护照名 Given Name <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="given_name"
                          onChange={this.onChangeData}
                          value={this.state.given_name}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        护照全名 Full Name as shown on your passport
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="full_name"
                          onChange={this.onChangeData}
                          value={this.state.full_name}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> 中文名 Chinese Name </div>
                    </div>
                    <div class="col-md-8">
                      <div class="">
                        <input
                          type="text"
                          class="form-control"
                          name="china_name"
                          onChange={this.onChangeData}
                          defaultValue={this.state.china_name}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        性别 Gender
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          required="required"
                          name="gender"
                          onChange={this.onChangeData}
                          value={this.state.gender}
                        >
                          <option value="">Select Gender</option>
                          <option value="女性 Female">女性 Female</option>
                          <option value="男性 Male">男性 Male</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        国籍 Nationality <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="nationality"
                          onChange={this.onChangeData}
                          value={this.state.nationality}
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        出生日期 Date of Birth
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="date"
                          class="form-control"
                          required="required"
                          name="dob"
                          onChange={this.onChangeData}
                          defaultValue={this.state.dob}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        出生国家 Country of Birth{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="birth_country"
                          onChange={this.onChangeData}
                          value={this.state.birth_country}
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country />
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        照片 Photo <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          required={
                            this.state.photo == "" || this.state.photo == null
                              ? true
                              : false
                          }
                          type="file"
                          id="photo"
                          class="form-control"
                          accept="image/*"
                          name="photo"
                          onChange={this.onChangeData}
                          defaultValue={this.state.photo}
                        />
                        *照片参考格式：彩色2寸免冠证件照，白色背景无边框，头部占照片尺寸的2/3，照片尺寸不能低于320*240像素，高宽比为4：3，大小为100-500KB，JPG格式
                        (*.jpg,*.jpeg,*.png). 点击裁剪照片 Reference:JPG Color
                        2"Bareheaded Certificate (White Background Without
                        Border) The head is 2/3 of the size of the photograph,
                        the photo size is not less than 320 x 240 pixels, the
                        aspect ratio is 4:3, the size is 100 - 500 kb).
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        母语 Native Language{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          required="required"
                          name="native_language"
                          onChange={this.onChangeData}
                          value={this.state.native_language}
                        >
                          <Language />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        婚姻状态 Marital Status{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          type="text"
                          class="form-control"
                          required="required"
                          name="marital_status"
                          onChange={this.onChangeData}
                          value={this.state.marital_status}
                        >
                          <option value="">Select One</option>
                          <option value="已婚 Married">已婚 Married</option>
                          <option value="未婚 Single">未婚 Single</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        宗教 Religion <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          type="text"
                          class="form-control"
                          required="required"
                          name="religion"
                          onChange={this.onChangeData}
                          value={this.state.religion}
                        >
                          <option value=""> 清选择 Please Choose</option>
                          <option value="无宗教信仰 Non-religion">
                            无宗教信仰 Non-religion
                          </option>
                          <option value="基督教 Christian">
                            基督教 Christian
                          </option>
                          <option value="伊斯兰教 Islam">伊斯兰教 Islam</option>
                          <option value=" 佛教 Buddhism"> 佛教 Buddhism</option>
                          <option value="摩门教 Mormonism">
                            摩门教 Mormonism
                          </option>
                          <option value="无神论 Atheism">无神论 Atheism</option>
                          <option value="犹太教 Judaism">犹太教 Judaism</option>
                          <option value="天主教 Catholicism">
                            {" "}
                            天主教 Catholicism
                          </option>
                          <option value="东正教 Orthodox Church">
                            东正教 Orthodox Church
                          </option>
                          <option value="印度教 Hinduism">
                            {" "}
                            印度教 Hinduism
                          </option>
                          <option value="其他 other">其他 other</option>
                          <option value="道教 Taoism"> 道教 Taoism</option>
                          <option value="泛灵论 Polytheism">
                            {" "}
                            泛灵论 Polytheism
                          </option>
                          <option value="鬼教 Ghost religion">
                            鬼教 Ghost religion
                          </option>
                          <option value=" 新教 Protestantism">
                            {" "}
                            新教 Protestantism
                          </option>
                          <option value="儒教 Confucianism">
                            {" "}
                            儒教 Confucianism
                          </option>
                          <option value="拜物教 fetishism">
                            {" "}
                            拜物教 fetishism
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        职业 Occupation <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          type="text"
                          class="form-control"
                          required="required"
                          name="occupation"
                          onChange={this.onChangeData}
                          value={this.state.occupation}
                        >
                          <Occupation />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        工作或学习单位 Employer or Institution Affiliated
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="affiliate"
                          onChange={this.onChangeData}
                          defaultValue={this.state.affiliate}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">微信 Wechat</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="wechat"
                          onChange={this.onChangeData}
                          defaultValue={this.state.wechat}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        联系电话 Phone/Mobile{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          required="required"
                          name="code"
                          onChange={this.onChangeData}
                          value={this.state.code}
                        >
                          <option value="">Country Code</option>

                          {Code.map((c, i) => {
                            return (
                              <option value={c.code}>
                                {c.code + " " + c.name}
                              </option>
                            );
                          })}
                        </select>

                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="phone"
                          onChange={this.onChangeData}
                          defaultValue={this.state.phone}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        出生地点 Place of Birth
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="birth_place"
                          onChange={this.onChangeData}
                          defaultValue={this.state.birth_place}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    通讯地址 <br />
                    Correspondence Address
                  </p>
                </div>
                <div class="col-lg-6">
                  <p style={{ padding: `15px` }}> 家庭地址 Home Address</p>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        详细地址 Detailed Address{" "}
                        <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="parmanent_home"
                          onChange={this.onChangeData}
                          defaultValue={this.state.parmanent_home}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        省/城市 City/Province{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="parmanent_city"
                          onChange={this.onChangeData}
                          defaultValue={this.state.parmanent_city}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        国家 Country <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="parmanent_country"
                          onChange={this.onChangeData}
                          value={this.state.parmanent_country}
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country />
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        邮编 Zipcode <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="parmanent_zipcode"
                          onChange={this.onChangeData}
                          defaultValue={this.state.parmanent_zipcode}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        电话/手机 Phone or Mobile{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="parmanent_phone"
                          onChange={this.onChangeData}
                          defaultValue={this.state.parmanent_phone}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        常用邮箱 Main Email{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="email"
                          class="form-control"
                          required="required"
                          name="parmanent_email"
                          onChange={this.onChangeData}
                          defaultValue={this.state.parmanent_email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p>当前地址 Current Address</p>
                  <input
                    type="checkbox"
                    name="same_as_current"
                    onChange={this.same_as_current}
                  />
                  与本国家庭通讯地址相同 The same as home address
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        详细地址 Detailed Address{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="current_home"
                          onChange={this.onChangeData}
                          defaultValue={this.state.current_home}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        省/城市 City/Province
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="current_city"
                          onChange={this.onChangeData}
                          defaultValue={this.state.current_city}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        国家 Country <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="current_country"
                          onChange={this.onChangeData}
                          value={this.state.current_country}
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        邮编 Zipcode <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="current_zipcode"
                          onChange={this.onChangeData}
                          defaultValue={this.state.current_zipcode}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        电话/手机 Phone or Mobile{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="current_phone"
                          onChange={this.onChangeData}
                          defaultValue={this.state.current_phone}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        常用邮箱 Main Email{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="email"
                          class="form-control"
                          required="required"
                          name="current_email"
                          onChange={this.onChangeData}
                          defaultValue={this.state.current_email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    证照信息 <br />
                    Passport and Visa Information
                  </p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        护照号码 Passport No
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="passport_no"
                          onChange={this.onChangeData}
                          defaultValue={this.state.passport_no}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">旧护照号码 Old Passport No</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="old_passport"
                          onChange={this.onChangeData}
                          defaultValue={this.state.old_passport}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        护照有效期 Passport Expiry Date{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="date"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.new_expiry_date_start}
                          name="new_expiry_date_start"
                        />
                        -
                        <input
                          type="date"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.new_expiry_date_end}
                          name="new_expiry_date_end"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        旧护照到期时间 Expiration of Old Passport
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="date"
                          class="form-control"
                          name="old_expaire_date"
                          onChange={this.onChangeData}
                          defaultValue={this.state.old_expaire_date}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      签证申请所在大使馆或领事馆 Which Chinese embassy or
                      consulate would you apply visa at
                      <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        required="required"
                        name="emabassy"
                        onChange={this.onChangeData}
                        value={this.state.emabassy}
                        class="form-control"
                      >
                        <option value="">Select Embassy</option>
                        <Country />
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    在华学习经历 <br />
                    Learning Experience In China
                  </p>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      是否曾经或当前在中国学习 Have you studied or whether
                      studying in China currently?{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <span style={{ paddingRight: `12px` }}>
                        <input
                          type="radio"
                          name="studed_in_china"
                          onChange={this.onChangeData}
                          required="required"
                          value="Yes"
                          checked={this.state.studed_in_china === "Yes"}
                        />
                        Yes
                        <input
                          type="radio"
                          name="studed_in_china"
                          onChange={this.onChangeData}
                          value="No"
                          required="required"
                          checked={this.state.studed_in_china === "No"}
                        />
                        No
                      </span>
                    </div>
                  </div>
                </div>
                {this.state.studed_in_china === "Yes" && (
                  <>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          签证类型 Visa Type{" "}
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <select
                            class="form-control"
                            required="required"
                            name="visa_type"
                            onChange={this.onChangeData}
                            value={this.state.visa_type}
                          >
                            <Visa />
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          签证号 - visa no{" "}
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="text"
                            class="form-control"
                            required="required"
                            name="visa_no"
                            onChange={this.onChangeData}
                            defaultValue={this.state.visa_no}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          当前是否在中国 Whether in China now?
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <span style={{ paddingRight: `12px` }}>
                            <input
                              type="radio"
                              required="required"
                              name="in_china_new"
                              onChange={this.onChangeData}
                              value="Yes"
                              checked={this.state.in_china_new === "Yes"}
                            />
                            Yes
                          </span>
                          <span>
                            <input
                              type="radio"
                              required="required"
                              name="in_china_new"
                              onChange={this.onChangeData}
                              value="No"
                              checked={this.state.in_china_new === "No"}
                            />
                            No
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          签证到期时间 Expiry Date{" "}
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="date"
                            class="form-control"
                            required="required"
                            name="visa_expairy_date"
                            onChange={this.onChangeData}
                            defaultValue={this.state.visa_expairy_date}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          在华学习期限 Duration of study in China
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="date"
                            required="required"
                            name="study_period_china"
                            onChange={this.onChangeData}
                            defaultValue={this.state.study_period_china}
                          />
                          to
                          <input
                            type="date"
                            required="required"
                            name="study_period_china_end"
                            onChange={this.onChangeData}
                            defaultValue={this.state.study_period_china_end}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          在华学习院校 The institution in China that you have
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="text"
                            class="form-control"
                            required="required"
                            name="china_institute"
                            onChange={this.onChangeData}
                            defaultValue={this.state.china_institute}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    经济担保人信息 <br />
                    Financial Sponsor's Information
                    <span className="colors star">*</span>
                  </p>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">姓名 Name</th>
                        <th scope="col">关系 Relationship</th>
                        <th scope="col">单位名称 Work Place</th>
                        <th scope="col">职业 Occupation</th>
                        <th scope="col">联系电话 Phone/Mobile</th>
                        <th scope="col">联系邮箱 Email</th>
                        <th scope="col">
                          <span className="addbtn" onClick={this.addSponser}>
                            <i class="fa-solid fa-plus"></i> 添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sponserInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.sponsor_name}</td>
                            <td>{s.sponsor_relation}</td>
                            <td>{s.sponsor_word_place}</td>
                            <td>{s.sponsor_occupation}</td>
                            <td>{s.sponsor_phone}</td>
                            <td>{s.sponsor_email}</td>
                            <td>
                              <span onClick={() => this.editSponser(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                                {"    "}
                              </span>
                              <span onClick={() => this.deleteSponser(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.sponser === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            姓名 Name <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="sponsor_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            单位名称 Work Place{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            class="form-control"
                            required="required"
                            name="sponsor_word_place"
                            onChange={this.onChangeData}
                            defaultValue={this.state.sponsor_word_place}
                          />
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            行业类型 Industry Type
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <select
                            class="form-control"
                            name="sponsor_industry"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.sponsor_industry}
                          >
                            <option value="">Select Industry</option>
                            <Industry />
                          </select>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系电话 Phone/Mobile{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="sponsor_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            关系 Relationship{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="sponsor_relation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.sponsor_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            职业 Occupation{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="sponsor_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.sponsor_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系邮箱 Email{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="email"
                              class="form-control"
                              required="required"
                              name="sponsor_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.sponserError}</p>

                      {this.state.sponserAdd === true && (
                        <span
                          onClick={this.submitSponser}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.sponserUpdate === true && (
                        <span
                          onClick={this.submitUpdateSponser}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update - 更新
                        </span>
                      )}
                      <span
                        onClick={this.cancleSponser}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    事务担保人信息 <br />
                    Guarantor's Information
                    <span className="colors star">*</span>
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">姓名 Name</th>
                        <th scope="col">关系 Relationship</th>
                        <th scope="col">单位名称 Work Place </th>
                        <th scope="col">职业 Occupation</th>
                        <th scope="col">联系电话 Phone/Mobile</th>
                        <th scope="col">联系邮箱 Email</th>
                        <th scope="col">
                          <span className="addbtn" onClick={this.addGuarante}>
                            <i class="fa-solid fa-plus"></i>添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.guaranteInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.guarante_name}</td>
                            <td>{s.guarante_relation}</td>
                            <td>{s.guarante_work_place}</td>
                            <td>{s.guarante_occupation}</td>
                            <td>{s.guarante_phone}</td>
                            <td>{s.guarante_email}</td>
                            <td>
                              <span onClick={() => this.editGuarante(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                              </span>
                              <span onClick={() => this.deleteGuarante(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.guarante === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Name <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="guarante_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            单位名称 Work Place{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="guarante_work_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_work_place}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            行业类型 Industry Type{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <select
                            class="form-control"
                            name="guarante_industry"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.guarante_industry}
                          >
                            <option value="">Select Industry</option>
                            <Industry />
                          </select>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系电话 Phone/Mobile{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="guarante_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            关系 Relationship{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="guarante_relation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.guarante_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            职业 Occupation{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="guarante_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.guarante_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系邮箱 Email{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="email"
                              class="form-control"
                              placeholder=" "
                              name="guarante_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.guaranteError}</p>
                      {this.state.guaranteAdd === true && (
                        <span
                          onClick={this.submitGuarante}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.guaranteUpdate === true && (
                        <span
                          onClick={this.submitUpdateGuarante}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update - 更新
                        </span>
                      )}
                      <span
                        onClick={this.cancleGuarante}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    紧急联系人 <br />
                    Emergency Contact
                    <span className="colors star">*</span>
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">姓名 Name</th>
                        <th scope="col">关系 Relationship</th>
                        <th scope="col">单位名称 Work Place </th>
                        <th scope="col">职业 Occupation</th>
                        <th scope="col">联系电话 Phone/Mobile</th>
                        <th scope="col">联系邮箱 Email</th>
                        <th scope="col">
                          <span className="addbtn" onClick={this.addEmergency}>
                            <i class="fa-solid fa-plus"></i>添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.emergencyInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.emergency_name}</td>
                            <td>{s.emergency_relation}</td>
                            <td>{s.emergency_work_place}</td>
                            <td>{s.emergency_occupation}</td>
                            <td>{s.emergency_phone}</td>
                            <td>{s.emergency_email}</td>
                            <td>
                              <span onClick={() => this.editEmergency(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                              </span>
                              <span onClick={() => this.deleteEmergency(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.emergency === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            姓名 Name <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              required="required"
                              class="form-control"
                              name="emergency_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            单位名称 Work Place{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="emergency_work_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_work_place}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            行业类型 Industry Type{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <select
                            class="form-control"
                            name="emergency_industry"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.emergency_industry}
                          >
                            <option value="">Select Industry</option>
                            <Industry />
                          </select>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系电话 Phone/Mobile{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="emergency_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            关系 Relationship{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="emergency_relation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.emergency_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            职业 Occupation{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="emergency_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.emergency_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系邮箱 Email{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="email"
                              class="form-control"
                              required="required"
                              name="emergency_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.emergencyError}</p>
                      {this.state.emergencyAdd === true && (
                        <span
                          onClick={this.submitEmergency}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.emergencyUpdate === true && (
                        <span
                          onClick={this.submitUpdateEmergency}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update - 更新
                        </span>
                      )}
                      <span
                        onClick={this.cancleEmergency}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
                {this.props.loader === true && (
                  <div class="alert alert-danger" role="alert">
                    We are working Please wait.
                  </div>
                )}
                {this.state.sponserError2}
                {this.state.guaranteError2}
                {this.state.emergencyError2}

                <div class="col-lg-12 text-center my-5">
                  {this.props.loader === false &&
                    this.props.appStatus == "0" && (
                      <button type="submit" class="btn-one">
                        保存 Save
                      </button>
                    )}

                  {this.props.insertIds > 0 ? (
                    <Link
                      to={
                        `/apply-two/old/` +
                        this.props.insertIds +
                        "/" +
                        data.type +
                        "/" +
                        url[7]
                      }
                      class="btn-one"
                    >
                      下一步 Next
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,

    appStatus: state.auth.datastatus,

    insertIds: state.auth.insertID,

    stepone: state.auth.stepone,
    steponeid: state.auth.steponeid,

    family_name: state.auth.family_name,
    given_name: state.auth.given_name,
    full_name: state.auth.full_name,
    china_name: state.auth.china_name,
    gender: state.auth.gender,
    nationality: state.auth.nationality,
    dob: state.auth.dob,
    birth_country: state.auth.birth_country,
    photo: state.auth.photo,
    native_language: state.auth.native_language,
    marital_status: state.auth.marital_status,
    religion: state.auth.religion,
    occupation: state.auth.occupation,
    affiliate: state.auth.affiliate,
    wechat: state.auth.wechat,
    phone: state.auth.phone,
    code: state.auth.code,
    birth_place: state.auth.birth_place,

    parmanent_home: state.auth.parmanent_home,
    parmanent_city: state.auth.parmanent_city,
    parmanent_country: state.auth.parmanent_country,
    parmanent_zipcode: state.auth.parmanent_zipcode,
    parmanent_phone: state.auth.parmanent_phone,
    parmanent_email: state.auth.parmanent_email,

    current_home: state.auth.current_home,
    current_city: state.auth.current_city,
    current_country: state.auth.current_country,
    current_zipcode: state.auth.current_zipcode,
    current_phone: state.auth.current_phone,
    current_email: state.auth.current_email,

    passport_no: state.auth.passport_no,
    old_passport: state.auth.old_passport,
    new_expiry_date_start: state.auth.new_expiry_date_start,
    new_expiry_date_end: state.auth.new_expiry_date_end,
    old_expaire_date: state.auth.old_expaire_date,
    emabassy: state.auth.emabassy,

    studed_in_china: state.auth.studed_in_china,
    visa_type: state.auth.visa_type,
    visa_no: state.auth.visa_no,
    in_china_new: state.auth.in_china_new,
    visa_expairy_date: state.auth.visa_expairy_date,
    study_period_china: state.auth.study_period_china,
    study_period_china_end: state.auth.study_period_china_end,
    china_institute: state.auth.china_institute,

    sponser: state.auth.sponser,
    gurdian: state.auth.gurdian,
    emergency: state.auth.emergency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationOne: (data, ii) => dispatch(applicationOne(data, ii)),
  applicationOneData: (data, post) => dispatch(applicationOneData(data, post)),
  applyOneData: (data) => dispatch(applyOneData(data)),
  applyOneDataGet: () => dispatch(applyOneDataGet()),
  applyOneDataGet22: (id) => dispatch(applyOneDataGet22(id)),
  applyAll: (apply_type, apply_id, user_type, explore) =>
    dispatch(applyAll(apply_type, apply_id, user_type, explore)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyOne);
