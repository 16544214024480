import { useState } from "react";
import { connect } from "react-redux";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import Circuler from "../helper/Circuler";
import { useEffect } from "react";
import { circulerShow } from "../../services/actions/AuthAction";

function AllApplication(props) {
  const [sector, setSector] = useState("Study");
  const [intake, setInatke] = useState("all");
  const [id, setId] = useState("all");

  const checkHandler = (a) => {
    setSector(a);
    setId("all");
    setInatke("all");
    props.circulerShow(sector, "all", "all");
  };

  const checkHandler2 = (a) => {
    setId(a);
    props.circulerShow(sector, id, intake);
  };

  const checkHandler3 = (a) => {
    setInatke(a);
    props.circulerShow(sector, id, intake);
  };

  useEffect(() => {
    props.circulerShow(sector, id, intake);
  }, [props.circulr, id, sector, intake]);

  return (
    <>
      <Navber />
      <div className="find">
        <div className="container py-5 ">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="display-4 fsw-bold">
                Find Your<span className="cor-1"> Dream Place </span>{" "}
              </h2>
              <div className="py-5">
                <span
                  onClick={() => checkHandler("Study")}
                  class={sector === "Study" ? "btn-one" : "btn-three"}
                >
                  Study
                </span>
                <span
                  onClick={() => checkHandler("Work")}
                  class={sector === "Work" ? "btn-one" : "btn-three"}
                >
                  Work
                </span>
                <span
                  onClick={() => checkHandler("Tourist")}
                  class={sector === "Tourist" ? "btn-one" : "btn-three"}
                >
                  Tour
                </span>
              </div>
            </div>
          </div>
          <div className="row pt-5 ">
            <div className="col-md-4">
              {props.explores.map((u, i) => {
                return (
                  <p
                    className={
                      sector === u.type
                        ? id === u.id
                          ? "btn-one tab shows"
                          : "tab shows"
                        : "tab hides"
                    }
                    onClick={() => checkHandler2(u.id)}
                  >
                    {u.name}
                  </p>
                );
              })}
              {sector === "Study" && (
                <p
                  class={id === "all" ? "btn-one tab shows" : "tab shows"}
                  onClick={() => checkHandler2("all")}
                >
                  Show All Study
                </p>
              )}
              {sector === "Tourist" && (
                <p
                  class={id === "all" ? "btn-one tab shows" : "tab shows"}
                  onClick={() => checkHandler2("all")}
                >
                  Show All Tour
                </p>
              )}
              {sector === "Work" && (
                <p
                  class={id === "all" ? "btn-one tab shows" : "tab shows"}
                  onClick={() => checkHandler2("all")}
                >
                  Show All Work
                </p>
              )}
            </div>
            <div className="col-md-8">
              <div className="table-responsive py-4">
                <p>
                  <span
                    onClick={() => checkHandler3("三月入学 March intake")}
                    class={
                      intake === "三月入学 March intake"
                        ? "btn-one"
                        : "btn-three"
                    }
                  >
                    三月入学 March intake
                  </span>

                  <span
                    onClick={() => checkHandler3("九月入学 September intake")}
                    class={
                      intake === "九月入学 September intake"
                        ? "btn-one"
                        : "btn-three"
                    }
                  >
                    九月入学 September intake
                  </span>

                  <span
                    onClick={() => checkHandler3("all")}
                    class={intake === "all" ? "btn-one" : "btn-three"}
                  >
                    All Intake
                  </span>
                </p>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>No</span>
                      </th>
                      <th scope="col">
                        <span>Institute</span>
                      </th>
                      <th scope="col">
                        <span>
                          Country <br />
                          Program
                        </span>
                      </th>
                      {sector === "Study" && (
                        <th scope="col">
                          <span>End Date</span>
                        </th>
                      )}

                      <th scope="col">
                        <span>Details</span>
                      </th>
                      <th scope="col">
                        <span>Explore</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <Circuler sourse="all" type={id} sector={sector} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    uniDetai: state.auth.blogDetai,
    explores: state.auth.explores,
    circulr: state.auth.circulr,
  };
};

export default connect(mapStateToProps, { circulerShow })(AllApplication);
