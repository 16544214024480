import React, { useEffect } from "react";
import {
  applicationByID,
  applicationOneData,
  unknownOneData,
  AgentApplicationOneData,
  applyOneDataGet,
} from "../../services/actions/AuthAction";
import { connect } from "react-redux";

function ApplicationDataP(props) {
  var data = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (data.type === 1) {
      if (props.pageType === "view") {
        props.applicationByID(props.post);
      } else if (props.pageType === "unknoun") {
        props.unknownOneData(data.id);
      } else if (props.pageType === "newtable") {
        props.applyOneDataGet();
      } else {
        props.applicationOneData(data.id, props.post);
      }
    }
    if (data.type === 2) {
      if (props.pageType === "unknoun") {
        props.applicationByID(props.post);
      } else {
        props.applicationByID(props.post);
      }
    }
  }, []);

  return (
    <div class="col-lg-5 mx-auto text-center ">
      {props.stepone.length === undefined ? (
        <img src={props.stepone.photo} alt="" class="img-fluid" />
      ) : (
        <img src="/image/download.jpeg" alt="" class="img-fluid" />
      )}

      <p class="h4">
        {props.stepone.length === undefined
          ? props.stepone.full_name
          : "Applicant Name"}
      </p>
      <p class="h4">
        {props.stepone.length === undefined
          ? props.stepone.passport_no
          : "Applicant Passport"}
        |
        {props.stepone.length === undefined
          ? props.stepone.p_country
          : "Applicant Country"}
      </p>
      <p class="h4">
        {props.stepone.length === undefined
          ? props.stepone.programe
          : "Application Programe"}
        |
        {props.stepone.length === undefined
          ? props.stepone.payment === 1
            ? "Paid"
            : "Not Paid"
          : "Not Paid"}
      </p>
      <p class="py-4">
        <span class="btn-one">View Summary!</span>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    about: state.auth.aboutt,
    stepone: state.auth.stepone,
  };
};

export default connect(mapStateToProps, {
  applicationOneData,
  applicationByID,
  unknownOneData,
  AgentApplicationOneData,
  applyOneDataGet,
})(ApplicationDataP);
