import React from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import {
  applicationTwo,
  applicationTwoData,
  applyTwoData,
  applyTwoDataGet,
  circulerDetails,
} from "../../services/actions/AuthAction";
import { Link } from "react-router-dom";
import Country from "../helper/Country";
import Industry from "../helper/Industry";
import Relation from "../helper/Relation";
import Occupation from "../helper/Occupation";
import Degree from "../helper/Degree";
import Marks from "../helper/Marks";
import CertificateType from "../helper/CertificateType";
import Hsk from "../helper/Hsk";
import SchoolType from "../helper/SchoolType";
import ApplyData from "../helper/ApplyData";
import RefRelation from "../helper/RefRelation";
import Code from "../helper/Code";
import Proficiency from "../helper/Proficiency";
import RefType from "../helper/RefType";
import Ber from "../helper/Ber";
import Degree2 from "../helper/Degree2";

class ApplyTwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      two: 1,
      sector: "",

      reference: false,
      reference_name: "",
      reference_work_place: "",
      reference_phone: "",
      reference_relation: "",
      reference_occupation: "",
      reference_email: "",
      referenceInfo: [],
      referenceError: "",
      referenceAdd: true,
      referenceUpdate: false,
      referenceItem: null,

      degreeInput: false,
      degree: "",
      school_type: "",
      country: "",
      school_name: "",
      attendence_form: "",
      attendence_to: "",
      field_study: "",
      major_deploma: "",
      contact: "",
      phone: "",
      email: "",
      remarks: "",
      degreeInfo: [],
      degreeError: "",
      degreeAdd: true,
      degreeUpdate: false,
      degreeItem: null,

      work: false,
      work_attendence_form: "",
      work_attendence_to: "",
      work_work_place: "",
      work_industry_type: "",
      work_occupation: "",
      work_phone: "",
      work_contact: "",
      work_email: "",
      workInfo: [],
      workError: "",
      workAdd: true,
      workUpdate: false,
      workItem: null,

      family: false,
      family_name: "",
      family_relation: "",
      family_nationality: "",
      family_live: "",
      family_institute: "",
      family_ins_type: "",
      family_occupation: "",
      family_phone: "",
      family_email: "",
      familyInfo: [],
      familyError: "",
      familyError2: "",
      familyAdd: true,
      familyUpdate: false,
      familyItem: null,

      research: false,
      research_year: "",
      research_essay: "",
      research_artical: "",
      research_title: "",
      research_level: "",
      research_auther: "",
      research_ranking: "",
      research_volumes: "",
      research_inclusion: "",
      researchInfo: [],
      researchError: "",
      researchAdd: true,
      researchUpdate: false,
      researchItem: null,

      mejor_subject: "",
      teaching_language: "",
      mejor_degree: "",
      expected_school: "",
      mejor_year: "",
      march_intake: "",
      september_intake: "",
      reference_type: "",
      reference_no: "",
      rcode: "",

      english_proficiency: "",
      ielts: "",
      toefl: "",
      gre: "",
      gmat: "",
      duolingo: "",
      toeic: "",
      china_proficiency: "",
      hsk_level: "",
      hsk_score: "",
      hsk_no: "",
      listening_score: "",
      reading_score: "",
      writing_score: "",
      chinese_start: "",
      chinese_end: "",
      teacher_own: "",
      chinese_institute: "",
      other_english: "",

      highest_degree: "",
      schoolname: "",
      highest_mark: "",
      certificate_type: "",
      any_failer: "",
      is_it_publish: "",
      have_work: "",
      work_china: "",
      working_start: "",
      working_end: "",
    };
  }
  // research
  addResearch = () => {
    this.setState({ research: true });
    this.setState({ researchAdd: true });
    this.setState({ researchUpdate: false });
  };
  cancleResearch = () => {
    this.setState({ research: false });
    this.setState({ researchAdd: false });
    this.setState({ researchUpdate: false });
    this.setState({ research_year: "" });
    this.setState({ research_essay: "" });
    this.setState({ research_artical: "" });
    this.setState({ research_title: "" });
    this.setState({ research_level: "" });
    this.setState({ research_auther: "" });
    this.setState({ research_ranking: "" });
    this.setState({ research_volumes: "" });
    this.setState({ research_inclusion: "" });
  };
  submitResearch = () => {
    const item = {
      research_year: this.state.research_year,
      research_essay: this.state.research_essay,
      research_artical: this.state.research_artical,
      research_title: this.state.research_title,
      research_level: this.state.research_level,
      research_auther: this.state.research_auther,
      research_ranking: this.state.research_ranking,
      research_volumes: this.state.research_volumes,
      research_inclusion: this.state.research_inclusion,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 9) {
      this.setState({ researchError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        researchInfo: [...prevState.researchInfo, item],
      }));
      this.setState({ researchError: "" });
      this.setState({ research: false });
      this.setState({ research_year: "" });
      this.setState({ research_essay: "" });
      this.setState({ research_artical: "" });
      this.setState({ research_title: "" });
      this.setState({ research_level: "" });
      this.setState({ research_auther: "" });
      this.setState({ research_ranking: "" });
      this.setState({ research_volumes: "" });
      this.setState({ research_inclusion: "" });
    }
  };

  submitUpdateResearch = () => {
    const item = {
      research_year: this.state.research_year,
      research_essay: this.state.research_essay,
      research_artical: this.state.research_artical,
      research_title: this.state.research_title,
      research_level: this.state.research_level,
      research_auther: this.state.research_auther,
      research_ranking: this.state.research_ranking,
      research_volumes: this.state.research_volumes,
      research_inclusion: this.state.research_inclusion,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 9) {
      this.setState({ researchError: "All * mark fields are requird." });
    } else {
      const { researchInfo } = this.state;
      researchInfo[this.state.researchItem] = item;

      this.setState({
        researchInfo: researchInfo,
      });

      this.setState({ researchError: "" });
      this.setState({ research: false });
      this.setState({ research_year: "" });
      this.setState({ research_essay: "" });
      this.setState({ research_artical: "" });
      this.setState({ research_title: "" });
      this.setState({ research_level: "" });
      this.setState({ research_auther: "" });
      this.setState({ research_ranking: "" });
      this.setState({ research_volumes: "" });
      this.setState({ research_inclusion: "" });
    }
  };

  editResearch(si) {
    this.setState({ researchAdd: false });
    this.setState({ researchUpdate: true });
    this.setState({ researchItem: si });
    this.setState({ research: true });

    const ii = this.state.researchInfo[si];

    this.setState({ research_year: ii.research_year });
    this.setState({ research_essay: ii.research_essay });
    this.setState({ research_artical: ii.research_artical });
    this.setState({ research_title: ii.research_title });
    this.setState({ research_level: ii.research_level });
    this.setState({ research_auther: ii.research_auther });
    this.setState({ research_ranking: ii.research_ranking });
    this.setState({ research_volumes: ii.research_volumes });
    this.setState({ research_inclusion: ii.research_inclusion });
  }
  deleteResearch(ix) {
    this.state.researchInfo.splice(ix, 1);
    this.setState({
      researchInfo: this.state.researchInfo,
    });
  }
  // end research
  // start family
  addFamily = () => {
    this.setState({ family: true });
    this.setState({ familyAdd: true });
    this.setState({ familyUpdate: false });
  };

  cancleFamily = () => {
    this.setState({ family: false });
    this.setState({ familyAdd: false });
    this.setState({ familyUpdate: false });
    this.setState({ family_name: "" });
    this.setState({ family_relation: "" });
    this.setState({ family_nationality: "" });
    this.setState({ family_live: "" });
    this.setState({ family_institute: "" });
    this.setState({ family_ins_type: "" });
    this.setState({ family_occupation: "" });
    this.setState({ family_phone: "" });
    this.setState({ family_email: "" });
  };

  submitFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,
      family_nationality: this.state.family_nationality,
      family_live: this.state.family_live,
      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 9) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        familyInfo: [...prevState.familyInfo, item],
      }));
      this.setState({ familyError: "" });
      this.setState({ familyError2: "" });
      this.setState({ family: false });

      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_nationality: "" });
      this.setState({ family_live: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  submitUpdateFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,
      family_nationality: this.state.family_nationality,
      family_live: this.state.family_live,
      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 9) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      const { familyInfo } = this.state;
      familyInfo[this.state.familyItem] = item;

      this.setState({
        familyInfo: familyInfo,
      });

      this.setState({ familyError: "" });
      this.setState({ family: false });

      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_nationality: "" });
      this.setState({ family_live: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  editFamily(si) {
    this.setState({ familyAdd: false });
    this.setState({ familyUpdate: true });
    this.setState({ familyItem: si });
    this.setState({ family: true });

    const ii = this.state.familyInfo[si];

    this.setState({ family_name: ii.family_name });
    this.setState({ family_relation: ii.family_relation });
    this.setState({ family_nationality: ii.family_nationality });
    this.setState({ family_live: ii.family_live });
    this.setState({ family_institute: ii.family_institute });
    this.setState({ family_ins_type: ii.family_ins_type });
    this.setState({ family_occupation: ii.family_occupation });
    this.setState({ family_phone: ii.family_phone });
    this.setState({ family_email: ii.family_email });
  }
  deleteFamily(ix) {
    this.state.familyInfo.splice(ix, 1);
    this.setState({
      familyInfo: this.state.familyInfo,
    });
  }

  //end family
  //work
  addWork = () => {
    this.setState({ work: true });
    this.setState({ workAdd: true });
    this.setState({ workUpdate: false });
  };

  cancleWork = () => {
    this.setState({ work: false });
    this.setState({ workAdd: false });
    this.setState({ workUpdate: false });

    this.setState({ work_attendence_form: "" });
    this.setState({ work_attendence_to: "" });
    this.setState({ work_work_place: "" });
    this.setState({ work_industry_type: "" });
    this.setState({ work_occupation: "" });
    this.setState({ work_phone: "" });
    this.setState({ work_contact: "" });
    this.setState({ work_email: "" });
  };

  submitWork = () => {
    const item = {
      work_attendence_form: this.state.work_attendence_form,
      work_attendence_to: this.state.work_attendence_to,
      work_work_place: this.state.work_work_place,
      work_industry_type: this.state.work_industry_type,
      work_occupation: this.state.work_occupation,
      work_phone: this.state.work_phone,
      work_contact: this.state.work_contact,
      work_email: this.state.work_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 8) {
      this.setState({ workError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        workInfo: [...prevState.workInfo, item],
      }));
      this.setState({ workError: "" });
      this.setState({ work: false });

      this.setState({ work_attendence_form: "" });
      this.setState({ work_attendence_to: "" });
      this.setState({ work_work_place: "" });
      this.setState({ work_industry_type: "" });
      this.setState({ work_occupation: "" });
      this.setState({ work_phone: "" });
      this.setState({ work_contact: "" });
      this.setState({ work_email: "" });
    }
  };

  submitUpdateWork = () => {
    const item = {
      work_attendence_form: this.state.work_attendence_form,
      work_attendence_to: this.state.work_attendence_to,
      work_work_place: this.state.work_work_place,
      work_industry_type: this.state.work_industry_type,
      work_occupation: this.state.work_occupation,
      work_phone: this.state.work_phone,
      work_contact: this.state.work_contact,
      work_email: this.state.work_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 8) {
      this.setState({ workError: "All * mark fields are requird." });
    } else {
      const { workInfo } = this.state;
      workInfo[this.state.workItem] = item;

      this.setState({
        workInfo: workInfo,
      });

      this.setState({ workError: "" });
      this.setState({ work: false });

      this.setState({ work_attendence_form: "" });
      this.setState({ work_attendence_to: "" });
      this.setState({ work_work_place: "" });
      this.setState({ work_industry_type: "" });
      this.setState({ work_occupation: "" });
      this.setState({ work_phone: "" });
      this.setState({ work_contact: "" });
      this.setState({ work_email: "" });
    }
  };

  editWork(si) {
    this.setState({ workAdd: false });
    this.setState({ workUpdate: true });
    this.setState({ workItem: si });
    this.setState({ work: true });

    const ii = this.state.workInfo[si];

    this.setState({ work_attendence_form: ii.work_attendence_form });
    this.setState({ work_attendence_to: ii.work_attendence_to });
    this.setState({ work_work_place: ii.work_work_place });
    this.setState({ work_industry_type: ii.work_industry_type });
    this.setState({ work_occupation: ii.work_occupation });
    this.setState({ work_phone: ii.work_phone });
    this.setState({ work_contact: ii.work_contact });
    this.setState({ work_email: ii.work_email });
  }
  deleteWork(ix) {
    this.state.workInfo.splice(ix, 1);
    this.setState({
      workInfo: this.state.workInfo,
    });
  }
  //end work
  //garante start
  addReference = () => {
    this.setState({ reference: true });
    this.setState({ referenceAdd: true });
    this.setState({ referenceUpdate: false });
  };

  cancleReference = () => {
    this.setState({ reference: false });
    this.setState({ referenceAdd: false });
    this.setState({ referenceUpdate: false });
    this.setState({ reference_name: "" });
    this.setState({ reference_work_place: "" });
    this.setState({ reference_phone: "" });
    this.setState({ reference_relation: "" });
    this.setState({ reference_occupation: "" });
    this.setState({ reference_email: "" });
  };

  submitReference = () => {
    const item = {
      reference_name: this.state.reference_name,
      reference_work_place: this.state.reference_work_place,
      reference_phone: this.state.reference_phone,
      reference_relation: this.state.reference_relation,
      reference_occupation: this.state.reference_occupation,
      reference_email: this.state.reference_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ referenceError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        referenceInfo: [...prevState.referenceInfo, item],
      }));
      this.setState({ referenceError: "" });
      this.setState({ reference: false });

      this.setState({ reference_name: "" });
      this.setState({ reference_work_place: "" });
      this.setState({ reference_phone: "" });
      this.setState({ reference_relation: "" });
      this.setState({ reference_occupation: "" });
      this.setState({ reference_email: "" });
    }
  };

  submitUpdateReference = () => {
    const item = {
      reference_name: this.state.reference_name,
      reference_work_place: this.state.reference_work_place,
      reference_phone: this.state.reference_phone,
      reference_relation: this.state.reference_relation,
      reference_occupation: this.state.reference_occupation,
      reference_email: this.state.reference_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ referenceError: "All * mark fields are requird." });
    } else {
      const { referenceInfo } = this.state;
      referenceInfo[this.state.referenceItem] = item;

      this.setState({
        referenceInfo: referenceInfo,
      });

      this.setState({ referenceError: "" });
      this.setState({ reference: false });

      this.setState({ reference_name: "" });
      this.setState({ reference_work_place: "" });
      this.setState({ reference_phone: "" });
      this.setState({ reference_relation: "" });
      this.setState({ reference_occupation: "" });
      this.setState({ reference_email: "" });
    }
  };

  editReference(si) {
    this.setState({ referenceAdd: false });
    this.setState({ referenceUpdate: true });
    this.setState({ referenceItem: si });
    this.setState({ reference: true });

    const ii = this.state.referenceInfo[si];
    this.setState({ reference_name: ii.reference_name });
    this.setState({ reference_work_place: ii.reference_work_place });
    this.setState({ reference_phone: ii.reference_phone });
    this.setState({ reference_relation: ii.reference_relation });
    this.setState({ reference_occupation: ii.reference_occupation });
    this.setState({ reference_email: ii.reference_email });
  }
  deleteReference(ix) {
    this.state.referenceInfo.splice(ix, 1);
    this.setState({
      referenceInfo: this.state.referenceInfo,
    });
  }
  //garante

  //Degree
  addDegree = () => {
    this.setState({ degreeInput: true });
    this.setState({ degreeAdd: true });
    this.setState({ degreeUpdate: false });
  };

  cancleDegree = () => {
    this.setState({ degreeInput: false });
    this.setState({ degreeAdd: false });
    this.setState({ degreeUpdate: false });
    this.setState({ degree: "" });
    this.setState({ school_type: "" });
    this.setState({ country: "" });
    this.setState({ school_name: "" });
    this.setState({ attendence_form: "" });
    this.setState({ attendence_to: "" });
    this.setState({ field_study: "" });
    this.setState({ major_deploma: "" });
    this.setState({ contact: "" });
    this.setState({ phone: "" });
    this.setState({ email: "" });
    this.setState({ remarks: "" });
  };

  submitDegree = () => {
    const item = {
      degree: this.state.degree,
      school_type: this.state.school_type,
      country: this.state.country,
      school_name: this.state.school_name,
      attendence_form: this.state.attendence_form,
      attendence_to: this.state.attendence_to,
      field_study: this.state.field_study,
      major_deploma: this.state.major_deploma,
      contact: this.state.contact,
      phone: this.state.phone,
      email: this.state.email,
      remarks: this.state.remarks,
    };

    if (
      item.degree === "" ||
      item.school_type === "" ||
      item.country === "" ||
      item.school_name === "" ||
      item.attendence_form === "" ||
      item.attendence_to === "" ||
      item.major_deploma === ""
    ) {
      this.setState({ degreeError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        degreeInfo: [...prevState.degreeInfo, item],
      }));
      this.setState({ degreeError: "" });
      this.setState({ degreeInput: false });

      this.setState({ degree: "" });
      this.setState({ school_type: "" });
      this.setState({ country: "" });
      this.setState({ school_name: "" });
      this.setState({ attendence_form: "" });
      this.setState({ attendence_to: "" });
      this.setState({ field_study: "" });
      this.setState({ major_deploma: "" });
      this.setState({ contact: "" });
      this.setState({ phone: "" });
      this.setState({ email: "" });
      this.setState({ remarks: "" });
    }
  };

  submitUpdateDegree = () => {
    const item = {
      degree: this.state.degree,
      school_type: this.state.school_type,
      country: this.state.country,
      school_name: this.state.school_name,
      attendence_form: this.state.attendence_form,
      attendence_to: this.state.attendence_to,
      field_study: this.state.field_study,
      major_deploma: this.state.major_deploma,
      contact: this.state.contact,
      phone: this.state.phone,
      email: this.state.email,
      remarks: this.state.remarks,
    };

    if (
      item.degree === "" ||
      item.school_type === "" ||
      item.country === "" ||
      item.school_name === "" ||
      item.attendence_form === "" ||
      item.attendence_to === "" ||
      item.major_deploma === ""
    ) {
      this.setState({ degreeError: "All * mark fields are requird." });
    } else {
      const { degreeInfo } = this.state;
      degreeInfo[this.state.degreeItem] = item;

      this.setState({
        degreeInfo: degreeInfo,
      });

      this.setState({ degreeError: "" });
      this.setState({ degree: false });

      this.setState({ degree_name: "" });
      this.setState({ school_type: "" });
      this.setState({ country: "" });
      this.setState({ school_name: "" });
      this.setState({ attendence_form: "" });
      this.setState({ attendence_to: "" });
      this.setState({ field_study: "" });
      this.setState({ major_deploma: "" });
      this.setState({ contact: "" });
      this.setState({ phone: "" });
      this.setState({ email: "" });
      this.setState({ remarks: "" });
    }
  };

  editDegree(si) {
    this.setState({ degreeAdd: false });
    this.setState({ degreeUpdate: true });
    this.setState({ degreeItem: si });
    this.setState({ degreeInput: true });

    const ii = this.state.degreeInfo[si];
    this.setState({ degree: JSON.parse(this.props.degrees)[si] });
    this.setState({ school_type: ii.school_type });
    this.setState({ country: ii.country });
    this.setState({ school_name: ii.school_name });
    this.setState({ attendence_form: ii.attendence_form });
    this.setState({ attendence_to: ii.attendence_to });
    this.setState({ field_study: ii.field_study });
    this.setState({ major_deploma: ii.major_deploma });
    this.setState({ contact: ii.contact });
    this.setState({ phone: ii.phone });
    this.setState({ email: ii.email });
    this.setState({ remarks: ii.remarks });
  }
  deleteDegree(ix) {
    this.state.degreeInfo.splice(ix, 1);
    this.setState({
      degreeInfo: this.state.degreeInfo,
    });
  }
  //Degree

  checkHandler = (a) => {
    this.setState({ sector: a });
  };

  onChangeData = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState({ [name]: checked });
    } else if (type === "file") {
      this.setState({ photo: e.target.files[0] });
    } else if (type === "radio") {
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  onSubmitDetails = (e) => {
    e.preventDefault();

    this.setState({ two: 2 });

    const formData = {
      mejor_subject: this.state.mejor_subject,
      teaching_language: this.state.teaching_language,

      mejor_degree:
        this.state.mejor_degree === ""
          ? this.props.circulrDetailsProgram
          : this.state.mejor_degree,

      expected_school:
        this.props.circulrDetailsUniversity === ""
          ? this.state.expected_school
          : this.props.circulrDetailsUniversity,

      mejor_year: this.state.mejor_year,

      march_intake:
        this.state.march_intake === ""
          ? this.props.circulrDetailsIntake
          : this.state.march_intake,

      september_intake: this.state.september_intake,
      reference_type: this.state.reference_type,
      reference_no: this.state.reference_no,
      rcode: this.state.rcode,

      english_proficiency: this.state.english_proficiency,
      ielts: this.state.ielts,
      toefl: this.state.toefl,
      gre: this.state.gre,
      gmat: this.state.gmat,
      duolingo: this.state.duolingo,
      toeic: this.state.toeic,
      china_proficiency: this.state.china_proficiency,
      hsk_level: this.state.hsk_level,
      hsk_score: this.state.hsk_score,
      hsk_no: this.state.hsk_no,
      listening_score: this.state.listening_score,
      reading_score: this.state.reading_score,
      writing_score: this.state.writing_score,
      chinese_start: this.state.chinese_start,
      chinese_end: this.state.chinese_end,
      teacher_own: this.state.teacher_own,
      chinese_institute: this.state.chinese_institute,
      other_english: this.state.other_english,

      highest_degree: this.state.highest_degree,
      schoolname: this.state.schoolname,
      highest_mark: this.state.highest_mark,
      certificate_type: this.state.certificate_type,
      any_failer: this.state.any_failer,
      is_it_publish: this.state.is_it_publish,

      have_work: this.state.have_work,
      work_china: this.state.work_china,
      working_start: this.state.working_start,
      working_end: this.state.working_end,

      referenceInfo: this.state.referenceInfo,
      degreeInfo: this.state.degreeInfo,
      workInfo: this.state.workInfo,
      familyInfo: this.state.familyInfo,
      researchInfo: this.state.researchInfo,
    };
    console.log(formData);

    if (this.state.familyInfo.length === 0) {
      this.setState({ familyError2: "Please Add Family Information" });
    } else {
      this.props.applyTwoData(window.location.href.split("/")[5], formData);
    }
  };

  componentDidMount() {
    this.props.applyTwoDataGet(window.location.href.split("/")[5]);
    window.scrollTo(0, 0);
    if (window.location.href.split("/")[7] !== "null") {
      this.props.circulerDetails(window.location.href.split("/")[7]);
    }
  }
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.bal !== this.props.bal) {
      this.setState({
        degreeInfo: this.props.bal,
      });
    }
    if (previousProps.refInfoo !== this.props.refInfoo) {
      this.setState({
        referenceInfo: this.props.refInfoo,
      });
    }
    if (previousProps.workInfo1 !== this.props.workInfo1) {
      this.setState({
        workInfo: this.props.workInfo1,
      });
    }
    if (previousProps.familyInfo1 !== this.props.familyInfo1) {
      this.setState({
        familyInfo: this.props.familyInfo1,
      });
    }
    if (previousProps.researchInfo1 !== this.props.researchInfo1) {
      this.setState({
        researchInfo: this.props.researchInfo1,
      });
    }
    if (previousProps.mejor_subject !== this.props.mejor_subject) {
      this.setState({
        mejor_subject: this.props.mejor_subject,
      });
    }
    if (previousProps.teaching_language !== this.props.teaching_language) {
      this.setState({
        teaching_language: this.props.teaching_language,
      });
    }
    if (previousProps.mejor_degree !== this.props.mejor_degree) {
      this.setState({
        mejor_degree: this.props.mejor_degree,
      });
    }
    if (previousProps.expected_school !== this.props.expected_school) {
      this.setState({
        expected_school: this.props.expected_school,
      });
    }
    if (previousProps.mejor_year !== this.props.mejor_year) {
      this.setState({
        mejor_year: this.props.mejor_year,
      });
    }
    if (previousProps.march_intake !== this.props.march_intake) {
      this.setState({
        march_intake: this.state.march_intake,
      });
    }
    if (previousProps.september_intake !== this.props.september_intake) {
      this.setState({
        september_intake: this.props.september_intake,
      });
    }
    if (previousProps.reference_type !== this.props.reference_type) {
      this.setState({
        reference_type: this.props.reference_type,
      });
    }
    if (previousProps.reference_no !== this.props.reference_no) {
      this.setState({
        reference_no: this.props.reference_no,
      });
    }
    if (previousProps.rcode !== this.props.rcode) {
      this.setState({
        rcode: this.props.rcode,
      });
    }
    if (previousProps.english_proficiency !== this.props.english_proficiency) {
      this.setState({
        english_proficiency: this.props.english_proficiency,
      });
    }
    if (previousProps.ielts !== this.props.ielts) {
      this.setState({
        ielts: this.props.ielts,
      });
    }
    if (previousProps.toefl !== this.props.toefl) {
      this.setState({
        toefl: this.props.toefl,
      });
    }
    if (previousProps.gre !== this.props.gre) {
      this.setState({
        gre: this.props.gre,
      });
    }
    if (previousProps.gmat !== this.props.gmat) {
      this.setState({
        gmat: this.props.gmat,
      });
    }
    if (previousProps.duolingo !== this.props.duolingo) {
      this.setState({
        duolingo: this.props.duolingo,
      });
    }
    if (previousProps.toeic !== this.props.toeic) {
      this.setState({
        toeic: this.props.toeic,
      });
    }
    if (previousProps.china_proficiency !== this.props.china_proficiency) {
      this.setState({
        china_proficiency: this.props.china_proficiency,
      });
    }
    if (previousProps.hsk_level !== this.props.hsk_level) {
      this.setState({
        hsk_level: this.props.hsk_level,
      });
    }
    if (previousProps.hsk_score !== this.props.hsk_score) {
      this.setState({
        hsk_score: this.props.hsk_score,
      });
    }
    if (previousProps.hsk_no !== this.props.hsk_no) {
      this.setState({
        hsk_no: this.props.hsk_no,
      });
    }
    if (previousProps.listening_score !== this.props.listening_score) {
      this.setState({
        listening_score: this.props.listening_score,
      });
    }
    if (previousProps.reading_score !== this.props.reading_score) {
      this.setState({
        reading_score: this.props.reading_score,
      });
    }
    if (previousProps.writing_score !== this.props.writing_score) {
      this.setState({
        writing_score: this.props.writing_score,
      });
    }
    if (previousProps.chinese_start !== this.props.chinese_start) {
      this.setState({
        chinese_start: this.props.chinese_start,
      });
    }
    if (previousProps.chinese_end !== this.props.chinese_end) {
      this.setState({
        chinese_end: this.props.chinese_end,
      });
    }
    if (previousProps.teacher_own !== this.props.teacher_own) {
      this.setState({
        teacher_own: this.props.teacher_own,
      });
    }
    if (previousProps.chinese_institute !== this.props.chinese_institute) {
      this.setState({
        chinese_institute: this.props.chinese_institute,
      });
    }
    if (previousProps.other_english !== this.props.other_english) {
      this.setState({
        other_english: this.props.other_english,
      });
    }
    if (previousProps.highest_degree !== this.props.highest_degree) {
      this.setState({
        highest_degree: this.props.highest_degree,
      });
    }
    if (previousProps.schoolname !== this.props.schoolname) {
      this.setState({
        schoolname: this.props.schoolname,
      });
    }
    if (previousProps.highest_mark !== this.props.highest_mark) {
      this.setState({
        highest_mark: this.props.highest_mark,
      });
    }
    if (previousProps.certificate_type !== this.props.certificate_type) {
      this.setState({
        certificate_type: this.props.certificate_type,
      });
    }
    if (previousProps.any_failer !== this.props.any_failer) {
      this.setState({
        any_failer: this.props.any_failer,
      });
    }
    if (previousProps.is_it_publish !== this.props.is_it_publish) {
      this.setState({
        is_it_publish: this.props.is_it_publish,
      });
    }
    if (previousProps.have_work !== this.props.have_work) {
      this.setState({
        have_work: this.props.have_work,
      });
    }
    if (previousProps.work_china !== this.props.work_china) {
      this.setState({
        work_china: this.props.work_china,
      });
    }
    if (previousProps.working_start !== this.props.working_start) {
      this.setState({
        working_start: this.props.working_start,
      });
    }
    if (previousProps.working_end !== this.props.working_end) {
      this.setState({
        working_end: this.props.working_end,
      });
    }
  }
  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    var id = window.location.href.split("/")[5];
    var url = window.location.href.split("/");
    console.log(this.props.circulrDetailsProgram);
    console.log(this.state.mejor_degree);
    return (
      <>
        <UserHeader />
        <div class="">
          <div class="container">
            <div class="row">
              <ApplyData pageType="newtable" data={id} />
            </div>

            <Ber page="two" />

            <div class="row mb-5">
              <div class="col-lg-12">
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <input type="hidden" name="user_id" value={data.id} />

              <div class="row application">
                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    学习专业 <br />
                    Major's Information
                  </p>
                </div>

                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        专业 Subject <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          required="required"
                          name="mejor_subject"
                          onChange={this.onChangeData}
                          defaultValue={this.state.mejor_subject}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        授课语言 Teaching Language
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          name="teaching_language"
                          class="form-control"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.teaching_language}
                        >
                          <option value="">None</option>
                          <option value="英语 English">英语 English</option>
                          <option value="中语 Chinese">中语 Chinese</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        学历 Degree<span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        {this.props.circulrDetailsProgram !== "" ? (
                          <select
                            id="yes"
                            name="mejor_degree"
                            class="form-control"
                            required="required"
                            onChange={this.onChangeData}
                            value={
                              this.state.mejor_degree === ""
                                ? this.props.circulrDetailsProgram
                                : this.state.mejor_degree
                            }
                          >
                            <option value="">Please Choose aaa</option>
                            <Degree2 />
                          </select>
                        ) : (
                          <select
                            id="no"
                            name="mejor_degree"
                            class="form-control"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.mejor_degree}
                          >
                            <option value="">Please Choose</option>
                            <Degree2 />
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        学院 College, Expected University/Scholarship Critaria
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        {this.props.circulrDetailsUniversity === "" ? (
                          <input
                            type="text"
                            class="form-control"
                            required="required"
                            name="expected_school"
                            onChange={this.onChangeData}
                            defaultValue={this.state.expected_school}
                          />
                        ) : (
                          <input
                            type="text"
                            class="form-control"
                            required="required"
                            name="expected_school"
                            onChange={this.onChangeData}
                            value={this.props.circulrDetailsUniversity}
                            readOnly
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        入学年份 Entry Year
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="mejor_year"
                          onChange={this.onChangeData}
                          value={this.state.mejor_year}
                          class="form-control"
                        >
                          <option value="">Please Choose</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        入学 session <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="radio"
                        name="march_intake"
                        required="required"
                        onChange={this.onChangeData}
                        value="三月入学 March intake"
                        checked={
                          this.state.march_intake === ""
                            ? this.props.circulrDetailsIntake ===
                              "三月入学 March intake"
                              ? true
                              : false
                            : this.state.march_intake ===
                              "三月入学 March intake"
                            ? true
                            : false
                        }
                      />
                      三月入学 March intake qq
                      <br />
                      <input
                        type="radio"
                        name="march_intake"
                        required="required"
                        onChange={this.onChangeData}
                        value="九月入学 September intake"
                        checked={
                          this.state.march_intake === ""
                            ? this.props.circulrDetailsIntake ===
                              "九月入学 September intake"
                              ? true
                              : false
                            : this.state.march_intake ===
                              "九月入学 September intake"
                            ? true
                            : false
                        }
                      />
                      九月入学 September intake
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        推荐类型 Reference Type{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="reference_type"
                          onChange={this.onChangeData}
                          value={this.state.reference_type}
                          class="form-control"
                        >
                          <option value="">请选择 Please Choose</option>
                          <RefType />
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        推荐单位电话 Reference Contact
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          required="required"
                          name="rcode"
                          onChange={this.onChangeData}
                          value={this.state.rcode}
                        >
                          <option value="">Country</option>

                          {Code.map((c, i) => {
                            return <option value={c.name}>{c.name}</option>;
                          })}
                        </select>
                        <input
                          class="form-control"
                          type="text"
                          placeholder=""
                          name="reference_no"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.reference_no}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    推荐人信息
                    <br /> Referee Information
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">姓名 Name</th>
                        <th scope="col">关系 Relationship </th>
                        <th scope="col">单位名称 Work Place</th>
                        <th scope="col">职业 Occupation</th>
                        <th scope="col">联系电话 Phone/Mobile</th>
                        <th scope="col">联系邮箱 Email</th>
                        <th scope="col">
                          <span className="addbtn" onClick={this.addReference}>
                            <i class="fa-solid fa-plus"></i>添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.referenceInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.reference_name}</td>
                            <td>{s.reference_relation}</td>
                            <td>{s.reference_work_place}</td>
                            <td>{s.reference_occupation}</td>
                            <td>{s.reference_phone}</td>
                            <td>{s.reference_email}</td>
                            <td>
                              <span onClick={() => this.editReference(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                              </span>
                              <span onClick={() => this.deleteReference(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.reference === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            姓名 Name<span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="reference_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            单位名称 Work Place
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="reference_work_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_work_place}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系电话 Phone/Mobile
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="reference_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            关系 Relationship with the student
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="reference_relation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.reference_relation}
                            >
                              <RefRelation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            职业 Occupation
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="reference_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              value={this.state.reference_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            联系邮箱 Email<span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="email"
                              class="form-control"
                              placeholder=" "
                              name="reference_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.referenceError}</p>
                      {this.state.referenceAdd === true && (
                        <span
                          onClick={this.submitReference}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.referenceUpdate === true && (
                        <span
                          onClick={this.submitUpdateReference}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update
                        </span>
                      )}
                      <span
                        onClick={this.cancleReference}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}

                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    研究论文 <br />
                    Research Paper
                  </p>

                  <div class="col-lg-6">
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">是否发布 Is It Publish</div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="radio"
                            required="required"
                            name="is_it_publish"
                            onChange={this.onChangeData}
                            value="Yes"
                            checked={this.state.is_it_publish === "Yes"}
                          />
                          Yes
                          <input
                            type="radio"
                            required="required"
                            name="is_it_publish"
                            onChange={this.onChangeData}
                            value="No"
                            checked={this.state.is_it_publish === "No"}
                          />
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.is_it_publish === "Yes" && (
                    <>
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">研究课题 Research Topic </th>
                            <th scope="col">发表文章 Publish an artical </th>
                            <th scope="col">
                              杂志或期刊标题Magazine Or Journal Title{" "}
                            </th>
                            <th scope="col">杂志级别Magazine Level</th>
                            <th scope="col">期刊收录Journal Inclusion</th>
                            <th scope="col">
                              <span
                                className="addbtn"
                                onClick={this.addResearch}
                              >
                                <i class="fa-solid fa-plus"></i> 添加 Add
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.researchInfo.map((s, i) => {
                            return (
                              <tr key={i}>
                                <td>{s.research_essay}</td>
                                <td>{s.research_artical}</td>
                                <td>{s.research_title}</td>
                                <td>{s.research_level}</td>
                                <td>{s.research_inclusion}</td>

                                <td>
                                  <span onClick={() => this.editResearch(i)}>
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </span>
                                  <span onClick={() => this.deleteResearch(i)}>
                                    <i class="fa-solid fa-trash"></i>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      {this.state.research === true && (
                        <>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  年Year<span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_year"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_year}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  论文主题Essay Topic
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_essay"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_essay}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  发表文章Publish an artical
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_artical"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_artical}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  杂志或期刊标题Magazine or Journal Title
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_title"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_title}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  杂志级别Magazine Level
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_level"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_level}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  作者姓名Author Name
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_auther"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_auther}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  排名My Ranking
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_ranking"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_ranking}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  出版年份和卷数Year Of publication and number
                                  of volumes
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    name="research_volumes"
                                    onChange={this.onChangeData}
                                    defaultValue={this.state.research_volumes}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="row mb-4">
                              <div class="col-md-4">
                                <div class="bg">
                                  期刊收录Journal Inclusion
                                  <span className="colors star">*</span>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class=" ">
                                  <select
                                    class="form-control"
                                    required="required"
                                    name="research_inclusion"
                                    onChange={this.onChangeData}
                                    value={this.state.research_inclusion}
                                  >
                                    <option value="">Select Inclusion</option>
                                    <option value="CSSCI">CSSCI</option>
                                    <option value="CSCD">CSCD</option>
                                    <option value="SCI">SCI</option>
                                    <option value="SSCI">SSCI</option>
                                    <option value="EI">EI</option>
                                    <option value="A&HCI">A&HCI</option>
                                    <option value="Others">Others</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <p>{this.state.researchError}</p>
                            {this.state.researchAdd === true && (
                              <span
                                onClick={this.submitResearch}
                                className="btn btn-success"
                              >
                                <i class="fa-solid fa-floppy-disk"></i> 保存
                                Save
                              </span>
                            )}
                            {this.state.researchUpdate === true && (
                              <span
                                onClick={this.submitUpdateResearch}
                                className="btn btn-success"
                              >
                                <i class="fa-solid fa-check"></i>Update
                              </span>
                            )}
                            <span
                              onClick={this.cancleResearch}
                              className="btn btn-danger"
                            >
                              <i class="fa-solid fa-xmark"></i>关闭 Close
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div class="col-lg-12">
                  <p class="fast_track mb-4">
                    语言能力 <br />
                    Language Proficiency
                  </p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        英语水平 English Proficiency
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="english_proficiency"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.english_proficiency}
                        >
                          <option value="">Please Choose</option>
                          <Proficiency />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> 雅思 IELTS</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="ielts"
                          onChange={this.onChangeData}
                          defaultValue={this.state.ielts}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> 托福 TOEFL </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="toefl"
                          onChange={this.onChangeData}
                          defaultValue={this.state.toefl}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> 成绩 GRE </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="gre"
                          onChange={this.onChangeData}
                          defaultValue={this.state.gre}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">成绩 GMAT </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="gmat"
                          onChange={this.onChangeData}
                          defaultValue={this.state.gmat}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> 多邻国成绩 Duolingo </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="duolingo"
                          onChange={this.onChangeData}
                          defaultValue={this.state.duolingo}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> 托业成绩 TOEIC </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="toeic"
                          onChange={this.onChangeData}
                          defaultValue={this.state.toeic}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        汉语水平 Chinese Proficiency
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="china_proficiency"
                          required="required"
                          onChange={this.onChangeData}
                          value={this.state.china_proficiency}
                        >
                          <option value="">Please Choose</option>
                          <Proficiency />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">等级 HSK Level</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="hsk_level"
                          onChange={this.onChangeData}
                          value={this.state.hsk_level}
                        >
                          <Hsk />
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.hsk_level !== "" && (
                    <>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            HSK成绩 HSK Scores & HSKK成绩 HSKK Scores
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="hsk_score"
                              onChange={this.onChangeData}
                              defaultValue={this.state.hsk_score}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">证书编号 HSK No </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="hsk_no"
                              onChange={this.onChangeData}
                              defaultValue={this.state.hsk_no}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            听力成绩 Listening & 阅读成绩 Reading & 书写成绩
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              placeholder="Listening"
                              name="listening_score"
                              onChange={this.onChangeData}
                              defaultValue={this.state.listening_score}
                            />
                            <input
                              type="text"
                              placeholder="Reading"
                              name="reading_score"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reading_score}
                            />
                            <input
                              type="text"
                              placeholder="Writing"
                              name="writing_score"
                              onChange={this.onChangeData}
                              defaultValue={this.state.writing_score}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        学习汉语时间 Time of Chinese language learning{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="date"
                          name="chinese_start"
                          onChange={this.onChangeData}
                          defaultValue={this.state.chinese_start}
                        />
                        to
                        <input
                          type="date"
                          name="chinese_end"
                          onChange={this.onChangeData}
                          defaultValue={this.state.chinese_end}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        汉语教师是否为中国国籍 Whether the Chinese teacher own a
                        Chinese nationality
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="radio"
                          name="teacher_own"
                          onChange={this.onChangeData}
                          value="Yes"
                          checked={this.state.teacher_own === "Yes"}
                        />
                        Yes
                        <input
                          type="radio"
                          name="teacher_own"
                          onChange={this.onChangeData}
                          value="No"
                          checked={this.state.teacher_own === "No"}
                        />
                        No
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        汉语学习机构名称 Name of institution for Chinese
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          name="chinese_institute"
                          class="form-control"
                          onChange={this.onChangeData}
                          defaultValue={this.state.chinese_institute}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">其他 Other Proficiency Score</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          name="other_english"
                          class="form-control"
                          onChange={this.onChangeData}
                          defaultValue={this.state.other_english}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 ">
                  <div className="fast_track">
                    教育背景 <br />
                    Education Background
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div class="bg">
                      最高学历 <br />
                      Highest Degree
                      <span className="colors star">*</span>
                    </div>

                    <select
                      class="form-control"
                      name="highest_degree"
                      required="required"
                      onChange={this.onChangeData}
                      value={this.state.highest_degree}
                    >
                      <option value="">Select Degree</option>
                      <Degree />
                    </select>
                  </div>
                  <div className="col-md-4">
                    <div class="bg">
                      最高学历毕业院校 <br />
                      Highest degree graduation School
                      <span className="colors star">*</span>
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      name="schoolname"
                      required="required"
                      onChange={this.onChangeData}
                      defaultValue={this.state.schoolname}
                    />
                  </div>
                  <div className="col-md-4">
                    <div class="bg">
                      如果满分是100分，最高学历成绩 <br />
                      Mark range of your
                      <span className="colors star">*</span>
                    </div>

                    <select
                      class="form-control"
                      name="highest_mark"
                      required="required"
                      onChange={this.onChangeData}
                      value={this.state.highest_mark}
                    >
                      <option value="">Select Marks</option>
                      <Marks />
                    </select>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div class="bg">
                      Certificate Type<span className="colors star">*</span>
                    </div>

                    <div class="">
                      <select
                        class="form-control"
                        name="certificate_type"
                        required="required"
                        onChange={this.onChangeData}
                        value={this.state.certificate_type}
                      >
                        <option value="">Select Type</option>
                        <CertificateType />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div class="bg">
                      最高学历成绩是否挂科 <br /> Any failure in your highest
                      degree marks
                    </div>
                    <input
                      type="radio"
                      name="any_failer"
                      required="required"
                      onChange={this.onChangeData}
                      value="Yes"
                      checked={this.state.any_failer === "Yes"}
                    />
                    Yes
                    <input
                      type="radio"
                      name="any_failer"
                      required="required"
                      onChange={this.onChangeData}
                      value="No"
                      checked={this.state.any_failer === "No"}
                    />
                    No
                  </div>
                </div>
                <p className="colors star mt-5">
                  请从高中开始填写 (包括在中国的学习经历) * Start from high
                  school till now, include the study experience in China.
                </p>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">学历 Degree</th>
                      <th scope="col">学校 School name</th>
                      <th scope="col">开始时间 attendance (from)</th>
                      <th scope="col">结束时间 attendance (to)</th>
                      <th scope="col">联系人 Contact person</th>

                      <th scope="col">
                        <span className="addbtn" onClick={this.addDegree}>
                          <i class="fa-solid fa-plus"></i> 添加 Add
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.degreeInfo.map((s, i) => {
                      return (
                        <tr key={i}>
                          <td>{s.degree}</td>
                          <td>{s.school_name}</td>
                          <td>{s.attendence_form}</td>
                          <td>{s.attendence_to}</td>
                          <td>{s.contact}</td>

                          <td>
                            <span onClick={() => this.editDegree(i)}>
                              <i class="fa-solid fa-pen-to-square"></i>
                            </span>
                            <span onClick={() => this.deleteDegree(i)}>
                              <i class="fa-solid fa-trash"></i>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {this.state.degreeInput === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            学历 Degree<span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <select
                            class="form-control"
                            name="degree"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.degree}
                          >
                            <option value="">Select Degree</option>
                            <Degree />
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            学校类型 School Type
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <select
                            class="form-control"
                            name="school_type"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.school_type}
                          >
                            <option value="">Select School</option>
                            <SchoolType />
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            国家 Country<span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <select
                            class="form-control"
                            name="country"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.country}
                          >
                            <option value="">Select Country</option>
                            <Country />
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            学校名称 School Name
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="school_name"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.school_name}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            开始时间 attendance (from)
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="date"
                            class="form-control"
                            name="attendence_form"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.attendence_form}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            结束时间 attendance (to)
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="date"
                            class="form-control"
                            name="attendence_to"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.attendence_to}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">专业领域 Field of study </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="field_study"
                            onChange={this.onChangeData}
                            defaultValue={this.state.field_study}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            获得证书 Major and Diploma Received
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="major_deploma"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.major_deploma}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            联系人 Contact person
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="contact"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.contact}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">联系电话 Phone/Mobile</div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="phone"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.phone}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">联系邮箱 Email</div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            onChange={this.onChangeData}
                            defaultValue={this.state.email}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="bg">备注 Remark</div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="remarks"
                            placeholder="GPA"
                            onChange={this.onChangeData}
                            defaultValue={this.state.remarks}
                          />
                        </div>
                      </div>

                      <p>{this.state.degreeError}</p>
                      {this.state.degreeAdd === true && (
                        <span
                          onClick={this.submitDegree}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.degreeUpdate === true && (
                        <span
                          onClick={this.submitUpdateDegree}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update
                        </span>
                      )}
                      <span
                        onClick={this.cancleDegree}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}

                <div class="col-lg-12 my-5">
                  <p class="fast_track">
                    工作经历 <br /> Work Experience
                  </p>
                  <p>
                    请按时间顺序，从最近的时间开始填写，两段经历的间隔最好不要超过6个月。
                    Please fill in from the latest time in chronological order.
                    Any gap between two experiences is better NOT more than 6
                    months.
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">开始时间 Year of attendance (from)</th>
                        <th scope="col">结束时间 Year of attendance (to)</th>
                        <th scope="col">单位名称 Company</th>
                        <th scope="col">职业 Occupation</th>
                        <th scope="col">证明人 Reference</th>
                        <th scope="col">联系电话 Phone/Mobile</th>
                        <th scope="col">联系邮箱 Email</th>

                        <th scope="col">
                          <span className="addbtn" onClick={this.addWork}>
                            <i class="fa-solid fa-plus"></i> 添加 Add
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.workInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.work_attendence_form}</td>
                            <td>{s.work_attendence_to}</td>
                            <td>{s.work_work_place}</td>
                            <td>{s.work_occupation}</td>
                            <td>{s.work_contact}</td>
                            <td>{s.work_phone}</td>
                            <td>{s.work_email}</td>

                            <td>
                              <span onClick={() => this.editWork(i)}>
                                <i class="fa-solid fa-pen-to-square"></i>
                              </span>
                              <span onClick={() => this.deleteWork(i)}>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.work === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            开始时间 attendance (from)
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="date"
                            class="form-control"
                            name="work_attendence_form"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.work_attendence_form}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            截止时间 attendance (to)
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="date"
                            class="form-control"
                            name="work_attendence_to"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.work_attendence_to}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            单位名称 Work Place{" "}
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="work_work_place"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.work_work_place}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            行业类型 Industry Type
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <select
                            class="form-control"
                            name="work_industry_type"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.work_industry_type}
                          >
                            <option value="">Select Industry</option>
                            <Industry />
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            职业 Occupation
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <select
                            class="form-control"
                            name="work_occupation"
                            required="required"
                            onChange={this.onChangeData}
                            value={this.state.work_occupation}
                          >
                            <Occupation />
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            联系电话 Phone/Mobile
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="work_phone"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.work_phone}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            联系人 Contact person
                            <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            name="work_contact"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.work_contact}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">
                            联系邮箱 Email<span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <input
                            type="email"
                            class="form-control"
                            name="work_email"
                            required="required"
                            onChange={this.onChangeData}
                            defaultValue={this.state.work_email}
                          />
                        </div>
                      </div>

                      <p>{this.state.workError}</p>
                      {this.state.workAdd === true && (
                        <span
                          onClick={this.submitWork}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.workUpdate === true && (
                        <span
                          onClick={this.submitUpdateWork}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update
                        </span>
                      )}
                      <span
                        onClick={this.cancleWork}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}

                <div class="col-lg-4">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        是否曾经在华工作 Have you ever worked in China?
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="radio"
                        name="have_work"
                        required="required"
                        onChange={this.onChangeData}
                        value="Yes"
                        checked={this.state.have_work === "Yes"}
                      />
                      Yes
                      <input
                        type="radio"
                        name="have_work"
                        required="required"
                        onChange={this.onChangeData}
                        value="No"
                        checked={this.state.have_work === "No"}
                      />
                      No
                    </div>
                  </div>
                </div>
              </div>
              {this.state.have_work === "Yes" && (
                <>
                  <div class="col-lg-4">
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          在华工作单位 Work Place in China
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          name="work_china"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.work_china}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          在华工作期限 Working Duration in China
                          <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="date"
                          class="form-control"
                          name="working_start"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.working_start}
                        />
                        to
                        <input
                          type="date"
                          class="form-control"
                          name="working_end"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.working_end}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div class="col-lg-12 my-5">
                <p class="fast_track">
                  家庭成员信息 <br />
                  Family Members
                  <span className="colors star">*</span>
                </p>
                <p>
                  请务必填写父母信息，可增加其他家庭成员。 The parents'
                  information is required. 请填写至少两条家庭成员信息！ Please
                  input at least two family members information
                </p>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">姓名 Name</th>
                      <th scope="col"> 关系 Relationship</th>
                      <th scope="col">国籍 Nationality</th>
                      <th scope="col">机构/单位 Institution/Employer</th>
                      <th scope="col">职业 Occupation</th>
                      <th scope="col">联系电话 Phone/Mobile</th>
                      <th scope="col">联系邮箱 Email</th>

                      <th scope="col">
                        <span className="addbtn" onClick={this.addFamily}>
                          <i class="fa-solid fa-plus"></i>添加 Add
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.familyInfo.map((s, i) => {
                      return (
                        <tr key={i}>
                          <td>{s.family_name}</td>
                          <td>{s.family_relation}</td>
                          <td>{s.family_nationality}</td>
                          <td>{s.family_institute}</td>
                          <td>{s.family_occupation}</td>
                          <td>{s.family_phone}</td>
                          <td>{s.family_email}</td>

                          <td>
                            <span onClick={() => this.editFamily(i)}>
                              <i class="fa-solid fa-pen-to-square"></i>
                            </span>
                            <span onClick={() => this.deleteFamily(i)}>
                              <i class="fa-solid fa-trash"></i>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div class="row">
                {this.state.family === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="bg">
                        姓名 Name<span className="colors star">*</span>
                      </div>

                      <input
                        type="text"
                        class="form-control"
                        name="family_name"
                        required="required"
                        onChange={this.onChangeData}
                        defaultValue={this.state.family_name}
                      />
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        关系 Relationship <span className="colors star">*</span>
                      </div>

                      <select
                        class="form-control"
                        name="family_relation"
                        required="required"
                        onChange={this.onChangeData}
                        value={this.state.family_relation}
                      >
                        <option value="">Select One</option>
                        <Relation />
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">
                        国籍 Nationality<span className="colors star">*</span>
                      </div>

                      <select
                        class="form-control"
                        name="family_nationality"
                        required="required"
                        onChange={this.onChangeData}
                        value={this.state.family_nationality}
                      >
                        <option value="">Select Country</option>
                        <Country />
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        是否健在 Still alive
                        <span className="colors star">*</span>
                      </div>

                      <select
                        class="form-control"
                        name="family_live"
                        required="required"
                        onChange={this.onChangeData}
                        value={this.state.family_live}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        机构/单位 Institution/Employer
                        <span className="colors star">*</span>
                      </div>

                      <input
                        type="text"
                        class="form-control"
                        name="family_institute"
                        required="required"
                        onChange={this.onChangeData}
                        defaultValue={this.state.family_institute}
                      />
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        行业类型 Industry Type
                        <span className="colors star">*</span>
                      </div>

                      <select
                        class="form-control"
                        name="family_ins_type"
                        required="required"
                        onChange={this.onChangeData}
                        value={this.state.family_ins_type}
                      >
                        <Industry />
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        职业 Occupation<span className="colors star">*</span>
                      </div>

                      <select
                        class="form-control"
                        name="family_occupation"
                        required="required"
                        onChange={this.onChangeData}
                        value={this.state.family_occupation}
                      >
                        <Occupation />
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        联系电话 Phone/Mobile
                        <span className="colors star">*</span>
                      </div>

                      <input
                        type="text"
                        class="form-control"
                        name="family_phone"
                        required="required"
                        onChange={this.onChangeData}
                        defaultValue={this.state.family_phone}
                      />
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">
                        联系邮箱 Email<span className="colors star">*</span>
                      </div>

                      <input
                        type="email"
                        class="form-control"
                        name="family_email"
                        required="required"
                        onChange={this.onChangeData}
                        defaultValue={this.state.family_email}
                      />

                      <p>{this.state.familyError}</p>
                      {this.state.familyAdd === true && (
                        <span
                          onClick={this.submitFamily}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-floppy-disk"></i> 保存 Save
                        </span>
                      )}
                      {this.state.familyUpdate === true && (
                        <span
                          onClick={this.submitUpdateFamily}
                          className="btn btn-success"
                        >
                          <i class="fa-solid fa-check"></i>Update
                        </span>
                      )}
                      <span
                        onClick={this.cancleFamily}
                        className="btn btn-danger"
                      >
                        <i class="fa-solid fa-xmark"></i>关闭 Close
                      </span>
                    </div>
                  </>
                )}
              </div>
              {this.props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {this.props.sucMsg}
                </div>
              )}
              {this.props.loader === true && (
                <div class="alert alert-danger" role="alert">
                  We are working Please wait.
                </div>
              )}
              {this.state.familyError2}

              <div class="col-lg-12 text-center my-5">
                <Link
                  to={
                    `/apply-one/` +
                    url[4] +
                    "/" +
                    url[5] +
                    "/" +
                    data.type +
                    "/" +
                    url[7]
                  }
                  class="btn-one"
                >
                  上一步 Previous
                </Link>

                {this.props.loader === false &&
                  this.props.appStatus === "0" && (
                    <button type="submit" class="btn-one">
                      保存 Save
                    </button>
                  )}

                {/* {this.props.steptwo > 0 ? ( */}
                {this.state.two === 2 ? (
                  <Link
                    to={
                      `/apply-three/` +
                      url[4] +
                      "/" +
                      url[5] +
                      "/" +
                      data.type +
                      "/" +
                      url[7]
                    }
                    class="btn-one"
                  >
                    下一步 Next
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    steptwo: state.auth.insertID,

    appStatus: state.auth.datastatus,

    mejor_subject: state.auth.mejor_subject,
    teaching_language: state.auth.teaching_language,
    mejor_degree: state.auth.mejor_degree,
    expected_school: state.auth.expected_school,
    mejor_year: state.auth.mejor_year,
    march_intake: state.auth.march_intake,
    september_intake: state.auth.september_intake,
    reference_type: state.auth.reference_type,
    reference_no: state.auth.reference_no,
    rcode: state.auth.rcode,
    english_proficiency: state.auth.english_proficiency,
    ielts: state.auth.ielts,
    toefl: state.auth.toefl,
    gre: state.auth.gre,
    gmat: state.auth.gmat,
    duolingo: state.auth.duolingo,
    toeic: state.auth.toeic,
    china_proficiency: state.auth.china_proficiency,
    hsk_level: state.auth.hsk_level,
    hsk_score: state.auth.hsk_score,
    hsk_no: state.auth.hsk_no,
    listening_score: state.auth.listening_score,
    reading_score: state.auth.reading_score,
    writing_score: state.auth.writing_score,
    chinese_start: state.auth.chinese_start,
    chinese_end: state.auth.chinese_end,
    teacher_own: state.auth.teacher_own,
    chinese_institute: state.auth.chinese_institute,
    other_english: state.auth.other_english,
    highest_degree: state.auth.highest_degree,
    schoolname: state.auth.schoolname,
    highest_mark: state.auth.highest_mark,
    certificate_type: state.auth.certificate_type,
    any_failer: state.auth.any_failer,
    is_it_publish: state.auth.is_it_publish,
    have_work: state.auth.have_work,
    work_china: state.auth.work_china,
    working_start: state.auth.working_start,
    working_end: state.auth.working_end,

    bal: state.auth.bal,
    refInfoo: state.auth.ref,
    workInfo1: state.auth.workInfo1,
    familyInfo1: state.auth.familyInfo1,
    researchInfo1: state.auth.researchInfo1,
    circulrDetailsUniversity: state.auth.circulrDetailsUniversity,
    circulrDetailsProgram: state.auth.circulrDetailsProgram,
    circulrDetailsIntake: state.auth.circulrDetailsIntake,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationTwo: (data, ii) => dispatch(applicationTwo(data, ii)),
  applicationTwoData: (data, post) => dispatch(applicationTwoData(data, post)),
  applyTwoData: (id, data) => dispatch(applyTwoData(id, data)),
  applyTwoDataGet: (id) => dispatch(applyTwoDataGet(id)),
  circulerDetails: (id) => dispatch(circulerDetails(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ApplyTwo);
