import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sms_count } from "../../services/actions/AuthAction";

function SMS(props) {
  useEffect(() => {
    props.Notificationp();
  }, []);

  return (
    <>
      <Link className="nav-link" to="/chat">
        <i className="fa-solid fa-envelope"></i> {props.chat}
      </Link>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    chat: state.auth.chat_counts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  Notificationp: () => dispatch(sms_count()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SMS);
