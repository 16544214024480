function RefRelation(props) {
  return (
    <>
      <option value="">Select </option>
      <option value="老师 Teacher">老师 Teacher</option>
      <option value="雇主 Employer">雇主 Employer</option>
      <option value="其他 Others">其他 Others</option>
    </>
  );
}
export default RefRelation;
