import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { circulerDetails } from "../../services/actions/AuthAction";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function CircularPage(props) {
  let { id } = useParams();

  useEffect(() => {
    props.circulerDetails(id);
  }, []);
  var data = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <Navber />
      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <p className="h1 mb-5">{props.uniDetai.university}</p>
            <p className="h3 mb-2">{props.uniDetai.title}</p>
            <p className="h4 mb-2">{props.uniDetai.types}</p>
            <p className="h6 mb-2">{props.uniDetai.address}</p>
            <p className="h6 mb-2">{props.uniDetai.program}</p>
            {props.uniDetai.start_date !== null && (
              <p>Start: {props.uniDetai.start_date}</p>
            )}
            {props.uniDetai.end_date !== null && (
              <p>End : {props.uniDetai.end_date}</p>
            )}
          </div>
          <div className="col-lg-6">
            <div className="card-two">
              {props.uniDetai.picture !== undefined && (
                <OwlCarousel
                  items={1}
                  className="owl-carousel owl-carousel-three owl-theme owl-loaded owl-drag text-center"
                  loop
                  nav
                  margin={8}
                  autoplay
                >
                  {JSON.parse(props.uniDetai.picture).map((u, i) => {
                    return (
                      <img
                        src={JSON.parse(props.uniDetai.picture)[i]}
                        alt=""
                        className="img-fluid w-100"
                      />
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>
          <div className="col-lg-12 pt-5">
            <p> {props.uniDetai.details} </p>
          </div>
        </div>
        {props.uniDetai.pdf !== null && (
          <a
            download="download"
            className="btn btn-danger"
            href={props.uniDetai.pdf}
          >
            Downlaod
          </a>
        )}

        {data ? (
          <Link
            className="btn btn-info"
            to={`/apply-one/new/000/` + data.type + "/" + props.uniDetai.id}
          >
            Apply Now...
          </Link>
        ) : (
          <Link className="btn btn-info" to={`/login`}>
            Login and Apply
          </Link>
        )}
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    uniDetai: state.auth.circulrDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  circulerDetails: (data) => dispatch(circulerDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CircularPage);
