import {
  ABOUT,
  ALLGALLERY,
  APPLICATION_VIEW,
  APPLY_FINAL,
  APPLY_FOUR,
  APPLY_FOUR_DATA,
  APPLY_ONE,
  APPLY_ONE_DATA,
  APPLY_ONE_DATA2,
  APPLY_THREE,
  APPLY_THREE_DATA,
  APPLY_TWO,
  APPLY_TWO_DATA,
  BLOG,
  BLOGDETAILS,
  CHENGE_PASSWORD,
  CIRCULER,
  CIRCULER_DETAILS,
  CIRCULER_SHOW,
  CONTACT,
  DELETE,
  FAILED,
  FOOTER,
  FORGOT,
  GALLERY,
  HOMEDATA,
  HOMEDATA2,
  INDEXABOUT,
  LOADING,
  LOGIN,
  LOGOUT,
  MARKREAD,
  NOTIFICATION,
  NOTIFICATIONDATA,
  OLDMESSAGE,
  PAYMENT,
  PAYMENT_SEND,
  PROFILE,
  PROFILE_INFO,
  REG,
  RESET,
  SERVICE,
  SMSS,
  STEPONE,
  STEPONEDATA,
  STEPTHREE,
  STEPTHREEDATA,
  STEPTWO,
  STEPTWODATA,
  UNIVERSITY,
  UNIVERSITYDETAILS,
  VERIFY,
} from "../constants";

const initialState = {
  loading: false,
  redirect: false,
  errMsg: null,
  sucMsg: null,
  auth: false,

  appStatus: "0",
  count: [],

  university: [],
  uniDetai: [],
  blog: [],
  blogDetai: [],
  gallery: [],
  allgallery: [],
  aboutt: null,
  service: null,
  circulr: [],
  show: [],
  explores: [],
  circulrDetails: [],

  stepone: Object,
  steponedone: "update",
  steponeid: 0,
  steptwo: Object,

  viewOne: Object,
  viewTwo: Object,
  viewThree: Object,

  peopleunknownone: Object,

  profile: [],
  valid_ordinary_passport: null,
  original_higtest_diploma: null,
  original_transcript: null,
  written_resume: null,
  physical_record: null,
  criminal_record: null,
  recommendation_letter: null,
  study_plan: null,
  research_achievement: null,
  language_proficiency: null,
  other: null,
  visa_page: null,
  bank: null,
  current_university: null,
  vaccine: null,

  extra: "",
  desc: "",
  text: "",
  social: [],

  pp: "",

  dataname: "",
  datapassport: "",
  datacountry: "",
  dataprogramme: "",
  datapayment: "",
  dataphoto: "",
  dataid: "",

  usefull: [],
  more: [],
  phone: "",
  email: "",
  typee: null,

  home: [],
  notific: [],
  chat_counts: 0,
  notificData: [],
  payment: [],

  family_name: "",
  given_name: "",
  full_name: "",
  chinese_name: "",
  gender: "",
  nationality: "",
  dob: "",
  birth_country: "",
  photo: "",
  native_language: "",
  marital_status: "",
  religion: "",
  occupation: "",
  institute_affiliated: "",
  wechat: "",
  code: "",
  birth_place: "",

  c_current_address: "",
  c_city: "",
  c_country: "",
  c_cipcode: "",
  c_phone: "",
  c_email: "",
  passport_no: "",
  old_passport_no: "",
  new_expiry_date: "",
  old_expiry_date: "",
  studed_in_china: "",
  visa_type: "",
  visa_no: "",
  is_china_new: "",

  old_china_institute: "",
  sponsor_name: "",
  sponsor_word_place: "",
  sponsor_phone: "",
  sponsor_industry: "",
  sponsor_relation: "",
  sponsor_occupation: "",
  sponsor_email: "",
  guarante_name: "",
  guarante_work_place: "",
  guarante_phone: "",
  guarante_industry: "",
  guarante_relation: "",
  guarante_occupation: "",
  guarante_email: "",
  emergency_name: "",
  emergency_work_place: "",
  emergency_phone: "",
  emergency_industry: "",
  emergency_relation: "",
  emergency_occupation: "",
  emergency_email: "",

  insertID: 0,

  mejor_subject: "",
  teaching_language: "",
  mejor_degree: "",
  expected_school: "",
  mejor_year: "",
  march_intake: "",
  september_intake: "",
  reference_type: "",
  reference_no: "",
  rcode: "",
  english_proficiency: "",
  ielts: "",
  toefl: "",
  gre: "",
  gmat: "",
  duolingo: "",
  toeic: "",
  china_proficiency: "",
  hsk_level: "",
  hsk_score: "",
  hsk_no: "",
  listening_score: "",
  reading_score: "",
  writing_score: "",
  chinese_start: "",
  chinese_end: "",
  teacher_own: "",
  chinese_institute: "",
  other_english: "",
  highest_degree: "",
  schoolname: "",
  highest_mark: "",
  certificate_type: "",
  any_failer: "",
  is_it_publish: "",
  have_work: "",
  work_china: "",
  working_start: "",
  working_end: "",

  bal: [],
  ref: [],
  workInfo1: [],
  familyInfo1: [],
  researchInfo1: [],

  china_name: "",
  affiliate: "",
  parmanent_home: "",
  parmanent_city: "",
  parmanent_country: "",
  parmanent_zipcode: "",
  parmanent_phone: "",
  parmanent_email: "",
  current_home: "",
  current_city: "",
  current_country: "",
  current_zipcode: "",
  current_phone: "",
  current_email: "",
  old_passport: "",
  new_expiry_date_start: "",
  new_expiry_date_end: "",
  old_expaire_date: "",
  emabassy: "",

  in_china_new: "",
  visa_expairy_date: "",
  study_period_china: "",
  study_period_china_end: "",
  china_institute: "",
  sponser: [],
  gurdian: [],
  emergency: [],

  are_you_immigrend: "",
  collect: "",
  details: "",
  original_country: "",
  parants_are_chines: "",
  current_citizenship: "",
  csc: "",
  hobbis: "",
  smoking: "",
  criminal_recoreds: "",
  special_disease: "",
  crime: "",
  physical_exam: "",
  video: "",
  familyData: [],
  award: [],
  fphone: "",
  circulrDetailsProgram: "",
  circulrDetailsUniversity: "",
  circulrDetailsIntake: "",
  chat: [],
};
export default function AuthReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        loading: false,
        redirect: true,
        auth: true,
        typee: payload,
        sucMsg: "Login Success.",
      };

    case FAILED:
      return {
        ...state,
        loading: false,
        errMsg: payload,
      };
    case LOGOUT:
      return { ...state, loading: false, auth: false, redirect: false };
    case LOADING:
      return {
        ...state,
        loading: true,
        errMsg: null,
        sucMsg: null,
        dataname: "",
        datapassport: "",
        datacountry: "",
        dataprogramme: "",
        datapayment: "",
        dataphoto: "",
        dataid: "",
        datastatus: "0",
      };

    case REG:
      return {
        ...state,
        loading: false,
        sucMsg:
          "Congratulation! You Have Successfully Create a Account, Please Verify Your Email And Login.",
      };
    case RESET:
      return {
        ...state,
        loading: false,
        sucMsg: "Password Change Successfully.",
      };
    case CHENGE_PASSWORD:
      return {
        ...state,
        loading: false,
        sucMsg: payload.message,
      };

    case VERIFY:
      return {
        ...state,
        loading: false,
        sucMsg: payload.message,
      };

    case CONTACT:
      return {
        ...state,
        loading: false,

        sucMsg: "Thanks For Contact.",
      };
    case FORGOT:
      return {
        ...state,
        loading: false,

        sucMsg: payload.message,
      };

    case UNIVERSITY:
      return {
        ...state,
        loading: false,
        university: payload.uni,
      };

    case UNIVERSITYDETAILS:
      return {
        ...state,
        loading: false,
        uniDetai: payload.uni,
      };
    case BLOG:
      return {
        ...state,
        loading: false,
        blog: payload.uni,
      };

    case BLOGDETAILS:
      return {
        ...state,
        loading: false,
        blogDetai: payload.uni,
      };
    case GALLERY:
      return {
        ...state,
        loading: false,
        gallery: payload.gallery,
      };
    case ALLGALLERY:
      return {
        ...state,
        loading: false,
        allgallery: payload.galleryy,
      };
    case NOTIFICATION:
      return {
        ...state,
        loading: false,
        notific: payload.notification,
      };
    case SMSS:
      return {
        ...state,
        loading: false,
        chat_counts: payload,
      };

    case NOTIFICATIONDATA:
      return {
        ...state,
        loading: false,
        notificData: payload.notification,
      };
    case MARKREAD:
      return {
        ...state,
        loading: false,
        notificData: payload.notification,
      };
    case INDEXABOUT:
      return {
        ...state,
        loading: false,
        aboutt: payload.about.data,
      };
    case ABOUT:
      return {
        ...state,
        loading: false,
        aboutt: payload.about.content,
      };
    case SERVICE:
      return {
        ...state,
        loading: false,
        service: payload.service.content,
      };

    case CIRCULER:
      return {
        ...state,
        loading: false,

        circulr: payload.circuler,
        show: payload.circuler,
        explores: payload.explores,
      };

    case CIRCULER_SHOW:
      // function data(payload) {
      //   if (payload.id === "all" && payload.intake === "all") {
      //     return state.circulr.filter((i) => i.types === payload.type);
      //   } else if (payload.id !== "all" && payload.intake === "all") {
      //     return state.circulr.filter(
      //       (i) => i.types === payload.type && i.explore === payload.id
      //     );
      //   } else if (payload.id === "all" && payload.intake !== "all") {
      //     return state.circulr.filter(
      //       (i) => i.types === payload.type && i.intake === payload.intake
      //     );
      //   } else if (payload.id !== "all" && payload.intake !== "all") {
      //     return state.circulr.filter(
      //       (i) =>
      //         i.types === payload.type &&
      //         i.explore === payload.id &&
      //         i.intake === payload.intake
      //     );
      //   }
      // }
      // console.log(data);
      console.log(payload.intake);
      return {
        ...state,
        loading: false,
        show: state.circulr.filter((item) => {
          if (payload.id === "all" && payload.intake === "all") {
            return item.types === payload.type;
          }

          if (payload.id !== "all" && payload.intake === "all") {
            return item.types === payload.type && item.explore === payload.id;
          }

          if (payload.id === "all" && payload.intake !== "all") {
            return (
              item.types === payload.type && item.intake === payload.intake
            );
          }

          if (payload.id !== "all" && payload.intake !== "all") {
            return (
              item.types === payload.type &&
              item.intake === payload.intake &&
              item.explore === payload.id
            );
          }

          return (
            item.types === payload.type &&
            item.explore === payload.id &&
            item.intake === payload.intake
          );
        }),
      };

    case CIRCULER_DETAILS:
      return {
        ...state,
        loading: false,
        circulrDetails: payload.circuler,
        circulrDetailsProgram: payload.circuler.program,
        circulrDetailsUniversity: payload.circuler.university,
        circulrDetailsIntake: payload.circuler.intake,
      };

    case STEPONE:
      return {
        ...state,
        loading: false,
        steponedone: payload.status,
        steponeid: payload.id,
        sucMsg:
          "Your Completed First Part Of Your Application, Please Complete Full Process. Go To Next Page.",
      };
    case STEPONEDATA:
      return {
        ...state,
        loading: false,
        stepone: payload.data,
        steponeid: payload.data.id,

        family_name: payload.data.family_name,
        given_name: payload.data.given_name,
        full_name: payload.data.full_name,
        chinese_name: payload.data.chinese_name,
        gender: payload.data.gender,
        nationality: payload.data.nationality,
        dob: payload.data.dob,
        birth_country: payload.data.birth_country,

        photo: payload.data.photo,
        native_language: payload.data.native_language,
        marital_status: payload.data.marital_status,

        religion: payload.data.religion,

        occupation: payload.data.occupation,
        institute_affiliated: payload.data.institute_affiliated,
        wechat: payload.data.wechat,
        phone: payload.data.phone,
        birth_place: payload.data.birth_place,

        p_home_address: payload.data.p_home_address,
        p_city: payload.data.p_city,
        p_country: payload.data.p_country,

        p_zipcode: payload.data.p_zipcode,
        p_phone: payload.data.p_phone,
        p_email: payload.data.p_email,
        c_current_address: payload.data.c_current_address,
        c_city: payload.data.c_city,
        c_country: payload.data.c_country,

        c_cipcode: payload.data.c_cipcode,
        c_phone: payload.data.c_phone,
        c_email: payload.data.c_email,
        passport_no: payload.data.passport_no,
        old_passport_no: payload.data.old_passport_no,
        new_expiry_date: payload.data.new_expiry_date,
        old_expiry_date: payload.data.old_expiry_date,
        studed_in_china: payload.data.studed_in_china,

        visa_type: payload.data.visa_type,
        visa_no: payload.data.visa_no,
        is_china_new: payload.data.is_china_new,

        visa_expairy_date: payload.data.visa_expairy_date,
        study_period_china: payload.data.study_period_china,
        study_period_china_end: payload.data.study_period_china_end,
        old_china_institute: payload.data.old_china_institute,
        sponsor_name: payload.data.sponsor_name,
        sponsor_word_place: payload.data.sponsor_word_place,
        sponsor_phone: payload.data.sponsor_phone,
        sponsor_industry: payload.data.sponsor_industry,
        sponsor_relation: payload.data.sponsor_relation,
        sponsor_occupation: payload.data.sponsor_occupation,
        sponsor_email: payload.data.sponsor_email,
        guarante_name: payload.data.guarante_name,
        guarante_work_place: payload.data.guarante_work_place,
        guarante_phone: payload.data.guarante_phone,
        guarante_industry: payload.data.guarante_industry,
        guarante_relation: payload.data.guarante_relation,
        guarante_occupation: payload.data.guarante_occupation,
        guarante_email: payload.data.guarante_email,
        emergency_name: payload.data.emergency_name,
        emergency_work_place: payload.data.emergency_work_place,
        emergency_phone: payload.data.emergency_phone,
        emergency_industry: payload.data.emergency_industry,
        emergency_relation: payload.data.emergency_relation,
        emergency_occupation: payload.data.emergency_occupation,
        emergency_email: payload.data.emergency_email,
      };
    case STEPTWO:
      return {
        ...state,
        loading: false,
        sucMsg: "Data Store Please Go Next Page.",
      };
    case STEPTWODATA:
      return {
        ...state,
        loading: false,
        steptwo: payload.data,
      };

    case STEPTHREE:
      return {
        ...state,
        loading: false,
        sucMsg:
          "You Have Successfully Completed Your Application. Please Wait For Reply",
      };
    case STEPTHREEDATA:
      return {
        ...state,
        loading: false,
        valid_ordinary_passport: payload.data.valid_ordinary_passport_name,
        original_higtest_diploma: payload.data.original_higtest_diploma_name,
        original_transcript: payload.data.original_transcript_name,
        written_resume: payload.data.written_resume_name,
        physical_record: payload.data.physical_record_name,
        criminal_record: payload.data.criminal_record_name,
        recommendation_letter: payload.data.recommendation_letter_name,
        study_plan: payload.data.study_plan_name,
        research_achievement: payload.data.research_achievement_name,
        language_proficiency: payload.data.language_proficiency_name,
        other: payload.data.other_name,
      };
    case FOOTER:
      return {
        ...state,
        loading: false,
        text: payload.footer.text,
        social: payload.footer.social,
        usefull: payload.footer.usefull,
        more: payload.footer.more,
        fphone: payload.footer.phone,
        email: payload.footer.email,
      };
    case HOMEDATA:
      return {
        ...state,
        loading: false,
        home: payload.data,
        count: payload.count,
      };

    case HOMEDATA2:
      return {
        ...state,
        loading: false,
        home: payload.data,
      };
    case DELETE:
      return {
        ...state,
        loading: false,
        home: state.home.filter((i) => i.appid !== payload),
      };

    case PROFILE:
      return {
        ...state,
        loading: false,
        sucMsg: "Your Profile Updated",
      };

    case PROFILE_INFO:
      return {
        ...state,
        loading: false,
        profile: payload.user,
      };
    case OLDMESSAGE:
      return {
        ...state,
        loading: false,
        chat: payload,
      };

    case APPLICATION_VIEW:
      return {
        ...state,
        loading: false,
        viewOne: payload.one,
        viewTwo: payload.two,
        viewThree: payload.three,
        viewFour: payload.four,
      };
    case APPLY_FINAL:
      return {
        ...state,
        loading: false,
        sucMsg: "Apply completed, wait for Admin response.",
      };
    case PAYMENT:
      return {
        ...state,
        loading: false,
        payment: payload.data,
      };
    case PAYMENT_SEND:
      return {
        ...state,
        loading: false,
        sucMsg: "Your Payment is under review, Please wait.",
      };

    case APPLY_ONE:
      return {
        ...state,
        loading: false,
        sucMsg: "Inserted",
        insertID: payload.id,
      };

    case APPLY_ONE_DATA:
      return {
        ...state,
        loading: false,

        appStatus: payload.data.status,

        family_name: payload.data.family_name,
        given_name: payload.data.given_name,
        full_name: payload.data.full_name,
        china_name: payload.data.china_name,
        gender: payload.data.gender,
        nationality: payload.data.nationality,
        dob: payload.data.dob,
        birth_country: payload.data.birth_country,
        photo: payload.data.photo,
        native_language: payload.data.native_language,
        marital_status: payload.data.marital_status,
        religion: payload.data.religion,
        occupation: payload.data.occupation,
        affiliate: payload.data.affiliate,
        wechat: payload.data.wechat,
        phone: payload.data.phone,
        code: payload.data.code,
        birth_place: payload.data.birth_place,

        parmanent_home: payload.data.parmanent_home,
        parmanent_city: payload.data.parmanent_city,
        parmanent_country: payload.data.parmanent_country,
        parmanent_zipcode: payload.data.parmanent_zipcode,
        parmanent_phone: payload.data.parmanent_phone,
        parmanent_email: payload.data.parmanent_email,

        current_home: payload.data.current_home,
        current_city: payload.data.current_city,
        current_country: payload.data.current_country,
        current_zipcode: payload.data.current_zipcode,
        current_phone: payload.data.current_phone,
        current_email: payload.data.current_email,

        passport_no: payload.data.passport_no,
        old_passport: payload.data.old_passport,
        new_expiry_date_start: payload.data.new_expiry_date_start,
        new_expiry_date_end: payload.data.new_expiry_date_end,
        old_expaire_date: payload.data.old_expaire_date,
        emabassy: payload.data.emabassy,

        studed_in_china: payload.data.studed_in_china,
        visa_type: payload.data.visa_type,
        visa_no: payload.data.visa_no,
        in_china_new: payload.data.in_china_new,
        visa_expairy_date: payload.data.visa_expairy_date,
        study_period_china: payload.data.study_period_china,
        study_period_china_end: payload.data.study_period_china_end,
        china_institute: payload.data.china_institute,

        insertID: payload.insertID,
        sponser: payload.sponserInfo,
        gurdian: payload.guaranteInfo,
        emergency: payload.emergencyInfo,
      };

    case APPLY_ONE_DATA2:
      return {
        ...state,
        loading: false,
        dataname: payload.full_name,
        datapassport: payload.passport_no,
        datacountry: payload.parmanent_country,
        dataprogramme: payload.programe,
        datapayment: payload.payment,
        dataphoto: payload.photo,
        dataid: payload.id,
        datastatus: payload.status,
      };

    case APPLY_TWO:
      return {
        ...state,
        loading: false,
        sucMsg: "Inserted",
        insertID: payload.id,
      };

    case APPLY_TWO_DATA:
      return {
        ...state,
        loading: false,
        sucMsg: "Old Data Restore.",
        circulrDetailsUniversity: "",
        circulrDetailsProgram: "",

        mejor_subject: payload.data.mejor_subject,
        teaching_language: payload.data.teaching_language,
        mejor_degree: payload.data.mejor_degree,
        expected_school: payload.data.expected_school,
        mejor_year: payload.data.mejor_year,
        march_intake: payload.data.march_intake,
        september_intake: payload.data.september_intake,
        reference_type: payload.data.reference_type,
        reference_no: payload.data.reference_no,
        rcode: payload.data.rcode,
        english_proficiency: payload.data.english_proficiency,
        ielts: payload.data.ielts,
        toefl: payload.data.toefl,
        gre: payload.data.gre,
        gmat: payload.data.gmat,
        duolingo: payload.data.duolingo,
        toeic: payload.data.toeic,
        china_proficiency: payload.data.china_proficiency,
        hsk_level: payload.data.hsk_level,
        hsk_score: payload.data.hsk_score,
        hsk_no: payload.data.hsk_no,
        listening_score: payload.data.listening_score,
        reading_score: payload.data.reading_score,
        writing_score: payload.data.writing_score,
        chinese_start: payload.data.chinese_start,
        chinese_end: payload.data.chinese_end,
        teacher_own: payload.data.teacher_own,
        chinese_institute: payload.data.chinese_institute,
        other_english: payload.data.other_english,
        highest_degree: payload.data.highest_degree,
        schoolname: payload.data.schoolname,
        highest_mark: payload.data.highest_mark,
        certificate_type: payload.data.certificate_type,
        any_failer: payload.data.any_failer,
        is_it_publish: payload.data.is_it_publish,
        have_work: payload.data.have_work,
        work_china: payload.data.work_china,
        working_start: payload.data.working_start,
        working_end: payload.data.working_end,

        bal: payload.degreeInfo,
        ref: payload.refInfo,
        workInfo1: payload.workInfo,
        familyInfo1: payload.familyInfo,
        researchInfo1: payload.researchInfo,
      };
    case APPLY_THREE:
      return {
        ...state,
        loading: false,
        sucMsg: "Inserted",
        insertID: payload.id,
      };

    case APPLY_THREE_DATA:
      return {
        ...state,
        loading: false,
        sucMsg: "Previous Data Restore.",

        are_you_immigrend: payload.data.are_you_immigrend,
        collect: payload.data.collect,
        details: payload.data.details,
        original_country: payload.data.original_country,
        parants_are_chines: payload.data.parants_are_chines,
        current_citizenship: payload.data.current_citizenship,
        csc: payload.data.csc,
        hobbis: payload.data.hobbis,
        smoking: payload.data.smoking,
        criminal_recoreds: payload.data.criminal_recoreds,
        special_disease: payload.data.special_disease,
        crime: payload.data.crime,
        physical_exam: payload.data.physical_exam,
        video: payload.data.video,

        familyData: payload.family,
        award: payload.award,
      };
    case APPLY_FOUR:
      return {
        ...state,
        loading: false,
        sucMsg: "Inserted, You need to final submit",
      };
    case APPLY_FOUR_DATA:
      return {
        ...state,
        loading: false,
        valid_ordinary_passport: payload.data.valid_ordinary_passport,
        valid_ordinary_passport_name: payload.data.valid_ordinary_passport_name,

        original_higtest_diploma: payload.data.original_higtest_diploma,
        original_higtest_diploma_name:
          payload.data.original_higtest_diploma_name,

        original_transcript: payload.data.original_transcript,
        original_transcript_name: payload.data.original_transcript_name,

        written_resume: payload.data.written_resume,
        written_resume_name: payload.data.written_resume_name,

        physical_record: payload.data.physical_record,
        physical_record_name: payload.data.physical_record_name,

        criminal_record: payload.data.criminal_record,
        criminal_record_name: payload.data.criminal_record_name,

        recommendation_letter: payload.data.recommendation_letter,
        recommendation_letter_name: payload.data.recommendation_letter_name,

        study_plan: payload.data.study_plan,
        study_plan_name: payload.data.study_plan_name,

        research_achievement: payload.data.research_achievement,
        research_achievement_name: payload.data.research_achievement_name,

        language_proficiency: payload.data.language_proficiency,
        language_proficiency_name: payload.data.language_proficiency_name,

        other: payload.data.other,
        other_name: payload.data.other_name,

        visa_page: payload.data.visa_page,
        visa_page_name: payload.data.visa_page_name,

        bank: payload.data.bank,
        bank_name: payload.data.bank_name,

        current_university: payload.data.current_university,
        current_university_name: payload.data.current_university_name,

        vaccine: payload.data.vaccine,
        vaccine_name: payload.data.vaccine_name,
      };

    default:
      return state;
  }
}
