import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";

import AuthenticatedRoute from "./Route/AuthenticRoute";

import Index from "./component/Index";
import About from "./component/About";
import Login from "./component/Login";
import Register from "./component/Register";
import Reset from "./component/Reset";
import Forgot from "./component/Forgot";
import Contact from "./component/pages/Contact";
import UniversityDetails1 from "./component/pages/UniversityDetails1";
import BlogDetails from "./component/pages/BlogDetails";
import Home from "./component/user/Home";
import Application1 from "./component/pages/Application1";
import Service from "./component/pages/Service";
import Application2 from "./component/pages/Application2";
import Application3 from "./component/pages/Application3";
import CirculerPage from "./component/pages/CirculerPage";
import ProfileUpdate from "./component/user/ProfileUpdate";
import Logout from "./component/Logout";
import AgentHome from "./component/user/AgentHome";
import AllApplication from "./component/pages/AllApllication";
import AgentApplication1 from "./component/pages/AgentApplication1";
import AgentApplication2 from "./component/pages/AgentApplication2";
import AgentApplication3 from "./component/pages/AgentApplication3";
import Gallary from "./component/pages/Gallary";
import NotificationPage from "./component/pages/NotificationPage";
import Verify from "./component/Verify";
import Password from "./component/user/Password";
import ViewApplicaton from "./component/user/ViewApplicaton";
import Unknown1 from "./component/pages/Unknown1";
import Unknown2 from "./component/pages/Unknown2";
import Unknown3 from "./component/pages/Unknown3";
import AgentUnknown1 from "./component/pages/AgentUnknown1";
import Payment from "./component/user/Payment";
import Test1 from "./component/pages/Test1";
import Test2 from "./component/pages/Test2";
import Test3 from "./component/pages/Test3";
import ApplyOne from "./component/pages/ApplyOne";
import ApplyTwo from "./component/pages/ApplyTwo";
import ApplyThree from "./component/pages/ApplyThree";
import ApplyFour from "./component/pages/ApplyFour";
import ApplyView from "./component/pages/ApplyView";
import Test4 from "./component/pages/Test4";
import AgentStudent from "./component/agent/AgentStudent";
import Chat from "./component/user/Chat";

function App() {
  // if (!localStorage.getItem("token")) {
  //   // return <Navigate replace to="/log-out" />;
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verify/:id" element={<Verify />} />
        <Route exact path="/reset/:id" element={<Reset />} />
        <Route exact path="/forgot" element={<Forgot />} />

        <Route exact path="/circuler/:id" element={<CirculerPage />} />

        {/* <Route exact path="/about" element={<About />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/university/:id" element={<UniversityDetails1 />} />
        <Route exact path="/blog/:id" element={<BlogDetails />} />
        <Route exact path="/circuler/:id" element={<CirculerPage />} />
        <Route exact path="/all-gallary" element={<Gallary />} /> */}

        <Route exact path="/home" element={<Home />} />
        <Route exact path="/notification" element={<NotificationPage />} />
        <Route exact path="/password-change" element={<Password />} />
        <Route exact path="/log-out" element={<Logout />} />
        <Route exact path="/update-profile" element={<ProfileUpdate />} />
        <Route exact path="/application-one/:id" element={<Application1 />} />
        <Route exact path="/application-two/:id" element={<Application2 />} />
        <Route exact path="/application-three/:id" element={<Application3 />} />
        <Route exact path="/all-application" element={<AllApplication />} />
        <Route
          exact
          path="/view-application/:id"
          element={<ViewApplicaton />}
        />
        <Route exact path="/agent-home" element={<AgentHome />} />
        <Route exact path="/agent/:id?" element={<AgentStudent />} />
        <Route
          exact
          path="/edit-application/:id"
          element={<AgentApplication1 />}
        />
        <Route
          exact
          path="/edit-application-two/:id"
          element={<AgentApplication2 />}
        />
        <Route
          exact
          path="/edit-application-three/:id"
          element={<AgentApplication3 />}
        />

        <Route exact path="/people-unknown-one" element={<Unknown1 />} />
        <Route exact path="/people-unknown-two/:id" element={<Unknown2 />} />
        <Route exact path="/people-unknown-three/:id" element={<Unknown3 />} />

        <Route exact path="/agent-unknown-one" element={<AgentUnknown1 />} />

        <Route exact path="/payment/:id" element={<Payment />} />
        <Route exact path="/test1/:id" element={<Test1 />} />
        <Route exact path="/test2/:id" element={<Test2 />} />
        <Route exact path="/test3/:id" element={<Test3 />} />
        <Route exact path="/test4/:id" element={<Test4 />} />

        <Route
          exact
          path="/apply-one/:apply_type/:apply_id/:user_type/:explore"
          element={<ApplyOne />}
        />
        <Route
          exact
          path="/apply-two/:apply_type/:apply_id/:user_type/:explore"
          element={<ApplyTwo />}
        />
        <Route
          exact
          path="/apply-three/:apply_type/:apply_id/:user_type/:explore"
          element={<ApplyThree />}
        />
        <Route
          exact
          path="/apply-four/:apply_type/:apply_id/:user_type/:explore"
          element={<ApplyFour />}
        />
        <Route
          exact
          path="/apply-view/:apply_type/:apply_id/:user_type/:explore"
          element={<ApplyView />}
        />

        {/* 
        <Route exact path="/apply-one/:id?" element={<ApplyOne />} /> 
        <Route exact path="/apply-two/:id?" element={<ApplyTwo />} />
        <Route exact path="/apply-three/:id?" element={<ApplyThree />} />
        <Route exact path="/apply-four/:id?" element={<ApplyFour />} />
        <Route exact path="/apply-view/:id?" element={<ApplyView />} />
      */}

        <Route exact path="/chat" element={<Chat />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
