import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Footer from "./header/Footer";
import Navber from "./header/Navber";
import University from "./helper/University";
import Blog from "./helper/Blog";
import Gallery from "./helper/Gallery";
import Circuler from "./helper/Circuler";
import About from "./helper/About";

SwiperCore.use([EffectCoverflow, Pagination]);

function Index(props) {
  return (
    <>
      <Navber />

      {/* banner area start */}
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <h1>Fly and Explore Your</h1>
              <p className="h1">
                <span className="cor-1">Dream</span>{" "}
                <span className="cor-2">with Us!</span>{" "}
              </p>
              <p>
                Poppins is available via an open source license. You're free to
                use it with your Adobe Fonts account just as you would any other
                font in the Adobe Fonts library.
              </p>
              <div className="my-5">
                <a href="#" className="btn-one">
                  Learn More
                </a>
              </div>
              <h4 className="h2">
                Our University <span className="bor-bot"> Partners </span>{" "}
              </h4>
              <OwlCarousel
                items={3}
                className="owl-carousel owl-theme owl-loaded owl-drag mt-5"
                loop
                nav
                margin={8}
              >
                <div>
                  <img
                    src="image/p-1.png"
                    alt=""
                    className="img-fluid st1image"
                  />
                </div>
                <div>
                  <img
                    src="image/p-2.png"
                    alt=""
                    className="img-fluid st1image"
                  />
                </div>
                <div>
                  <img
                    src="image/p-1.png"
                    alt=""
                    className="img-fluid st1image"
                  />
                </div>
                <div>
                  <img
                    src="image/p-2.png"
                    alt=""
                    className="img-fluid st1image"
                  />
                </div>
                <div>
                  <img
                    src="image/p-1.png"
                    alt=""
                    className="img-fluid st1image"
                  />
                </div>
                <div>
                  <img
                    src="image/p-2.png"
                    alt=""
                    className="img-fluid st1image"
                  />
                </div>
              </OwlCarousel>
            </div>
            <div className="col-lg-5 d-none d-sm-block">
              <img src="image/banner.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      {/*    banner area end */}

      {/* about area start */}
      <div className="about py-5">
        <div className="container  py-5">
          <div className="row g-5">
            <div className="col-lg-3 my-auto">
              <p className="h1">Explore Our </p>
              <p className="h1 cor-2">
                Services &nbsp;{" "}
                <i className="fa-regular fa-circle-right h4"></i>{" "}
              </p>
              <div className="mt-3">
                <a href="#" className="btn-one">
                  Application
                </a>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card-one">
                <img src="image/a-1.png" alt="" className="img-fluid ps-5" />
                <h5>Travel Visa</h5>
                <p>
                  Poppins is available via an open source license. You're free
                  to use it with your Adobe Fonts account just as you would any
                  other font in the Adobe Fonts library.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card-one blue">
                <img src="image/a-2.png" alt="" className="img-fluid ps-5" />
                <h5>Travel Visa</h5>
                <p className="mb-0">
                  Poppins is available via an open source license. You're free
                  to use it with your Adobe Fonts account just as you would any
                  other font in the Adobe Fonts library.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card-one">
                <img src="image/a-3.png" alt="" className="img-fluid ps-5" />
                <h5>Travel Visa</h5>
                <p>
                  Poppins is available via an open source license. You're free
                  to use it with your Adobe Fonts account just as you would any
                  other font in the Adobe Fonts library.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container  py-5">
          <div className="row">
            <div className="col-lg-5">
              <img src="image/about.png" alt="" className="img-fluid" />
            </div>
            <div className="col-lg-7 my-auto">
              <h2 className="h1 display-2">About Us </h2>
              <About />
              <div className="mt-5">
                <Link to="/about" className="btn-one">
                  More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about area end  */}

      {/* -   University area start  */}
      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-12 mb-5">
            <h2 className="display-4 fsw-bold">
              Explore Your <span className="cor-1"> Dream University </span>{" "}
            </h2>
          </div>
        </div>
        <div className="row g-5">
          <University />
        </div>
      </div>
      {/* University  area end */}

      {/* Application Process area start */}
      <div className="process py-5">
        <div className="container py-5 ">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <h2 className="display-4 fsw-bold">
                Application <span className="cor-1"> Process </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-lg-3">
              <div className="icon">
                <img src="image/google-docs.png" alt="" className="img-fluid" />
              </div>
              <p className="h4">Application Materials</p>
              <p>Need to prepare application documents for submission.</p>
            </div>
            <div className="col-lg-1 ">
              <img src="image/arrow.png" alt="" className="img-fluid mt-5" />
            </div>
            <div className="col-lg-3">
              <div className="icon">
                <img src="image/apply.png" alt="" className="img-fluid" />
              </div>
              <p className="h4">Application Submission</p>
              <p>We will proceed your application materials for review.</p>
            </div>
            <div className="col-lg-1 ">
              <img src="image/arrow.png" alt="" className="img-fluid mt-5" />
            </div>
            <div className="col-lg-3">
              <div className="icon">
                <img src="image/mission.png" alt="" className="img-fluid" />
              </div>
              <p className="h4">Admission Result</p>
              <p>
                You will get admission result with a very short period of time
                after review.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 mx-auto text-center">
              <img
                src="image/cloud-computing.png"
                alt=""
                className="img-fluid"
              />
              <p className="h3">Application Materials Lists</p>

              <div className="pt-5">
                <a href="#" className="btn-one me-4">
                  {" "}
                  Student{" "}
                </a>
                <a href="#" className="btn-one me-4">
                  {" "}
                  Tourist{" "}
                </a>
                <a href="#" className="btn-one">
                  {" "}
                  Work{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Application Process area end */}

      {/* <!--     Find Your Dream Place area start --> */}
      <div className="find">
        <div className="container py-5 ">
          <div className="row">
            <div className="col-lg-9">
              <h2 className="display-4 fsw-bold">
                Find Your<span className="cor-1"> Dream Place </span>{" "}
              </h2>
              <div className="py-5">
                <span className="tab"> Visa Type </span>
                <span className="tab"> Country </span>
                <span className="tab"> Program </span>
              </div>
            </div>
            <div className="col-lg-3 text-end mb-5 mb-sm-0">
              <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search Your Dream"
                  aria-label="Search"
                />
                <button
                  className="btn btn-outline-success d-none"
                  type="submit"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
          <div className="row pt-5 card">
            <div className="col-lg-12">
              <div className="table-responsive py-4">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>No</span>
                      </th>
                      <th scope="col">
                        <span>Country</span>
                      </th>
                      <th scope="col">
                        <span>Program</span>
                      </th>
                      <th scope="col">
                        <span>University</span>
                      </th>
                      {/* <th scope="col">
                        <span>Explore</span>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <Circuler sourse="index" />
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-5 mx-auto text-center">
              <div className="my-4">
                <Link to="/all-application" className="btn-one d-block">
                  Start Your Application
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--     Find Your Dream Place area end --> */}

      {/* <!--     student area start --> */}
      <div className="students">
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <h2 className="display-4 fsw-bold">
                Students <span className="cor-1"> Feedbacks </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Swiper --> */}

              <div className="swiper-wrapper mb-5">
                <Swiper
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  pagination={true}
                  className="swiper mySwiper"
                >
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <img src="image/s-1.png" />
                      <p className="h4">
                        Dora <br />
                        Yunnan University
                      </p>
                      <p>
                        Poppins is available via an open source license. You're
                        free to use it with your Adobe Fonts account just as you
                        would any other font in the Adobe Fonts library. Poppins
                        is available via an open source license. You're free to
                        use it with your Adobe Fonts account just as you would
                        any other font in the Adobe Fonts library
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <img src="image/s-2.png" />
                      <p className="h4">
                        Dora <br />
                        Yunnan University
                      </p>
                      <p>
                        Poppins is available via an open source license. You're
                        free to use it with your Adobe Fonts account just as you
                        would any other font in the Adobe Fonts library. Poppins
                        is available via an open source license. You're free to
                        use it with your Adobe Fonts account just as you would
                        any other font in the Adobe Fonts library
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <img src="image/s-3.png" />
                      <p className="h4">
                        Dora <br />
                        Yunnan University
                      </p>
                      <p>
                        Poppins is available via an open source license. You're
                        free to use it with your Adobe Fonts account just as you
                        would any other font in the Adobe Fonts library. Poppins
                        is available via an open source license. You're free to
                        use it with your Adobe Fonts account just as you would
                        any other font in the Adobe Fonts library
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>

                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--     student area end --> */}

      {/* <!--     student area start --> */}
      <div className="students">
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <h2 className="display-4 fsw-bold">
                Students <span className="cor-1"> Feedbacks </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <OwlCarousel
                items={3}
                className="owl-carousel owl-carousel-two owl-theme owl-loaded owl-drag py-5 text-center"
                loop
                nav
                margin={8}
              >
                <div className=" ">
                  <img className="w-75 m-auto" src="image/s-1.png" />
                  <p className="h4">
                    Dora s <br />
                    Yunnan University
                  </p>
                  <p>
                    Poppins is available via an open source license. You're free
                    to use it with your Adobe Fonts account just as you would
                    any other font in the Adobe Fonts library. Poppins is
                    available via an open source license. You're free to use it
                    with your Adobe Fonts account just as you would any other
                    font in the Adobe Fonts library
                  </p>
                </div>
                <div className=" ">
                  <img className="w-75 m-auto" src="image/s-2.png" />
                  <p className="h4">
                    Dora <br />
                    Yunnan University
                  </p>
                  <p>
                    Poppins is available via an open source license. You're free
                    to use it with your Adobe Fonts account just as you would
                    any other font in the Adobe Fonts library. Poppins is
                    available via an open source license. You're free to use it
                    with your Adobe Fonts account just as you would any other
                    font in the Adobe Fonts library
                  </p>
                </div>
                <div className=" ">
                  <img className="w-75 m-auto" src="image/s-3.png" />
                  <p className="h4">
                    Dora <br />
                    Yunnan University
                  </p>
                  <p>
                    Poppins is available via an open source license. You're free
                    to use it with your Adobe Fonts account just as you would
                    any other font in the Adobe Fonts library. Poppins is
                    available via an open source license. You're free to use it
                    with your Adobe Fonts account just as you would any other
                    font in the Adobe Fonts library
                  </p>
                </div>

                <div className="owl-nav">
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev disabled"
                  ></button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                  ></button>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>

      <Gallery />

      <div className="blog">
        <div className="container my-5 py-5 ">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <h2 className="display-4 fsw-bold">
                Our Experts <span className="cor-1"> Blogs & Tips </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-12">
              {/* <OwlCarousel
                items={3}
                className="owl-carousel owl-carousel-two owl-theme owl-loaded owl-drag py-5"
                loop
                nav
                margin={8}
              > */}
              <Blog />

              {/* <div className="owl-nav">
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev disabled"
                  >
                    {" "}
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                  >
                    {" "}
                  </button>
                </div> */}
              {/* </OwlCarousel> */}

              <div className="text-center">
                <a href="#" className="btn-one">
                  View All
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Index;
