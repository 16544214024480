function Relation(props) {
  return (
    <>
      <option value="">无 None</option>
      <option value="父亲 Father">父亲 Father</option>
      <option value="母亲 Mother">母亲 Mother</option>
      <option value="配偶 Spouse">配偶 Spouse</option>
      <option value="叔伯 Uncle">叔伯 Uncle</option>
      <option value="兄弟 Brother">兄弟 Brother</option>
      <option value="姐妹 Sister">姐妹 Sister</option>
      <option value="子女 Children">子女 Children</option>
      <option value="其他 Others">其他 Others</option>
      <option value="老师 Teacher">老师 Teacher</option>
      <option value="雇主Employer">雇主Employer</option>
    </>
  );
}
export default Relation;
