function Industry(props) {
  return (
    <>
      <option value="无 None">无 None</option>
      <option value="政府机构 Government and Public Administration">
        政府机构 Government and Public Administration
      </option>
      <option value="教育Education and Training">
        教育Education and Training
      </option>
      <option value="金融保险 Financial/Insurance Services">
        金融保险 Financial/Insurance Services
      </option>
      <option value="通信 Communications">通信 Communications</option>
      <option value="计算机眼务业 Information Technology and Computers">
        计算机眼务业 Information Technology and Computers
      </option>
      <option value="美药 Healthcare and Health Sciences">
        美药 Healthcare and Health Sciences
      </option>
      <option value="科学研究 Research Institute">
        科学研究 Research Institute
      </option>
      <option value="制造业业 Manufacturing">制造业业 Manufacturing</option>
      <option value="建筑房地产 Construction/Real Estate">
        建筑房地产 Construction/Real Estate
      </option>
      <option value="文娱 Sports and Recreation">
        文娱 Sports and Recreation
      </option>
      <option value="住宿餐饮 Hospitality">住宿餐饮 Hospitality</option>
      <option value="农林牧渔 Agriculture">农林牧渔 Agriculture</option>
      <option value="社会工作 Community-based Organization">
        社会工作 Community-based Organization
      </option>
      <option value="批发季售 Wholesale/Retail Services">
        批发季售 Wholesale/Retail Services
      </option>
      <option value="其亡 Others">其亡 Others</option>
    </>
  );
}
export default Industry;
