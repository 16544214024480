import React from "react";
import { Link } from "react-router-dom";

function Navber(props) {
  var data = JSON.parse(localStorage.getItem("user"));

  return (
    <nav id="navbar" className="navbar nav navbar-expand-lg">
      <div className="container">
        <Link
          className="navbar-brand"
          to={
            data !== null
              ? data.type === 2
                ? "/agent-home"
                : "/home"
              : "/login"
          }
        >
          <img
            src={window.location.origin + `/admin/public/blog/logo.png`}
            alt=""
            className="img-fluid"
            width="300"
            height="50"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="">
            <i className="fa-solid fa-bars"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {/*  <li className="nav-item">
               <Link className="nav-link scroll" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link scroll" to="/service">
                Services{" "}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link scroll" to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link scroll" to="/contact-us">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link cor-1 openBtn"
                href="#"
                onclick="openSearch()"
              >
                <i className="fa-solid fa-magnifying-glass cor-1"></i>
              </a>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link btn-one text-white py-2 px-4"
                to="/all-application"
              >
                Application
              </Link>
            </li> */}
            <li className="nav-item">
              {data !== null ? (
                data.type === 2 ? (
                  <Link
                    className="nav-link btn-one text-white px-3"
                    to="/agent-home"
                  >
                    <i className="fa-solid fa-user"></i>
                  </Link>
                ) : (
                  <Link className="nav-link btn-one text-white px-3" to="/home">
                    <i className="fa-solid fa-user"></i>
                  </Link>
                )
              ) : (
                <Link className="nav-link btn-one text-white px-3" to="/login">
                  <i className="fa-solid fa-user"></i>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navber;
