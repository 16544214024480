import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Navber from "../header/Navber";
import Footer from "../header/Footer";
import { ServiceData, contact } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

function Service(props) {
  useEffect(() => {
    props.ServiceData();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => props.contact(data);

  return (
    <>
      <Navber />

      <div class="container py-5 my-5">
        <div class="row">
          <div class="col-lg-12">
            <div dangerouslySetInnerHTML={{ __html: props.about }}></div>
          </div>

          <div class="col-lg-6 my-auto">
            <p class="h1 mb-5">
              If you have any quarry Please Leave a message !
            </p>
            {props.sucMsg !== null && (
              <div class="alert alert-primary" role="alert">
                {props.sucMsg}
              </div>
            )}
          </div>
          <div class="col-lg-6 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="contact-area pt-5">
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder=" "
                        {...register("name", { required: true })}
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Mail</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder=" "
                        {...register("email", { required: true })}
                      />
                      {errors.email && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <textarea
                        placeholder="Masseage"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        {...register("message", { required: true })}
                      ></textarea>
                      {errors.message && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-md-12 text-end">
                    <div class=" ">
                      <button type="submit" class="btn-two">
                        Masseage
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    about: state.auth.service,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ServiceData: () => dispatch(ServiceData()),
  contact: (data) => dispatch(contact(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Service);
