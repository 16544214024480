function Hsk(props) {
  return (
    <>
      <option value="">None</option>
      <option value="New HSK LEVEL 1">New HSK LEVEL 1</option>
      <option value="New HSK LEVEL 2">New HSK LEVEL 2</option>
      <option value="New HSK LEVEL 3">New HSK LEVEL 3</option>
      <option value="New HSK LEVEL 4">New HSK LEVEL 4</option>
      <option value="New HSK LEVEL 5">New HSK LEVEL 5</option>
      <option value="New HSK LEVEL 6">New HSK LEVEL 6</option>

      <option value="HSK Base level C(score level:1)">
        HSK Base level C(score level:1)
      </option>
      <option value="HSK Base level B(score level:2)">
        HSK Base level B(score level:2)
      </option>
      <option value="HSK Base level A(score level:3)">
        HSK Base level A(score level:3)
      </option>

      <option value="HSK Junior level C(score level:3)">
        HSK Junior level C(score level:3)
      </option>
      <option value="HSK Junior level B(score level:4)">
        HSK Junior level B(score level:4)
      </option>
      <option value="HSK Junior level A(score level:5)">
        HSK Junior level A(score level:5)
      </option>

      <option value="HSK Secondary level C(score level:6)">
        HSK Secondary level C(score level:6)
      </option>
      <option value="HSK Secondary level B(score level:7)">
        HSK Secondary level B(score level:7)
      </option>
      <option value="HSK Secondary level A(score level:8)">
        HSK Secondary level A(score level:8)
      </option>

      <option value="HSK Senior level C(score level:9)">
        HSK Senior level C(score level:9)
      </option>
      <option value="HSK Senior level B(score level:10)">
        HSK Senior level B(score level:10)
      </option>
      <option value="HSK Senior level A(score level:11)">
        HSK Senior level A(score level:11)
      </option>
    </>
  );
}
export default Hsk;
