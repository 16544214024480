import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { blogDetails } from "../../services/actions/AuthAction";
import Footer from "../header/Footer";
import Navber from "../header/Navber";

function BlogDetails(props) {
  let { id } = useParams();

  useEffect(() => {
    props.blogDetails(id);
  }, []);

  return (
    <>
      <Navber />
      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <p className="h1 mb-5">{props.uniDetai.title}</p>
          </div>
          <div className="col-lg-6">
            <div className="card-two">
              <img
                src={props.uniDetai.image}
                alt=""
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div className="col-lg-12 pt-5">
            <p> {props.uniDetai.details} </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    uniDetai: state.auth.blogDetai,
  };
};

const mapDispatchToProps = (dispatch) => ({
  blogDetails: (data) => dispatch(blogDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
