function Visa(props) {
  return (
    <>
      <option value="">Select</option>
      <option value="X1 VISA">X1 VISA</option>
      <option value="X2 VISA">X2 VISA</option>
      <option value="Residence Permite">Residence Permite</option>
      <option value="Stay Certification">Stay Certification</option>
      <option value="Other">Other</option>
      <option value="F VISA">F VISA</option>
      <option value="Z VISA">Z VISA</option>
      <option value="M VISA">M VISA</option>
      <option value="L VISA">L VISA</option>
      <option value="S1 VISA">S1 VISA</option>
      <option value="S2 VISA">S2 VISA</option>
      <option value="NO VISA">NO VISA</option>
      <option value="Q1 VISA">Q1 VISA</option>
      <option value="Q2 VISA">Q2 VISA</option>
    </>
  );
}
export default Visa;
