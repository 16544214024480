import React, { useEffect } from "react";
import { blog } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Blog(props) {
  useEffect(() => {
    props.blog();
  }, []);

  return (
    <>
      {props.univ.map((u, i) => {
        return (
          <div key={i} className="pb-5">
            <div className="card-two">
              <img src={u.image} alt="" className="img-fluid" />
              <p className="h5 my-4">
                <Link to={`blog/` + u.id}>{u.title}</Link>
              </p>
              <p>{u.details.substr(0, 150)}</p>
              <div className="mt-4 text-end">
                <Link to={`blog/` + u.id} className="btn-one">
                  Details
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    univ: state.auth.blog,
  };
};

export default connect(mapStateToProps, { blog })(Blog);
