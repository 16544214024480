function Language(props) {
  return (
    <>
      <option value="">Select Language </option>
      <option value="English">English </option>
      <option value="Mandarin Chinese">Mandarin Chinese</option>
      <option value="Hindi">Hindi</option>
      <option value="Spanish">Spanish</option>
      <option value="French">French</option>
      <option value="Standard Arabic">Standard Arabic</option>
      <option value="Bengali">Bengali</option>
      <option value="Russian">Russian</option>
      <option value="Portuguese">Portuguese</option>
      <option value="Indonesian">Indonesian</option>
      <option value="Urdu">Urdu</option>
      <option value="Standard German">Standard German</option>
      <option value="Japanese">Japanese</option>
      <option value="Swahili">Swahili</option>
      <option value="Marathi">Marathi</option>
      <option value="Telugu">Telugu</option>
      <option value="Western Punjabi">Western Punjabi</option>
      <option value="Wu Chinese">Wu Chinese</option>
      <option value="Tamil">Tamil</option>
      <option value="Turkish">Turkish</option>
      <option value="Korean">Korean</option>
      <option value="Vietnamese">Vietnamese</option>
      <option value="Yue Chinese">Yue Chinese</option>
      <option value="Javanese">Javanese</option>
      <option value="Italian">Italian</option>
      <option value="Egyptian Spoken Arabic">Egyptian Spoken Arabic</option>
      <option value="Hausa">Hausa</option>
      <option value="Thai">Thai</option>
      <option value="Gujarati">Gujarati</option>
      <option value="Kannada">Kannada</option>
      <option value="Iranian Persian">Iranian Persian</option>
      <option value="Bhojpuri">Bhojpuri</option>
      <option value="Southern Min Chinese">Southern Min Chinese</option>
      <option value="Hakka Chinese">Hakka Chinese</option>
      <option value="Jinyu Chinese">Jinyu Chinese</option>
      <option value="Filipino">Filipino</option>
      <option value="Burmese">Burmese</option>
      <option value="Polish">Polish</option>
      <option value="Yoruba">Yoruba</option>
      <option value="Odia">Odia</option>
      <option value="Malayalam">Malayalam</option>
      <option value="Xiang Chinese">Xiang Chinese</option>
      <option value="Maithili">Maithili</option>
      <option value="Ukrainian">Ukrainian</option>
      <option value="Moroccan Spoken Arabic">Moroccan Spoken Arabic</option>
      <option value="Eastern Punjabi">Eastern Punjabi</option>
      <option value="Sunda">Sunda</option>
      <option value="Algerian Spoken Arabic">Algerian Spoken Arabic</option>
      <option value="Sudanese Spoken Arabic">Sudanese Spoken Arabic</option>
      <option value="Nigerian Pidgin">Nigerian Pidgin</option>
      <option value="Zulu">Zulu</option>
      <option value="Igbo">Igbo</option>
      <option value="Amharic">Amharic</option>
      <option value="Northern Uzbek">Northern Uzbek</option>
      <option value="Sindhi">Sindhi</option>
      <option value="North Levantine  Spoken Arabic">
        North Levantine Spoken Arabic
      </option>
      <option value="Nepali">Nepali</option>
      <option value="Romanian">Romanian</option>
      <option value="Tagalog">Tagalog</option>
      <option value="Dutch">Dutch</option>
      <option value="Saidi Spoken Arabic">Saidi Spoken Arabic</option>
      <option value="Gan Chinese">Gan Chinese</option>
      <option value="Northern Pashto">Northern Pashto</option>
      <option value="Magahi">Magahi</option>
      <option value="Saraiki">Saraiki</option>
      <option value="Xhosa">Xhosa</option>
      <option value="Malay">Malay</option>
      <option value="Khmer">Khmer</option>
      <option value="Afrikaans">Afrikaans</option>
      <option value="Sinhala">Sinhala</option>
      <option value="Somali">Somali</option>
      <option value="Chhattisgarhi">Chhattisgarhi</option>
      <option value="Cebuano">Cebuano</option>
      <option value="Mesopotamian Spoken Arabic">
        Mesopotamian Spoken Arabic
      </option>
      <option value="Assamese">Assamese</option>
      <option value="Northeastern Thai">Northeastern Thai</option>
      <option value="Northern Kurdish">Northern Kurdish</option>
      <option value="Hijazi Spoken Arabic">Hijazi Spoken Arabic</option>
      <option value="Nigerian Fulfulde">Nigerian Fulfulde</option>
      <option value="Bavarian">Bavarian</option>
      <option value="Bamanankan">Bamanankan</option>
      <option value="South Azerbaijani">South Azerbaijani</option>
      <option value="Northern Sotho">Northern Sotho</option>
      <option value="Setswana">Setswana</option>
      <option value="Souther Sotho">Souther Sotho</option>
      <option value="Czech">Czech</option>
      <option value="Greek">Greek</option>
      <option value="Chittagonian">Chittagonian</option>
      <option value="Kazakh">Kazakh</option>
      <option value="Swedish">Swedish</option>
      <option value="Deccan">Deccan</option>
      <option value="Hungarian">Hungarian</option>
      <option value="Jula">Jula</option>
      <option value="Sadri">Sadri</option>
      <option value="Kinyarwanda">Kinyarwanda</option>
      <option value="Cameroonian Pidgin">Cameroonian Pidgin</option>
      <option value="Sylheti">Sylheti</option>
      <option value="South Levantine Spoken Arabic">
        South Levantine Spoken Arabic
      </option>
      <option value="Tunisian Spoken Arabic">Tunisian Spoken Arabic</option>
      <option value="Sanaani  Spoken Arabic">Sanaani Spoken Arabi</option>
    </>
  );
}
export default Language;
