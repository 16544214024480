import React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import Notification from "./Notification";
import SMS from "./SMS";

function UserHeader(props) {
  const navigate = useNavigate();

  var data = JSON.parse(localStorage.getItem("user"));
  const bal = {
    borderRadius: "50%",
  };

  const LogOutMsg = () => {
    const shouldRemove = window.confirm("Are you sure want to Log out?");
    if (shouldRemove) {
      navigate("/log-out");
    }
  };

  return (
    <nav id="navbar" className="navbar nav navbar-expand-lg">
      <div className="container">
        <Link
          className="navbar-brand"
          to={
            data !== null
              ? data.type === 2
                ? "/agent-home"
                : "/home"
              : "/login"
          }
        >
          <img
            src={window.location.origin + `/admin/public/blog/logo.png`}
            className="img-fluid"
            width="300"
            height="50"
          />
          <img src="/title-top.png" height="50" alt="s" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="">
            <i className="fa-solid fa-bars"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Notification />
            </li>
            <li className="nav-item">
              <SMS />
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Account
                {/* {data.name} */}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/update-profile">
                    Profile Update
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/password-change">
                    Password Change
                  </Link>
                </li>
                <li>
                  <button onClick={LogOutMsg} className="dropdown-item">
                    Log Out
                  </button>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              {data.type === 2 ? (
                <Link className="nav-link px-3" to="/agent-home">
                  <img
                    src={
                      data.image === null
                        ? window.location.origin + "/image/avater.png"
                        : data.image
                    }
                    width="50"
                    height="50"
                    style={bal}
                    className=""
                    alt={data.name}
                  />
                </Link>
              ) : (
                <Link className="nav-link px-3" to="/home">
                  <img
                    src={
                      data.image === null
                        ? window.location.origin + "/image/avater.png"
                        : data.image
                    }
                    width="50"
                    height="50"
                    style={bal}
                    className=""
                    alt={data.name}
                  />
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default UserHeader;
