import React, { useEffect, useState } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { unknownOne, unknownOneData } from "../../services/actions/AuthAction";
import { Link, Navigate, useParams } from "react-router-dom";
import Country from "../helper/Country";
import ApplicationDataP from "../helper/ApplicationDataP";

function Unknown1(props) {
  const [sector, setSector] = useState("Study");
  let { id, type } = useParams();
  var data = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("image", data.photo[0]);
    formData.append("user_id", data.user_id);
    formData.append("hidden_id", data.hidden_id);
    formData.append("family_name", data.family_name);
    formData.append("given_name", data.given_name);
    formData.append("full_name", data.full_name);
    formData.append("chinese_name", data.chinese_name);
    formData.append("gender", data.gender);
    formData.append("nationality", data.nationality);
    formData.append("dob", data.dob);
    formData.append("birth_country", data.birth_country);
    formData.append("native_language", data.native_language);
    formData.append("marital_status", data.marital_status);
    formData.append("religion", data.religion);
    formData.append("occupation", data.occupation);
    formData.append("institute_affiliated", data.institute_affiliated);
    formData.append("wechat", data.wechat);
    formData.append("phone", data.phone);
    formData.append("birth_place", data.birth_place);
    formData.append("p_home_address", data.p_home_address);
    formData.append("p_city", data.p_city);
    formData.append("p_country", data.p_country);
    formData.append("p_zipcode", data.p_zipcode);
    formData.append("p_phone", data.p_phone);
    formData.append("p_email", data.p_email);
    formData.append("c_current_address", data.c_current_address);
    formData.append("c_city", data.c_city);
    formData.append("c_country", data.c_country);
    formData.append("c_cipcode", data.c_cipcode);
    formData.append("c_phone", data.c_phone);
    formData.append("c_email", data.c_email);
    formData.append("passport_no", data.passport_no);
    formData.append("old_passport_no", data.old_passport_no);
    formData.append("new_expiry_date", data.new_expiry_date);
    formData.append("old_expiry_date", data.old_expiry_date);
    formData.append("studed_in_china", data.studed_in_china);
    formData.append("visa_type", data.visa_type);
    formData.append("visa_no", data.visa_no);
    formData.append("is_china_new", data.is_china_new);
    formData.append("visa_expairy_date", data.visa_expairy_date);
    formData.append("study_period_china", data.study_period_china);
    formData.append("old_china_institute", data.old_china_institute);
    formData.append("sponsor_name", data.sponsor_name);
    formData.append("sponsor_word_place", data.sponsor_word_place);
    formData.append("sponsor_phone", data.sponsor_phone);
    formData.append("sponsor_relation", data.sponsor_relation);
    formData.append("sponsor_occupation", data.sponsor_occupation);
    formData.append("sponsor_email", data.sponsor_email);
    formData.append("guarante_name", data.guarante_name);
    formData.append("guarante_work_place", data.guarante_work_place);
    formData.append("guarante_phone", data.guarante_phone);
    formData.append("guarante_relation", data.guarante_relation);
    formData.append("guarante_occupation", data.guarante_occupation);
    formData.append("guarante_email", data.guarante_email);
    formData.append("emergency_name", data.emergency_name);
    formData.append("emergency_work_place", data.emergency_work_place);
    formData.append("emergency_phone", data.emergency_phone);
    formData.append("emergency_relation", data.emergency_relation);
    formData.append("emergency_occupation", data.emergency_occupation);
    formData.append("emergency_email", data.emergency_email);
    formData.append("sector", sector);

    props.unknownOne(
      formData,
      props.stepone !== null ? props.stepone.id : "new"
    );
  };

  useEffect(() => {
    if (data.type === 1) {
      props.unknownOneData(data.id);
    }
  }, []);

  useEffect(() => {
    // you can do async server request and fill up form
    setTimeout(() => {
      reset({
        lastName: "luo",
      });
    }, 5000);
  }, [reset]);

  const checkHandler = (a) => {
    setSector(a);
  };

  return (
    <>
      <UserHeader />
      <div class="">
        <div class="container">
          <div class="row">
            <ApplicationDataP post={id} pageType="unknoun" />
          </div>
          <div class="row mb-5">
            <div class="col-lg-12">
              <p class="display-3 mb-5 fsw-bold">
                <span class="cor-1"> Personal </span> Information
              </p>
              <p>
                <span
                  onClick={() => checkHandler("Study")}
                  class={sector === "Study" ? "btn-one" : "btn-three"}
                >
                  Study
                </span>
                <span
                  onClick={() => checkHandler("Tourist")}
                  class={sector === "Tourist" ? "btn-one" : "btn-three"}
                >
                  Tourist
                </span>
                <span
                  onClick={() => checkHandler("Work")}
                  class={sector === "Work" ? "btn-one" : "btn-three"}
                >
                  Work
                </span>
              </p>

              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <input type="hidden" {...register("user_id")} value={data.id} />

            {props.stepone !== null ? (
              <input type="hidden" {...register("hidden_id")} value={id} />
            ) : (
              <input
                type="hidden"
                {...register("hidden_id")}
                defaultValue="new"
              />
            )}
            <div class="row application">
              <div class="col-lg-12">
                <p class="h3 mb-4">Personal Information</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Family Name</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("family_name")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.family_name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> Given Name </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("given_name")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.given_name : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> Full Name </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("full_name")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.full_name : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> Chinese Name </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("chinese_name")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.chinese_name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Gender
                      <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        class="form-control"
                        {...register("gender", { required: true })}
                        defaultValue={
                          props.stepone !== null ? props.stepone.gender : ""
                        }
                      >
                        <option
                          selected={
                            props.stepone.gender == "" ? "selected" : ""
                          }
                          value=""
                        >
                          Select Gender
                        </option>
                        <option
                          selected={
                            props.stepone.gender == "Male" ? "selected" : ""
                          }
                          value="Male"
                        >
                          Male
                        </option>
                        <option
                          selected={
                            props.stepone.gender == "Female" ? "selected" : ""
                          }
                          value="Female"
                        >
                          Female
                        </option>
                      </select>
                      {errors.gender && (
                        <span className="colors">This field is required</span>
                      )}
                      {/*
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("gender")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.gender : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      {" "}
                      Nationality <span className="colors star">*</span>{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("nationality", { required: true })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.nationality
                            : ""
                        }
                      />
                      {errors.nationality && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      {" "}
                      Date Of Birth <span className="colors star">*</span>{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="date"
                        class="form-control"
                        placeholder=" "
                        {...register("dob", { required: true })}
                        defaultValue={
                          props.stepone !== null ? props.stepone.dob : ""
                        }
                      />
                      {errors.dob && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      {" "}
                      Country Of Birth <span className="colors star">
                        *
                      </span>{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("birth_country")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.birth_country
                            : ""
                        }
                      /> */}
                      <select
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.birth_country
                            : ""
                        }
                        {...register("birth_country", { required: true })}
                        class="form-control"
                      >
                        <option value="">Select Country</option>
                        <Country
                          select={
                            props.stepone !== null
                              ? props.stepone.birth_country
                              : ""
                          }
                        />
                      </select>{" "}
                      {errors.birth_country && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      {" "}
                      Photo <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      {props.stepone !== null &&
                      props.stepone.photo !== null ? (
                        <input
                          required="required"
                          type="file"
                          class="form-control"
                          accept="image/*"
                          {...register("photo", { required: true })}
                        />
                      ) : (
                        <input
                          type="file"
                          class="form-control"
                          accept="image/*"
                          {...register("photo", { required: true })}
                        />
                      )}

                      {errors.photo && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Native Language <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("native_language", { required: true })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.native_language
                            : ""
                        }
                      />
                      {errors.native_language && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Marital Status <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        type="text"
                        class="form-control"
                        {...register("marital_status", { required: true })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.marital_status
                            : ""
                        }
                      >
                        <option
                          selected={
                            props.stepone.marital_status == "" ? "selected" : ""
                          }
                          value=""
                        >
                          Select One
                        </option>
                        <option
                          selected={
                            props.stepone.marital_status == "Married"
                              ? "selected"
                              : ""
                          }
                          value="Married"
                        >
                          Married
                        </option>
                        <option
                          selected={
                            props.stepone.marital_status == "Single"
                              ? "selected"
                              : ""
                          }
                          value="Single"
                        >
                          Single
                        </option>
                        <option
                          selected={
                            props.stepone.marital_status == "Divode"
                              ? "selected"
                              : ""
                          }
                          value="Divode"
                        >
                          Divode
                        </option>
                      </select>
                      {errors.marital_status && (
                        <span className="colors">This field is required</span>
                      )}
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("marital_status")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.marital_status
                            : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Religion <span className="colors star">*</span>{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        type="text"
                        class="form-control"
                        {...register("religion", { required: true })}
                        value={
                          props.stepone !== null ? props.stepone.religion : ""
                        }
                      >
                        <option value="">Select One</option>
                        <option value="Islam">Islam</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Christan">Christan</option>
                      </select>
                      {errors.religion && (
                        <span className="colors">This field is required</span>
                      )}
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("religion")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.religion : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Occupation <span className="colors star">*</span>{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("occupation", { required: true })}
                        defaultValue={
                          props.stepone !== null ? props.stepone.occupation : ""
                        }
                      />
                      {errors.occupation && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Institution Affiliated{" "}
                      <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("institute_affiliated", {
                          required: true,
                        })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.institute_affiliated
                            : ""
                        }
                      />
                      {errors.institute_affiliated && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">WeChat</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("wechat")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.wechat : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Phone </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("phone")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.phone : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Place Of Birth <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.birth_place
                            : ""
                        }
                        {...register("birth_place", { required: true })}
                        class="form-control"
                      >
                        <option value="">Select Country</option>
                        <Country
                          select={
                            props.stepone !== null
                              ? props.stepone.birth_place
                              : ""
                          }
                        />
                      </select>
                      {errors.birth_place && (
                        <span className="colors">This field is required</span>
                      )}
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("birth_place")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.birth_place
                            : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5">
                <p class="h3">Correspondence Address</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Home Address </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("p_home_address")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.p_home_address
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">City/Province </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        {...register("p_city")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.p_city : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Country</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        defaultValue={
                          props.stepone !== null ? props.stepone.p_country : ""
                        }
                        {...register("p_country")}
                        class="form-control"
                      >
                        <option value="">Select Country</option>
                        <Country
                          select={
                            props.stepone !== null
                              ? props.stepone.p_country
                              : ""
                          }
                        />
                      </select>

                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("p_country")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.p_country : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Zipcode</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("p_zipcode")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.p_zipcode : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Phone</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("p_phone")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.p_phone : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Email</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("p_email")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.p_email : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Current Address</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("c_current_address")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.c_current_address
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">City/Province </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("c_city")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.c_city : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Country</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        defaultValue={
                          props.stepone !== null ? props.stepone.c_country : ""
                        }
                        {...register("c_country")}
                        class="form-control"
                      >
                        <option value="">Select Country</option>
                        <Country
                          select={
                            props.stepone !== null
                              ? props.stepone.c_country
                              : ""
                          }
                        />
                      </select>
                      {/*                       
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("c_country")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.c_country : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Zipcode</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("c_cipcode")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.c_cipcode : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Phone</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("c_phone")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.c_phone : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Email</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("c_email")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.c_email : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5">
                <p class="h3">Passport and Visa Information</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Passport No <span className="colors star">*</span>{" "}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("passport_no", { required: true })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.passport_no
                            : ""
                        }
                      />
                      {errors.passport_no && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Old Passport No</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("old_passport_no")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.old_passport_no
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Expiry Date <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="date"
                        class="form-control"
                        placeholder=" "
                        {...register("new_expiry_date", { required: true })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.new_expiry_date
                            : ""
                        }
                      />
                      {errors.new_expiry_date && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Expiration Date</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="date"
                        class="form-control"
                        placeholder=" "
                        {...register("old_expiry_date")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.old_expiry_date
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5">
                <p class="h3">Learning Experience In China</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Have you studied in China? </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <span style={{ paddingRight: `12px` }}>
                        <input
                          type="radio"
                          {...register("studed_in_china")}
                          value="Yes"
                          checked={
                            props.stepone !== null
                              ? props.stepone.studed_in_china === "Yes"
                                ? "checked"
                                : null
                              : null
                          }
                        />
                        Yes
                      </span>
                      <span>
                        <input
                          type="radio"
                          {...register("studed_in_china")}
                          value="No"
                          checked={
                            props.stepone !== null
                              ? props.stepone.studed_in_china === "No"
                                ? "checked"
                                : null
                              : null
                          }
                        />
                        No
                      </span>
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("studed_in_china")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.studed_in_china
                            : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Visa Type <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("visa_type", { required: true })}
                        defaultValue={
                          props.stepone !== null ? props.stepone.visa_type : ""
                        }
                      /> */}
                      <select
                        class="form-control"
                        {...register("visa_type", { required: true })}
                        defaultValue={
                          props.stepone !== null ? props.stepone.visa_type : ""
                        }
                      >
                        <option
                          selected={
                            props.stepone.visa_type == "" ? "selected" : ""
                          }
                          value=""
                        >
                          Select
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type == "Business/Tourist Visa"
                              ? "selected"
                              : ""
                          }
                          value="Business/Tourist Visa"
                        >
                          Business/Tourist Visa
                        </option>

                        <option
                          selected={
                            props.stepone.visa_type == "Work Visa"
                              ? "selected"
                              : ""
                          }
                          value="Work Visa"
                        >
                          Work Visa
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type == "Student Visa"
                              ? "selected"
                              : ""
                          }
                          value="Student Visa"
                        >
                          Student Visa
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type == "Exchange Visitor Visa"
                              ? "selected"
                              : ""
                          }
                          value="Exchange Visitor Visa"
                        >
                          Exchange Visitor Visa
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type == "Transit/Ship Crew Visa"
                              ? "selected"
                              : ""
                          }
                          value="Transit/Ship Crew Visa"
                        >
                          Transit/Ship Crew Visa
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type == "Religious Worker Visa"
                              ? "selected"
                              : ""
                          }
                          value="Religious Worker Visa"
                        >
                          Religious Worker Visa
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type == "Domestic Employee Visa"
                              ? "selected"
                              : ""
                          }
                          value="Domestic Employee Visa"
                        >
                          Domestic Employee Visa
                        </option>
                        <option
                          selected={
                            props.stepone.visa_type ==
                            "Journalist and Media Visa"
                              ? "selected"
                              : ""
                          }
                          value="Journalist and Media Visa"
                        >
                          Journalist and Media Visa
                        </option>
                      </select>

                      {errors.visa_type && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Visa No</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("visa_no")}
                        defaultValue={
                          props.stepone !== null ? props.stepone.visa_no : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">
                      The institution in China that you have studied in
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      In China now? <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <span style={{ paddingRight: `12px` }}>
                        <input
                          type="radio"
                          {...register("is_china_new", { required: true })}
                          value="Yes"
                          checked={
                            props.stepone !== null
                              ? props.stepone.is_china_new === "Yes"
                                ? "checked"
                                : null
                              : null
                          }
                        />
                        Yes
                      </span>
                      <span>
                        <input
                          type="radio"
                          {...register("is_china_new")}
                          value="No"
                          checked={
                            props.stepone !== null
                              ? props.stepone.is_china_new === "No"
                                ? "checked"
                                : null
                              : null
                          }
                        />
                        No
                      </span>
                      {errors.is_china_new && (
                        <span className="colors">This field is required</span>
                      )}
                      {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("is_china_new")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.is_china_new
                            : ""
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">
                      Expiry Date <span className="colors star">*</span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="date"
                        class="form-control"
                        placeholder=" "
                        {...register("visa_expairy_date", { required: true })}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.visa_expairy_date
                            : ""
                        }
                      />
                      {errors.visa_expairy_date && (
                        <span className="colors">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> Study Period in China</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("study_period_china")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.study_period_china
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("old_china_institute")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.old_china_institute
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5">
                <p class="h3">Financial Sponsor's Information</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("sponsor_name")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.sponsor_name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Work Place</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("sponsor_word_place")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.sponsor_word_place
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Phone</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("sponsor_phone")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.sponsor_phone
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Relationship</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("sponsor_relation")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.sponsor_relation
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Occupation</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("sponsor_occupation")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.sponsor_occupation
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Email</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("sponsor_email")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.sponsor_email
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 my-5">
                <p class="h3">Guarantor's Information</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("guarante_name")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.guarante_name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Work Place</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("guarante_work_place")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.guarante_work_place
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Phone</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("guarante_phone")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.guarante_phone
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Relationship</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("guarante_relation")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.guarante_relation
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Occupation</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("guarante_occupation")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.guarante_occupation
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Email</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("guarante_email")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.guarante_email
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 my-5">
                <p class="h3">Emergency Contact</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("emergency_name")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.emergency_name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Work Place</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("emergency_work_place")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.emergency_work_place
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Phone</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("emergency_phone")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.emergency_phone
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Relationship</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("emergency_relation")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.emergency_relation
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Occupation</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("emergency_occupation")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.emergency_occupation
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Email</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        {...register("emergency_email")}
                        defaultValue={
                          props.stepone !== null
                            ? props.stepone.emergency_email
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}

              <div class="col-lg-12 text-center my-5">
                {props.steponedone === "update" && (
                  <button type="submit" class="btn-one">
                    Save
                  </button>
                )}
                {props.steponeid > 0 ? (
                  <>
                    <Link
                      to={"/people-unknown-two/" + props.steponeid}
                      class="btn-one"
                    >
                      Next
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    stepone: state.auth.stepone,
    steponedone: state.auth.steponedone,
    steponeid: state.auth.steponeid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  unknownOne: (data, ii) => dispatch(unknownOne(data, ii)),
  unknownOneData: (data) => dispatch(unknownOneData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unknown1);
