import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import { useForm } from "react-hook-form";
import { profileUpdate, profileInfo } from "../../services/actions/AuthAction";
import { connect } from "react-redux";

function ProfileUpdate(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("image", data.pic[0]);
    formData.append("name", data.name);
    formData.append("passport", data.passport);
    formData.append("country", data.country);
    formData.append("program", data.program);
    // console.log(formData);
    props.profileUpdate(formData);
  };
  useEffect(() => {
    props.profileInfo();
  }, []);

  useEffect(() => {
    // you can do async server request and fill up form
    setTimeout(() => {
      reset({
        name: props.profile?.name,
        passport: props.profile?.passport,
      });
    }, 2000);
  }, [reset]);

  return (
    <>
      <Navber />

      <div class="container py-5 my-5">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div class="row">
            <div class="col-lg-10">
              <p class="h1 mb-5">Hay Update Your Basic Information</p>
            </div>

            <div class="col-lg-6 mx-auto">
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}

              <div class="contact-area pt-5">
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput133"
                        {...register("name", { required: true })}
                        defaultValue={props.profile?.name}
                      />
                      {errors.name && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Passport</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput12"
                        {...register("passport", { required: true })}
                        defaultValue={
                          props.profile !== null ? props.profile.passport : ""
                        }
                      />
                      {errors.passport && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Country</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput11111"
                        {...register("country", { required: true })}
                        defaultValue={
                          props.profile !== null ? props.profile.country : ""
                        }
                      />
                      {errors.country && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Program</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput111"
                        {...register("program", { required: true })}
                        defaultValue={
                          props.profile !== null ? props.profile.program : ""
                        }
                      />
                      {errors.program && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Profile Picture</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="file"
                        class="form-control"
                        id="exampleFormControlInput11"
                        {...register("pic")}
                      />
                      {errors.pic && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row pt-4">
                  <div class="col-md-12 text-end">
                    <div class=" ">
                      <button type="submit" class="btn-two">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    profile: state.auth.profile,
  };
};
const mapDispatchToProps = (dispatch) => ({
  profileUpdate: (data) => dispatch(profileUpdate(data)),
  profileInfo: () => dispatch(profileInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);
