import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Navber from "./header/Navber";
import Footer from "./header/Footer";
import { aboutUS } from "../services/actions/AuthAction";
import { connect } from "react-redux";

function About(props) {
  useEffect(() => {
    props.aboutUS();
  }, []);
  return (
    <>
      <Navber />
      
      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-9 mx-auto text-center">
            <h2 className="h1 display-5 mb-5">About Us </h2>
            <p className="fs-3">{props.about}</p>
            <div className="mt-5">
              <a href="#" className="btn-one">
                Submit Your Mail Address
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    about: state.auth.aboutt,
  };
};

const mapDispatchToProps = (dispatch) => ({
  aboutUS: () => dispatch(aboutUS()),
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
