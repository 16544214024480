import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { verifyEmail } from "../services/actions/AuthAction";
import Footer from "./header/Footer";
import Navber from "./header/Navber";

function Verify(props) {
  let { id } = useParams();

  useEffect(() => {
    props.verifyEmail(id);
  }, []);

  return (
    <>
      <Navber />

      {/* <!-- login area start   --> */}
      <div class="container py-5 my-5">
        <div class="login-area">
          <div class="row">
            <div class="col-lg-7 mx-auto text-center">
              <div class="pb-5 mb-5">
                <img src="image/logo.png" alt="" class="img-fluid" />
              </div>
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
              {props.errMsg !== null && (
                <div class="alert alert-danger" role="alert">
                  {props.errMsg}
                </div>
              )}

              <div class="row pt-4">
                <div class="col-md-8 text-start my-auto">
                  <p class="small mb-0">
                    <Link className="btn btn-one" to="/login">
                      {" "}
                      Please Login !{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- login area end   --> */}

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
  };
};

export default connect(mapStateToProps, { verifyEmail })(Verify);
