import React, { useEffect, useState, useRef } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";

import { sendMessage, oldMessage } from "../../services/actions/AuthAction";
import { useForm } from "react-hook-form";
import local from "../../Route/Url";

function NotificationPage(props) {
  var data = JSON.parse(localStorage.getItem("user"));
  var token = localStorage.getItem("token");
  const listRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();

    props.oldMessage(data.id);

    window.Pusher.logToConsole = true;
    var pusher = new window.Pusher("a3d59094219c41a31f73", {
      cluster: "ap2",
      authEndpoint: local + `/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      },
    });

    const channel = pusher.subscribe("private-sweet." + data.id);
    channel.bind("my-event", function (dataa) {
      const info = {
        msg: dataa.message,
        id: dataa.user.id,
        sender: dataa.sender,
      };
      setMessages((messages) => [...messages, info]);
    });
  }, []);

  // const submit = (e) => {
  //   e.preventDefault();
  //   const info = {
  //     msg: message,
  //     id: data.id,
  //     sender: "user",
  //   };
  //   props.sendMessage(info);
  //   setMessage("");
  // };
  const onSubmit = (dataa) => {
    const info = {
      msg: dataa.message,
      id: data.id,
      sender: "user",
    };
    props.sendMessage(info);
    reset();
  };

  return (
    <>
      <UserHeader />
      <div className="container-fluid my-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="data">
              <div className="row my-5">
                <div className="col-lg-5 mx-auto text-center ">
                  <p className="h4">{data.name}</p>
                  <p className="h4">
                    {data.passport} | {data.country}
                  </p>
                  <p className="h4">{data.program}</p>
                  <p className="py-4">
                    {/* <Link className="btn-one" to="/all-application">
                      Application
                    </Link> */}
                  </p>
                </div>
              </div>

              <div className="container">
                <div className="card makeHeight">
                  <div className="card-body relative">
                    {props.chat.map((message, i) => {
                      return i !== props.chat.length - 1 ? (
                        <div className="ddd">
                          <span
                            className={
                              message.sender !== "user"
                                ? "chat_left"
                                : "chat_right"
                            }
                          >
                            {message.msg}
                          </span>
                        </div>
                      ) : (
                        <div className="ddd pp" ref={listRef}>
                          <span
                            className={
                              message.sender !== "user"
                                ? "chat_left"
                                : "chat_right"
                            }
                          >
                            {message.msg}
                          </span>
                        </div>
                      );
                    })}

                    {messages.map((message, i) => {
                      return i !== messages.length - 1 ? (
                        <div className="ddd">
                          <span
                            className={
                              message.sender !== "user"
                                ? "chat_left"
                                : "chat_right"
                            }
                          >
                            {message.msg}
                          </span>
                        </div>
                      ) : (
                        <div className="ddd pp" ref={listRef}>
                          <span
                            className={
                              message.sender !== "user"
                                ? "chat_left"
                                : "chat_right"
                            }
                          >
                            {message.msg}
                          </span>
                        </div>
                      );
                    })}

                    {/* <div className="ddd" ref={listRef}>
                      <p className="chat_left">
                        <img
                          src={
                            data.image === null
                              ? window.location.origin + "/image/avater.png"
                              : data.image
                          }
                          width="50"
                          height={20}
                          style={bal}
                          className="img-fluid"
                          alt={data.name}
                        />
                        Hello
                      </p>
                    </div> */}
                    <br />
                  </div>
                </div>
                {/* <form onSubmit={(e) => submit(e)} className="fixed"> */}
                <form onSubmit={handleSubmit(onSubmit)} className="fixed">
                  <div className="row mt-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                      {/* <input
                        type="text"
                        name="text"
                        className="form-control"
                        style={{
                          background: "#d7eaee",
                          border: `1px solid #00ff2b !important`,
                        }}
                        //value={message}
                        // onChange={(e) => setMessage(e.target.value)}
                        {...register("message", { required: true })}
                      /> */}
                      <textarea
                        name="text"
                        className="form-control"
                        style={{
                          background: "#d7eaee",
                          border: "1px solid #00ff2b !important",
                        }}
                        {...register("message", { required: true })}
                      ></textarea>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        name="submit"
                        className="btn btn-info"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    home: state.auth.notificData,
    chat: state.auth.chat,
  };
};

export default connect(mapStateToProps, { sendMessage, oldMessage })(
  NotificationPage
);
