import React from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { resets } from "../services/actions/AuthAction";
import Footer from "./header/Footer";
import Navber from "./header/Navber";

function Reset(props) {
  let { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    props.resets(data);
  };
  return (
    <>
      <Navber />

      {/* <!-- login area start   --> */}
      <div class="container py-5 my-5">
        <div class="login-area">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="row">
              <div class="col-lg-7 mx-auto text-center">
                <div class="pb-5 mb-5">
                  <img src="image/logo.png" alt="" class="img-fluid" />
                </div>
                {props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {props.sucMsg}
                  </div>
                )}
                {props.errMsg !== null && (
                  <div class="alert alert-danger" role="alert">
                    {props.errMsg}
                  </div>
                )}
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Password</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input {...register("token")} type="hidden" value={id} />
                      <input
                        type="password"
                        class="form-control"
                        id="exampleFormControlInput1a"
                        {...register("password", { required: true })}
                      />
                      {errors.password && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-md-3">
                    <div class="bg">Confirm Password</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="password"
                        class="form-control"
                        id="exampleFormControlInput1"
                        {...register("confrim", { required: true })}
                      />
                      {errors.confrim && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-md-8 text-start my-auto">
                    <p class="small mb-0">
                      Haven't any account?{" "}
                      <Link to="/register"> Please Registration ! </Link>
                    </p>
                  </div>
                  <div class="col-md-4">
                    <div class=" ">
                      <button type="submit" class="btn-two">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <!-- login area end   --> */}

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
  };
};

export default connect(mapStateToProps, { resets })(Reset);
