function Proficiency(props) {
  return (
    <>
      <option value="无 None">无 None</option>
      <option value="差 Poor">差 Poor</option>
      <option value="般 Average">般 Average</option>
      <option value="较好 Good">较好 Good</option>
      <option value="好 Very good">好 Very good</option>
      <option value="很好 Excellent">很好 Excellent</option>
    </>
  );
}
export default Proficiency;
