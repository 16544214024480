import axios from "axios";
import local from "../../Route/Url";
import {
  LOADING,
  FAILED,
  LOGIN,
  LOGOUT,
  REDIRECT_FALSE,
  REG,
  FORGOT,
  DETAILS,
  BAZAR,
  ACCEPT,
  PASS,
  CONTACT,
  UNIVERSITY,
  UNIVERSITYDETAILS,
  BLOG,
  BLOGDETAILS,
  GALLERY,
  STEPONE,
  ABOUT,
  SERVICE,
  STEPTWO,
  STEPONEDATA,
  CIRCULER,
  CIRCULER_DETAILS,
  STEPTWODATA,
  STEPTHREE,
  STEPTHREEDATA,
  FOOTER,
  HOMEDATA,
  PROFILE,
  PROFILE_INFO,
  ALLGALLERY,
  NOTIFICATION,
  NOTIFICATIONDATA,
  MARKREAD,
  VERIFY,
  CHENGE_PASSWORD,
  INDEXABOUT,
  APPLICATION_VIEW,
  PAYMENT,
  PAYMENT_SEND,
  APPLY_ONE,
  APPLY_TWO,
  APPLY_ONE_DATA,
  APPLY_THREE_DATA,
  APPLY_TWO_DATA,
  APPLY_THREE,
  APPLY_FOUR,
  APPLY_ONE_DATA2,
  APPLY_FOUR_DATA,
  APPLY_FINAL,
  RESET,
  DELETE,
  HOMEDATA2,
  OLDMESSAGE,
  SMSS,
  CIRCULER_SHOW,
  APPLY,
} from "../constants";
const URL = local;

export const login = (value) => async (dispatch) => {
  dispatch({ type: LOADING });
  await axios
    .post(`${URL}/login`, value)
    .then((res) => {
      console.log(res);
      dispatch({
        type: LOGIN,
        payload: res.data.user.type,
      });
      const user = {
        id: res.data.user.id,
        name: res.data.user.name,
        passport: res.data.user.passport,
        country: res.data.user.country,
        program: res.data.user.program,
        phone: res.data.user.phone,
        image: res.data.user.image,
        email: res.data.user.email,
        type: parseInt(res.data.user.type),
      };
      localStorage.setItem("token", "Bearer " + res.data.token);
      localStorage.setItem("user", JSON.stringify(user));
    })
    .catch((err) => {
      dispatch({
        type: FAILED,
        payload: err.response.data.error,
      });
    });
};

export const register = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .post(`${URL}/register`, id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: REG,
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log(err.response.data);
      dispatch({
        type: FAILED,
        payload: err.response.data,
      });
    });
};
export const password_change = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/change_password`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: CHENGE_PASSWORD,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FAILED,
        payload: err.response.data.message,
      });
    });
};
export const forgott = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .post(`${URL}/forgot`, id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: FORGOT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const verifyEmail = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/verify/` + id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: VERIFY,
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      dispatch({
        type: FAILED,
        payload: err.response.data.message,
      });
    });
};

export const resets = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .post(`${URL}/reset`, id)
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: RESET,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const logout = () => async (dispatch) => {
  localStorage.clear();
  dispatch({
    type: LOGOUT,
  });
};
export const contact = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .post(`${URL}/contact`, id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const university = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/university`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: UNIVERSITY,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const universityDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/university_details/` + id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: UNIVERSITYDETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FAILED,
        payload: "Problems.",
      });
    });
};

export const blog = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/blog`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: BLOG,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const blogDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/blog_details/` + id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: BLOGDETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FAILED,
        payload: "Problems.",
      });
    });
};

export const gallery = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/gallery`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GALLERY,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const AllGallery = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/all-gallery`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: ALLGALLERY,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const Notificationp = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/notification/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: NOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const sms_count = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/sms_count`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SMSS,
        payload: res.data.chat,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const NotificationData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/notification_data/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: NOTIFICATIONDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const markRead = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  console.log(id);
  await axios
    .get(`${URL}/mark_read/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: MARKREAD,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const circulerList = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/circulerList/` + id)
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: CIRCULER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const circulerShow = (type, id, intake) => async (dispatch) => {
  dispatch({ type: LOADING });

  dispatch({
    type: CIRCULER_SHOW,
    payload: { type: type, id: id, intake: intake },
  });
};

export const circulerDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/circuler_details/` + id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: CIRCULER_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const aboutUS = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/about/`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: ABOUT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const IndexAboutUS = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/index_about/`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: INDEXABOUT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const ServiceData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/service/`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SERVICE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const ApplicationData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/service/`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SERVICE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationOne = (id, hidden) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/application_one/` + hidden, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const AgentApplicationOne = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/agent_application_one`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const unknownOne = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/unknown_one`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationOneData = (id, post) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/application_one_data/` + id + `/` + post, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: STEPONEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const AgentApplicationOneData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/agent_application_one_data/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: STEPONEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const unknownOneData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/unknown_one_data/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPONEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationTwo = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/application_two`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTWO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const AgentApplicationTwo = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/agent_application_two`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTWO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const unknownTwo = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/unknown_two`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTWO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationTwoData = (id, post) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/application_two_data/` + id + `/` + post, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTWODATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const AgentApplicationTwoData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/agent_application_two_data/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTWODATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const unknownTwoData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/unknown_two_data/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTWODATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationThree = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/application_three`, id, {
      headers: {
        Authorization: token,
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTHREE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationThreeData = (id, post) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/application_three_data/` + id + `/` + post, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTHREEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const unknownThree = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/unknown_three`, id, {
      headers: {
        Authorization: token,
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTHREE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const unknownThreeData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/unknown_three_data/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTHREEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const AgentApplicationThree = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/agent_application_three`, id, {
      headers: {
        Authorization: token,
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTHREE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const AgentApplicationThreeData = (post) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/agent_application_three_data/` + post, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: STEPTHREEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const footer = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/footer/`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: FOOTER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const homeData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  const token = localStorage.getItem("token");

  await axios
    .get(`${URL}/home`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: HOMEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const agentHomeData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  const token = localStorage.getItem("token");

  await axios
    .get(`${URL}/agent_home/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: HOMEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const agentHomeQuery = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/agent_home_query/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: HOMEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const agentFilter = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/agent_home_filter`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: HOMEDATA2,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const profileUpdate = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  // console.log(id);
  await axios
    .post(`${URL}/profile_update`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: PROFILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const profileInfo = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  // console.log(id);
  await axios
    .get(`${URL}/profile_info`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: PROFILE_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applicationView = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/application_view/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data.data.one);
      dispatch({
        type: APPLICATION_VIEW,
        payload: {
          one: res.data.data.one,
          two: res.data.data.two,
          three: res.data.data.three,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applicationByID = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/application_by_id/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data.data.one);
      dispatch({
        type: STEPONEDATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const paymentMethod = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/payment_method`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: PAYMENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const paymentSend = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/payment_send`, id, {
      headers: {
        Authorization: token,
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: PAYMENT_SEND,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyOneData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/apply_one_new`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_ONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyAll =
  (apply_type, apply_id, user_type, explore) => async (dispatch) => {
    dispatch({ type: LOADING });
    const token = localStorage.getItem("token");

    const id = {
      apply_type: apply_type,
      apply_id: apply_id,
      user_type: user_type,
      explore: explore,
    };

    //console.log(id);

    await axios
      .post(`${URL}/apply_all`, id, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        //    console.log(res.data);
        dispatch({
          type: APPLY_ONE_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        //   dispatch({
        //     type: FAILED,
        //     payload: err.response.data,
        //   });
      });
  };

export const applyOneDataGet = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_one_data_get`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res);
      dispatch({
        type: APPLY_ONE_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyOneDataGet22 = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_one_data_get22/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res);
      dispatch({
        type: APPLY_ONE_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyOneDataGet2 = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_one_data_get2`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_ONE_DATA2,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applyOneDataGet3 = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_one_data_get3/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_ONE_DATA2,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyTwoData = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/apply_two/` + id, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_TWO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applyTwoDataGet = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_two_data_get/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_TWO_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyThreeData = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/apply_three/` + id, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: APPLY_THREE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applyThreeDataGet = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_three_data_get/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_THREE_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyFourData = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/apply_four/` + id, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_FOUR,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applyFourDataGet = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_four_data_get/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_FOUR_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applyFourDataDelete = (table, name, id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_four_data_delete/` + table + "/" + name + "/" + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch({
      //   type: APPLY_FOUR_DATA,
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const applyViewData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/apply_view_data/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLICATION_VIEW,
        payload: {
          one: res.data.data.one,
          two: res.data.data.two,
          three: res.data.data.three,
          four: res.data.data.four,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const applyFinal = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/final_submit/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: APPLY_FINAL,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const deteleApply = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .delete(`${URL}/deteleApply/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: DELETE,
        payload: id,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
export const sendMessage = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .post(`${URL}/msg`, id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      // dispatch({
      //   type: DELETE,
      //   payload: id,
      // });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const oldMessage = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const token = localStorage.getItem("token");
  await axios
    .get(`${URL}/old_messages/` + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: OLDMESSAGE,
        payload: res.data.chat,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};

export const accept = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  await axios
    .get(`${URL}/accept/` + id)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: ACCEPT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      //   dispatch({
      //     type: FAILED,
      //     payload: err.response.data,
      //   });
    });
};
