import React, { useEffect } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { Link, json, useParams } from "react-router-dom";
import { applicationView } from "../../services/actions/AuthAction";
import ApplicationDataP from "../helper/ApplicationDataP";
import Jsonn from "./Jsonn";

function ViewApplicaton(props) {
  var data = JSON.parse(localStorage.getItem("user"));
  let { id } = useParams();
  useEffect(() => {
    props.applicationView(id);
  }, []);
  const bal = {
    borderRadius: "50%",
  };
  const tdd = {
    border: `1px solid`,
  };
  const tddd = {
    background: "#b7aeae",
    border: `1px solid`,
  };
  const tddk = {
    background: "#b7aeae",
    border: `1px solid`,
  };
  const headsss = {
    background: `rgb(234, 171, 171)`,
    color: "white",
    textAlign: "justify",
  };

  return (
    <>
      <UserHeader />
      <div class="container my-5">
        <div class="row g-5">
          <div class="col-lg-12">
            <div class="data">
              <div class="row my-5">
                <ApplicationDataP post={id} pageType="view" />
              </div>

              <div class="table-responsive my-5">
                <table class="table table-borderless text-center">
                  <tbody>
                    <tr>
                      <td style={headsss} colSpan={4}>
                        个人基本信息 Personal Information
                      </td>
                    </tr>
                    <tr>
                      <td style={tddd}>护照姓 Passport Family Name</td>
                      <td style={tdd}>{props.viewOne.family_name}</td>

                      <td style={tddd}>护照名 Given Name</td>
                      <td style={tdd}>{props.viewOne.family_name}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>
                        护照全名 Full Name as shown on your passport
                      </td>
                      <td style={tdd}>{props.viewOne.full_name}</td>
                      <td style={tddd}> Chinese Name </td>
                      <td style={tdd}>{props.viewOne.chinese_name}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>性别 Gender</td>
                      <td style={tdd}>{props.viewOne.gender}</td>
                      <td style={tddd}>母语 Native Language </td>
                      <td style={tdd}>{props.viewOne.native_language}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>婚姻状态 Marital Status</td>
                      <td style={tdd}>{props.viewOne.marital_status}</td>
                      <td style={tddd}>宗教 Religion</td>
                      <td style={tdd}>{props.viewOne.religion}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Occupation</td>
                      <td style={tdd}>{props.viewOne.occupation}</td>
                      <td style={tddd}>
                        工作或学习单位 Employer or Institution Affiliated
                      </td>
                      <td style={tdd}>{props.viewOne.institute_affiliated}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Nationality</td>
                      <td style={tdd}>{props.viewOne.nationality}</td>
                      <td style={tddd}>出生日期 Date of Birth</td>
                      <td style={tdd}>{props.viewOne.dob}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>出生国家 Country of Birth</td>
                      <td style={tdd}>{props.viewOne.birth_country}</td>
                      <td style={tddd}>微信 Wechat</td>
                      <td style={tdd}>{props.viewOne.wechat}</td>
                    </tr>

                    <tr>
                      <td style={tddd}> Phone</td>
                      <td style={tdd}>{props.viewOne.phone}</td>
                      <td style={tddd}>出生地点 Place of Birth</td>
                      <td style={tdd}>{props.viewOne.birth_place}</td>
                    </tr>
                    <td style={headsss} colSpan={4}>
                      Parmanent Addess
                    </td>
                    <tr>
                      <td style={tddd}>Parmanent 家庭地址 Home Address</td>
                      <td style={tdd}>{props.viewOne.p_home_address}</td>
                      <td style={tddd}>Parmanent City</td>
                      <td style={tdd}>{props.viewOne.p_city}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Parmanent 国家 Country</td>
                      <td style={tdd}>{props.viewOne.p_country}</td>
                      <td style={tddd}>Parmanent 邮编 Zipcode</td>
                      <td style={tdd}>{props.viewOne.p_zipcode}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Parmanent 电话/手机 Phone or Mobile</td>
                      <td style={tdd}>{props.viewOne.p_phone}</td>
                      <td style={tddd}>Parmanent 常用邮箱 Main Email</td>
                      <td style={tdd}>{props.viewOne.p_email}</td>
                    </tr>
                    <td style={headsss} colSpan={4}>
                      Current Addess
                    </td>
                    <tr>
                      <td style={tddd}> Address</td>
                      <td style={tdd}>{props.viewOne.c_home_address}</td>
                      <td style={tddd}> City</td>
                      <td style={tdd}>{props.viewOne.c_city}</td>
                    </tr>

                    <tr>
                      <td style={tddd}> 国家 Country</td>
                      <td style={tdd}>{props.viewOne.c_country}</td>
                      <td style={tddd}> 邮编 Zipcode</td>
                      <td style={tdd}>{props.viewOne.c_zipcode}</td>
                    </tr>

                    <tr>
                      <td style={tddd}> 电话/手机 Phone or Mobile</td>
                      <td style={tdd}>{props.viewOne.c_phone}</td>
                      <td style={tddd}> 常用邮箱 Main Email</td>
                      <td style={tdd}>{props.viewOne.c_email}</td>
                    </tr>
                    <td style={headsss} colSpan={4}>
                      证照信息 Passport and Visa Information
                    </td>
                    <tr>
                      <td style={tddd}>
                        护照号码 Passport No <br /> 护照有效期 Expiry Date
                      </td>
                      <td style={tdd}>
                        {props.viewOne.passport_no}
                        <br /> {props.viewOne.new_expiry_date}
                      </td>

                      <td style={tddd}>
                        旧护照号码 Old Passport No <br /> 旧护照到期时间
                        Expiration of Old Passport
                      </td>
                      <td style={tdd}>
                        {props.viewOne.old_passport_no}
                        <br /> {props.viewOne.old_expiry_date}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddd}>
                        签证申请所在大使馆或领事馆 Which Chinese embassy or
                        consulate would you apply visa at
                      </td>
                      <td style={tdd}>{props.viewOne.emabassy}</td>
                    </tr>
                    <td style={headsss} colSpan={4}>
                      在华学习经历 Learning Experience In China
                    </td>
                    <tr>
                      <td style={tddd}>
                        是否曾经或当前在中国学习 Have you studied or whether
                        studying in China currently?
                      </td>
                      <td style={tdd}>{props.viewOne.studed_in_china}</td>
                      <td style={tddd}>签证类型 Visa Type</td>
                      <td style={tdd}>{props.viewOne.visa_type}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Visa No</td>
                      <td style={tdd}>{props.viewOne.visa_no}</td>
                      <td style={tddd}>
                        当前是否在中国 Whether in China now?*
                      </td>
                      <td style={tdd}>{props.viewOne.is_china_new}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>签证到期时间 Expiry Date</td>
                      <td style={tdd}>{props.viewOne.visa_expairy_date}</td>
                      <td style={tddd}>
                        在华学习期限 Duration of study in China
                      </td>
                      <td style={tdd}>{props.viewOne.study_period_china}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>
                        在华学习院校 The institution in China that you have
                      </td>
                      <td style={tdd}>{props.viewOne.china_institute}</td>
                    </tr>

                    <td style={headsss} colSpan={4}>
                      经济担保人信息 Financial Sponsor's Information
                    </td>

                    <tr>
                      <td style={tddd}>姓名 Name</td>
                      <td style={tdd}>{props.viewOne.sponsor_name}</td>
                      <td style={tddd}>单位名称 Work Place </td>
                      <td style={tdd}>{props.viewOne.sponsor_word_place}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>联系电话 Phone/Mobile</td>
                      <td style={tdd}>{props.viewOne.sponsor_phone}</td>
                      <td style={tddd}>关系 Relationship </td>
                      <td style={tdd}>{props.viewOne.sponsor_relation}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>职业 Occupation</td>
                      <td style={tdd}>{props.viewOne.sponsor_occupation}</td>
                      <td style={tddd}>联系邮箱 Email</td>
                      <td style={tdd}>{props.viewOne.sponsor_email}</td>
                    </tr>
                    <td style={headsss} colSpan={4}>
                      Guarante Information
                    </td>
                    <tr>
                      <td style={tddd}>Guarante Name</td>
                      <td style={tdd}>{props.viewOne.guarante_name}</td>
                      <td style={tddd}>Guarante Word Place</td>
                      <td style={tdd}>{props.viewOne.guarante_word_place}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Guarante Phone</td>
                      <td style={tdd}>{props.viewOne.guarante_phone}</td>
                      <td style={tddd}>Guarante Relation</td>
                      <td style={tdd}>{props.viewOne.guarante_relation}</td>
                    </tr>

                    <tr>
                      <td style={tddd}> Guarante Occupation</td>
                      <td style={tdd}>{props.viewOne.guarante_occupation}</td>
                      <td style={tddd}>Guarante Email</td>
                      <td style={tdd}>{props.viewOne.guarante_email}</td>
                    </tr>
                    <td style={headsss} colSpan={4}>
                      Emergency Information
                    </td>
                    <tr>
                      <td style={tddd}>Emergency Name</td>
                      <td style={tdd}>{props.viewOne.emergency_name}</td>
                      <td style={tddd}>Emergency Word Place</td>
                      <td style={tdd}>{props.viewOne.emergency_word_place}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Emergency Phone</td>
                      <td style={tdd}>{props.viewOne.emergency_phone}</td>
                      <td style={tddd}>Emergency Relation</td>
                      <td style={tdd}>{props.viewOne.emergency_relation}</td>
                    </tr>

                    <tr>
                      <td style={tddd}>Emergency Email</td>
                      <td style={tdd}>{props.viewOne.emergency_email}</td>
                      <td style={tddd}>Emergency Occupation</td>
                      <td style={tdd}>{props.viewOne.emergency_occupation}</td>
                    </tr>

                    <tr>
                      <td style={{ color: `red` }} colSpan={4}>
                        Academic Info
                      </td>
                    </tr>
                    {props.viewTwo !== undefined && (
                      <>
                        <td style={headsss} colSpan={4}>
                          Language Proficiency
                        </td>
                        <tr>
                          <td style={tddk}>English Proficiency</td>
                          <td style={tdd}>
                            {props.viewTwo.english_proficiency}
                          </td>
                          <td style={tddk}>IELTS</td>
                          <td style={tdd}>{props.viewTwo.ielts}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Toefl</td>
                          <td style={tdd}>{props.viewTwo.toefl}</td>
                          <td style={tddk}>GRE</td>
                          <td style={tdd}>{props.viewTwo.gre}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>GMAT</td>
                          <td style={tdd}>{props.viewTwo.gmat}</td>
                          <td style={tddk}>Chinese Proficiency</td>
                          <td style={tdd}>
                            {props.viewTwo.chinese_proficiency}
                          </td>
                        </tr>

                        <tr>
                          <td style={tddk}>HSK Level</td>
                          <td style={tdd}>{props.viewTwo.hsk_level}</td>
                          <td style={tddk}>HSKK Score</td>
                          <td style={tdd}>{props.viewTwo.hskk_score}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>HSK No</td>
                          <td style={tdd}>{props.viewTwo.hsk_no}</td>
                          <td style={tddk}>Listen Score</td>
                          <td style={tdd}>{props.viewTwo.listen_score}</td>
                        </tr>

                        <td style={headsss} colSpan={4}>
                          Education Background
                        </td>

                        <tr>
                          <td style={tddk}>Highest Degree</td>
                          <td style={tdd}>{props.viewTwo.highest_degree}</td>
                          <td style={tddk}>School Name</td>
                          <td style={tdd}>{props.viewTwo.schoolname}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Form to End</td>
                          <td style={tdd}>
                            {props.viewTwo.form_to_end} To
                            {props.viewTwo.form_to_end_from}
                          </td>
                          <td style={tddk}>Certificate Type</td>
                          <td style={tdd}>{props.viewTwo.certificate_type}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Degree 1</td>
                          <td style={tdd}>{props.viewTwo.degree1}</td>
                          <td style={tddk}>School Name 1</td>
                          <td style={tdd}>{props.viewTwo.schoolname1}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Form to End 1</td>
                          <td style={tdd}>
                            {props.viewTwo.form_to_end1} To
                            {props.viewTwo.form_to_end1_from}
                          </td>
                          <td style={tddk}>Contact 1</td>
                          <td style={tdd}>{props.viewTwo.contact1}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Degree 2</td>
                          <td style={tdd}>{props.viewTwo.degree2}</td>
                          <td style={tddk}>School Name 2</td>
                          <td style={tdd}>{props.viewTwo.schoolname2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Form to End 2</td>
                          <td style={tdd}>
                            {props.viewTwo.form_to_end2} To
                            {props.viewTwo.form_to_end2_from}
                          </td>
                          <td style={tddk}>Contact 2</td>
                          <td style={tdd}>{props.viewTwo.contact2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Degree 3</td>
                          <td style={tdd}>{props.viewTwo.degree3}</td>
                          <td style={tddk}>School Name 3</td>
                          <td style={tdd}>{props.viewTwo.schoolname3}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Form to End 3</td>
                          <td style={tdd}>
                            {props.viewTwo.form_to_end3} To
                            {props.viewTwo.form_to_end3_from}
                          </td>
                          <td style={tddk}>Contact 3</td>
                          <td style={tdd}>{props.viewTwo.contact3}</td>
                        </tr>
                        <td style={headsss} colSpan={4}>
                          Company
                        </td>

                        <tr>
                          <td style={tddk}>Company 1</td>
                          <td style={tdd}>{props.viewTwo.company1}</td>
                          <td style={tddk}>Occupation 1</td>
                          <td style={tdd}>{props.viewTwo.occupation1}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Form to End 1</td>
                          <td style={tdd}>{props.viewTwo.com_from_to_end1}</td>
                          <td style={tddk}>com_contact1</td>
                          <td style={tdd}>{props.viewTwo.com_contact1}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Company 2</td>
                          <td style={tdd}>{props.viewTwo.company2}</td>
                          <td style={tddk}>Occupation 2</td>
                          <td style={tdd}>{props.viewTwo.occupation2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Form to End 2</td>
                          <td style={tdd}>{props.viewTwo.com_from_to_end2}</td>
                          <td style={tddk}>Contact 2</td>
                          <td style={tdd}>{props.viewTwo.com_contact2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Company 3</td>
                          <td style={tdd}>{props.viewTwo.company3}</td>
                          <td style={tddk}>Occupation 3</td>
                          <td style={tdd}>{props.viewTwo.occupation3}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Form to End 3</td>
                          <td style={tdd}>{props.viewTwo.com_from_to_end3}</td>
                          <td style={tddk}>Contact 3</td>
                          <td style={tdd}>{props.viewTwo.com_contact3}</td>
                        </tr>
                        <td style={headsss} colSpan={4}>
                          Family Members
                        </td>

                        <tr>
                          <td style={tddk}>Name 1</td>
                          <td style={tdd}>{props.viewTwo.name1}</td>
                          <td style={tddk}>Relation 1</td>
                          <td style={tdd}>{props.viewTwo.relation1}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Nationality 1</td>
                          <td style={tdd}>{props.viewTwo.nationality1}</td>
                          <td style={tddk}>Work Place 1</td>
                          <td style={tdd}>{props.viewTwo.work_place1}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Occupation 1</td>
                          <td style={tdd}>{props.viewTwo.f_occupation1}</td>
                          <td style={tddk}>Phone 1</td>
                          <td style={tdd}>{props.viewTwo.f_phone1}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Email 1</td>
                          <td style={tdd}>{props.viewTwo.f_email1}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Name 2</td>
                          <td style={tdd}>{props.viewTwo.name2}</td>
                          <td style={tddk}>Relation 2</td>
                          <td style={tdd}>{props.viewTwo.relation2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Nationality 2</td>
                          <td style={tdd}>{props.viewTwo.nationality2}</td>
                          <td style={tddk}>Work Place 2</td>
                          <td style={tdd}>{props.viewTwo.work_place2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Occupation 2</td>
                          <td style={tdd}>{props.viewTwo.f_occupation2}</td>
                          <td style={tddk}>Phone 2</td>
                          <td style={tdd}>{props.viewTwo.f_phone2}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Email 2</td>
                          <td style={tdd}>{props.viewTwo.f_email2}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Name 3</td>
                          <td style={tdd}>{props.viewTwo.name3}</td>
                          <td style={tddk}>Relation 3</td>
                          <td style={tdd}>{props.viewTwo.relation3}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Nationality 3</td>
                          <td style={tdd}>{props.viewTwo.nationality3}</td>
                          <td style={tddk}>Work Place 3</td>
                          <td style={tdd}>{props.viewTwo.work_place3}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Occupation 3</td>
                          <td style={tdd}>{props.viewTwo.f_occupation3}</td>
                          <td style={tddk}>Phone 3</td>
                          <td style={tdd}>{props.viewTwo.f_phone3}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Email 3</td>
                          <td style={tdd}>{props.viewTwo.f_email3}</td>
                        </tr>

                        <tr>
                          <td style={tddk}>Name 4</td>
                          <td style={tdd}>{props.viewTwo.name4}</td>
                          <td style={tddk}>Relation 4</td>
                          <td style={tdd}>{props.viewTwo.relation4}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Nationality 4</td>
                          <td style={tdd}>{props.viewTwo.nationality4}</td>
                          <td style={tddk}>Work Place 4</td>
                          <td style={tdd}>{props.viewTwo.work_place4}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Occupation 4</td>
                          <td style={tdd}>{props.viewTwo.f_occupation4}</td>
                          <td style={tddk}>Phone 4</td>
                          <td style={tdd}>{props.viewTwo.f_phone4}</td>
                        </tr>
                        <tr>
                          <td style={tddk}>Email 4</td>
                          <td style={tdd}>{props.viewTwo.f_email4}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td style={{ color: `red` }} colSpan={4}>
                        Uploaded Document
                      </td>
                    </tr>
                    {props.viewThree.length !== undefined ? (
                      <>
                        <tr>
                          <td style={tddk}>Valid Ordinary Passport</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.valid_ordinary_passport !==
                              undefined &&
                            props.viewThree.valid_ordinary_passport !== null
                              ? JSON.parse(
                                  props.viewThree.valid_ordinary_passport
                                ).map((u, i) => {
                                  return (
                                    <a href={u} target="_blank">
                                      {i + 1 + `. `}
                                      {
                                        JSON.parse(
                                          props.viewThree
                                            .valid_ordinary_passport_name
                                        )[i]
                                      }
                                      <br />
                                    </a>
                                  );
                                })
                              : ""}
                          </td>
                          <td style={tddk}>Original Higtest Diploma</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.original_higtest_diploma !==
                              undefined &&
                            props.viewThree.original_higtest_diploma !== null
                              ? JSON.parse(
                                  props.viewThree.original_higtest_diploma
                                ).map((u, i) => {
                                  return (
                                    <a href={u} target="_blank">
                                      {i + 1 + `. `}
                                      {
                                        JSON.parse(
                                          props.viewThree
                                            .original_higtest_diploma_name
                                        )[i]
                                      }
                                      <br />
                                    </a>
                                  );
                                })
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={tddk}>Original Transcript</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.original_transcript !== undefined &&
                            props.viewThree.original_transcript !== null
                              ? JSON.parse(
                                  props.viewThree.original_transcript
                                ).map((u, i) => {
                                  return (
                                    <a href={u} target="_blank">
                                      {i + 1 + `. `}
                                      {
                                        JSON.parse(
                                          props.viewThree
                                            .original_transcript_name
                                        )[i]
                                      }
                                      <br />
                                    </a>
                                  );
                                })
                              : ""}
                          </td>
                          <td style={tddk}>Written Resume</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.written_resume !== undefined &&
                            props.viewThree.written_resume !== null
                              ? JSON.parse(props.viewThree.written_resume).map(
                                  (u, i) => {
                                    return (
                                      <a href={u} target="_blank">
                                        {i + 1 + `. `}
                                        {
                                          JSON.parse(
                                            props.viewThree.written_resume_name
                                          )[i]
                                        }
                                        <br />
                                      </a>
                                    );
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={tddk}>Physical Record</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.physical_record !== undefined &&
                            props.viewThree.physical_record !== null
                              ? JSON.parse(props.viewThree.physical_record).map(
                                  (u, i) => {
                                    return (
                                      <a href={u} target="_blank">
                                        {i + 1 + `. `}
                                        {
                                          JSON.parse(
                                            props.viewThree.physical_record_name
                                          )[i]
                                        }
                                        <br />
                                      </a>
                                    );
                                  }
                                )
                              : ""}
                          </td>
                          <td style={tddk}>Criminal Record</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.criminal_record !== undefined &&
                            props.viewThree.criminal_record !== null
                              ? JSON.parse(props.viewThree.criminal_record).map(
                                  (u, i) => {
                                    return (
                                      <a href={u} target="_blank">
                                        {i + 1 + `. `}
                                        {
                                          JSON.parse(
                                            props.viewThree.criminal_record_name
                                          )[i]
                                        }
                                        <br />
                                      </a>
                                    );
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={tddk}>Recommendation Letter</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.recommendation_letter !==
                              undefined &&
                            props.viewThree.recommendation_letter !== null
                              ? JSON.parse(
                                  props.viewThree.recommendation_letter
                                ).map((u, i) => {
                                  return (
                                    <a href={u} target="_blank">
                                      {i + 1 + `. `}
                                      {
                                        JSON.parse(
                                          props.viewThree
                                            .recommendation_letter_name
                                        )[i]
                                      }
                                      <br />
                                    </a>
                                  );
                                })
                              : ""}
                          </td>
                          <td style={tddk}>Study Plan</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.study_plan !== undefined &&
                            props.viewThree.study_plan !== null
                              ? JSON.parse(props.viewThree.study_plan).map(
                                  (u, i) => {
                                    return (
                                      <a href={u} target="_blank">
                                        {i + 1 + `. `}
                                        {
                                          JSON.parse(
                                            props.viewThree.study_plan_name
                                          )[i]
                                        }
                                        <br />
                                      </a>
                                    );
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={tddk}>Research Achievement</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.research_achievement !==
                              undefined &&
                            props.viewThree.research_achievement !== null
                              ? JSON.parse(
                                  props.viewThree.research_achievement
                                ).map((u, i) => {
                                  return (
                                    <a href={u} target="_blank">
                                      {i + 1 + `. `}
                                      {
                                        JSON.parse(
                                          props.viewThree
                                            .research_achievement_name
                                        )[i]
                                      }
                                      <br />
                                    </a>
                                  );
                                })
                              : ""}
                          </td>
                          <td style={tddk}>Language Proficiency</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.language_proficiency !==
                              undefined &&
                            props.viewThree.language_proficiency !== null
                              ? JSON.parse(
                                  props.viewThree.language_proficiency
                                ).map((u, i) => {
                                  return (
                                    <a href={u} target="_blank">
                                      {i + 1 + `. `}
                                      {
                                        JSON.parse(
                                          props.viewThree
                                            .language_proficiency_name
                                        )[i]
                                      }
                                      <br />
                                    </a>
                                  );
                                })
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={tddk}>Other</td>
                          <td style={tdd}>
                            {props.viewThree !== null &&
                            props.viewThree.other !== undefined &&
                            props.viewThree.other !== null
                              ? JSON.parse(props.viewThree.other).map(
                                  (u, i) => {
                                    return (
                                      <a href={u} target="_blank">
                                        {i + 1 + `. `}
                                        {
                                          JSON.parse(
                                            props.viewThree.other_name
                                          )[i]
                                        }
                                        <br />
                                      </a>
                                    );
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    home: state.auth.home,
    viewOne: state.auth.viewOne,
    viewTwo: state.auth.viewTwo,
    viewThree: state.auth.viewThree,
    pp: state.auth.pp,
  };
};

export default connect(mapStateToProps, { applicationView })(ViewApplicaton);
