import React, { useEffect, useState } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NotificationData, markRead } from "../../services/actions/AuthAction";
function NotificationPage(props) {
  var data = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    props.NotificationData();
  }, []);

  const markit = (id) => {
    props.markRead(id);
  };

  return (
    <>
      <UserHeader />
      <div class="container-fluid my-5">
        <div class="row g-5">
          <div class="col-lg-12">
            <div class="data">
              <div class="row my-5">
                <div class="col-lg-5 mx-auto text-center ">
                  <p class="h4">{data.name}</p>
                  <p class="h4">
                    {data.passport} | {data.country}
                  </p>
                  <p class="h4">{data.program}</p>
                  <p class="py-4">
                    {/* <Link class="btn-one" to="/all-application">
                      Application
                    </Link> */}
                  </p>
                </div>
              </div>

              <div class="table-responsive my-5">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">NO</th>
                      <th scope="col">Message</th>
                      <th scope="col">Notify</th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.home.map((d, i) => {
                      return (
                        <tr>
                          <th>{i + 1}</th>
                          <td>
                            {" "}
                            <strong>{d.unique_id}</strong> {d.message}
                          </td>
                          <td>{d.created_at}</td>

                          <td>
                            {d.status === 0 ? (
                              d.read_at
                            ) : (
                              <span
                                className="btn btn-info"
                                onClick={() => markit(d.id)}
                              >
                                Mark Read
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    home: state.auth.notificData,
  };
};

export default connect(mapStateToProps, { NotificationData, markRead })(
  NotificationPage
);
