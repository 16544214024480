import React, { useEffect, useState } from "react";
// import Footer from "./header/Footer";
// import Navber from "./header/Navber";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { login } from "../services/actions/AuthAction";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

function Login(props) {
  const [isChecked, setIsChecked] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  const onSubmit = (data) => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(user_captcha) === true) {
      props.login(data);
      loadCaptchaEnginge(4);
      document.getElementById("user_captcha_input").value = "";
    } else {
      alert("Captcha Does Not Match");
      document.getElementById("user_captcha_input").value = "";
    }
    // reset();
  };

  if (props.redirect === true) {
    if (props.typee === 1) {
      return <Navigate replace to="/home" />;
    } else {
      return <Navigate replace to="/agent-home" />;
    }
  }
  const checkHandler = () => {
    setIsChecked(!isChecked);
  };
  const styles = {
    fontSize: `24px`,
    color: `#6c6edd`,
  };
  return (
    <>
      {/* <Navber /> */}
      <div className="row m-4">
        <div className="col-md-6">
          <div style={{ float: `right` }}>
            <img
              src={window.location.origin + `/admin/public/blog/logo.png`}
              alt="p"
              height="60"
            />
          </div>
        </div>
        <div className="col-md-6">
          <img src="title-top.png" height="60" alt="s" />
          <Link
            to="/all-application"
            className="btn btn-info text-light"
            style={{ float: `right` }}
          >
            <i class="fa-solid fa-globe"></i> Explore
          </Link>
        </div>
      </div>

      <div class="back">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div
              className="col-md-4"
              style={{
                background: "white",
                marginTop: "70px",
                opacity: `.9`,
                padding: `43px 25px`,
              }}
            >
              本系统用于国际学生报名申请、结果查询和在校信息查询等。 <br /> This
              system is used for international students submitting applications
              to the University, information inquiry, etc.
              <br /> <br />
              强烈建议使用360浏览器(10.0以上)(点击下载)或谷歌浏览器(点击下载)。
              <br />
              It is strongly suggested that you use 360 browser (version 10.0 or
              above) (click to download) or Chrome browser (click to download)
              to finish online application.
              <br /> <br />
              第一次使用本系统进行报名时，请先注册。同一电子邮箱只允许注册一次。填写在线申请.国际学生网报流程
              <br />
              If this is your first time using this site, please register first
              to login in the system. Each email address is allowed to register
              for one account only. Please click to see Registration guide.
            </div>

            <div
              className="col-md-4"
              style={{
                background: "white",
                marginTop: "70px",
                opacity: `.9`,
                padding: `43px 25px`,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <p style={styles}>国际学生服务平台</p>

                {props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {props.sucMsg}
                  </div>
                )}
                {props.errMsg !== null && (
                  <div class="alert alert-danger" role="alert">
                    {props.errMsg}
                  </div>
                )}

                <div class="mb-3">
                  <div class="input-group ">
                    <span class="input-group-text">
                      <i class="fa fa-user icon"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Username/Email 账号/邮箱"
                      class="form-control"
                      id="exampleFormControlInput1"
                      {...register("email", { required: true })}
                    />
                  </div>

                  {errors.email && <span>This field is required</span>}
                </div>

                <div class="mb-3">
                  <div class="input-group">
                    <span class="input-group-text">
                      <i class="fa fa-lock icon"></i>
                    </span>
                    <input
                      class="form-control"
                      placeholder="Password 密码"
                      type={isChecked ? "text" : "password"}
                      id="exampleFormControlInput1"
                      {...register("password", { required: true })}
                    />

                    {!isChecked && (
                      <span class="input-group-text">
                        <i class="fa fa-eye icon" onClick={checkHandler}></i>
                      </span>
                    )}
                    {isChecked && (
                      <span class="input-group-text">
                        <i
                          class="fa fa-eye-slash icon"
                          onClick={checkHandler}
                        ></i>
                      </span>
                    )}
                  </div>
                  {errors.password && <span>This field is required</span>}
                </div>
                <div class="input-group mt-3">
                  <input
                    placeholder="Verification code 验证码"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                  />
                  <LoadCanvasTemplate />
                </div>

                {/* <div class="col-md-8 text-start my-auto">
                    <p class="small mb-0">
                      Haven't any account?
                      <Link to="/register">Please Registration !</Link>
                    </p>
                    <p class="small">
                      Forget Your password?
                      <Link to="/forgot"> Please Reset password !</Link>
                    </p>
                  </div> */}
                <div class="input-group mt-3" style={{ display: `grid` }}>
                  <button type="submit" className="btn btn-danger ">
                    Log in 登录
                  </button>

                  <Link className="btn btn-primary mt-3" to="/register">
                    Create New Account 注册
                  </Link>
                  <br />
                  <Link to="/forgot"> Forgot password?</Link>
                </div>
              </form>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <div className="text-center pt-5">
        <hr />
        <p style={{ padding: `50px 0 100px 0` }}>
          ©2023 Xi'an Explorning Chang'an Cultural Exchange Co., Ltd.
        </p>
      </div>
      {/* <!-- login area end   --> */}

      {/* <Footer /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    typee: state.auth.typee,
    redirect: state.auth.redirect,
  };
};

export default connect(mapStateToProps, { login })(Login);
