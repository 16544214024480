import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, Navigate, redirect } from "react-router-dom";
import { register } from "../services/actions/AuthAction";
import Footer from "./header/Footer";
import Navber from "./header/Navber";

function Register(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    props.register(data);
    reset();

    return redirect("login");
  };

  return (
    <>
      <Navber />
      {/* <!--   header end --> */}

      {/* <!-- login area start   --> */}
      <div className="container py-5 my-5">
        <div className="login-area">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-7 mx-auto text-center">
                <div className="">
                  <img
                    src={window.location.origin + `/admin/public/blog/logo.png`}
                    alt=""
                    style={{
                      width: `400px`,
                      height: `112px`,
                      paddingBottom: `30px`,
                    }}
                  />
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="bg">Name</div>
                  </div>
                  <div className="col-md-9">
                    <div className=" ">
                      <input
                        type="text"
                        className="form-control"
                        {...register("name", { required: true })}
                      />
                      {errors.name && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="bg">Mail</div>
                  </div>
                  <div className="col-md-9">
                    <div className=" ">
                      <input
                        type="email"
                        className="form-control"
                        {...register("email", { required: true })}
                      />
                      {errors.email && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="bg">Phone</div>
                  </div>
                  <div className="col-md-9">
                    <div className=" ">
                      <input
                        type="text"
                        className="form-control"
                        {...register("phone", { required: true })}
                      />
                      {errors.phone && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="bg">Password</div>
                  </div>
                  <div className="col-md-9">
                    <div className=" ">
                      <input
                        type="password"
                        className="form-control"
                        {...register("password", { required: true })}
                      />
                      {errors.password && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-3">
                    <div className="bg">Confirm Password</div>
                  </div>
                  <div className="col-md-9">
                    <div className=" ">
                      <input
                        type="password"
                        className="form-control"
                        {...register("password_confirmation", {
                          required: true,
                        })}
                      />
                      {errors.password_confirmation && (
                        <span>This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  {props.sucMsg !== null && (
                    <div class="alert alert-primary" role="alert">
                      {props.sucMsg}
                      <br />
                      <p>
                        NB. If Registration mail not in your Inbox folder,
                        Please check "Spam" folder also.
                      </p>
                      <Link to="/login">Login</Link>
                    </div>
                  )}
                  {props.errMsg !== null && (
                    <div class="alert alert-danger" role="alert">
                      {Object.values(JSON.parse(props.errMsg))}
                    </div>
                  )}

                  <div className="col-md-8 text-start my-auto">
                    <p className="small mb-0">
                      Have account Already?
                      <Link to="/login">Please Login !</Link>
                    </p>
                    <p className="small">
                      Haven't forget Your password?
                      <Link to="/forgot"> Please Reset password !</Link>
                    </p>
                  </div>

                  <div className="col-md-4">
                    <div className=" ">
                      <button type="submit" className="btn btn-info">
                        Registration
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    redirect: state.auth.redirect,
  };
};

export default connect(mapStateToProps, { register })(Register);
