import React, { useEffect } from "react";
import { IndexAboutUS } from "../../services/actions/AuthAction";
import { connect } from "react-redux";

function About(props) {
  useEffect(() => {
    props.IndexAboutUS();
  }, []);

  return <p>{props.about}</p>;
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    about: state.auth.aboutt,
  };
};

export default connect(mapStateToProps, { IndexAboutUS })(About);
