import React, { useEffect } from "react";
import { gallery } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Gallery(props) {
  useEffect(() => {
    props.gallery();
  }, []);

  console.log(props.galleryy.length > 0 ? props.galleryy[0]["image"] : "");

  return (
    <>
      <div className="container py-5 my-5">
        <div className="row pb-5">
          <div className="col-lg-6 pb-5 pb-sm-0  my-auto">
            <h2 className="display-4 fsw-bold">
              Moments with <br />
              <span className="cor-1"> Students & Partners </span>{" "}
            </h2>
            <div className="mt-4">
              <Link to="/all-gallary" className="btn-one">
                Photo Gallary
              </Link>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row g-4">
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[0]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[0]["image"]
                        : ""
                    }
                  />
                </a>
              </div>

              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[1]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[1]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[2]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[2]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[3]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[3]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 pt-4">
            <div className="row g-4">
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[4]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[4]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[5]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[5]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[6]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[6]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
              <div className="col-md-6">
                <a
                  data-fancybox="gallery"
                  data-src={
                    props.galleryy.length > 0 ? props.galleryy[7]["image"] : ""
                  }
                >
                  <img
                    className="img-fluid"
                    src={
                      props.galleryy.length > 0
                        ? props.galleryy[7]["image"]
                        : ""
                    }
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pt-4">
            <a
              data-fancybox="gallery"
              data-src={
                props.galleryy.length > 0 ? props.galleryy[8]["image"] : ""
              }
            >
              <img
                className="img-fluid"
                src={
                  props.galleryy.length > 0 ? props.galleryy[8]["image"] : ""
                }
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    galleryy: state.auth.gallery,
  };
};

export default connect(mapStateToProps, { gallery })(Gallery);
