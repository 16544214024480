import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Notificationp } from "../../services/actions/AuthAction";

function Notification(props) {
  useEffect(() => {
    props.Notificationp();
  }, []);

  return (
    <>
      <Link className="nav-link" to="/notification">
        <i className="fa-solid fa-bell"></i> {props.notific.length}
      </Link>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    notific: state.auth.notific,
  };
};

const mapDispatchToProps = (dispatch) => ({
  Notificationp: () => dispatch(Notificationp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
