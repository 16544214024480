import React from "react";

function Country(props) {
  return (
    <>
      <option
        selected={props.select === "Afghanistan" ? "selected" : ""}
        value="Afghanistan"
      >
        Afghanistan
      </option>
      <option
        selected={props.select === "Aland Islands" ? "selected" : ""}
        value="Aland Islands"
      >
        Aland Islands
      </option>
      <option
        selected={props.select === "Albania" ? "selected" : ""}
        value="Albania"
      >
        Albania
      </option>
      <option
        selected={props.select === "Algeria" ? "selected" : ""}
        value="Algeria"
      >
        Algeria
      </option>
      <option
        selected={props.select === "American Samoa" ? "selected" : ""}
        value="American Samoa"
      >
        American Samoa
      </option>
      <option
        selected={props.select === "Andorra" ? "selected" : ""}
        value="Andorra"
      >
        Andorra
      </option>
      <option
        selected={props.select === "Angola" ? "selected" : ""}
        value="Angola"
      >
        Angola
      </option>
      <option
        selected={props.select === "Anguilla" ? "selected" : ""}
        value="Anguilla"
      >
        Anguilla
      </option>
      <option
        selected={props.select === "Antarctica" ? "selected" : ""}
        value="Antarctica"
      >
        Antarctica
      </option>
      <option
        selected={props.select === "Antigua And Barbuda" ? "selected" : ""}
        value="Antigua And Barbuda"
      >
        Antigua And Barbuda
      </option>
      <option
        selected={props.select === "Argentina" ? "selected" : ""}
        value="Argentina"
      >
        Argentina
      </option>
      <option
        selected={props.select === "Armenia" ? "selected" : ""}
        value="Armenia"
      >
        Armenia
      </option>
      <option
        selected={props.select === "Aruba" ? "selected" : ""}
        value="Aruba"
      >
        Aruba
      </option>
      <option
        selected={props.select === "Australia" ? "selected" : ""}
        value="Australia"
      >
        Australia
      </option>
      <option
        selected={props.select === "Austria" ? "selected" : ""}
        value="Austria"
      >
        Austria
      </option>
      <option
        selected={props.select === "Azerbaijan" ? "selected" : ""}
        value="Azerbaijan"
      >
        Azerbaijan
      </option>
      <option
        selected={props.select === "The Bahamas" ? "selected" : ""}
        value="The Bahamas"
      >
        The Bahamas
      </option>
      <option
        selected={props.select === "Bahrain" ? "selected" : ""}
        value="Bahrain"
      >
        Bahrain
      </option>
      <option
        selected={props.select === "Bangladesh" ? "selected" : ""}
        value="Bangladesh"
      >
        Bangladesh
      </option>
      <option
        selected={props.select === "Barbados" ? "selected" : ""}
        value="Barbados"
      >
        Barbados
      </option>
      <option
        selected={props.select === "Belarus" ? "selected" : ""}
        value="Belarus"
      >
        Belarus
      </option>
      <option
        selected={props.select === "Belgium" ? "selected" : ""}
        value="Belgium"
      >
        Belgium
      </option>
      <option
        selected={props.select === "Belize" ? "selected" : ""}
        value="Belize"
      >
        Belize
      </option>
      <option
        selected={props.select === "Benin" ? "selected" : ""}
        value="Benin"
      >
        Benin
      </option>
      <option
        selected={props.select === "Bermuda" ? "selected" : ""}
        value="Bermuda"
      >
        Bermuda
      </option>
      <option
        selected={props.select === "Bhutan" ? "selected" : ""}
        value="Bhutan"
      >
        Bhutan
      </option>
      <option
        selected={props.select === "Bolivia" ? "selected" : ""}
        value="Bolivia"
      >
        Bolivia
      </option>
      <option
        selected={props.select === "Bosnia and Herzegovina" ? "selected" : ""}
        value="Bosnia and Herzegovina"
      >
        Bosnia and Herzegovina
      </option>
      <option
        selected={props.select === "Botswana" ? "selected" : ""}
        value="Botswana"
      >
        Botswana
      </option>
      <option
        selected={props.select === "Bouvet Island" ? "selected" : ""}
        value="Bouvet Island"
      >
        Bouvet Island
      </option>
      <option
        selected={props.select === "Brazil" ? "selected" : ""}
        value="Brazil"
      >
        Brazil
      </option>
      <option
        selected={
          props.select === "British Indian Ocean Territory" ? "selected" : ""
        }
        value="British Indian Ocean Territory"
      >
        British Indian Ocean Territory
      </option>
      <option
        selected={props.select === "Brunei" ? "selected" : ""}
        value="Brunei"
      >
        Brunei
      </option>
      <option
        selected={props.select === "Bulgaria" ? "selected" : ""}
        value="Bulgaria"
      >
        Bulgaria
      </option>
      <option
        selected={props.select === "Burkina Faso" ? "selected" : ""}
        value="Burkina Faso"
      >
        Burkina Faso
      </option>
      <option
        selected={props.select === "Burundi" ? "selected" : ""}
        value="Burundi"
      >
        Burundi
      </option>
      <option
        selected={props.select === "Cambodia" ? "selected" : ""}
        value="Cambodia"
      >
        Cambodia
      </option>
      <option
        selected={props.select === "Cameroon" ? "selected" : ""}
        value="Cameroon"
      >
        Cameroon
      </option>
      <option
        selected={props.select === "Canada" ? "selected" : ""}
        value="Canada"
      >
        Canada
      </option>
      <option
        selected={props.select === "Cape Verde" ? "selected" : ""}
        value="Cape Verde"
      >
        Cape Verde
      </option>
      <option
        selected={props.select === "Cayman Islands" ? "selected" : ""}
        value="Cayman Islands"
      >
        Cayman Islands
      </option>
      <option
        selected={props.select === "Central African Republic" ? "selected" : ""}
        value="Central African Republic"
      >
        Central African Republic
      </option>
      <option selected={props.select === "Chad" ? "selected" : ""} value="Chad">
        Chad
      </option>
      <option
        selected={props.select === "Chile" ? "selected" : ""}
        value="Chile"
      >
        Chile
      </option>
      <option
        selected={props.select === "China" ? "selected" : ""}
        value="China"
      >
        China
      </option>
      <option
        selected={props.select === "Christmas Island" ? "selected" : ""}
        value="Christmas Island"
      >
        Christmas Island
      </option>
      <option
        selected={props.select === "Cocos (Keeling) Islands" ? "selected" : ""}
        value="Cocos (Keeling) Islands"
      >
        Cocos (Keeling) Islands
      </option>
      <option
        selected={props.select === "Colombia" ? "selected" : ""}
        value="Colombia"
      >
        Colombia
      </option>
      <option
        selected={props.select === "Comoros" ? "selected" : ""}
        value="Comoros"
      >
        Comoros
      </option>
      <option
        selected={props.select === "Congo" ? "selected" : ""}
        value="Congo"
      >
        Congo
      </option>
      <option
        selected={
          props.select === "Democratic Republic of the Congo" ? "selected" : ""
        }
        value="Democratic Republic of the Congo"
      >
        Democratic Republic of the Congo
      </option>
      <option
        selected={props.select === "Cook Islands" ? "selected" : ""}
        value="Cook Islands"
      >
        Cook Islands
      </option>
      <option
        selected={props.select === "Costa Rica" ? "selected" : ""}
        value="Costa Rica"
      >
        Costa Rica
      </option>
      <option
        selected={
          props.select === "Cote D'Ivoire (Ivory Coast)" ? "selected" : ""
        }
        value="Cote D'Ivoire (Ivory Coast)"
      >
        Cote D'Ivoire (Ivory Coast)
      </option>
      <option
        selected={props.select === "Croatia" ? "selected" : ""}
        value="Croatia"
      >
        Croatia
      </option>
      <option selected={props.select === "Cuba" ? "selected" : ""} value="Cuba">
        Cuba
      </option>
      <option
        selected={props.select === "Cyprus" ? "selected" : ""}
        value="Cyprus"
      >
        Cyprus
      </option>
      <option
        selected={props.select === "Czech Republic" ? "selected" : ""}
        value="Czech Republic"
      >
        Czech Republic
      </option>
      <option
        selected={props.select === "Denmark" ? "selected" : ""}
        value="Denmark"
      >
        Denmark
      </option>
      <option
        selected={props.select === "Djibouti" ? "selected" : ""}
        value="Djibouti"
      >
        Djibouti
      </option>
      <option
        selected={props.select === "Dominica" ? "selected" : ""}
        value="Dominica"
      >
        Dominica
      </option>
      <option
        selected={props.select === "Dominican Republic" ? "selected" : ""}
        value="Dominican Republic"
      >
        Dominican Republic
      </option>
      <option
        selected={props.select === "East Timor" ? "selected" : ""}
        value="East Timor"
      >
        East Timor
      </option>
      <option
        selected={props.select === "Ecuador" ? "selected" : ""}
        value="Ecuador"
      >
        Ecuador
      </option>
      <option
        selected={props.select === "Egypt" ? "selected" : ""}
        value="Egypt"
      >
        Egypt
      </option>
      <option
        selected={props.select === "El Salvador" ? "selected" : ""}
        value="El Salvador"
      >
        El Salvador
      </option>
      <option
        selected={props.select === "Equatorial Guinea" ? "selected" : ""}
        value="Equatorial Guinea"
      >
        Equatorial Guinea
      </option>
      <option
        selected={props.select === "Eritrea" ? "selected" : ""}
        value="Eritrea"
      >
        Eritrea
      </option>
      <option
        selected={props.select === "Estonia" ? "selected" : ""}
        value="Estonia"
      >
        Estonia
      </option>
      <option
        selected={props.select === "Ethiopia" ? "selected" : ""}
        value="Ethiopia"
      >
        Ethiopia
      </option>
      <option
        selected={props.select === "Falkland Islands" ? "selected" : ""}
        value="Falkland Islands"
      >
        Falkland Islands
      </option>
      <option
        selected={props.select === "Faroe Islands" ? "selected" : ""}
        value="Faroe Islands"
      >
        Faroe Islands
      </option>
      <option
        selected={props.select === "Fiji Islands" ? "selected" : ""}
        value="Fiji Islands"
      >
        Fiji Islands
      </option>
      <option
        selected={props.select === "Finland" ? "selected" : ""}
        value="Finland"
      >
        Finland
      </option>
      <option
        selected={props.select === "France" ? "selected" : ""}
        value="France"
      >
        France
      </option>
      <option
        selected={props.select === "French Guiana" ? "selected" : ""}
        value="French Guiana"
      >
        French Guiana
      </option>
      <option
        selected={props.select === "French Polynesia" ? "selected" : ""}
        value="French Polynesia"
      >
        French Polynesia
      </option>
      <option
        selected={
          props.select === "French Southern Territories" ? "selected" : ""
        }
        value="French Southern Territories"
      >
        French Southern Territories
      </option>
      <option
        selected={props.select === "Gabon" ? "selected" : ""}
        value="Gabon"
      >
        Gabon
      </option>
      <option
        selected={props.select === "Gambia The" ? "selected" : ""}
        value="Gambia The"
      >
        Gambia The
      </option>
      <option
        selected={props.select === "Georgia" ? "selected" : ""}
        value="Georgia"
      >
        Georgia
      </option>
      <option
        selected={props.select === "Germany" ? "selected" : ""}
        value="Germany"
      >
        Germany
      </option>
      <option
        selected={props.select === "Ghana" ? "selected" : ""}
        value="Ghana"
      >
        Ghana
      </option>
      <option
        selected={props.select === "Gibraltar" ? "selected" : ""}
        value="Gibraltar"
      >
        Gibraltar
      </option>
      <option
        selected={props.select === "Greece" ? "selected" : ""}
        value="Greece"
      >
        Greece
      </option>
      <option
        selected={props.select === "Greenland" ? "selected" : ""}
        value="Greenland"
      >
        Greenland
      </option>
      <option
        selected={props.select === "Grenada" ? "selected" : ""}
        value="Grenada"
      >
        Grenada
      </option>
      <option
        selected={props.select === "Guadeloupe" ? "selected" : ""}
        value="Guadeloupe"
      >
        Guadeloupe
      </option>
      <option selected={props.select === "Guam" ? "selected" : ""} value="Guam">
        Guam
      </option>
      <option
        selected={props.select === "Guatemala" ? "selected" : ""}
        value="Guatemala"
      >
        Guatemala
      </option>
      <option
        selected={props.select === "Guernsey and Alderney" ? "selected" : ""}
        value="Guernsey and Alderney"
      >
        Guernsey and Alderney
      </option>
      <option
        selected={props.select === "Guinea" ? "selected" : ""}
        value="Guinea"
      >
        Guinea
      </option>
      <option
        selected={props.select === "Guinea-Bissau" ? "selected" : ""}
        value="Guinea-Bissau"
      >
        Guinea-Bissau
      </option>
      <option
        selected={props.select === "Guyana" ? "selected" : ""}
        value="Guyana"
      >
        Guyana
      </option>
      <option
        selected={props.select === "Haiti" ? "selected" : ""}
        value="Haiti"
      >
        Haiti
      </option>
      <option
        selected={
          props.select === "Heard Island and McDonald Islands" ? "selected" : ""
        }
        value="Heard Island and McDonald Islands"
      >
        Heard Island and McDonald Islands
      </option>
      <option
        selected={props.select === "Honduras" ? "selected" : ""}
        value="Honduras"
      >
        Honduras
      </option>
      <option
        selected={props.select === "Hong Kong S.A.R." ? "selected" : ""}
        value="Hong Kong S.A.R."
      >
        Hong Kong S.A.R.
      </option>
      <option
        selected={props.select === "Hungary" ? "selected" : ""}
        value="Hungary"
      >
        Hungary
      </option>
      <option
        selected={props.select === "Iceland" ? "selected" : ""}
        value="Iceland"
      >
        Iceland
      </option>
      <option
        selected={props.select === "India" ? "selected" : ""}
        value="India"
      >
        India
      </option>
      <option
        selected={props.select === "Indonesia" ? "selected" : ""}
        value="Indonesia"
      >
        Indonesia
      </option>
      <option selected={props.select === "Iran" ? "selected" : ""} value="Iran">
        Iran
      </option>
      <option selected={props.select === "Iraq" ? "selected" : ""} value="Iraq">
        Iraq
      </option>
      <option
        selected={props.select === "Ireland" ? "selected" : ""}
        value="Ireland"
      >
        Ireland
      </option>
      <option
        selected={props.select === "Israel" ? "selected" : ""}
        value="Israel"
      >
        Israel
      </option>
      <option
        selected={props.select === "Italy" ? "selected" : ""}
        value="Italy"
      >
        Italy
      </option>
      <option
        selected={props.select === "Jamaica" ? "selected" : ""}
        value="Jamaica"
      >
        Jamaica
      </option>
      <option
        selected={props.select === "Japan" ? "selected" : ""}
        value="Japan"
      >
        Japan
      </option>
      <option
        selected={props.select === "Jersey" ? "selected" : ""}
        value="Jersey"
      >
        Jersey
      </option>
      <option
        selected={props.select === "Jordan" ? "selected" : ""}
        value="Jordan"
      >
        Jordan
      </option>
      <option
        selected={props.select === "Kazakhstan" ? "selected" : ""}
        value="Kazakhstan"
      >
        Kazakhstan
      </option>
      <option
        selected={props.select === "Kenya" ? "selected" : ""}
        value="Kenya"
      >
        Kenya
      </option>
      <option
        selected={props.select === "Kiribati" ? "selected" : ""}
        value="Kiribati"
      >
        Kiribati
      </option>
      <option
        selected={props.select === "North Korea" ? "selected" : ""}
        value="North Korea"
      >
        North Korea
      </option>
      <option
        selected={props.select === "South Korea" ? "selected" : ""}
        value="South Korea"
      >
        South Korea
      </option>
      <option
        selected={props.select === "Kuwait" ? "selected" : ""}
        value="Kuwait"
      >
        Kuwait
      </option>
      <option
        selected={props.select === "Kyrgyzstan" ? "selected" : ""}
        value="Kyrgyzstan"
      >
        Kyrgyzstan
      </option>
      <option selected={props.select === "Laos" ? "selected" : ""} value="Laos">
        Laos
      </option>
      <option
        selected={props.select === "Latvia" ? "selected" : ""}
        value="Latvia"
      >
        Latvia
      </option>
      <option
        selected={props.select === "Lebanon" ? "selected" : ""}
        value="Lebanon"
      >
        Lebanon
      </option>
      <option
        selected={props.select === "Lesotho" ? "selected" : ""}
        value="Lesotho"
      >
        Lesotho
      </option>
      <option
        selected={props.select === "Liberia" ? "selected" : ""}
        value="Liberia"
      >
        Liberia
      </option>
      <option
        selected={props.select === "Libya" ? "selected" : ""}
        value="Libya"
      >
        Libya
      </option>
      <option
        selected={props.select === "Liechtenstein" ? "selected" : ""}
        value="Liechtenstein"
      >
        Liechtenstein
      </option>
      <option
        selected={props.select === "Lithuania" ? "selected" : ""}
        value="Lithuania"
      >
        Lithuania
      </option>
      <option
        selected={props.select === "Luxembourg" ? "selected" : ""}
        value="Luxembourg"
      >
        Luxembourg
      </option>
      <option
        selected={props.select === "Macau S.A.R." ? "selected" : ""}
        value="Macau S.A.R."
      >
        Macau S.A.R.
      </option>
      <option
        selected={props.select === "North Macedonia" ? "selected" : ""}
        value="North Macedonia"
      >
        North Macedonia
      </option>
      <option
        selected={props.select === "Madagascar" ? "selected" : ""}
        value="Madagascar"
      >
        Madagascar
      </option>
      <option
        selected={props.select === "Malawi" ? "selected" : ""}
        value="Malawi"
      >
        Malawi
      </option>
      <option
        selected={props.select === "Malaysia" ? "selected" : ""}
        value="Malaysia"
      >
        Malaysia
      </option>
      <option
        selected={props.select === "Maldives" ? "selected" : ""}
        value="Maldives"
      >
        Maldives
      </option>
      <option selected={props.select === "Mali" ? "selected" : ""} value="Mali">
        Mali
      </option>
      <option
        selected={props.select === "Malta" ? "selected" : ""}
        value="Malta"
      >
        Malta
      </option>
      <option
        selected={props.select === "Man (Isle of)" ? "selected" : ""}
        value="Man (Isle of)"
      >
        Man (Isle of)
      </option>
      <option
        selected={props.select === "Marshall Islands" ? "selected" : ""}
        value="Marshall Islands"
      >
        Marshall Islands
      </option>
      <option
        selected={props.select === "Martinique" ? "selected" : ""}
        value="Martinique"
      >
        Martinique
      </option>
      <option
        selected={props.select === "Mauritania" ? "selected" : ""}
        value="Mauritania"
      >
        Mauritania
      </option>
      <option
        selected={props.select === "Mauritius" ? "selected" : ""}
        value="Mauritius"
      >
        Mauritius
      </option>
      <option
        selected={props.select === "Mayotte" ? "selected" : ""}
        value="Mayotte"
      >
        Mayotte
      </option>
      <option
        selected={props.select === "Mexico" ? "selected" : ""}
        value="Mexico"
      >
        Mexico
      </option>
      <option
        selected={props.select === "Micronesia" ? "selected" : ""}
        value="Micronesia"
      >
        Micronesia
      </option>
      <option
        selected={props.select === "Moldova" ? "selected" : ""}
        value="Moldova"
      >
        Moldova
      </option>
      <option
        selected={props.select === "Monaco" ? "selected" : ""}
        value="Monaco"
      >
        Monaco
      </option>
      <option
        selected={props.select === "Mongolia" ? "selected" : ""}
        value="Mongolia"
      >
        Mongolia
      </option>
      <option
        selected={props.select === "Montenegro" ? "selected" : ""}
        value="Montenegro"
      >
        Montenegro
      </option>
      <option
        selected={props.select === "Montserrat" ? "selected" : ""}
        value="Montserrat"
      >
        Montserrat
      </option>
      <option
        selected={props.select === "Morocco" ? "selected" : ""}
        value="Morocco"
      >
        Morocco
      </option>
      <option
        selected={props.select === "Mozambique" ? "selected" : ""}
        value="Mozambique"
      >
        Mozambique
      </option>
      <option
        selected={props.select === "Myanmar" ? "selected" : ""}
        value="Myanmar"
      >
        Myanmar
      </option>
      <option
        selected={props.select === "Namibia" ? "selected" : ""}
        value="Namibia"
      >
        Namibia
      </option>
      <option
        selected={props.select === "Nauru" ? "selected" : ""}
        value="Nauru"
      >
        Nauru
      </option>
      <option
        selected={props.select === "Nepal" ? "selected" : ""}
        value="Nepal"
      >
        Nepal
      </option>
      <option
        selected={
          props.select === "Bonaire, Sint Eustatius and Saba" ? "selected" : ""
        }
        value="Bonaire, Sint Eustatius and Saba"
      >
        Bonaire, Sint Eustatius and Saba
      </option>
      <option
        selected={props.select === "Netherlands" ? "selected" : ""}
        value="Netherlands"
      >
        Netherlands
      </option>
      <option
        selected={props.select === "New Caledonia" ? "selected" : ""}
        value="New Caledonia"
      >
        New Caledonia
      </option>
      <option
        selected={props.select === "New Zealand" ? "selected" : ""}
        value="New Zealand"
      >
        New Zealand
      </option>
      <option
        selected={props.select === "Nicaragua" ? "selected" : ""}
        value="Nicaragua"
      >
        Nicaragua
      </option>
      <option
        selected={props.select === "Niger" ? "selected" : ""}
        value="Niger"
      >
        Niger
      </option>
      <option
        selected={props.select === "Nigeria" ? "selected" : ""}
        value="Nigeria"
      >
        Nigeria
      </option>
      <option selected={props.select === "Niue" ? "selected" : ""} value="Niue">
        Niue
      </option>
      <option
        selected={props.select === "Norfolk Island" ? "selected" : ""}
        value="Norfolk Island"
      >
        Norfolk Island
      </option>
      <option
        selected={props.select === "Northern Mariana Islands" ? "selected" : ""}
        value="Northern Mariana Islands"
      >
        Northern Mariana Islands
      </option>
      <option
        selected={props.select === "Norway" ? "selected" : ""}
        value="Norway"
      >
        Norway
      </option>
      <option selected={props.select === "Oman" ? "selected" : ""} value="Oman">
        Oman
      </option>
      <option
        selected={props.select === "Pakistan" ? "selected" : ""}
        value="Pakistan"
      >
        Pakistan
      </option>
      <option
        selected={props.select === "Palau" ? "selected" : ""}
        value="Palau"
      >
        Palau
      </option>
      <option
        selected={
          props.select === "Palestinian Territory Occupied" ? "selected" : ""
        }
        value="Palestinian Territory Occupied"
      >
        Palestinian Territory Occupied
      </option>
      <option
        selected={props.select === "Panama" ? "selected" : ""}
        value="Panama"
      >
        Panama
      </option>
      <option
        selected={props.select === "Papua new Guinea" ? "selected" : ""}
        value="Papua new Guinea"
      >
        Papua new Guinea
      </option>
      <option
        selected={props.select === "Paraguay" ? "selected" : ""}
        value="Paraguay"
      >
        Paraguay
      </option>
      <option selected={props.select === "Peru" ? "selected" : ""} value="Peru">
        Peru
      </option>
      <option
        selected={props.select === "Philippines" ? "selected" : ""}
        value="Philippines"
      >
        Philippines
      </option>
      <option
        selected={props.select === "Pitcairn Island" ? "selected" : ""}
        value="Pitcairn Island"
      >
        Pitcairn Island
      </option>
      <option
        selected={props.select === "Poland" ? "selected" : ""}
        value="Poland"
      >
        Poland
      </option>
      <option
        selected={props.select === "Portugal" ? "selected" : ""}
        value="Portugal"
      >
        Portugal
      </option>
      <option
        selected={props.select === "Puerto Rico" ? "selected" : ""}
        value="Puerto Rico"
      >
        Puerto Rico
      </option>
      <option
        selected={props.select === "Qatar" ? "selected" : ""}
        value="Qatar"
      >
        Qatar
      </option>
      <option
        selected={props.select === "Reunion" ? "selected" : ""}
        value="Reunion"
      >
        Reunion
      </option>
      <option
        selected={props.select === "Romania" ? "selected" : ""}
        value="Romania"
      >
        Romania
      </option>
      <option
        selected={props.select === "Russia" ? "selected" : ""}
        value="Russia"
      >
        Russia
      </option>
      <option
        selected={props.select === "Rwanda" ? "selected" : ""}
        value="Rwanda"
      >
        Rwanda
      </option>
      <option
        selected={props.select === "Saint Helena" ? "selected" : ""}
        value="Saint Helena"
      >
        Saint Helena
      </option>
      <option
        selected={props.select === "Saint Kitts And Nevis" ? "selected" : ""}
        value="Saint Kitts And Nevis"
      >
        Saint Kitts And Nevis
      </option>
      <option
        selected={props.select === "Saint Lucia" ? "selected" : ""}
        value="Saint Lucia"
      >
        Saint Lucia
      </option>
      <option
        selected={
          props.select === "Saint Pierre and Miquelon" ? "selected" : ""
        }
        value="Saint Pierre and Miquelon"
      >
        Saint Pierre and Miquelon
      </option>
      <option
        selected={
          props.select === "Saint Vincent And The Grenadines" ? "selected" : ""
        }
        value="Saint Vincent And The Grenadines"
      >
        Saint Vincent And The Grenadines
      </option>
      <option
        selected={props.select === "Saint-Barthelemy" ? "selected" : ""}
        value="Saint-Barthelemy"
      >
        Saint-Barthelemy
      </option>
      <option
        selected={
          props.select === "Saint-Martin (French part)" ? "selected" : ""
        }
        value="Saint-Martin (French part)"
      >
        Saint-Martin (French part)
      </option>
      <option
        selected={props.select === "Samoa" ? "selected" : ""}
        value="Samoa"
      >
        Samoa
      </option>
      <option
        selected={props.select === "San Marino" ? "selected" : ""}
        value="San Marino"
      >
        San Marino
      </option>
      <option
        selected={props.select === "Sao Tome and Principe" ? "selected" : ""}
        value="Sao Tome and Principe"
      >
        Sao Tome and Principe
      </option>
      <option
        selected={props.select === "Saudi Arabia" ? "selected" : ""}
        value="Saudi Arabia"
      >
        Saudi Arabia
      </option>
      <option
        selected={props.select === "Senegal" ? "selected" : ""}
        value="Senegal"
      >
        Senegal
      </option>
      <option
        selected={props.select === "Serbia" ? "selected" : ""}
        value="Serbia"
      >
        Serbia
      </option>
      <option
        selected={props.select === "Seychelles" ? "selected" : ""}
        value="Seychelles"
      >
        Seychelles
      </option>
      <option
        selected={props.select === "Sierra Leone" ? "selected" : ""}
        value="Sierra Leone"
      >
        Sierra Leone
      </option>
      <option
        selected={props.select === "Singapore" ? "selected" : ""}
        value="Singapore"
      >
        Singapore
      </option>
      <option
        selected={props.select === "Slovakia" ? "selected" : ""}
        value="Slovakia"
      >
        Slovakia
      </option>
      <option
        selected={props.select === "Slovenia" ? "selected" : ""}
        value="Slovenia"
      >
        Slovenia
      </option>
      <option
        selected={props.select === "Solomon Islands" ? "selected" : ""}
        value="Solomon Islands"
      >
        Solomon Islands
      </option>
      <option
        selected={props.select === "Somalia" ? "selected" : ""}
        value="Somalia"
      >
        Somalia
      </option>
      <option
        selected={props.select === "South Africa" ? "selected" : ""}
        value="South Africa"
      >
        South Africa
      </option>
      <option
        selected={props.select === "South Georgia" ? "selected" : ""}
        value="South Georgia"
      >
        South Georgia
      </option>
      <option
        selected={props.select === "South Sudan" ? "selected" : ""}
        value="South Sudan"
      >
        South Sudan
      </option>
      <option
        selected={props.select === "Spain" ? "selected" : ""}
        value="Spain"
      >
        Spain
      </option>
      <option
        selected={props.select === "Sri Lanka" ? "selected" : ""}
        value="Sri Lanka"
      >
        Sri Lanka
      </option>
      <option
        selected={props.select === "Sudan" ? "selected" : ""}
        value="Sudan"
      >
        Sudan
      </option>
      <option
        selected={props.select === "Suriname" ? "selected" : ""}
        value="Suriname"
      >
        Suriname
      </option>
      <option
        selected={
          props.select === "Svalbard And Jan Mayen Islands" ? "selected" : ""
        }
        value="Svalbard And Jan Mayen Islands"
      >
        Svalbard And Jan Mayen Islands
      </option>
      <option
        selected={props.select === "Swaziland" ? "selected" : ""}
        value="Swaziland"
      >
        Swaziland
      </option>
      <option
        selected={props.select === "Sweden" ? "selected" : ""}
        value="Sweden"
      >
        Sweden
      </option>
      <option
        selected={props.select === "Switzerland" ? "selected" : ""}
        value="Switzerland"
      >
        Switzerland
      </option>
      <option
        selected={props.select === "Syria" ? "selected" : ""}
        value="Syria"
      >
        Syria
      </option>
      <option
        selected={props.select === "Taiwan" ? "selected" : ""}
        value="Taiwan"
      >
        Taiwan
      </option>
      <option
        selected={props.select === "Tajikistan" ? "selected" : ""}
        value="Tajikistan"
      >
        Tajikistan
      </option>
      <option
        selected={props.select === "Tanzania" ? "selected" : ""}
        value="Tanzania"
      >
        Tanzania
      </option>
      <option
        selected={props.select === "Thailand" ? "selected" : ""}
        value="Thailand"
      >
        Thailand
      </option>
      <option selected={props.select === "Togo" ? "selected" : ""} value="Togo">
        Togo
      </option>
      <option
        selected={props.select === "Tokelau" ? "selected" : ""}
        value="Tokelau"
      >
        Tokelau
      </option>
      <option
        selected={props.select === "Tonga" ? "selected" : ""}
        value="Tonga"
      >
        Tonga
      </option>
      <option
        selected={props.select === "Trinidad And Tobago" ? "selected" : ""}
        value="Trinidad And Tobago"
      >
        Trinidad And Tobago
      </option>
      <option
        selected={props.select === "Tunisia" ? "selected" : ""}
        value="Tunisia"
      >
        Tunisia
      </option>
      <option
        selected={props.select === "Turkey" ? "selected" : ""}
        value="Turkey"
      >
        Turkey
      </option>
      <option
        selected={props.select === "Turkmenistan" ? "selected" : ""}
        value="Turkmenistan"
      >
        Turkmenistan
      </option>
      <option
        selected={props.select === "Turks And Caicos Islands" ? "selected" : ""}
        value="Turks And Caicos Islands"
      >
        Turks And Caicos Islands
      </option>
      <option
        selected={props.select === "Tuvalu" ? "selected" : ""}
        value="Tuvalu"
      >
        Tuvalu
      </option>
      <option
        selected={props.select === "Uganda" ? "selected" : ""}
        value="Uganda"
      >
        Uganda
      </option>
      <option
        selected={props.select === "Ukraine" ? "selected" : ""}
        value="Ukraine"
      >
        Ukraine
      </option>
      <option
        selected={props.select === "United Arab Emirates" ? "selected" : ""}
        value="United Arab Emirates"
      >
        United Arab Emirates
      </option>
      <option
        selected={props.select === "United Kingdom" ? "selected" : ""}
        value="United Kingdom"
      >
        United Kingdom
      </option>
      <option
        selected={props.select === "United States" ? "selected" : ""}
        value="United States"
      >
        United States
      </option>
      <option
        selected={
          props.select === "United States Minor Outlying Islands"
            ? "selected"
            : ""
        }
        value="United States Minor Outlying Islands"
      >
        United States Minor Outlying Islands
      </option>
      <option
        selected={props.select === "Uruguay" ? "selected" : ""}
        value="Uruguay"
      >
        Uruguay
      </option>
      <option
        selected={props.select === "Uzbekistan" ? "selected" : ""}
        value="Uzbekistan"
      >
        Uzbekistan
      </option>
      <option
        selected={props.select === "Vanuatu" ? "selected" : ""}
        value="Vanuatu"
      >
        Vanuatu
      </option>
      <option
        selected={
          props.select === "Vatican City State (Holy See)" ? "selected" : ""
        }
        value="Vatican City State (Holy See)"
      >
        Vatican City State (Holy See)
      </option>
      <option
        selected={props.select === "Venezuela" ? "selected" : ""}
        value="Venezuela"
      >
        Venezuela
      </option>
      <option
        selected={props.select === "Vietnam" ? "selected" : ""}
        value="Vietnam"
      >
        Vietnam
      </option>
      <option
        selected={props.select === "Virgin Islands (British)" ? "selected" : ""}
        value="Virgin Islands (British)"
      >
        Virgin Islands (British)
      </option>
      <option
        selected={props.select === "Virgin Islands (US)" ? "selected" : ""}
        value="Virgin Islands (US)"
      >
        Virgin Islands (US)
      </option>
      <option
        selected={
          props.select === "Wallis And Futuna Islands" ? "selected" : ""
        }
        value="Wallis And Futuna Islands"
      >
        Wallis And Futuna Islands
      </option>
      <option
        selected={props.select === "Western Sahara" ? "selected" : ""}
        value="Western Sahara"
      >
        Western Sahara
      </option>
      <option
        selected={props.select === "Yemen" ? "selected" : ""}
        value="Yemen"
      >
        Yemen
      </option>
      <option
        selected={props.select === "Zambia" ? "selected" : ""}
        value="Zambia"
      >
        Zambia
      </option>
      <option
        selected={props.select === "Zimbabwe" ? "selected" : ""}
        value="Zimbabwe"
      >
        Zimbabwe
      </option>
      <option
        selected={props.select === "Kosovo" ? "selected" : ""}
        value="Kosovo"
      >
        Kosovo
      </option>
      <option
        selected={props.select === "Curaçao" ? "selected" : ""}
        value="Curaçao"
      >
        Curaçao
      </option>
      <option
        selected={
          props.select === "Sint Maarten (Dutch part)" ? "selected" : ""
        }
        value="Sint Maarten (Dutch part)"
      >
        Sint Maarten (Dutch part)
      </option>
    </>
  );
}

export default Country;
