import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AllGallery } from "../../services/actions/AuthAction";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Gallary(props) {
  let { id } = useParams();

  useEffect(() => {
    props.AllGallery();
  }, []);

  return (
    <>
      <Navber />
      <div class="students sister py-5">
        <div class="container  py-5">
          <div class="row">
            {props.uniDetai.length > 0 && (
              <OwlCarousel
                items={1}
                className="owl-carousel owl-carousel-three owl-theme owl-loaded owl-drag text-center"
                loop
                nav
                margin={8}
                autoplay
              >
                {props.uniDetai.map((u, i) => {
                  return (
                    <div key={i + 1} class="col-lg-12">
                      <div class="mb-5">
                        <img class="m-auto img-fluid" src={u.image} />
                        <p>{u.title}</p>
                        <p>{u.details}</p>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            )}

            <div class="col-lg-12 text-center">
              <p class="h4">
                {/* Meetup of Yunnan University International Students.*/}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    uniDetai: state.auth.allgallery,
  };
};

const mapDispatchToProps = (dispatch) => ({
  AllGallery: () => dispatch(AllGallery()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gallary);
