import React, { useEffect, useState } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { applyViewData, applyFinal } from "../../services/actions/AuthAction";
import ApplyData from "../helper/ApplyData";
import Ber from "../helper/Ber";

function ApplyView(props) {
  const [hide, setHide] = useState(0);
  var data = JSON.parse(localStorage.getItem("user"));
  var id = window.location.href.split("/")[5];
  useEffect(() => {
    window.scrollTo(0, 0);
    props.applyViewData(id);
  }, []);
  const bal = {
    borderRadius: "50%",
  };
  const tdd = {
    // border: `1px solid #afa4a4`,
  };
  const tddd = {
    // background: "#b7aeae",
    // border: `1px solid #afa4a4`,
  };
  const tddk = {
    background: "#b7aeae",
    border: `1px solid #afa4a4`,
  };
  const headsss = {
    background: `linear-gradient(to right,#239de6,#00d1ff)`,
    color: `white`,
    textAlign: `justify`,
    borderRadius: `0 20px 20px 0`,
  };

  const headp = {
    background: `linear-gradient(to right,#239de6,#00d1ff)`,
    color: `white`,
    textAlign: `justify`,
    borderRadius: `0 20px 20px 0`,
    width: `60%`,
    padding: `10px`,
  };
  const pp = {
    color: `#4d3f4d`,
    border: `1px solid`,
  };
  const hh = {
    boxShadow: `0 1px 4px 0 black `,
    margin: `3px`,
    padding: `5px`,
    overflowX: `scroll`,
  };

  const finalSubmit = (e) => {
    setHide(1);
    props.applyFinal(e);
  };
  var url = window.location.href.split("/");

  return (
    <>
      <UserHeader />

      <div class="container my-5">
        <div class="row g-5">
          <div class="col-lg-12">
            <div class="data">
              <div class="row my-5">
                <ApplyData pageType="newtable" data={id} />
              </div>
              <div className="columns">
                <Ber page="view" />

                {hide !== 1 ? (
                  <p style={pp} id="hello2">
                    If you submitted and uploaded all of your information then
                    you need to re-check and final submission, After your final
                    submission you can't change anything without Admin
                    permission. And submitting you ensure that all information
                    is currect
                    <br />
                    {props.viewOne.status === "0" &&
                      props.viewOne.family_name != null &&
                      props.viewTwo.mejor_subject != null &&
                      props.viewThree.collect != null &&
                      props.viewFour.valid_ordinary_passport != null && (
                        <span
                          className="btn btn-danger"
                          onClick={() => finalSubmit(props.viewOne.id)}
                        >
                          Final Submit
                        </span>
                      )}
                  </p>
                ) : (
                  ""
                )}
              </div>
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
              <div class="table-responsive my-5 nnnn " id="hello">
                <div style={hh}>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          个人基本信息 <br />
                          Personal Information
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>护照姓 Passport Family Name </td>
                        <td style={tdd}>{props.viewOne.family_name}</td>
                        <td style={tddd}>照片 Photo</td>
                        <td style={tdd} rowSpan="3">
                          {props.viewOne.photo != null ? (
                            <img
                              src={props.viewOne.photo}
                              alt=""
                              class="img-fluid"
                              width="150"
                              height="180"
                            />
                          ) : (
                            <img
                              src="/image/download.jpeg"
                              alt=""
                              class="img-fluid"
                              width="150"
                              height="180"
                            />
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td style={tddd}>护照名 Given Name</td>
                        <td style={tdd}>{props.viewOne.given_name}</td>
                      </tr>

                      <tr>
                        <td style={tddd}>
                          护照全名 Full Name as shown on your passport
                        </td>
                        <td style={tdd}>{props.viewOne.full_name}</td>
                      </tr>

                      <tr>
                        <td style={tddd}>中文名 Chinese Name</td>
                        <td style={tdd}>{props.viewOne.china_name}</td>
                        <td colSpan={2}></td>
                      </tr>

                      <tr>
                        <td style={tddd}>性别 Gender</td>
                        <td style={tdd}>{props.viewOne.gender}</td>
                        <td colSpan={2}></td>
                      </tr>

                      <tr>
                        <td style={tddd}>国籍 Nationality</td>
                        <td style={tdd}>{props.viewOne.nationality}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>出生日期 Date of Birth</td>
                        <td style={tdd}>{props.viewOne.dob}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>出生国家 Country of Birth</td>
                        <td style={tdd}>{props.viewOne.birth_country}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>母语 Native Language</td>
                        <td style={tdd}>{props.viewOne.native_language}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>婚姻状态 Marital Status</td>
                        <td style={tdd}>{props.viewOne.marital_status}</td>
                        <td colSpan={2}></td>
                      </tr>

                      <tr>
                        <td style={tddd}>宗教 Religion</td>
                        <td style={tdd}>{props.viewOne.religion}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>职业 Occupation </td>
                        <td style={tdd}>{props.viewOne.occupation}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          工作或学习单位 Employer or Institution Affiliated
                        </td>
                        <td style={tdd}>{props.viewOne.affiliate}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>微信 Wechat</td>
                        <td style={tdd}>{props.viewOne.wechat}</td>
                        <td colSpan={2}></td>
                      </tr>

                      <tr>
                        <td style={tddd}>出生地点 Place of Birth</td>
                        <td style={tdd}>{props.viewOne.birth_place}</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>联系电话 Phone/Mobile</td>
                        <td style={tdd}>
                          {props.viewOne.code}-{props.viewOne.phone}
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div style={hh}>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          家庭地址 <br />
                          Home Address
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>Parmanent Address</td>
                        <td style={tdd}></td>

                        <td style={tddd}>Current Address</td>
                        <td style={tdd}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>详细地址 Detailed Address</td>
                        <td style={tdd}>{props.viewOne.parmanent_home}</td>

                        <td style={tddd}>Current Home</td>
                        <td style={tdd}>{props.viewOne.current_home}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>省/城市 City/Province</td>
                        <td style={tdd}>{props.viewOne.parmanent_city}</td>

                        <td style={tddd}>省/城市 City/Province</td>
                        <td style={tdd}>{props.viewOne.current_city}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>国家 Country </td>
                        <td style={tdd}>{props.viewOne.parmanent_country}</td>

                        <td style={tddd}>国家 Country </td>
                        <td style={tdd}>{props.viewOne.current_country}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>邮编 Zipcode</td>
                        <td style={tdd}>{props.viewOne.parmanent_zipcode}</td>

                        <td style={tddd}>邮编 Zipcode</td>
                        <td style={tdd}>{props.viewOne.current_zipcode}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>联系电话 Phone/Mobile</td>
                        <td style={tdd}>{props.viewOne.parmanent_phone}</td>
                        <td style={tddd}>联系电话 Phone/Mobile</td>
                        <td style={tdd}>{props.viewOne.current_phone}</td>
                      </tr>

                      <tr>
                        <td style={tddd}>常用邮箱 Main Email</td>
                        <td style={tdd}>{props.viewOne.parmanent_email}</td>
                        <td style={tddd}>常用邮箱 Main Email</td>
                        <td style={tdd}>{props.viewOne.current_email}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div style={hh}>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          证照信息 <br />
                          Passport and Visa Information
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>护照号码 Passport No</td>
                        <td style={tdd}>{props.viewOne.passport_no}</td>
                        <td style={tddd}>护照有效期 Passport Expiry Date</td>
                        <td style={tdd}>
                          {props.viewOne.new_expiry_date_start}-
                          {props.viewOne.new_expiry_date_start}
                        </td>
                      </tr>
                      <tr>
                        <td style={tddd}>旧护照号码 Old Passport No</td>
                        <td style={tdd}>{props.viewOne.old_passport}</td>
                        <td style={tddd}>
                          旧护照到期时间 Expiration of Old Passport
                        </td>
                        <td style={tdd}>{props.viewOne.old_expaire_date}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          签证申请所在大使馆或领事馆 Which Chinese embassy or
                          consulate would you apply visa at*
                        </td>
                        <td style={tdd}>{props.viewOne.emabassy}</td>
                        {/* <td style={tddd}>Embassy Name</td>
                      <td style={tdd}>{props.viewOne.name_emabassy}</td> */}
                      </tr>
                      <tr>
                        <td style={tddd}>
                          是否曾经或当前在中国学习 Have you studied or whether
                          studying in China currently?
                        </td>
                        <td style={tdd}>{props.viewOne.studed_in_china}</td>
                        <td style={tddd}></td>
                        <td style={tdd}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>签证类型 Visa Type</td>
                        <td style={tdd}>{props.viewOne.visa_type}</td>
                        <td style={tddd}>签证号 - Visa no </td>
                        <td style={tdd}>{props.viewOne.visa_no}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          当前是否在中国 Whether in China now?
                        </td>
                        <td style={tdd}>{props.viewOne.in_china_new}</td>
                        <td style={tddd}>签证到期时间 Expiry Date</td>
                        <td style={tdd}>{props.viewOne.visa_expairy_date}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          在华学习期限 Duration of study in China
                        </td>
                        <td style={tdd}>{props.viewOne.study_period_china}</td>
                        <td style={tddd}>
                          在华学习院校 The institution in China that you have
                        </td>
                        <td style={tdd}>{props.viewOne.china_institute}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    经济担保人信息 <br /> Financial Sponsor's Information
                  </p>

                  <table
                    style={{ marginBottom: `0` }}
                    class="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          姓名 <br />
                          Name
                        </th>
                        <th scope="col">
                          单位名称 <br />
                          Work Place{" "}
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          关系 <br />
                          Relationship
                        </th>
                        <th scope="col">
                          职业 <br /> Occupation
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                        <th scope="col">
                          行业类型 <br />
                          Industry
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewOne.sponsor_name !== undefined &&
                        JSON.parse(props.viewOne.sponsor_name).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {
                                  JSON.parse(props.viewOne.sponsor_word_place)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.sponsor_phone)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.sponsor_relation)[i]}
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewOne.sponsor_occupation)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.sponsor_email)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.sponsor_industry)[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    事务担保人信息 <br />
                    Guarantor's Information
                  </p>

                  <table
                    style={{ marginBottom: `0` }}
                    class="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          姓名 <br />
                          Name
                        </th>
                        <th scope="col">
                          单位名称 <br />
                          Work Place{" "}
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          关系 <br />
                          Relationship
                        </th>
                        <th scope="col">
                          职业 <br />
                          Occupation
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                        <th scope="col">
                          行业类型 <br />
                          Industry
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {props.viewOne.guarante_name !== undefined &&
                        JSON.parse(props.viewOne.guarante_name).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {
                                  JSON.parse(props.viewOne.guarante_work_place)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.guarante_phone)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.guarante_relation)[i]}
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewOne.guarante_occupation)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.guarante_email)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.guarante_industry)[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    紧急联系人 <br />
                    Emergency Contact
                  </p>

                  <table
                    style={{ marginBottom: `0` }}
                    class="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          姓名 <br />
                          Name
                        </th>
                        <th scope="col">
                          单位名称 <br />
                          Work Place
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          关系 <br />
                          Relationship
                        </th>
                        <th scope="col">
                          职业 <br />
                          Occupation
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                        <th scope="col">
                          行业类型 <br />
                          Industry Typ
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {props.viewOne.emergency_name !== undefined &&
                        JSON.parse(props.viewOne.emergency_name).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {
                                  JSON.parse(
                                    props.viewOne.emergency_work_place
                                  )[i]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.emergency_phone)[i]}
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewOne.emergency_relation)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {
                                  JSON.parse(
                                    props.viewOne.emergency_occupation
                                  )[i]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.emergency_email)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewOne.guarante_industry)[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    学习专业 <br />
                    Major's Information
                  </p>
                  <table class="table table-borderless text-center">
                    <tbody>
                      <tr>
                        <td style={tddd}>专业 Subject </td>
                        <td style={tdd}>{props.viewTwo?.mejor_subject}</td>
                        <td style={tddd}>授课语言 Teaching Language</td>
                        <td style={tdd}>{props.viewTwo?.teaching_language}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>学历 Degree</td>
                        <td style={tdd}>{props.viewTwo?.mejor_degree}</td>
                        <td style={tddd}>
                          学院 College, Expected University/Scholarship Critaria
                        </td>
                        <td style={tdd}>{props.viewTwo?.expected_school}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>入学年份 Entry Year</td>
                        <td style={tdd}>{props.viewTwo?.mejor_year}</td>
                        <td style={tddd}>入学 session</td>
                        <td style={tdd}>
                          {props.viewTwo?.march_intake}
                          {props.viewTwo?.september_intake}
                        </td>
                      </tr>
                      <tr>
                        <td style={tddd}>推荐类型 Reference Type</td>
                        <td style={tdd}>{props.viewTwo?.reference_type}</td>
                        <td style={tddd}> 推荐单位电话 Reference Contact</td>
                        <td style={tdd}>
                          {props.viewTwo?.rcode}-{props.viewTwo?.reference_no}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    推荐人信息 <br />
                    Referee Information
                  </p>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          姓名 <br />
                          Name
                        </th>
                        <th scope="col">Work place</th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          关系 <br />
                          Relationship
                        </th>
                        <th scope="col">
                          职业 <br />
                          Occupation
                        </th>
                        <th scope="col">
                          联系邮箱
                          <br /> Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewTwo?.reference_name !== undefined &&
                        JSON.parse(props.viewTwo?.reference_name).map(
                          (s, i) => {
                            return (
                              <tr>
                                <td>{s}</td>
                                <td>
                                  {
                                    JSON.parse(
                                      props.viewTwo?.reference_work_place
                                    )[i]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(props.viewTwo?.reference_phone)[
                                      i
                                    ]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(
                                      props.viewTwo?.reference_relation
                                    )[i]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(
                                      props.viewTwo?.reference_occupation
                                    )[i]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(props.viewTwo?.reference_email)[
                                      i
                                    ]
                                  }
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>

                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    研究论文 <br />
                    Research Paper
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          年 <br />
                          Year
                        </th>
                        <th scope="col">
                          论文主题 <br />
                          Essay Topic
                        </th>
                        <th scope="col">
                          发表文章 <br />
                          Publish an artical
                        </th>
                        <th scope="col">
                          杂志或期刊标题 <br />
                          Magazine or Journal Title
                        </th>
                        <th scope="col">
                          杂志级别 <br />
                          Magazine Level
                        </th>
                        <th scope="col">
                          作者姓名 <br />
                          Author Name
                        </th>
                        <th scope="col">
                          排名 <br />
                          My Ranking
                        </th>
                        <th scope="col">
                          出版年份和卷数 <br />
                          Year Of publication and number of volumes
                        </th>
                        <th scope="col">
                          期刊收录 <br />
                          Journal Inclusion
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewTwo?.research_year !== undefined &&
                        JSON.parse(props.viewTwo?.research_year).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_essay)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_artical)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_title)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_level)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_auther)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_ranking)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.research_volumes)[i]}
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewTwo?.research_inclusion)[
                                    i
                                  ]
                                }
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <br />

                <div style={hh}>
                  <table class="table table-borderless ">
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          语言能力 <br />
                          Language Proficiency
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>英语水平 English Proficiency</td>
                        <td style={tdd}>
                          {props.viewTwo?.english_proficiency}
                        </td>
                        <td style={tddd}>汉语水平 Chinese Proficiency</td>
                        <td style={tdd}>{props.viewTwo?.china_proficiency}</td>
                      </tr>

                      <tr>
                        <td style={tddd}>雅思 IELTS</td>
                        <td style={tdd}>{props.viewTwo?.ielts}</td>
                        <td style={tddd}>等级 HSK Level</td>
                        <td style={tdd}>{props.viewTwo?.hsk_level}</td>
                      </tr>

                      <tr>
                        <td style={tddd}>托福 TOEFL</td>
                        <td style={tdd}>{props.viewTwo?.toefl}</td>
                        <td style={tddd}>
                          HSK成绩 HSK Scores & HSKK成绩 HSKK Scores
                        </td>
                        <td style={tdd}>{props.viewTwo?.hsk_score}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>成绩 GRE</td>
                        <td style={tdd}>{props.viewTwo?.gre}</td>
                        <td style={tddd}>证书编号 HSK No</td>
                        <td style={tdd}>{props.viewTwo?.hsk_no}</td>
                      </tr>

                      <tr>
                        <td style={tddd}>成绩 GMAT</td>
                        <td style={tdd}>{props.viewTwo?.gmat}</td>
                        <td style={tddd}>
                          听力成绩 Listening & 阅读成绩 Reading & 书写成绩
                          Writing
                        </td>
                        <td style={tdd}>
                          {props.viewTwo?.listening_score}+
                          {props.viewTwo?.reading_score}+
                          {props.viewTwo?.writing_score}+
                        </td>
                      </tr>
                      <tr>
                        <td style={tddd}>多邻国成绩 Duolingo</td>
                        <td style={tdd}>{props.viewTwo?.duolingo}</td>
                        <td style={tddd}>其他 Other Proficiency Score</td>
                        <td style={tdd}>{props.viewTwo?.other_english}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>托业成绩 TOEIC</td>
                        <td style={tdd}>{props.viewTwo?.toeic}</td>
                        <td style={tddd}>
                          学习汉语时间 Time of Chinese language learning
                        </td>
                        <td style={tdd}>
                          {props.viewTwo?.chinese_start} to
                          {props.viewTwo?.chinese_end}
                        </td>
                      </tr>

                      <tr>
                        <td style={tddd}></td>
                        <td style={tdd}></td>
                        <td style={tddd}>
                          汉语教师是否为中国国籍 Whether the Chinese teacher own
                          a Chinese nationality
                        </td>
                        <td style={tdd}>{props.viewTwo?.teacher_own}</td>
                      </tr>
                      <tr>
                        <td style={tddd}></td>
                        <td style={tdd}></td>
                        <td style={tddd}>
                          汉语学习机构名称 Name of institution for Chinese
                        </td>
                        <td style={tdd}>{props.viewTwo?.chinese_institute}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />

                <div style={hh}>
                  <table
                    style={{ marginBottom: `0` }}
                    class="table table-borderless "
                  >
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          教育背景 <br />
                          Education Background
                        </td>
                        <td colSpan={2}></td>
                      </tr>

                      <tr>
                        <td style={tddd}>最高学历 Highest Degree</td>
                        <td style={tdd}>{props.viewTwo?.highest_degree}</td>
                        <td style={tddd}>学校 School name</td>
                        <td style={tdd}>{props.viewTwo?.schoolname}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          如果满分是100分，最高学历成绩 Mark range of your
                        </td>
                        <td style={tdd}>{props.viewTwo?.highest_mark}</td>
                        <td style={tddd}>Certificate Type</td>
                        <td style={tdd}>{props.viewTwo?.certificate_type}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          最高学历成绩是否挂科 Any failure in your highest
                          degree marks?
                        </td>
                        <td style={tdd}>{props.viewTwo?.any_failer}</td>
                        <td style={tddd}></td>
                        <td style={tdd}></td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          学历 <br />
                          Degree
                        </th>
                        <th scope="col">
                          学校 <br />
                          School Type
                        </th>
                        <th scope="col">
                          国家 <br />
                          Country
                        </th>
                        <th scope="col">
                          学校 <br />
                          School name
                        </th>
                        <th scope="col">
                          开始时间 Year of attendance (from)
                          <br />
                          -结束时间 Year of attendance (to)
                        </th>
                        <th scope="col">
                          专业领域 <br />
                          Field of study
                        </th>
                        <th scope="col">
                          获得证书 <br />
                          Major and Diploma Received*
                        </th>
                        <th scope="col">
                          联系人 <br />
                          Contact person
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                        <th scope="col">
                          备注 <br />
                          Remark
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewTwo?.degree !== undefined &&
                        JSON.parse(props.viewTwo?.degree).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {JSON.parse(props.viewTwo?.school_type)[i]}
                              </td>
                              <td>{JSON.parse(props.viewTwo?.country)[i]}</td>
                              <td>
                                {JSON.parse(props.viewTwo?.school_name)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.attendence_form)[i]}{" "}
                                to
                                {JSON.parse(props.viewTwo?.attendence_to)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.field_study)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.major_deploma)[i]}
                              </td>
                              <td>{JSON.parse(props.viewTwo?.contact)[i]}</td>
                              <td>{JSON.parse(props.viewTwo?.phone)[i]}</td>
                              <td>{JSON.parse(props.viewTwo?.email)[i]}</td>
                              <td>{JSON.parse(props.viewTwo?.remarks)[i]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    工作经历 <br />
                    Work Experience
                  </p>
                  <table class="table table-borderless text-center">
                    <tbody>
                      <tr>
                        <td style={tddd}>
                          是否曾经在华工作 Have you ever worked in China?
                        </td>
                        <td style={tdd}>{props.viewTwo?.have_work}</td>
                        <td style={tddd}>在华工作单位 Work Place in China</td>
                        <td style={tdd}>{props.viewTwo?.work_china}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          在华工作期限 Working Duration in China
                        </td>
                        <td style={tdd}>
                          {props.viewTwo?.working_start} to{" "}
                          {props.viewTwo?.working_end}
                        </td>
                        <td style={tddd}></td>
                        <td style={tdd}></td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{ marginBottom: `0` }}
                    class="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          开始时间 Year of attendance (from) - <br />
                          结束时间 Year of attendance (to)
                        </th>
                        <th scope="col">
                          单位名称 <br />
                          Work Place
                        </th>
                        <th scope="col">
                          行业类型 <br />
                          Industry
                        </th>
                        <th scope="col">
                          职业 <br />
                          Occupation
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          联系人 <br />
                          Contact person
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewTwo?.work_attendence_form !== undefined &&
                        JSON.parse(props.viewTwo?.work_attendence_form).map(
                          (s, i) => {
                            return (
                              <tr>
                                <td>
                                  {s} -
                                  {
                                    JSON.parse(
                                      props.viewTwo?.work_attendence_to
                                    )[i]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(props.viewTwo?.work_work_place)[
                                      i
                                    ]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(
                                      props.viewTwo?.work_industry_type
                                    )[i]
                                  }
                                </td>
                                <td>
                                  {
                                    JSON.parse(props.viewTwo?.work_occupation)[
                                      i
                                    ]
                                  }
                                </td>
                                <td>
                                  {JSON.parse(props.viewTwo?.work_phone)[i]}
                                </td>
                                <td>
                                  {JSON.parse(props.viewTwo?.work_contact)[i]}
                                </td>
                                <td>
                                  {JSON.parse(props.viewTwo?.work_email)[i]}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <br />

                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    家庭成员信息 <br />
                    Family Members
                  </p>
                  <table
                    style={{ marginBottom: `0` }}
                    class="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          姓名 <br />
                          Name
                        </th>
                        <th scope="col">
                          关系 <br />
                          Relationship
                        </th>
                        <th scope="col">
                          国籍 <br />
                          Nationality
                        </th>
                        <th scope="col">
                          是否健在 <br />
                          Still alive
                        </th>
                        <th scope="col">
                          机构/单位 <br />
                          Institution/Employer
                        </th>
                        <th scope="col">
                          行业类型 <br />
                          Industry Type
                        </th>
                        <th scope="col">
                          职业 <br />
                          Occupation
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewTwo?.family_name !== undefined &&
                        JSON.parse(props.viewTwo?.family_name).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {JSON.parse(props.viewTwo?.family_relation)[i]}
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewTwo?.family_nationality)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.family_live)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.family_institute)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.family_ins_type)[i]}
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewTwo?.family_occupation)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.family_phone)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewTwo?.family_email)[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <br />

                <div style={hh}>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          如何收集入学文件 <br />
                          how to collect your admission documents ?
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>拾取类型 Pick up type</td>
                        <td style={tdd}>{props.viewThree.collect}</td>
                        <td style={tddd}>收件人地址 Pickup Details Address</td>
                        <td style={tdd}>{props.viewThree.details}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />

                <div style={hh}>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td style={headsss} colSpan={2}>
                          其它信息 <br />
                          Other Information
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          申请者是否为移民 Are you an immigrant?
                        </td>
                        <td style={tdd}>{props.viewThree.are_you_immigrend}</td>
                        <td style={tddd}>
                          原来自国家或地区 Original Country or Region
                        </td>
                        <td style={tdd}>{props.viewThree.original_country}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          父母双方或一方是否为中国公民 Whether one or both
                          parents are Chinese citizen?
                        </td>
                        <td style={tdd}>
                          {props.viewThree.parants_are_chines}
                        </td>
                        <td style={tddd}>
                          何时取得当前国籍 When did you get the current
                          citizenship?
                        </td>
                        <td style={tdd}>
                          {props.viewThree.current_citizenship}
                        </td>
                      </tr>
                      <tr>
                        <td style={tddd}>编号 CSC/CIS NO</td>
                        <td style={tdd}>{props.viewThree.csc}</td>
                        <td style={tddd}>兴趣爱好 Hobbies</td>
                        <td style={tdd}>{props.viewThree.hobbis}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>是否吸烟 Smoking</td>
                        <td style={tdd}>{props.viewThree.smoking}</td>
                        <td style={tddd}>
                          是否有无犯罪证明 Do you have Certificate of Non-
                          criminal records?
                        </td>
                        <td style={tdd}>{props.viewThree.criminal_recoreds}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          是否有特殊疾病 Are there any particular diseases?
                        </td>
                        <td style={tdd}>{props.viewThree.special_disease}</td>
                        <td style={tddd}>
                          是否曾有过犯罪史 Have you ever committed a crime?
                        </td>
                        <td style={tdd}>{props.viewThree.crime}</td>
                      </tr>
                      <tr>
                        <td style={tddd}>
                          是否有体检表Do you have physical examination form?
                        </td>
                        <td style={tdd}>{props.viewThree.physical_exam}</td>
                        <td style={tddd}></td>
                        <td style={tdd}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />

                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    监护人信息（仅针对18岁以下学生） <br />
                    Guardian Information (for applicants age below 18 years old)
                  </p>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          姓名 <br />
                          Name
                        </th>
                        <th scope="col">
                          关系 <br />
                          Relationship
                        </th>

                        <th scope="col">
                          单位名称 <br />
                          Work Place
                        </th>
                        <th scope="col">
                          行业类型 <br />
                          Industry Type
                        </th>
                        <th scope="col">
                          职业 <br />
                          Occupation
                        </th>
                        <th scope="col">
                          联系电话 <br />
                          Phone/Mobile
                        </th>
                        <th scope="col">
                          联系邮箱 <br />
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewThree?.family_name !== undefined &&
                        JSON.parse(props.viewThree?.family_name).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {
                                  JSON.parse(props.viewThree?.family_relation)[
                                    i
                                  ]
                                }
                              </td>

                              <td>
                                {
                                  JSON.parse(props.viewThree?.family_institute)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {
                                  JSON.parse(props.viewThree?.family_ins_type)[
                                    i
                                  ]
                                }
                              </td>
                              <td>
                                {
                                  JSON.parse(
                                    props.viewThree?.family_occupation
                                  )[i]
                                }
                              </td>
                              <td>
                                {JSON.parse(props.viewThree?.family_phone)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewThree?.family_email)[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <br />
                <div style={hh}>
                  <p style={headp} colSpan={4}>
                    奖励信息 <br /> Award Information
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          奖励名称 <br />
                          Name of Reward
                        </th>
                        <th scope="col">
                          奖励性质
                          <br /> Reward type
                        </th>
                        <th scope="col">
                          奖励等级 <br />
                          Rward Level
                        </th>
                        <th scope="col">
                          奖励领域 <br />
                          Reward areas
                        </th>
                        <th scope="col">
                          奖励时间 <br />
                          Reward time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.viewThree?.award_name !== undefined &&
                        JSON.parse(props.viewThree?.award_name).map((s, i) => {
                          return (
                            <tr>
                              <td>{s}</td>
                              <td>
                                {JSON.parse(props.viewThree?.award_type)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewThree?.award_level)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewThree?.award_fields)[i]}
                              </td>
                              <td>
                                {JSON.parse(props.viewThree?.award_time)[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <br />

                <p style={headp} colSpan={4}>
                  上传材料 <br /> UPLOAD DOCUMENTS
                </p>
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td style={tddk}> 护照/Valid Ordinary Passport</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.valid_ordinary_passport !== undefined &&
                        props.viewFour?.valid_ordinary_passport !== null
                          ? JSON.parse(
                              props.viewFour?.valid_ordinary_passport
                            ).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {
                                    JSON.parse(
                                      props.viewFour
                                        ?.valid_ordinary_passport_name
                                    )[i]
                                  }
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>在华签证/Visa Page</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.visa_page !== undefined &&
                        props.viewFour?.visa_page !== null
                          ? JSON.parse(props.viewFour?.visa_page).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour?.visa_page_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>
                        最高学历证明/Highest Original Certificate ( Notarized
                        Copy )
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.original_higtest_diploma !==
                          undefined &&
                        props.viewFour?.original_higtest_diploma !== null
                          ? JSON.parse(
                              props.viewFour?.original_higtest_diploma
                            ).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {
                                    JSON.parse(
                                      props.viewFour
                                        ?.original_higtest_diploma_name
                                    )[i]
                                  }
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>
                        最高学历成绩单/Highest Academic Transcripts ( Notarized
                        Copy )
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.original_transcript !== undefined &&
                        props.viewFour?.original_transcript !== null
                          ? JSON.parse(props.viewFour?.original_transcript).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour?.original_transcript_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td style={tddk}>
                        个人简历/Detailed Written Resume / CV
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.written_resume !== undefined &&
                        props.viewFour?.written_resume !== null
                          ? JSON.parse(props.viewFour?.written_resume).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour.written_resume_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>体检报告/Physical Examination Record</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.physical_record !== undefined &&
                        props.viewFour?.physical_record !== null
                          ? JSON.parse(props.viewFour?.physical_record).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour?.physical_record_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>无犯罪证明/Non-Criminal Record</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.criminal_record !== undefined &&
                        props.viewFour?.criminal_record !== null
                          ? JSON.parse(props.viewFour?.criminal_record).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour?.criminal_record_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>
                        两封推荐信/Two Recommendation Letters
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.recommendation_letter !== undefined &&
                        props.viewFour?.recommendation_letter !== null
                          ? JSON.parse(
                              props.viewFour?.recommendation_letter
                            ).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {
                                    JSON.parse(
                                      props.viewFour?.recommendation_letter_name
                                    )[i]
                                  }
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>银行对账单/Bank Statement</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.bank !== undefined &&
                        props.viewFour?.bank !== null
                          ? JSON.parse(props.viewFour?.bank).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {JSON.parse(props.viewFour?.bank_name)[i]}
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>学习计划 study plan</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.study_plan !== undefined &&
                        props.viewFour?.study_plan !== null
                          ? JSON.parse(props.viewFour?.study_plan).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour?.study_plan_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>
                        研究成果/Original Research Achievements (If Any)
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.research_achievement !== undefined &&
                        props.viewFour?.research_achievement !== null
                          ? JSON.parse(
                              props.viewFour?.research_achievement
                            ).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {
                                    JSON.parse(
                                      props.viewFour?.research_achievement_name
                                    )[i]
                                  }
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>
                        语言证书/Certificate of Language Proficiency (HSK,
                        IELTS, Others)
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.language_proficiency !== undefined &&
                        props.viewFour?.language_proficiency !== null
                          ? JSON.parse(
                              props.viewFour?.language_proficiency
                            ).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {
                                    JSON.parse(
                                      props.viewFour?.language_proficiency_name
                                    )[i]
                                  }
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td style={tddk}>
                        在华表现证明/Current University Certificate (If you are
                        in China )
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.current_university !== undefined &&
                        props.viewFour?.current_university !== null
                          ? JSON.parse(props.viewFour?.current_university).map(
                              (u, i) => {
                                return (
                                  <a href={u} target="_blank">
                                    {i + 1 + `. `}
                                    {
                                      JSON.parse(
                                        props.viewFour?.current_university_name
                                      )[i]
                                    }
                                    <br />
                                  </a>
                                );
                              }
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td style={tddk}>
                        新冠疫苗接种证明/Vaccination Certificate
                      </td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.vaccine !== undefined &&
                        props.viewFour?.vaccine !== null
                          ? JSON.parse(props.viewFour?.vaccine).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {JSON.parse(props.viewFour?.vaccine_name)[i]}
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td style={tddk}>其他/Other</td>
                    </tr>
                    <tr>
                      <td style={tdd}>
                        {props.viewFour !== null &&
                        props.viewFour?.other !== undefined &&
                        props.viewFour?.other !== null
                          ? JSON.parse(props.viewFour?.other).map((u, i) => {
                              return (
                                <a href={u} target="_blank">
                                  {i + 1 + `. `}
                                  {JSON.parse(props.viewFour?.other_name)[i]}
                                  <br />
                                </a>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <Video /> */}
              {props.viewThree.video != null && (
                <video width="320" height="240" controls download>
                  <source src={props.viewThree.video} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              )}

              <div className="col-lg-12 text-center my-5">
                <p style={pp}>
                  If you submitted and uploaded all of your information then you
                  need to re-check and final submission, After your final
                  submission you can't change anything without Admin permission.
                  And submitting you ensure that all information is currect{" "}
                  <br />
                  {props.viewOne.status === "0" &&
                    props.viewOne.family_name != null &&
                    props.viewTwo.mejor_subject != null &&
                    props.viewThree.collect != null &&
                    props.viewFour.valid_ordinary_passport != null && (
                      <span
                        className="btn btn-danger"
                        onClick={() => finalSubmit(props.viewOne.id)}
                      >
                        Final Submit
                      </span>
                    )}
                </p>

                <Link
                  to={
                    `/apply-four/` +
                    url[4] +
                    "/" +
                    url[5] +
                    "/" +
                    data.type +
                    "/" +
                    url[7]
                  }
                  className="btn-one"
                >
                  上一步 Previous
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    home: state.auth.home,
    viewOne: state.auth.viewOne,
    viewTwo: state.auth.viewTwo,
    viewThree: state.auth.viewThree,
    viewFour: state.auth.viewFour,
    pp: state.auth.pp,
  };
};

export default connect(mapStateToProps, { applyViewData, applyFinal })(
  ApplyView
);
