function Marks(props) {
  return (
    <>
      <option value="None">None</option>
      <option value="Over 90">Over 90</option>
      <option value="80-90">80-90</option>
      <option value="70-80">70-80</option>
      <option value="60-70">60-70</option>
      <option value="50-60">50-60</option>
      <option value="40-50">40-50</option>
      <option value="Under -40">Under -40</option>
    </>
  );
}
export default Marks;
