function SchoolType(props) {
  return (
    <>
      <option value="无 None">无 None</option>
      <option value="大学 University">大学 University</option>
      <option value="高中 High school">高中 High school</option>
      <option value="初中Junior school">初中Junior school</option>
      <option value="培训机构 Training school">培训机构 Training school</option>
      <option value="其他 Others">其他 Others</option>
    </>
  );
}
export default SchoolType;
