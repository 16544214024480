import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import { useForm } from "react-hook-form";
import { paymentSend, paymentMethod } from "../../services/actions/AuthAction";
import { connect } from "react-redux";

function Payment(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("image", data.pic[0]);
    formData.append("bank", data.bank);
    formData.append("usebank", data.usebank);
    formData.append("amount", data.amount);
    formData.append("date", data.date);
    formData.append("application_id", data.application_id);
    // console.log(formData);
    props.paymentSend(formData);
    reset();
  };
  useEffect(() => {
    props.paymentMethod();
  }, []);
  let { id } = useParams();
  return (
    <>
      <Navber />

      <div class="container py-5 my-5">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div class="row">
            <div class="col-lg-10">
              <p class="h1 mb-5">Hay Update Your Payment Information</p>
            </div>

            <div class="col-lg-6 mx-auto">
              <h3 style={{ color: `#ffa214` }}>Our Bank And Account Number</h3>
              {props.payment.map((bank, i) => {
                return (
                  <p>
                    {i + 1}. Bank : <strong>{bank.bank}</strong>, Account:{" "}
                    <strong>{bank.account}</strong>
                  </p>
                );
              })}

              <div class="contact-area pt-5">
                {props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {props.sucMsg}
                  </div>
                )}
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Bank Account</div>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="hidden"
                      {...register("application_id")}
                      value={id}
                    />
                    <select
                      class="form-control"
                      {...register("bank", { required: true })}
                    >
                      <option value="">Select Bank</option>
                      {props.payment.map((bank, i) => {
                        return (
                          <option value={bank.id}>
                            {bank.bank}, {bank.account}
                          </option>
                        );
                      })}
                    </select>

                    {errors.bank && <span>This field is required</span>}
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Using Bank</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput12"
                        {...register("usebank", { required: true })}
                      />
                      {errors.usebank && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Amount</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="number"
                        class="form-control"
                        id="exampleFormControlInput11111"
                        {...register("amount", { required: true })}
                      />
                      {errors.amount && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Date</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="date"
                        class="form-control"
                        id="exampleFormControlInput111"
                        {...register("date", { required: true })}
                      />
                      {errors.date && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Attachment</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="file"
                        class="form-control"
                        id="exampleFormControlInput11"
                        {...register("pic")}
                      />
                      {errors.pic && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row pt-4">
                  <div class="col-md-12 text-end">
                    <div class=" ">
                      <button type="submit" class="btn-two">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    profile: state.auth.profile,
    payment: state.auth.payment,
  };
};
const mapDispatchToProps = (dispatch) => ({
  paymentSend: (data) => dispatch(paymentSend(data)),
  paymentMethod: () => dispatch(paymentMethod()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
