import React from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";

import {
  applicationTwo,
  applicationTwoData,
} from "../../services/actions/AuthAction";
import { Link, Navigate, useParams } from "react-router-dom";
import Country from "../helper/Country";
import ApplicationDataP from "../helper/ApplicationDataP";
import Industry from "../helper/Industry";
import Relation from "../helper/Relation";
import Occupation from "../helper/Occupation";
import Degree from "../helper/Degree";
import Marks from "../helper/Marks";
import CertificateType from "../helper/CertificateType";
import Hsk from "../helper/Hsk";
import SchoolType from "../helper/SchoolType";

class Test2 extends React.Component {
  constructor() {
    super();

    this.state = {
      sector: "",

      reference: true,
      reference_name: "",
      reference_work_place: "",
      reference_phone: "",
      reference_relation: "",
      reference_occupation: "",
      reference_email: "",
      referenceInfo: [],
      referenceError: "",
      referenceAdd: true,
      referenceUpdate: false,
      referenceItem: null,

      degreeInput: true,
      degree: "",
      school_type: "",
      country: "",
      school_name: "",
      attendence_form: "",
      attendence_to: "",
      field_study: "",
      major_deploma: "",
      contact: "",
      phone: "",
      email: "",
      remarks: "",
      degreeInfo: [],
      degreeError: "",
      degreeAdd: true,
      degreeUpdate: false,
      degreeItem: null,

      work: true,
      work_attendence_form: "",
      work_attendence_to: "",
      work_work_place: "",
      work_industry_type: "",
      work_occupation: "",
      work_phone: "",
      work_contact: "",
      work_email: "",
      workInfo: [],
      workError: "",
      workAdd: true,
      workUpdate: false,
      workItem: null,

      family: true,
      family_name: "",
      family_relation: "",
      family_nationality: "",
      family_live: "",
      family_institute: "",
      family_ins_type: "",
      family_occupation: "",
      family_phone: "",
      family_email: "",
      familyInfo: [],
      familyError: "",
      familyAdd: true,
      familyUpdate: false,
      familyItem: null,
    };
  }
  //family
  addFamily = () => {
    this.setState({ family: true });
    this.setState({ familyAdd: true });
    this.setState({ familyUpdate: false });
  };

  submitFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,
      family_nationality: this.state.family_nationality,
      family_live: this.state.family_live,
      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 9) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        familyInfo: [...prevState.familyInfo, item],
      }));
      this.setState({ familyError: "" });
      this.setState({ family: false });

      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_nationality: "" });
      this.setState({ family_live: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  submitUpdateFamily = () => {
    const item = {
      family_name: this.state.family_name,
      family_relation: this.state.family_relation,
      family_nationality: this.state.family_nationality,
      family_live: this.state.family_live,
      family_institute: this.state.family_institute,
      family_ins_type: this.state.family_ins_type,
      family_occupation: this.state.family_occupation,
      family_phone: this.state.family_phone,
      family_email: this.state.family_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 9) {
      this.setState({ familyError: "All * mark fields are requird." });
    } else {
      const { familyInfo } = this.state;
      familyInfo[this.state.familyItem] = item;

      this.setState({
        familyInfo: familyInfo,
      });

      this.setState({ familyError: "" });
      this.setState({ family: false });

      this.setState({ family_name: "" });
      this.setState({ family_relation: "" });
      this.setState({ family_nationality: "" });
      this.setState({ family_live: "" });
      this.setState({ family_institute: "" });
      this.setState({ family_ins_type: "" });
      this.setState({ family_occupation: "" });
      this.setState({ family_phone: "" });
      this.setState({ family_email: "" });
    }
  };

  editFamily(si) {
    this.setState({ familyAdd: false });
    this.setState({ familyUpdate: true });
    this.setState({ familyItem: si });
    this.setState({ family: true });

    const ii = this.state.familyInfo[si];

    this.setState({ family_name: ii.family_name });
    this.setState({ family_relation: ii.family_relation });
    this.setState({ family_nationality: ii.family_nationality });
    this.setState({ family_live: ii.family_live });
    this.setState({ family_institute: ii.family_institute });
    this.setState({ family_ins_type: ii.family_ins_type });
    this.setState({ family_occupation: ii.family_occupation });
    this.setState({ family_phone: ii.family_phone });
    this.setState({ family_email: ii.family_email });
  }
  deleteFamily(ix) {
    this.state.familyInfo.splice(ix, 1);
    this.setState({
      familyInfo: this.state.familyInfo,
    });
  }

  //end family
  //work
  addWork = () => {
    this.setState({ work: true });
    this.setState({ workAdd: true });
    this.setState({ workUpdate: false });
  };

  submitWork = () => {
    const item = {
      work_attendence_form: this.state.work_attendence_form,
      work_attendence_to: this.state.work_attendence_to,
      work_work_place: this.state.work_work_place,
      work_industry_type: this.state.work_industry_type,
      work_occupation: this.state.work_occupation,
      work_phone: this.state.work_phone,
      work_contact: this.state.work_contact,
      work_email: this.state.work_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 8) {
      this.setState({ workError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        workInfo: [...prevState.workInfo, item],
      }));
      this.setState({ workError: "" });
      this.setState({ work: false });

      this.setState({ work_attendence_form: "" });
      this.setState({ work_attendence_to: "" });
      this.setState({ work_work_place: "" });
      this.setState({ work_industry_type: "" });
      this.setState({ work_occupation: "" });
      this.setState({ work_phone: "" });
      this.setState({ work_contact: "" });
      this.setState({ work_email: "" });
    }
  };

  submitUpdateWork = () => {
    const item = {
      work_attendence_form: this.state.work_attendence_form,
      work_attendence_to: this.state.work_attendence_to,
      work_work_place: this.state.work_work_place,
      work_industry_type: this.state.work_industry_type,
      work_occupation: this.state.work_occupation,
      work_phone: this.state.work_phone,
      work_contact: this.state.work_contact,
      work_email: this.state.work_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 8) {
      this.setState({ workError: "All * mark fields are requird." });
    } else {
      const { workInfo } = this.state;
      workInfo[this.state.workItem] = item;

      this.setState({
        workInfo: workInfo,
      });

      this.setState({ workError: "" });
      this.setState({ work: false });

      this.setState({ work_attendence_form: "" });
      this.setState({ work_attendence_to: "" });
      this.setState({ work_work_place: "" });
      this.setState({ work_industry_type: "" });
      this.setState({ work_occupation: "" });
      this.setState({ work_phone: "" });
      this.setState({ work_contact: "" });
      this.setState({ work_email: "" });
    }
  };

  editWork(si) {
    this.setState({ workAdd: false });
    this.setState({ workUpdate: true });
    this.setState({ workItem: si });
    this.setState({ work: true });

    const ii = this.state.workInfo[si];

    this.setState({ work_attendence_form: ii.work_attendence_form });
    this.setState({ work_attendence_to: ii.work_attendence_to });
    this.setState({ work_work_place: ii.work_work_place });
    this.setState({ work_industry_type: ii.work_industry_type });
    this.setState({ work_occupation: ii.work_occupation });
    this.setState({ work_phone: ii.work_phone });
    this.setState({ work_contact: ii.work_contact });
    this.setState({ work_email: ii.work_email });
  }
  deleteWork(ix) {
    this.state.workInfo.splice(ix, 1);
    this.setState({
      workInfo: this.state.workInfo,
    });
  }
  //end work
  //garante start
  addReference = () => {
    this.setState({ reference: true });
    this.setState({ referenceAdd: true });
    this.setState({ referenceUpdate: false });
  };

  submitReference = () => {
    const item = {
      reference_name: this.state.reference_name,
      reference_work_place: this.state.reference_work_place,
      reference_phone: this.state.reference_phone,
      reference_relation: this.state.reference_relation,
      reference_occupation: this.state.reference_occupation,
      reference_email: this.state.reference_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ referenceError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        referenceInfo: [...prevState.referenceInfo, item],
      }));
      this.setState({ referenceError: "" });
      this.setState({ reference: false });

      this.setState({ reference_name: "" });
      this.setState({ reference_work_place: "" });
      this.setState({ reference_phone: "" });
      this.setState({ reference_relation: "" });
      this.setState({ reference_occupation: "" });
      this.setState({ reference_email: "" });
    }
  };

  submitUpdateReference = () => {
    const item = {
      reference_name: this.state.reference_name,
      reference_work_place: this.state.reference_work_place,
      reference_phone: this.state.reference_phone,
      reference_relation: this.state.reference_relation,
      reference_occupation: this.state.reference_occupation,
      reference_email: this.state.reference_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ referenceError: "All * mark fields are requird." });
    } else {
      const { referenceInfo } = this.state;
      referenceInfo[this.state.referenceItem] = item;

      this.setState({
        referenceInfo: referenceInfo,
      });

      this.setState({ referenceError: "" });
      this.setState({ reference: false });

      this.setState({ reference_name: "" });
      this.setState({ reference_work_place: "" });
      this.setState({ reference_phone: "" });
      this.setState({ reference_relation: "" });
      this.setState({ reference_occupation: "" });
      this.setState({ reference_email: "" });
    }
  };

  editReference(si) {
    this.setState({ referenceAdd: false });
    this.setState({ referenceUpdate: true });
    this.setState({ referenceItem: si });
    this.setState({ reference: true });

    const ii = this.state.referenceInfo[si];
    this.setState({ reference_name: ii.reference_name });
    this.setState({ reference_work_place: ii.reference_work_place });
    this.setState({ reference_phone: ii.reference_phone });
    this.setState({ reference_relation: ii.reference_relation });
    this.setState({ reference_occupation: ii.reference_occupation });
    this.setState({ reference_email: ii.reference_email });
  }
  deleteReference(ix) {
    this.state.referenceInfo.splice(ix, 1);
    this.setState({
      referenceInfo: this.state.referenceInfo,
    });
  }
  //garante

  //Degree
  addDegree = () => {
    this.setState({ degreeInput: true });
    this.setState({ degreeAdd: true });
    this.setState({ degreeUpdate: false });
  };

  submitDegree = () => {
    const item = {
      degree: this.state.degree,
      school_type: this.state.school_type,
      country: this.state.country,
      school_name: this.state.school_name,
      attendence_form: this.state.attendence_form,
      attendence_to: this.state.attendence_to,
      field_study: this.state.field_study,
      major_deploma: this.state.major_deploma,
      contact: this.state.contact,
      phone: this.state.phone,
      email: this.state.email,
      remarks: this.state.remarks,
    };

    if (
      item.degree === "" ||
      item.school_type === "" ||
      item.country === "" ||
      item.school_name === "" ||
      item.attendence_form === "" ||
      item.attendence_to === "" ||
      item.major_deploma === ""
    ) {
      this.setState({ degreeError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        degreeInfo: [...prevState.degreeInfo, item],
      }));
      this.setState({ degreeError: "" });
      this.setState({ degreeInput: false });

      this.setState({ degree: "" });
      this.setState({ school_type: "" });
      this.setState({ country: "" });
      this.setState({ school_name: "" });
      this.setState({ attendence_form: "" });
      this.setState({ attendence_to: "" });
      this.setState({ field_study: "" });
      this.setState({ major_deploma: "" });
      this.setState({ contact: "" });
      this.setState({ phone: "" });
      this.setState({ email: "" });
      this.setState({ remarks: "" });
    }
  };

  submitUpdateDegree = () => {
    const item = {
      degree: this.state.degree,
      school_type: this.state.school_type,
      country: this.state.country,
      school_name: this.state.school_name,
      attendence_form: this.state.attendence_form,
      attendence_to: this.state.attendence_to,
      field_study: this.state.field_study,
      major_deploma: this.state.major_deploma,
      contact: this.state.contact,
      phone: this.state.phone,
      email: this.state.email,
      remarks: this.state.remarks,
    };

    if (
      item.degree === "" ||
      item.school_type === "" ||
      item.country === "" ||
      item.school_name === "" ||
      item.attendence_form === "" ||
      item.attendence_to === "" ||
      item.major_deploma === ""
    ) {
      this.setState({ degreeError: "All * mark fields are requird." });
    } else {
      const { degreeInfo } = this.state;
      degreeInfo[this.state.degreeItem] = item;

      this.setState({
        degreeInfo: degreeInfo,
      });

      this.setState({ degreeError: "" });
      this.setState({ degree: false });

      this.setState({ degree_name: "" });
      this.setState({ school_type: "" });
      this.setState({ country: "" });
      this.setState({ school_name: "" });
      this.setState({ attendence_form: "" });
      this.setState({ attendence_to: "" });
      this.setState({ field_study: "" });
      this.setState({ major_deploma: "" });
      this.setState({ contact: "" });
      this.setState({ phone: "" });
      this.setState({ email: "" });
      this.setState({ remarks: "" });
    }
  };

  editDegree(si) {
    this.setState({ degreeAdd: false });
    this.setState({ degreeUpdate: true });
    this.setState({ degreeItem: si });
    this.setState({ degreeInput: true });

    const ii = this.state.degreeInfo[si];
    this.setState({ degree: ii.degree });
    this.setState({ school_type: ii.school_type });
    this.setState({ country: ii.country });
    this.setState({ school_name: ii.school_name });
    this.setState({ attendence_form: ii.attendence_form });
    this.setState({ attendence_to: ii.attendence_to });
    this.setState({ field_study: ii.field_study });
    this.setState({ major_deploma: ii.major_deploma });
    this.setState({ contact: ii.contact });
    this.setState({ phone: ii.phone });
    this.setState({ email: ii.email });
    this.setState({ remarks: ii.remarks });
  }
  deleteDegree(ix) {
    this.state.degreeInfo.splice(ix, 1);
    this.setState({
      degreeInfo: this.state.degreeInfo,
    });
  }
  //Degree

  checkHandler = (a) => {
    this.setState({ sector: a });
  };

  onChangeData = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmitDetails = (e) => {
    e.preventDefault();
  };
  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    var id = 1;
    return (
      <>
        <UserHeader />
        <div class="">
          <div class="container">
            <div class="row">
              <ApplicationDataP post={id} />
            </div>
            <div class="row mb-5">
              <div class="col-lg-12">
                <p class="display-3 mb-5 fsw-bold">
                  <span class="cor-1"> Academic </span> Information
                </p>

                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <input type="hidden" name="user_id" value={data.id} />

              <div class="row application">
                <div class="col-lg-12">
                  <p class="h3 mb-4">Major Information</p>
                </div>

                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Subject</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="ielts"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.ielts
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Teaching Language</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          name="english_proficiency"
                          class="form-control"
                          required="required"
                        >
                          <option value="Please Choose">Please Choose</option>
                          <option value="None">None</option>
                          <option value="Poor">Poor</option>
                          <option value="Average">Average</option>
                          <option value="Good">Good</option>
                          <option value="Very good">Very good</option>
                          <option value="Excellent">Excellent</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Degree</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          name="english_proficiency"
                          class="form-control"
                          required="required"
                        >
                          <option value="Please Choose">Please Choose</option>
                          <Degree />
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Expected Scholarship Critaria</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="ielts"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.ielts
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Year</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          name="english_proficiency"
                          class="form-control"
                          required="required"
                        >
                          <option value="">Please Choose</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Session</div>
                    </div>
                    <div class="col-md-8">
                      <input type="radio" name="ielts" />
                      March Intake <br />
                      <input type="radio" name="ielts" />
                      September Intake
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="h3">Reference Information</p>
                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name</td>
                      <td>Relationship</td>
                      <td>Work Place</td>
                      <td>Occupation</td>
                      <td>Phone</td>
                      <td>Email</td>
                      <td>
                        <span onClick={this.addReference}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.referenceInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.reference_name}</td>
                            <td>{s.reference_relation}</td>
                            <td>{s.reference_work_place}</td>
                            <td>{s.reference_occupation}</td>
                            <td>{s.reference_phone}</td>
                            <td>{s.reference_email}</td>
                            <td>
                              <span onClick={() => this.editReference(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteReference(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.reference === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Name</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="reference_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Work Place</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="reference_work_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_work_place}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Phone</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="reference_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Relationship</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="reference_relation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Occupation</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="reference_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Email</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="reference_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.reference_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.referenceError}</p>
                      {this.state.referenceAdd === true && (
                        <span
                          onClick={this.submitReference}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.referenceUpdate === true && (
                        <span
                          onClick={this.submitUpdateReference}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div class="col-lg-12">
                  <p class="h3 mb-4">Language Proficiency</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">English Proficiency </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="english_proficiency"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.english_proficiency
                              : ""
                          }
                        >
                          <option value="Please Choose">Please Choose</option>
                          <option value="None">None</option>
                          <option value="Poor">Poor</option>
                          <option value="Average">Average</option>
                          <option value="Good">Good</option>
                          <option value="Very good">Very good</option>
                          <option value="Excellent">Excellent</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> IELTS</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="ielts"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.ielts
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> TOEFL </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input type="text" class="form-control" name="toefl" />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> GRE </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="gre"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.gre
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> GMAT </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="gmat"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.gmat
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Chinese Proficiency </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="chinese_proficiency"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.chinese_proficiency
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">HSK Level</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="hsk_level"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.hsk_level
                              : ""
                          }
                        >
                          <Hsk />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">HSKK Scores</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="hskk_score"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.hskk_score
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">HSK No </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="hsk_no"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.hsk_no
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Listening Score </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          id=" "
                          placeholder=" "
                          name="listen_score"
                          defaultValue={
                            this.props.steptwo !== null
                              ? this.props.steptwo.listen_score
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="h3">Education Background</p>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div class="bg">
                      Highest Degree <span className="colors star">*</span>
                    </div>

                    <div class="mt-4">
                      <select
                        class="form-control"
                        name="highest_degree"
                        required="required"
                      >
                        <option value="">Select Degree</option>
                        <Degree />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="bg">Highest Degree School name</div>

                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        name="schoolname"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="bg">Marks</div>

                    <div class="mt-4 ">
                      <select
                        class="form-control"
                        name="highest_degree"
                        required="required"
                      >
                        <option value="">Select Marks</option>
                        <Marks />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="bg">Certificate Type</div>

                    <div class="mt-4 ">
                      <select
                        class="form-control"
                        name="highest_degree"
                        required="required"
                      >
                        <option value="">Select Type</option>
                        <CertificateType />
                      </select>
                    </div>
                  </div>
                </div>
                <table className="table" style={{ background: `#92c0d2` }}>
                  <thead>
                    <td>Degree</td>
                    <td>School Name</td>
                    <td>Year Of attendence form</td>
                    <td>Year Of attendence to</td>
                    <td>Contact Person</td>

                    <td>
                      <span onClick={this.addDegree}> Add</span>
                    </td>
                  </thead>
                  <tbody>
                    {this.state.degreeInfo.map((s, i) => {
                      return (
                        <tr key={i}>
                          <td>{s.degree}</td>
                          <td>{s.school_name}</td>
                          <td>{s.attendence_form}</td>
                          <td>{s.attendence_to}</td>
                          <td>{s.contact}</td>

                          <td>
                            <span onClick={() => this.editDegree(i)}>Edit</span>
                            <span onClick={() => this.deleteDegree(i)}>
                              Delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {this.state.degreeInput === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Degree</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <select
                              class="form-control"
                              name="degree"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.degree}
                            >
                              <option value="">Select Degree</option>
                              <Degree />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">School Type</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <select
                              class="form-control"
                              name="school_type"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.school_type}
                            >
                              <option value="">Select School</option>
                              <SchoolType />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Country</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <select
                              class="form-control"
                              name="country"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.country}
                            >
                              <option value="">Select Country</option>
                              <Country />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">School name</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="school_name"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.school_name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Attendence From</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="date"
                              class="form-control"
                              name="attendence_form"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.attendence_form}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Attendence To</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="date"
                              class="form-control"
                              name="attendence_to"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.attendence_to}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Fields of Study </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="field_study"
                              onChange={this.onChangeData}
                              defaultValue={this.state.field_study}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Major Of Deploma receive </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="major_deploma"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.major_deploma}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Contact Person</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="contact"
                              onChange={this.onChangeData}
                              defaultValue={this.state.contact}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Phone/Mobile</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Email</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.email}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Remarks</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="remarks"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.remarks}
                            />
                          </div>
                        </div>
                      </div>

                      <p>{this.state.degreeError}</p>
                      {this.state.degreeAdd === true && (
                        <span
                          onClick={this.submitDegree}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.degreeUpdate === true && (
                        <span
                          onClick={this.submitUpdateDegree}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="h3">Work Experience</p>

                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Year Of attendence form</td>
                      <td>Year Of attendence to</td>
                      <td>Company</td>
                      <td>Occupation</td>
                      <td>Reference</td>
                      <td>Mobile</td>
                      <td>Email</td>

                      <td>
                        <span onClick={this.addWork}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.workInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.work_attendence_form}</td>
                            <td>{s.work_attendence_to}</td>
                            <td>{s.work_work_place}</td>
                            <td>{s.work_occupation}</td>
                            <td>{s.work_contact}</td>
                            <td>{s.work_phone}</td>
                            <td>{s.work_email}</td>

                            <td>
                              <span onClick={() => this.editWork(i)}>Edit</span>
                              <span onClick={() => this.deleteWork(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.work === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Attendence From</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="date"
                              class="form-control"
                              name="work_attendence_form"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_attendence_form}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Attendence To</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="date"
                              class="form-control"
                              name="work_attendence_to"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_attendence_to}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Work Place</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="work_work_place"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_work_place}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Industry Type</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <select
                              class="form-control"
                              name="work_industry_type"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_industry_type}
                            >
                              <option value="">Select Industry</option>
                              <Industry />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Occupation</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <select
                              class="form-control"
                              name="work_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Phone/Mobile</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="work_phone"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Contact Person</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="work_contact"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_contact}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="bg">Email</div>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-4 ">
                            <input
                              type="text"
                              class="form-control"
                              name="work_email"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.work_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.workError}</p>
                      {this.state.workAdd === true && (
                        <span
                          onClick={this.submitWork}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.workUpdate === true && (
                        <span
                          onClick={this.submitUpdateWork}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="h3">Family Members</p>

                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name</td>
                      <td>Relation</td>
                      <td>Nationality</td>
                      <td>Work Place</td>
                      <td>Occupation</td>
                      <td>Mobile</td>
                      <td>Email</td>

                      <td>
                        <span onClick={this.addFamily}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.familyInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.family_name}</td>
                            <td>{s.family_relation}</td>
                            <td>{s.family_nationality}</td>
                            <td>{s.family_institute}</td>
                            <td>{s.family_occupation}</td>
                            <td>{s.family_phone}</td>
                            <td>{s.family_email}</td>

                            <td>
                              <span onClick={() => this.editFamily(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteFamily(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.family === true && (
                  <>
                    <div class="col-lg-3">
                      <div class="bg">Name</div>

                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_name"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_name}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Relationship</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_relation"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_relation}
                        >
                          <option value="">Select One</option>
                          <Relation />
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Nationality</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_nationality"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_nationality}
                        >
                          <option value="">Select Country</option>
                          <Country />
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Still ALive</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_live"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_live}
                        >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Institute/Employer</div>

                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_institute"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_institute}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Industry Type</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_ins_type"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_ins_type}
                        >
                          <Industry />
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="bg">Occupation</div>

                      <div class="mt-4 ">
                        <select
                          class="form-control"
                          name="family_occupation"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_occupation}
                        >
                          <Occupation />
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Phone</div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_phone"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_phone}
                        />
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="bg">Email</div>
                      <div class="mt-4 ">
                        <input
                          type="text"
                          class="form-control"
                          name="family_email"
                          required="required"
                          onChange={this.onChangeData}
                          defaultValue={this.state.family_email}
                        />
                      </div>
                      <p>{this.state.familyError}</p>
                      {this.state.familyAdd === true && (
                        <span
                          onClick={this.submitFamily}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.familyUpdate === true && (
                        <span
                          onClick={this.submitUpdateFamily}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
                <div class="col-lg-12 text-center my-5">
                  <Link to={"/application-one/" + id} class="btn-one">
                    Back
                  </Link>
                  <button type="submit" class="btn-one">
                    save
                  </button>
                  {this.props.steptwo !== null ? (
                    <Link to={"/application-three/" + id} class="btn-one">
                      Next
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    steptwo: state.auth.steptwo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationTwo: (data, ii) => dispatch(applicationTwo(data, ii)),
  applicationTwoData: (data, post) => dispatch(applicationTwoData(data, post)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Test2);
