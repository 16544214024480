import React from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";

import {
  applicationOne,
  applicationOneData,
} from "../../services/actions/AuthAction";
import { Link, Navigate, useParams } from "react-router-dom";
import Country from "../helper/Country";
import ApplicationDataP from "../helper/ApplicationDataP";
import Language from "../helper/Language";
import Relation from "../helper/Relation";
import Occupation from "../helper/Occupation";

class Test1 extends React.Component {
  constructor() {
    super();

    this.state = {
      sector: "",

      sponser: true,
      sponsor_name: "",
      sponsor_word_place: "",
      sponsor_phone: "",
      sponsor_relation: "",
      sponsor_occupation: "",
      sponsor_email: "",
      sponserInfo: [],
      sponserError: "",
      sponserAdd: true,
      sponserUpdate: false,
      sponserItem: null,

      guarante: true,
      guarante_name: "",
      guarante_work_place: "",
      guarante_phone: "",
      guarante_relation: "",
      guarante_occupation: "",
      guarante_email: "",
      guaranteInfo: [],
      guaranteError: "",
      guaranteAdd: true,
      guaranteUpdate: false,
      guaranteItem: null,

      emergency: true,
      emergency_name: "",
      emergency_work_place: "",
      emergency_phone: "",
      emergency_relation: "",
      emergency_occupation: "",
      emergency_email: "",
      emergencyInfo: [],
      emergencyError: "",
      emergencyAdd: true,
      emergencyUpdate: false,
      emergencyItem: null,

      studed_in_china: "",
    };
  }

  addSponser = () => {
    this.setState({ sponser: true });
    this.setState({ sponserAdd: true });
    this.setState({ sponserUpdate: false });
  };

  submitSponser = () => {
    const item = {
      sponsor_name: this.state.sponsor_name,
      sponsor_word_place: this.state.sponsor_word_place,
      sponsor_phone: this.state.sponsor_phone,
      sponsor_relation: this.state.sponsor_relation,
      sponsor_occupation: this.state.sponsor_occupation,
      sponsor_email: this.state.sponsor_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ sponserError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        sponserInfo: [...prevState.sponserInfo, item],
      }));
      this.setState({ sponserError: "" });
      this.setState({ sponser: false });

      this.setState({ sponsor_name: "" });
      this.setState({ sponsor_word_place: "" });
      this.setState({ sponsor_phone: "" });
      this.setState({ sponsor_relation: "" });
      this.setState({ sponsor_occupation: "" });
      this.setState({ sponsor_email: "" });
    }
  };

  submitUpdateSponser = () => {
    const item = {
      sponsor_name: this.state.sponsor_name,
      sponsor_word_place: this.state.sponsor_word_place,
      sponsor_phone: this.state.sponsor_phone,
      sponsor_relation: this.state.sponsor_relation,
      sponsor_occupation: this.state.sponsor_occupation,
      sponsor_email: this.state.sponsor_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ sponserError: "All * mark fields are requird." });
    } else {
      const { sponserInfo } = this.state;
      sponserInfo[this.state.sponserItem] = item;

      this.setState({
        sponserInfo: sponserInfo,
      });

      this.setState({ sponserError: "" });
      this.setState({ sponser: false });

      this.setState({ sponsor_name: "" });
      this.setState({ sponsor_word_place: "" });
      this.setState({ sponsor_phone: "" });
      this.setState({ sponsor_relation: "" });
      this.setState({ sponsor_occupation: "" });
      this.setState({ sponsor_email: "" });
    }
  };

  editSponser(si) {
    this.setState({ sponserAdd: false });
    this.setState({ sponserUpdate: true });
    const ii = this.state.sponserInfo[si];
    this.setState({ sponserItem: si });
    this.setState({ sponser: true });

    this.setState({ sponsor_name: ii.sponsor_name });
    this.setState({ sponsor_word_place: ii.sponsor_word_place });
    this.setState({ sponsor_phone: ii.sponsor_phone });
    this.setState({ sponsor_relation: ii.sponsor_relation });
    this.setState({ sponsor_occupation: ii.sponsor_occupation });
    this.setState({ sponsor_email: ii.sponsor_email });
  }
  deleteSponser(ix) {
    this.state.sponserInfo.splice(ix, 1);
    this.setState({
      sponserInfo: this.state.sponserInfo,
    });
  }
  //garante start
  addGuarante = () => {
    this.setState({ guarante: true });
    this.setState({ guaranteAdd: true });
    this.setState({ guaranteUpdate: false });
  };

  submitGuarante = () => {
    const item = {
      guarante_name: this.state.guarante_name,
      guarante_work_place: this.state.guarante_work_place,
      guarante_phone: this.state.guarante_phone,
      guarante_relation: this.state.guarante_relation,
      guarante_occupation: this.state.guarante_occupation,
      guarante_email: this.state.guarante_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ guaranteError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        guaranteInfo: [...prevState.guaranteInfo, item],
      }));
      this.setState({ guaranteError: "" });
      this.setState({ guarante: false });

      this.setState({ guarante_name: "" });
      this.setState({ guarante_work_place: "" });
      this.setState({ guarante_phone: "" });
      this.setState({ guarante_relation: "" });
      this.setState({ guarante_occupation: "" });
      this.setState({ guarante_email: "" });
    }
  };

  submitUpdateGuarante = () => {
    const item = {
      guarante_name: this.state.guarante_name,
      guarante_work_place: this.state.guarante_work_place,
      guarante_phone: this.state.guarante_phone,
      guarante_relation: this.state.guarante_relation,
      guarante_occupation: this.state.guarante_occupation,
      guarante_email: this.state.guarante_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ guaranteError: "All * mark fields are requird." });
    } else {
      const { guaranteInfo } = this.state;
      guaranteInfo[this.state.guaranteItem] = item;

      this.setState({
        guaranteInfo: guaranteInfo,
      });

      this.setState({ guaranteError: "" });
      this.setState({ guarante: false });

      this.setState({ guarante_name: "" });
      this.setState({ guarante_work_place: "" });
      this.setState({ guarante_phone: "" });
      this.setState({ guarante_relation: "" });
      this.setState({ guarante_occupation: "" });
      this.setState({ guarante_email: "" });
    }
  };

  editGuarante(si) {
    this.setState({ guaranteAdd: false });
    this.setState({ guaranteUpdate: true });
    this.setState({ guaranteItem: si });
    this.setState({ guarante: true });

    const ii = this.state.guaranteInfo[si];
    this.setState({ guarante_name: ii.guarante_name });
    this.setState({ guarante_work_place: ii.guarante_work_place });
    this.setState({ guarante_phone: ii.guarante_phone });
    this.setState({ guarante_relation: ii.guarante_relation });
    this.setState({ guarante_occupation: ii.guarante_occupation });
    this.setState({ guarante_email: ii.guarante_email });
  }
  deleteGuarante(ix) {
    this.state.guaranteInfo.splice(ix, 1);
    this.setState({
      guaranteInfo: this.state.guaranteInfo,
    });
  }
  //garante
  //Emergency stsrt
  addEmergency = () => {
    this.setState({ emergency: true });
    this.setState({ emergencyAdd: true });
    this.setState({ emergencyUpdate: false });
  };

  submitEmergency = () => {
    const item = {
      emergency_name: this.state.emergency_name,
      emergency_work_place: this.state.emergency_work_place,
      emergency_phone: this.state.emergency_phone,
      emergency_relation: this.state.emergency_relation,
      emergency_occupation: this.state.emergency_occupation,
      emergency_email: this.state.emergency_email,
    };
    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ emergencyError: "All * mark fields are requird." });
    } else {
      this.setState((prevState) => ({
        emergencyInfo: [...prevState.emergencyInfo, item],
      }));
      this.setState({ emergencyError: "" });
      this.setState({ emergency: false });

      this.setState({ emergency_name: "" });
      this.setState({ emergency_work_place: "" });
      this.setState({ emergency_phone: "" });
      this.setState({ emergency_relation: "" });
      this.setState({ emergency_occupation: "" });
      this.setState({ emergency_email: "" });
    }
  };

  submitUpdateEmergency = () => {
    const item = {
      emergency_name: this.state.emergency_name,
      emergency_work_place: this.state.emergency_work_place,
      emergency_phone: this.state.emergency_phone,
      emergency_relation: this.state.emergency_relation,
      emergency_occupation: this.state.emergency_occupation,
      emergency_email: this.state.emergency_email,
    };

    Object.keys(item).forEach((key) => {
      if (item[key] === "") {
        delete item[key];
      }
    });
    if (Object.keys(item).length !== 6) {
      this.setState({ emergencyError: "All * mark fields are requird." });
    } else {
      const { emergencyInfo } = this.state;
      emergencyInfo[this.state.emergencyItem] = item;

      this.setState({
        emergencyInfo: emergencyInfo,
      });

      this.setState({ emergencyError: "" });
      this.setState({ emergency: false });

      this.setState({ emergency_name: "" });
      this.setState({ emergency_work_place: "" });
      this.setState({ emergency_phone: "" });
      this.setState({ emergency_relation: "" });
      this.setState({ emergency_occupation: "" });
      this.setState({ emergency_email: "" });
    }
  };

  editEmergency(si) {
    this.setState({ emergencyAdd: false });
    this.setState({ emergencyUpdate: true });
    this.setState({ emergencyItem: si });
    this.setState({ emergency: true });

    const ii = this.state.emergencyInfo[si];
    this.setState({ emergency_name: ii.emergency_name });
    this.setState({ emergency_work_place: ii.emergency_work_place });
    this.setState({ emergency_phone: ii.emergency_phone });
    this.setState({ emergency_relation: ii.emergency_relation });
    this.setState({ emergency_occupation: ii.emergency_occupation });
    this.setState({ emergency_email: ii.emergency_email });
  }
  deleteEmergency(ix) {
    this.state.emergencyInfo.splice(ix, 1);
    this.setState({
      emergencyInfo: this.state.emergencyInfo,
    });
  }
  //Emergency

  checkHandler = (a) => {
    this.setState({ sector: a });
  };

  onChangeData = (e) => {
    const { name, value, type, checked } = e.target;
    type === "checkbox"
      ? this.setState({ [name]: checked })
      : this.setState({ [name]: value });
  };

  onSubmitDetails = (e) => {
    e.preventDefault();
    console.log(this.state.studed_in_china);
  };
  render() {
    var data = JSON.parse(localStorage.getItem("user"));
    return (
      <>
        <UserHeader />
        <div class="">
          <div class="container">
            <div class="row">
              <ApplicationDataP post={6} />
            </div>
            <div class="row mb-5">
              <div class="col-lg-12">
                <p class="display-3 mb-5 fsw-bold">
                  <span class="cor-1"> Personal </span> Information
                </p>
                <p>
                  <span
                    onClick={() => this.checkHandler("Study")}
                    class={
                      this.state.sector === "Study" ? "btn-one" : "btn-three"
                    }
                  >
                    Study
                  </span>
                  <span
                    onClick={() => this.checkHandler("Tourist")}
                    class={
                      this.state.sector === "Tourist" ? "btn-one" : "btn-three"
                    }
                  >
                    Tourist
                  </span>
                  <span
                    onClick={() => this.checkHandler("Work")}
                    class={
                      this.state.sector === "Work" ? "btn-one" : "btn-three"
                    }
                  >
                    Work
                  </span>
                </p>

                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}
              </div>
            </div>
            <form
              method="post"
              onSubmit={this.onSubmitDetails}
              encType="multipart/form-data"
            >
              <input type="hidden" name="user_id" value={data.id} />

              {this.props.stepone !== null ? (
                <input type="hidden" name="hidden_id" value={6} />
              ) : (
                <input type="hidden" name="hidden_id" defaultValue="new" />
              )}
              <div class="row application">
                <div class="col-lg-12">
                  <p class="h3 mb-4">Personal Information</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Family Name</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="family_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> Given Name </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="given_name"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.given_name
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> Full Name </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="full_name"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.full_name
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg"> Chinese Name </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="chinese_name"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.chinese_name
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Gender
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="gender"
                          required="required"
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Nationality <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="nationality"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        {" "}
                        Date Of Birth <span className="colors star">
                          *
                        </span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="date"
                          class="form-control"
                          placeholder=" "
                          name="dob"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        {" "}
                        Country Of Birth <span className="colors star">
                          *
                        </span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select name="birth_country" class="form-control">
                          <option value="">Select Country</option>
                          <Country />
                        </select>{" "}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        {" "}
                        Photo <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          required="required"
                          type="file"
                          class="form-control"
                          accept="image/*"
                          name="photo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Native Language <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          class="form-control"
                          name="native_language"
                          required="required"
                        >
                          <Language />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Marital Status <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          type="text"
                          class="form-control"
                          name="marital_status"
                        >
                          <option value="">Select One</option>
                          <option value="Married">Married</option>
                          <option value="Single">Single</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Religion <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          type="text"
                          class="form-control"
                          name="religion"
                          required="required"
                        >
                          <option value="">Select One</option>
                          <option value="Non Religion">Non Religion</option>
                          <option value="Christion">Christion</option>
                          <option value="Islam">Islam</option>
                          <option value="Buddhism">Buddhism</option>
                          <option value="Mormonism">Mormonism</option>
                          <option value="Atheism">Atheism</option>
                          <option value="Judaism">Judaism</option>
                          <option value="Orthodox Church">
                            Orthodox Church
                          </option>
                          <option value="Hinduism">Hinduism</option>
                          <option value="Other">Other</option>
                          <option value="Taoism">Taoism</option>
                          <option value="Ghost religion">Ghost religion</option>
                          <option value="Protestantism">Protestantism</option>
                          <option value="Confusianism">Confusianism</option>
                          <option value="fetishism">fetishism</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Occupation <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          type="text"
                          class="form-control"
                          name="occupation"
                          required="required"
                        >
                          <Occupation />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Institution Affiliated{" "}
                        <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="institute_affiliated"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">WeChat</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="wechat"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.wechat
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Phone </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="phone"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.phone
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Place Of Birth <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          required="required"
                          name="birth_place"
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country />
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="h3">Correspondence Address</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Home Address </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="p_home_address"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.p_home_address
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">City/Province </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          name="p_city"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.p_city
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Country</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.p_country
                              : ""
                          }
                          name="p_country"
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country
                            select={
                              this.props.stepone !== null
                                ? this.props.stepone.p_country
                                : ""
                            }
                          />
                        </select>

                        {/* <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        name="p_country"
                        defaultValue={
                          this.props.stepone !== null ? this.props.stepone.p_country : ""
                        }
                      /> */}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Zipcode</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="p_zipcode"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.p_zipcode
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Phone</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="p_phone"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.p_phone
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Email</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="p_email"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.p_email
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Current Address</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="c_current_address"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.c_current_address
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">City/Province </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="c_city"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.c_city
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Country</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <select
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.c_country
                              : ""
                          }
                          name="c_country"
                          class="form-control"
                        >
                          <option value="">Select Country</option>
                          <Country
                            select={
                              this.props.stepone !== null
                                ? this.props.stepone.c_country
                                : ""
                            }
                          />
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Zipcode</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="c_cipcode"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.c_cipcode
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Phone</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="c_phone"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.c_phone
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Email</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="c_email"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.c_email
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="h3">Passport and Visa Information</p>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Passport No <span className="colors star">*</span>{" "}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="passport_no"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Old Passport No</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          name="old_passport_no"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">
                        Expiry Date <span className="colors star">*</span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input type="date" name="new_expiry_date" />
                        -
                        <input type="date" name="new_expiry_date" />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div class="bg">Expiration Date</div>
                    </div>
                    <div class="col-md-8">
                      <div class=" ">
                        <input
                          type="date"
                          class="form-control"
                          placeholder=" "
                          name="old_expiry_date"
                          defaultValue={
                            this.props.stepone !== null
                              ? this.props.stepone.old_expiry_date
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 my-5">
                  <p class="h3">Learning Experience In China</p>
                </div>

                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Have you studied in China? </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <span style={{ paddingRight: `12px` }}>
                        <input
                          type="checkbox"
                          name="studed_in_china"
                          onChange={this.onChangeData}
                          value={this.state.studed_in_china}
                        />
                        Yes
                      </span>
                    </div>
                  </div>
                </div>
                {this.state.studed_in_china && (
                  <>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          Visa Type <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <select
                            class="form-control"
                            name="visa_type"
                            required="required"
                          >
                            <option value="">Select</option>
                            <option value="Business/Tourist Visa">
                              Business/Tourist Visa
                            </option>

                            <option value="Work Visa">Work Visa</option>
                            <option value="Student Visa">Student Visa</option>
                            <option value="Exchange Visitor Visa">
                              Exchange Visitor Visa
                            </option>
                            <option value="Transit/Ship Crew Visa">
                              Transit/Ship Crew Visa
                            </option>
                            <option value="Religious Worker Visa">
                              Religious Worker Visa
                            </option>
                            <option value="Domestic Employee Visa">
                              Domestic Employee Visa
                            </option>
                            <option value="Journalist and Media Visa">
                              Journalist and Media Visa
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">Visa No</div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=" "
                            name="visa_no"
                            defaultValue={
                              this.props.stepone !== null
                                ? this.props.stepone.visa_no
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          In China now? <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <span style={{ paddingRight: `12px` }}>
                            <input
                              type="radio"
                              name="is_china_new"
                              required="required"
                              value="Yes"
                            />
                            Yes
                          </span>
                          <span>
                            <input
                              type="radio"
                              name="is_china_new"
                              value="No"
                              checked={
                                this.props.stepone !== null
                                  ? this.props.stepone.is_china_new === "No"
                                    ? "checked"
                                    : null
                                  : null
                              }
                            />
                            No
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          Expiry Date <span className="colors star">*</span>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="date"
                            class="form-control"
                            placeholder=" "
                            name="visa_expairy_date"
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg"> Study Period in China</div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=" "
                            name="study_period_china"
                            defaultValue={
                              this.props.stepone !== null
                                ? this.props.stepone.study_period_china
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-4">
                        <div class="bg">
                          The institution in China that you have studied in
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class=" ">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=" "
                            name="old_china_institute"
                            defaultValue={
                              this.props.stepone !== null
                                ? this.props.stepone.old_china_institute
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="h3">Financial Sponsor's Information</p>
                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name</td>
                      <td>Relationship</td>
                      <td>Work Place</td>
                      <td>Occupation</td>
                      <td>Phone</td>
                      <td>Email</td>
                      <td>
                        <span onClick={this.addSponser}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.sponserInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.sponsor_name}</td>
                            <td>{s.sponsor_relation}</td>
                            <td>{s.sponsor_word_place}</td>
                            <td>{s.sponsor_occupation}</td>
                            <td>{s.sponsor_phone}</td>
                            <td>{s.sponsor_email}</td>
                            <td>
                              <span onClick={() => this.editSponser(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteSponser(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.sponser === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Name <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="sponsor_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Work Place <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="sponsor_word_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_word_place}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Phone <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="sponsor_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Relationship <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="sponsor_relation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Occupation <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="sponsor_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">
                            Email <span className="colors star">*</span>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              required="required"
                              name="sponsor_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.sponsor_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.sponserError}</p>

                      {this.state.sponserAdd === true && (
                        <span
                          onClick={this.submitSponser}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.sponserUpdate === true && (
                        <span
                          onClick={this.submitUpdateSponser}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="h3">Guarantor's Information</p>
                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name</td>
                      <td>Relationship</td>
                      <td>Work Place</td>
                      <td>Occupation</td>
                      <td>Phone</td>
                      <td>Email</td>
                      <td>
                        <span onClick={this.addGuarante}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.guaranteInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.guarante_name}</td>
                            <td>{s.guarante_relation}</td>
                            <td>{s.guarante_work_place}</td>
                            <td>{s.guarante_occupation}</td>
                            <td>{s.guarante_phone}</td>
                            <td>{s.guarante_email}</td>
                            <td>
                              <span onClick={() => this.editGuarante(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteGuarante(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.guarante === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Name</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="guarante_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Work Place</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="guarante_work_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_work_place}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Phone s</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="guarante_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Relationship</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="guarante_relation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Occupation</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="guarante_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Email</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="guarante_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.guarante_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.guaranteError}</p>
                      {this.state.guaranteAdd === true && (
                        <span
                          onClick={this.submitGuarante}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.guaranteUpdate === true && (
                        <span
                          onClick={this.submitUpdateGuarante}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div class="col-lg-12 my-5">
                  <p class="h3">Emergency Contact</p>
                  <table className="table" style={{ background: `#92c0d2` }}>
                    <thead>
                      <td>Name</td>
                      <td>Relationship</td>
                      <td>Work Place</td>
                      <td>Occupation</td>
                      <td>Phone</td>
                      <td>Email</td>
                      <td>
                        <span onClick={this.addEmergency}> Add</span>
                      </td>
                    </thead>
                    <tbody>
                      {this.state.emergencyInfo.map((s, i) => {
                        return (
                          <tr key={i}>
                            <td>{s.emergency_name}</td>
                            <td>{s.emergency_relation}</td>
                            <td>{s.emergency_work_place}</td>
                            <td>{s.emergency_occupation}</td>
                            <td>{s.emergency_phone}</td>
                            <td>{s.emergency_email}</td>
                            <td>
                              <span onClick={() => this.editEmergency(i)}>
                                Edit
                              </span>
                              <span onClick={() => this.deleteEmergency(i)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.emergency === true && (
                  <>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Name</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              name="emergency_name"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Work Place</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="emergency_work_place"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_work_place}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Phone</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="emergency_phone"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Relationship</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="emergency_relation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_relation}
                            >
                              <Relation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Occupation</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <select
                              class="form-control"
                              name="emergency_occupation"
                              required="required"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_occupation}
                            >
                              <Occupation />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <div class="bg">Email</div>
                        </div>
                        <div class="col-md-8">
                          <div class=" ">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              name="emergency_email"
                              onChange={this.onChangeData}
                              defaultValue={this.state.emergency_email}
                            />
                          </div>
                        </div>
                      </div>
                      <p>{this.state.emergencyError}</p>
                      {this.state.emergencyAdd === true && (
                        <span
                          onClick={this.submitEmergency}
                          className="btn btn-success"
                        >
                          Add
                        </span>
                      )}
                      {this.state.emergencyUpdate === true && (
                        <span
                          onClick={this.submitUpdateEmergency}
                          className="btn btn-success"
                        >
                          Update
                        </span>
                      )}
                    </div>
                  </>
                )}
                {this.props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {this.props.sucMsg}
                  </div>
                )}

                <div class="col-lg-12 text-center my-5">
                  {this.props.steponedone === "update" && (
                    <button type="submit" class="btn-one">
                      Save
                    </button>
                  )}
                  {this.props.steponeid > 0 ? (
                    <Link to={"/application-two/" + 6} class="btn-one">
                      Next
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    stepone: state.auth.stepone,
    steponedone: state.auth.steponedone,
    steponeid: state.auth.steponeid,

    family_name: state.auth.family_name,
    given_name: state.auth.given_name,
    full_name: state.auth.full_name,
    chinese_name: state.auth.chinese_name,
    gender: state.auth.gender,
    nationality: state.auth.nationality,
    dob: state.auth.dob,
    birth_country: state.auth.birth_country,
    photo: state.auth.photo,
    native_language: state.auth.native_language,
    marital_status: state.auth.marital_status,
    religion: state.auth.religion,
    occupation: state.auth.occupation,
    institute_affiliated: state.auth.institute_affiliated,
    wechat: state.auth.wechat,
    phone: state.auth.phone,
    birth_place: state.auth.birth_place,
    p_home_address: state.auth.p_home_address,
    p_city: state.auth.p_city,
    p_country: state.auth.p_country,
    p_zipcode: state.auth.p_zipcode,
    p_phone: state.auth.p_phone,
    p_email: state.auth.p_email,
    c_current_address: state.auth.c_current_address,
    c_city: state.auth.c_city,
    c_country: state.auth.c_country,
    c_cipcode: state.auth.c_cipcode,
    c_phone: state.auth.c_phone,
    c_email: state.auth.c_email,
    passport_no: state.auth.passport_no,
    old_passport_no: state.auth.old_passport_no,
    new_expiry_date: state.auth.new_expiry_date,
    old_expiry_date: state.auth.old_expiry_date,
    studed_in_china: state.auth.studed_in_china,
    visa_type: state.auth.visa_type,
    visa_no: state.auth.visa_no,
    is_china_new: state.auth.is_china_new,
    visa_expairy_date: state.auth.visa_expairy_date,
    study_period_china: state.auth.study_period_china,
    old_china_institute: state.auth.old_china_institute,
    sponsor_name: state.auth.sponsor_name,
    sponsor_word_place: state.auth.sponsor_word_place,
    sponsor_phone: state.auth.sponsor_phone,
    sponsor_relation: state.auth.sponsor_relation,
    sponsor_occupation: state.auth.sponsor_occupation,
    sponsor_email: state.auth.sponsor_email,
    guarante_name: state.auth.guarante_name,
    guarante_work_place: state.auth.guarante_work_place,
    guarante_phone: state.auth.guarante_phone,
    guarante_relation: state.auth.guarante_relation,
    guarante_occupation: state.auth.guarante_occupation,
    guarante_email: state.auth.guarante_email,
    emergency_name: state.auth.emergency_name,
    emergency_work_place: state.auth.emergency_work_place,
    emergency_phone: state.auth.emergency_phone,
    emergency_relation: state.auth.emergency_relation,
    emergency_occupation: state.auth.emergency_occupation,
    emergency_email: state.auth.emergency_email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationOne: (data, ii) => dispatch(applicationOne(data, ii)),
  applicationOneData: (data, post) => dispatch(applicationOneData(data, post)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Test1);
