import React, { useEffect } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  applicationTwo,
  applicationTwoData,
} from "../../services/actions/AuthAction";
import { Link, useParams } from "react-router-dom";
import ApplicationDataP from "../helper/ApplicationDataP";

function Application2(props) {
  var data = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data);
    props.applicationTwo(data);
  };

  let { id } = useParams();
  useEffect(() => {
    props.applicationTwoData(data.id, id);
  }, []);

  return (
    <>
      <UserHeader />
      <div class="">
        <div class="container">
          <div class="row">
            <ApplicationDataP post={id} />
          </div>
          <div class="row mb-5">
            <div class="col-lg-12">
              <p class="display-3 mb-5 fsw-bold">
                <span class="cor-1"> Academic </span> Information
              </p>

              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("user_id")} value={data.id} />
            <input type="hidden" {...register("hidden_id")} value={id} />

            <div class="row application">
              <div class="col-lg-12">
                <p class="h3 mb-4">Language Proficiency</p>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">English Proficiency | 英语水平</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        {...register("english_proficiency")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.english_proficiency
                            : ""
                        }
                      >
                        <option value="Please Choose">Please Choose</option>
                        <option value="None">None</option>
                        <option value="Poor">Poor</option>
                        <option value="Average">Average</option>
                        <option value="Good">Good</option>
                        <option value="Very good">Very good</option>
                        <option value="Excellent">Excellent</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> IELTS</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("ielts")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.ielts : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> TOEFL </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("toefl")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.toefl : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> GRE </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("gre")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.gre : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg"> GMAT </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("gmat")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.gmat : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Chinese Proficiency | 汉语水平</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("chinese_proficiency")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.chinese_proficiency
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">HSK Level</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        {...register("hsk_level")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.hsk_level : ""
                        }
                      >
                        <option value="None">None</option>
                        <option value="New HSK LEVEL 1">New HSK LEVEL 1</option>
                        <option value="New HSK LEVEL 2">New HSK LEVEL 2</option>
                        <option value="New HSK LEVEL 3">New HSK LEVEL 3</option>
                        <option value="New HSK LEVEL 4">New HSK LEVEL 4</option>
                        <option value="New HSK LEVEL 5">New HSK LEVEL 5</option>
                        <option value="New HSK LEVEL 6">New HSK LEVEL 6</option>

                        <option value="HSK Base level C(score level:1)">
                          HSK Base level C(score level:1)
                        </option>
                        <option value="HSK Base level B(score level:2)">
                          HSK Base level B(score level:2)
                        </option>
                        <option value="HSK Base level A(score level:3)">
                          HSK Base level A(score level:3)
                        </option>

                        <option value="HSK Junior level C(score level:3)">
                          HSK Junior level C(score level:3)
                        </option>
                        <option value="HSK Junior level B(score level:4)">
                          HSK Junior level B(score level:4)
                        </option>
                        <option value="HSK Junior level A(score level:5)">
                          HSK Junior level A(score level:5)
                        </option>

                        <option value="HSK Secondary level C(score level:6)">
                          HSK Secondary level C(score level:6)
                        </option>
                        <option value="HSK Secondary level B(score level:7)">
                          HSK Secondary level B(score level:7)
                        </option>
                        <option value="HSK Secondary level A(score level:8)">
                          HSK Secondary level A(score level:8)
                        </option>

                        <option value="HSK Senior level C(score level:9)">
                          HSK Senior level C(score level:9)
                        </option>
                        <option value="HSK Senior level B(score level:10)">
                          HSK Senior level B(score level:10)
                        </option>
                        <option value="HSK Senior level A(score level:11)">
                          HSK Senior level A(score level:11)
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">HSKK Scores</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("hskk_score")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.hskk_score : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">HSK No </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("hsk_no")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.hsk_no : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Listening Score </div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("listen_score")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.listen_score
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5">
                <p class="h3">Education Background</p>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div class="bg">Highest Degree</div>

                  <div class="mt-4">
                    <input
                      type="text"
                      class="form-control"
                      id=" "
                      placeholder=" "
                      {...register("highest_degree")}
                      defaultValue={
                        props.steptwo !== null
                          ? props.steptwo.highest_degree
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="bg">School name</div>

                  <div class="mt-4 ">
                    <input
                      type="text"
                      class="form-control"
                      id=" "
                      placeholder=" "
                      {...register("schoolname")}
                      defaultValue={
                        props.steptwo !== null ? props.steptwo.schoolname : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="bg">From To End</div>

                  <div class="mt-4 ">
                    From
                    <input
                      type="date"
                      {...register("form_to_end_from")}
                      defaultValue={
                        props.steptwo !== null
                          ? props.steptwo.form_to_end_from
                          : ""
                      }
                    />
                    to <br />
                    End
                    <input
                      type="date"
                      // class="form-control"
                      id=" "
                      placeholder=" "
                      {...register("form_to_end")}
                      defaultValue={
                        props.steptwo !== null ? props.steptwo.form_to_end : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="bg">Certificate Type</div>

                  <div class="mt-4 ">
                    <input
                      type="text"
                      class="form-control"
                      id=" "
                      placeholder=" "
                      {...register("certificate_type")}
                      defaultValue={
                        props.steptwo !== null
                          ? props.steptwo.certificate_type
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                {/* <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Highest Degree</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("highest_degree")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.highest_degree
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Degree</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("degree1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.degree1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("degree2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.degree2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("degree3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.degree3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                {/* <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">School name</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("schoolname")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.schoolname : ""
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">School name</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("schoolname1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.schoolname1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("schoolname2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.schoolname2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("schoolname3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.schoolname3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                {/* <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">From To End</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("form_to_end")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">From To End</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      From
                      <input
                        type="date"
                        {...register("form_to_end1_from")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end1_from
                            : ""
                        }
                      />
                      to <br />
                      End
                      <input
                        type="date"
                        // class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("form_to_end1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-md-12">
                    <div class=" ">
                      From
                      <input
                        type="date"
                        {...register("form_to_end2_from")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end2_from
                            : ""
                        }
                      />
                      to <br />
                      End
                      <input
                        type="date"
                        // class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("form_to_end2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      From
                      <input
                        type="date"
                        {...register("form_to_end3_from")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end3_from
                            : ""
                        }
                      />
                      to <br />
                      End
                      <input
                        type="date"
                        // class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("form_to_end3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.form_to_end3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                {/* <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Certificate Type</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("certificate_type")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.certificate_type
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Contact</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("contact1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.contact1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("contact2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.contact2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("contact3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.contact3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 my-5">
                <p class="h3">Work Experience</p>
              </div>
              <div class="col-lg-3">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Company</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("company1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.company1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("company2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.company2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("company3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.company3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Occupation</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("occupation1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.occupation1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("occupation2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.occupation2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("occupation3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.occupation3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">From To End</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("com_from_to_end1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.com_from_to_end1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("com_from_to_end2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.com_from_to_end2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("com_from_to_end3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.com_from_to_end3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Contact</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("com_contact1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.com_contact1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("com_contact2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.com_contact2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("com_contact3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.com_contact3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5">
                <p class="h3">Family Members</p>
              </div>
              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("name1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.name1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("name2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.name2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("name3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.name3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("name4")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.name4 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Relationship</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("relation1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.relation1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("relation2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.relation2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("relation3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.relation3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("relation4")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.relation4 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Nationality</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("nationality1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.nationality1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("nationality2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.nationality2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("nationality3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.nationality3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("nationality4")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.nationality4
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Work Place</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("work_place1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.work_place1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("work_place2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.work_place2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("work_place3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.work_place3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("work_place4")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.work_place4
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Occupation</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_occupation1")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.f_occupation1
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_occupation2")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.f_occupation2
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_occupation3")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.f_occupation3
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_occupation4")}
                        defaultValue={
                          props.steptwo !== null
                            ? props.steptwo.f_occupation4
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Phone</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_phone1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_phone1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_phone2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_phone2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_phone3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_phone3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_phone4")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_phone4 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="bg">Email</div>
                  </div>
                  <div class="col-md-12">
                    <div class="mt-4 ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_email1")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_email1 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_email2")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_email2 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_email3")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_email3 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id=" "
                        placeholder=" "
                        {...register("f_email4")}
                        defaultValue={
                          props.steptwo !== null ? props.steptwo.f_email4 : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}
              <div class="col-lg-12 text-center my-5">
                <Link to={"/application-one/" + id} class="btn-one">
                  Back
                </Link>
                <button type="submit" class="btn-one">
                  save
                </button>
                {props.steptwo !== null ? (
                  <Link to={"/application-three/" + id} class="btn-one">
                    Next
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    steptwo: state.auth.steptwo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applicationTwo: (data, ii) => dispatch(applicationTwo(data, ii)),
  applicationTwoData: (data, post) => dispatch(applicationTwoData(data, post)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application2);
