import React, { useEffect } from "react";
import Footer from "../header/Footer";
import UserHeader from "../header/UserHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  agentHomeQuery,
  deteleApply,
  agentFilter,
} from "../../services/actions/AuthAction";
import Degree2 from "../helper/Degree2";
import Country from "../helper/Country";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

function AgentHome(props) {
  var data = JSON.parse(localStorage.getItem("user"));
  var { id } = useParams();
  const url = window.location.pathname.split("/").pop();

  useEffect(() => {
    props.agentHomeQuery(id);
  }, [url]);
  const bal = {
    // borderRadius: "50%",
    marginLeft: `60px`,
    float: `left`,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    props.agentFilter(data);
  };

  function deteleApplyAction(ss) {
    const shouldRemove = window.confirm("Are you sure want to delete?");
    if (shouldRemove) {
      props.deteleApply(ss);
    }
  }
  function makeReset() {}
  console.log(props.home);
  return (
    <>
      <UserHeader />

      <div className="container my-3">
        <div className="rowwww">
          <div className="fast_track">My Student</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row p-5">
              <div className="col-md-4">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Degree</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        type="text"
                        class="form-control"
                        {...register("mejor_degree")}
                      >
                        <option value="">Select One</option>
                        <Degree2 />
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Nationality</div>
                  </div>
                  <div class="col-md-8">
                    <div class=" ">
                      <select
                        type="text"
                        class="form-control"
                        {...register("nationality")}
                      >
                        <option value="">Select One</option>
                        <Country />
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Program</div>
                  </div>
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      {...register("program")}
                      placeholder="Enter Program Name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="row mb-4">
                  <div class="col-md-4">
                    <div class="bg">Passport</div>
                  </div>
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      {...register("passport")}
                      placeholder="Enter Passport Number"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="row mb-4">
                  <div class="col-md-4"></div>
                  <div class="col-md-8">
                    <button className="btn btn-info">Search</button>
                    {/* <button className="btn btn-danger" onClick={() => makeReset()}>
                      Reset
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <Link
                  to="/agent/all"
                  className={id !== "all" ? `btn btninactive` : `btn btnactive`}
                >
                  All Student {props.count.all}
                </Link>
                <Link
                  to="/agent/incomplete"
                  className={
                    id !== "incomplete" ? `btn btninactive` : `btn btnactive`
                  }
                >
                  Incomplete {props.count.uncompleted}
                </Link>
                <Link
                  to="/agent/submitted"
                  className={
                    id !== "submitted" ? `btn btninactive` : `btn btnactive`
                  }
                >
                  Submitted {props.count.submitted}
                </Link>
                <Link
                  to="/agent/inprogress"
                  className={
                    id !== "inprogress" ? `btn btninactive` : `btn btnactive`
                  }
                >
                  In-Progress {props.count.inprogress}
                </Link>
                <Link
                  to="/agent/preadmission"
                  className={
                    id !== "preadmission" ? `btn btninactive` : `btn btnactive`
                  }
                >
                  Pre-Admission {props.count.preadmission}
                </Link>
                <Link
                  to="/agent/admitted"
                  className={
                    id !== "admitted" ? `btn btninactive` : `btn btnactive`
                  }
                >
                  Admitted {props.count.admitted}
                </Link>
                <Link
                  to="/agent/rejectd"
                  className={
                    id !== "rejectd" ? `btn btninactive` : `btn btnactive`
                  }
                >
                  Rejectd {props.count.rejected}
                </Link>
              </div>
            </div>
          </form>

          <div class="table-responsive my-5">
            <table class="table table-bordered text-center ">
              <thead className="cocc">
                <tr>
                  <th scope="col">S/L</th>
                  <th scope="col">Application ID</th>
                  <th scope="col">Name / Passport</th>
                  <th scope="col">Course / Degree</th>
                  <th scope="col">University Name / Expected Scholarship</th>
                  <th scope="col"> Status</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Manage</th>
                </tr>
              </thead>
              <tbody>
                {props.home.map((d, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>{d.status === "0" ? "" : d.unique_id}</td>
                      <td>
                        {d.full_name}
                        {/* <br />
                        {d.passport_no} */}
                      </td>
                      <td>
                        {d.mejor_subject}
                        <br />
                        {d.mejor_degree}
                      </td>
                      <td>
                        {d.university}
                        <br />
                        {d.expected_school}
                      </td>
                      <td>
                        {d.status === "0" && "Incomplete"}
                        {d.status === "2" && "Pending"}
                        {d.status === "3" && "In-Progress"}
                        {d.status === "4" && "Pre-Admission"}
                        {d.status === "5" && "Admitted"}
                        {d.status === "6" && "Rejected"}
                      </td>
                      <td>
                        {d.payment === "0" && "Application fee to be paid"}
                        {d.payment === "2" && "Application fee paid"}
                        {d.payment === "3" && "Admission fee to be paid"}
                        {d.payment === "4" && "Admission fee paid"}
                      </td>
                      <td>
                        <Link
                          className="btn btn-info text-white"
                          to={`/apply-view/old/` + d.appid + "/2/" + d.post_id}
                        >
                          View
                        </Link>
                        {d.status == "0" && (
                          <>
                            <Link
                              className="btn btn-success text-white"
                              to={
                                `/apply-one/old/` + d.appid + "/2/" + d.post_id
                              }
                            >
                              Edit
                            </Link>

                            <button
                              onClick={() => deteleApplyAction(d.appid)}
                              className="btn btn-danger"
                            >
                              Delete
                            </button>
                          </>
                        )}
                        <Link
                          className="btn btn-secondary text-white"
                          to={`/payment/` + d.appid}
                        >
                          Payment
                        </Link>
                        <a
                          className="btn btn-primary text-white"
                          href={d.doc}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Application Form
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    home: state.auth.home,
    count: state.auth.count,
  };
};

export default connect(mapStateToProps, {
  agentHomeQuery,
  deteleApply,
  agentFilter,
})(AgentHome);
