import React from "react";
import { Link } from "react-router-dom";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import { useForm } from "react-hook-form";
import { contact } from "../../services/actions/AuthAction";
import { connect } from "react-redux";

function Contact(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => props.contact(data);

  return (
    <>
      <Navber />

      <div class="container py-5 my-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="row">
            <div class="col-lg-10">
              <p class="h1 mb-5">Contact Us...</p>
              <p>
                We're a top growing education consultant in China Since 2015,
                Asapallince has been helping students with One-Stop service to
                get admitted to renowned Chinese Universities accompanied by
                various facilities like: Scholarships, Wavers on Tuition Fees,
                Accommodation Fees for overseas students.
              </p>
            </div>

            <div class="col-lg-6 mx-auto">
              {props.sucMsg !== null && (
                <div class="alert alert-primary" role="alert">
                  {props.sucMsg}
                </div>
              )}

              <div class="contact-area pt-5">
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Name</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput1"
                        {...register("name", { required: true })}
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Mail</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        {...register("email", { required: true })}
                      />
                      {errors.email && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <textarea
                        placeholder="Masseage"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        {...register("message", { required: true })}
                      ></textarea>
                      {errors.message && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-md-12 text-end">
                    <div class=" ">
                      <button type="submit" class="btn-two">
                        Masseage
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
  };
};

export default connect(mapStateToProps, { contact })(Contact);
