import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { universityDetails } from "../../services/actions/AuthAction";
import Footer from "../header/Footer";
import Navber from "../header/Navber";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function UniversityDetails1(props) {
  let { id } = useParams();

  useEffect(() => {
    props.universityDetails(id);
  }, []);

  return (
    <>
      <Navber />
      <div className="py-5 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto">
              {props.uniDetai.image !== undefined && (
                <OwlCarousel
                  items={1}
                  className="owl-carousel owl-carousel-three owl-theme owl-loaded owl-drag text-center"
                  loop
                  nav
                  margin={8}
                  autoplay
                >
                  {JSON.parse(props.uniDetai.image).map((u, i) => {
                    return (
                      <div className="p-5 pt-0">
                        <div className="card-two">
                          <img
                            className="m-auto img-fluid"
                            src={JSON.parse(props.uniDetai.image)[i]}
                          />
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
            <div className="col-lg-6">
              <div className="ps-5">
                {props.errMsg !== null && (
                  <div class="alert alert-danger" role="alert">
                    {props.errMsg}
                  </div>
                )}

                <p className="h1 mb-5">{props.uniDetai.name}</p>
                <p>Chinese Name: 四川大学</p>

                {props.uniDetai.province !== null && (
                  <p>Province: {props.uniDetai.province}</p>
                )}
                {props.uniDetai.city !== null && (
                  <p>City: {props.uniDetai.city}</p>
                )}
                {props.uniDetai.founded !== null && (
                  <p>Founded: {props.uniDetai.founded}</p>
                )}
                {props.uniDetai.cost !== null && (
                  <p>Cost of living: {props.uniDetai.cost} USD/year</p>
                )}
                {props.uniDetai.scholarship !== null && (
                  <p>Scholarships: {props.uniDetai.scholarship}</p>
                )}
                {props.uniDetai.world_rank !== null && (
                  <p>
                    World Rank : {props.uniDetai.world_rank} | Country Rank :{" "}
                    {props.uniDetai.country_rank}
                  </p>
                )}
                {props.uniDetai.student !== null && (
                  <p>
                    University students : {props.uniDetai.student} + |
                    International Student : {props.uniDetai.student} +
                  </p>
                )}
                <div className="mt-5">
                  <Link to="/all-application" className="btn-one">
                    Apply
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Overview
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Accommodation
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Application-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="Application-tab-pane"
                    aria-selected="false"
                  >
                    Application Guideline
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Scholarship-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Scholarship-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="Scholarship-tab-pane"
                    aria-selected="false"
                  >
                    Scholarship
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Programs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Programs-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="Programs-tab-pane"
                    aria-selected="false"
                  >
                    Programs
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="International-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#International-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="International-tab-pane"
                    aria-selected="false"
                  >
                    International Student
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Canteen-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Canteen-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="Canteen-tab-pane"
                    aria-selected="false"
                  >
                    Canteen
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="video-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#video-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="video-tab-pane"
                    aria-selected="false"
                  >
                    Video
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.overview}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.accommodation}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="Application-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Application-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.guideline}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="Scholarship-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Scholarship-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.scholarship_details}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="Programs-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Programs-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.program_details}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="International-tab-pane"
                  role="tabpanel"
                  aria-labelledby="International-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.int_student}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="Canteen-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Canteen-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    <p>{props.uniDetai.canteen}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="video-tab-pane"
                  role="tabpanel"
                  aria-labelledby="video-tab"
                  tabindex="0"
                >
                  <div className="contents mt-5 pt-4">
                    {(props.uniDetai.youtube !== null) &
                    (
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/{props.uniDetai.youtube}"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="my-5 text-center">
              <Link to="/all-application" className="btn-one">
                Apply
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    uniDetai: state.auth.uniDetai,
  };
};

const mapDispatchToProps = (dispatch) => ({
  universityDetails: (data) => dispatch(universityDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniversityDetails1);
