import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { forgott } from "../services/actions/AuthAction";
import Navber from "./header/Navber";
import Footer from "./header/Footer";

function Forgot(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    props.forgott(data);
  };
  return (
    <>
      <Navber />

      {/* <!-- login area start   --> */}
      <div class="container py-5 my-5">
        <div class="login-area">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="row">
              <div class="col-lg-7 mx-auto text-center">
                {props.sucMsg !== null && (
                  <div class="alert alert-primary" role="alert">
                    {props.sucMsg}
                    <p>
                      NB. If Forgot mail not in your Inbox folder, Please check
                      "Spam" folder also.
                    </p>
                  </div>
                )}
                <div class="pb-5 mb-5">
                  <img src="image/logo.png" alt="" class="img-fluid" />
                </div>
                <div class="row mb-4">
                  <div class="col-md-3">
                    <div class="bg">Email Address</div>
                  </div>
                  <div class="col-md-9">
                    <div class=" ">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder=" "
                        {...register("email", { required: true })}
                      />
                      {errors.email && <span>This field is required</span>}
                    </div>
                  </div>
                </div>

                <div class="row pt-4">
                  <div class="col-md-8 text-start my-auto">
                    <p class="small mb-0">
                      Haven't any account?{" "}
                      <Link to="/register"> Please Registration ! </Link>
                    </p>
                  </div>
                  <div class="col-md-4">
                    <div class=" ">
                      <button type="submit" class="btn btn-info">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <!-- login area end   --> */}

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
  };
};

export default connect(mapStateToProps, { forgott })(Forgot);
