function RefType(props) {
  return (
    <>
      <option value="个人推荐 Personal Recommendation">
        个人推荐 Personal Recommendation
      </option>
      <option value="机构推荐 Agent Recommendation">
        机构推荐 Agent Recommendation
      </option>
      <option value="自荐 Self Recommendation">自荐 Self Recommendation</option>
      <option value="合作院校 Cooperative Colleges Recommendation">
        合作院校 Cooperative Colleges Recommendation
      </option>
    </>
  );
}
export default RefType;
