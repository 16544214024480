export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET = "RESET";
export const FORGOT = "FORGOT";
export const FAILED = "FAILED";
export const LOADING = "LOADING";
export const REG = "REG";
export const VERIFY = "VERIFY";
export const CHENGE_PASSWORD = "CHENGE_PASSWORD";
export const CONTACT = "CONTACT";
export const UNIVERSITY = "UNIVERSITY";
export const UNIVERSITYDETAILS = "UNIVERSITYDETAILS";
export const BLOG = "BLOG";
export const BLOGDETAILS = "BLOGDETAILS";
export const GALLERY = "GALLERY";
export const ALLGALLERY = "ALLGALLERY";
export const NOTIFICATION = "NOTIFICATION";
export const SMSS = "SMSS";
export const NOTIFICATIONDATA = "NOTIFICATIONDATA";
export const MARKREAD = "MARKREAD";
export const ABOUT = "ABOUT";
export const INDEXABOUT = "INDEXABOUT";
export const SERVICE = "SERVICE";
export const CIRCULER = "CIRCULER";
export const CIRCULER_SHOW = "CIRCULER_SHOW";
export const CIRCULER_DETAILS = "CIRCULER_DETAILS";
export const APPLICATIONDATA = "APPLICATIONDATA";

export const STEPONE = "STEPONE";
export const STEPONEDATA = "STEPONEDATA";

export const STEPTWO = "STEPTWO";
export const STEPTWODATA = "STEPTWODATA";

export const STEPTHREE = "STEPTHREE";
export const STEPTHREEDATA = "STEPTHREEDATA";

export const FOOTER = "FOOTER";
export const HOMEDATA = "HOMEDATA";
export const HOMEDATA2 = "HOMEDATA2";
export const PROFILE = "PROFILE";
export const PROFILE_INFO = "PROFILE_INFO";
export const APPLICATION_VIEW = "APPLICATION_VIEW";
export const ACCEPT = "ACCEPT";
export const DELETE = "DELETE";

export const UNKNOWNONE = "UNKNOWNONE";
export const UNKNOWNONEDATA = "UNKNOWNONEDATA";

export const UNKNOWNTWO = "UNKNOWNTWO";
export const UNKNOWNTWODATA = "UNKNOWNTWODATA";

export const PAYMENT = "PAYMENT";
export const PAYMENT_SEND = "PAYMENT_SEND";

export const APPLY = "APPLY";

export const APPLY_ONE = "APPLY_ONE";
export const APPLY_ONE_DATA = "APPLY_ONE_DATA";
export const APPLY_ONE_DATA2 = "APPLY_ONE_DATA2";
export const APPLY_TWO = "APPLY_TWO";
export const APPLY_TWO_DATA = "APPLY_TWO_DATA";
export const APPLY_THREE = "APPLY_THREE";
export const APPLY_THREE_DATA = "APPLY_THREE_DATA";
export const APPLY_FOUR = "APPLY_FOUR";
export const APPLY_FOUR_DATA = "APPLY_FOUR_DATA";
export const APPLY_FINAL = "APPLY_FINAL";
export const OLDMESSAGE = "OLDMESSAGE";
