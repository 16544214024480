import React, { useEffect } from "react";

import { footer } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Footer(props) {
  useEffect(() => {
    props.footer();
  }, []);

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row g-3 g-sm-5">
            <div className="col-lg-3">
              {/* <img
                src="http://127.0.0.1:8000/public/blog/logo.png"
                alt=""
                className="img-fluid"
              />
              <p>{props.text}</p> */}

              {/* <div className="social">
                <a
                  rel="noopener noreferrer"
                  href={props.social[0]}
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook-f"></i>{" "}
                </a>
                <a
                  href={props.social[1]}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter"></i>{" "}
                </a>
                <a
                  href={props.social[2]}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>{" "}
                </a>
                <a
                  href={props.social[3]}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin-in"></i>{" "}
                </a>
              </div> */}
            </div>
            <div className="col-lg-3">
              <p className="h5">USEFUL LINKS</p>
              <ul>
                {props.usefull.map((u, i) => {
                  return (
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href={u[1]}>
                        {u[0]}
                      </a>
                    </li>
                  );
                })}
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    download="https://expxian.xyz/admin/public/blog/physical%20exam.pdf"
                    href="https://expxian.xyz/admin/public/blog/physical%20exam.pdf"
                  >
                    physical exam.pdf
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-3">
              <p className="h5">MORE</p>
              <ul>
                {props.more.map((u, i) => {
                  return (
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href={u[1]}>
                        {u[0]}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div> */} 
            <div className="col-lg-3">
              <p className="h5">Contact Us</p>
              <ul>
                <li>NEED HELP? </li>
                <li>Toll Free Customer Care </li>
                <li>
                  <span>{props.phone}</span>
                </li>
                <li>Need live support?</li>
                <li>
                  <span>{props.email} </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* <a href="#">Terms & Conditions </a>
              <a href="#">Privacy Policy </a>
              <a href="#">Help Center </a> */}
            </div>
            <div className="col-lg-4 text-start text-sm-end mt-4 mt-sm-0">
              <p className="small mb-0 text-white">
                ©2023.Xi'an Exploring Chang'an Cultural Exchange Co., Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="go-top">
        <i className="fa-solid fa-arrow-up"></i>
      </div>

      {/* <!--     overly search--> */}
      <div id="myOverlay" className="overlay">
        <span
          className="closebtn"
          onclick="closeSearch()"
          title="Close Overlay"
        >
          ×
        </span>
        <div className="overlay-content">
          <form action="/action_page.php">
            <input type="text" placeholder="Search.." name="search" />
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    text: state.auth.text,
    social: state.auth.social,
    usefull: state.auth.usefull,
    more: state.auth.more,
    phone: state.auth.fphone,
    email: state.auth.email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  footer: () => dispatch(footer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
