import React, { useEffect } from "react";
import {
  applyOneDataGet2,
  applyOneDataGet3,
} from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function ApplyData(props) {
  var data = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    var url = window.location.href.split("/");

    props.insertIds != 0
      ? props.applyOneDataGet3(props.insertIds)
      : props.applyOneDataGet3(props.data);

    // if (data.type === 1) {
    //   if (props.pageType === "newtablee") {
    //     // props.applyOneDataGet2();
    //   }

    //   if (url[5] > 0) {
    //     // props.applyOneDataGet3(insertIds);
    //     console.log(props.ids, url[5]);
    //   } else {
    //     console.log(props.ids, 0);
    //     // props.applyOneDataGet2();
    //   }
    // }
    // if (data.type === 2) {
    //   if (props.data > 0) {
    //     props.applyOneDataGet3(props.data);
    //   }
    // }
  }, [props.insertIds]);

  // console.log(props.ids);
  return (
    <div class="col-lg-5 mx-auto text-center ">
      {props.photo !== "" ? (
        <img
          src={props.photo}
          alt=""
          class="img-fluid"
          width="50%"
          height="50%"
        />
      ) : (
        <img
          src="/image/download.jpeg"
          alt=""
          class="img-fluid"
          width="50%"
          height="50%"
        />
      )}

      <p class="h5">
        {props.full_name !== "" ? props.full_name : "Applicant Name"}
      </p>
      <p class="h5">
        {props.passport_no !== "" ? props.passport_no : " Passport"} |{" "}
        {props.p_country !== "" ? props.p_country : " Country"}
      </p>
      <p class="h5">
        {props.datasta !== "0"
          ? props.programe !== ""
            ? props.programe + " | "
            : "Programe |"
          : ""}
        {props.payment === "0" && "Application fee to be paid"}
        {props.payment === "2" && "Application fee paid"}
        {props.payment === "3" && "Admission fee to be paid"}
        {props.payment === "4" && "Admission fee paid"}
      </p>
      <p class="h5">
        Status :{" "}
        <span class="text-primary">
          {props.datasta === "0" && "Incomplete"}
          {props.datasta === "2" && "Submitted"}
          {props.datasta === "3" && "In-Progress"}
          {props.datasta === "4" && "Pre-Admission"}
          {props.datasta === "5" && "Admitted"}
          {props.datasta === "6" && "Rejectd"}
        </span>
      </p>
      {/* <p class="py-4">
        {props.ids > 0 && (
          <Link to={`/apply-view/` + props.ids} class="btn-one">
            View Summary!
          </Link>
        )}
      </p> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    full_name: state.auth.dataname,
    passport_no: state.auth.datapassport,
    p_country: state.auth.datacountry,
    programe: state.auth.dataprogramme,
    payment: state.auth.datapayment,
    photo: state.auth.dataphoto,
    ids: state.auth.dataid,
    datasta: state.auth.datastatus,
    insertIds: state.auth.insertID,
  };
};

export default connect(mapStateToProps, {
  applyOneDataGet2,
  applyOneDataGet3,
})(ApplyData);
