function Occupation(props) {
  return (
    <>
      <option value="">清选择 Please Choose</option>
      <option value="职员 clerk">职员 clerk</option>
      <option value="学生 Student">学生 Student</option>
      <option value="教师 Teacher">教师 Teacher</option>
      <option value="医生 Doctor">医生 Doctor</option>
      <option value="工人 Worker">工人 Worker</option>
      <option value="军人 Soldier">军人 Soldier</option>
      <option value="工程师 Engineer">工程师 Engineer</option>
      <option value="学者 Scholar">学者 Scholar</option>
      <option value="家庭主妇 Housewife">家庭主妇 Housewife</option>
      <option value="退休 Retirement">退休 Retirement</option>
      <option value="经理 Manager">经理 Manager</option>
      <option value="政府公务人员 Civil Servants">
        政府公务人员 Civil Servants
      </option>
      <option value="农民 Farmer">农民 Farmer</option>
      <option value="其他 Other">其他 Other</option>
      <option value="警察 Policemen">警察 Policemen</option>
      <option value="牧民 Herders">牧民 Herders</option>
      <option value="记者 Journalist">记者 Journalist</option>
    </>
  );
}
export default Occupation;
