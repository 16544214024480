import React, { useEffect } from "react";
import { university } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function University(props) {
  useEffect(() => {
    props.university();
  }, []);
  console.log(props.univ);
  return (
    <>
      {props.univ !== null &&
        props.univ.map((u, i) => {
          return (
            <div key={i} className="col-lg-4">
              <div className="card-two">
                <img
                  src={JSON.parse(u.image)[0]}
                  alt=""
                  className="img-fluid"
                />
                <p className="h5 my-4">
                  <Link to={`university/` + u.id}>{u.name}</Link>
                </p>
                <p>{u.overview.substr(0, 150)}</p>
                <div className="mt-4 text-end">
                  <Link to={`university/` + u.id} className="btn-one">
                    Details
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.auth.loading,
    errMsg: state.auth.errMsg,
    sucMsg: state.auth.sucMsg,
    univ: state.auth.university,
  };
};

export default connect(mapStateToProps, { university })(University);
